import axios from "axios";
import { BaseUrl } from "../../services/InsightsViewAPI";

const mapFilterToList = (data) => {
    const mappedList = [
        {
            id: 1,
            attributeName: "Indication",
            subAttributes: data.therapy_area,
            level_one_key: "therapy_area",
            level_one_key_name: "therapy_area_name",
            level_two_key: "indication",
            level_two_key_name: "indication_name",
            isNested: true,
            isMultiLevel: true,
            isSearchAvailable: true,
            searchedTerm: ""
        },
        {
            id: 2,
            attributeName: "Study Type",
            subAttributes: data.study_type_category,
            level_one_key: "study_type_level_1",
            level_one_key_name: "study_type_level_1_name",
            level_two_key: "study_type_level_2",
            level_two_key_name: "study_type_level_2_name",
            level_three_key: "study_type_level_3",
            level_three_key_name: "study_type_level_3_name",
            isMultiLevel: true,
            isNested: true,
            isSearchAvailable: true,
            searchedTerm: ""
        },
        {
            id: 3,
            attributeName: "Publication Year Range",
            subAttributes: [],
            level_one_key: "",
            level_one_key_name: "",
            level_two_key: "",
            level_two_key_name: "",
            isNested: false,
            isSearchAvailable: false,
            searchedTerm: ""
        },
        {
            id: 4,
            attributeName: "Region",
            subAttributes: data.region,
            level_one_key: "region",
            level_one_key_name: "region_name",
            level_two_key: "country",
            level_two_key_name: "country_name",
            isNested: true,
            isMultiLevel: true,
            isSearchAvailable: true,
            searchedTerm: ""
        },
        {
            id: 5,
            attributeName: "Author",
            subAttributes: data.author,
            level_one_key: "author",
            level_one_key_name: "author_name",
            level_two_key: "",
            level_two_key_name: "",
            isNested: false,
            isSearchAvailable: true,
            searchedTerm: ""
        },
        {
            id: 6,
            attributeName: "Data Source",
            subAttributes: data.data_source,
            level_one_key: "data_source",
            level_one_key_name: "data_source_name",
            level_two_key: "",
            level_two_key_name: "",
            isNested: false,
            isSearchAvailable: false,
            searchedTerm: ""
        },
        {
            id: 7,
            attributeName: "Drug",
            subAttributes: data.drug,
            level_one_key: "drug",
            level_one_key_name: "drug_name",
            level_two_key: "",
            level_two_key_name: "",
            isNested: false,
            isSearchAvailable: true,
            searchedTerm: ""
        },
        {
            id: 8,
            attributeName: "Procedure",
            subAttributes: data.procedure,
            level_one_key: "procedure",
            level_one_key_name: "procedure_name",
            level_two_key: "",
            level_two_key_name: "",
            isNested: false,
            isSearchAvailable: true,
            searchedTerm: ""
        },
        {
            id: 9,
            attributeName: "Therapy",
            subAttributes: data.therapy,
            level_one_key: "therapy",
            level_one_key_name: "therapy_name",
            level_two_key: "",
            level_two_key_name: "",
            isNested: false,
            isSearchAvailable: true,
            searchedTerm: ""
        },
        {
            id: 10,
            attributeName: "Patient Population",
            subAttributes: data.patient_population_type,
            level_one_key: "patient_population_type",
            level_one_key_name: "patient_population_type_name",
            level_two_key: "patient_population_type_values_data",
            level_two_key_name: "patient_population_value_name",
            isNested: true,
            isSearchAvailable: false,
            searchedTerm: ""
        },
        {
            id: 11,
            attributeName: "Outcome",
            subAttributes: data.outcome_types,
            level_one_key: "outcome_type",
            level_one_key_name: "outcome_type_name",
            level_two_key: "outcome_values_data",
            level_two_key_name: "outcome_value_name",
            isNested: true,
            isSearchAvailable: true,
            searchedTerm: ""
        },
        {
            id: 12,
            attributeName: "Biomarkers",
            subAttributes: data.Biomarkers,
            level_one_key: "Biomarkers",
            level_one_key_name: "biomarker_name",
            level_two_key: "",
            level_two_key_name: "",
            isNested: false,
            isSearchAvailable: true,
            searchedTerm: ""
        },
        {
            id: 13,
            attributeName: "Statistical Analysis",
            subAttributes: data.statistical_analysis,
            level_one_key: "statistical_analysis",
            level_one_key_name: "statistical_analysis_name",
            level_two_key: "",
            level_two_key_name: "",
            isNested: false,
            isSearchAvailable: true,
            searchedTerm: ""
        },
    ];
    return mappedList;
};

const getAdvancedSearchList = async () => {
    const response = await axios.get(`${BaseUrl}/api/advanceSearchDropdown`, { withCredentials: true });
    return response.data.result;
};

export { mapFilterToList, getAdvancedSearchList };
