import React, { useEffect, useState } from "react";
import { Modal, Form, Row, Col, Select, Input } from "antd";
import "./SavedAnalysisModal.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleSaveNewAnalysis,
  onAnalysisUpdated,
  setSelectedAnalysisData,
  toggleCheckAnalysisSave,
  resetAll,
  setGlobalRawList,
  setAdvanceFilterDropdownList,
  setSelectedStudyListData,
  removeFromList,
  setPagination,
} from "../../../reducer/EICCommonSlice";
import {
  saveAnalysis,
  fetchAnalysisById,
  getStoryBoardNameList,
  addToStoryBoard,
} from "../../../services/InsightsViewAPI";
import {
  CurrentDateTime,
  showNotification,
} from "../../../shared/utils/CommonUtil";
import { isEqual } from "lodash";
import { useNavigate } from "react-router-dom";
import { CloseIcon } from "../../../assets/icons/svg";
import {
  getAdvancedSearchList,
  mapFilterToList,
} from "../../advanced-filter-view/AdvancedFilterViewHelper";

const { TextArea } = Input;
const { confirm } = Modal;

const SavedAnalysisModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [saveLoading, setSaveLoading] = useState(false);

  const {
    EICCommon: {
      searchTerm,
      selectedAdvanceFilters,
      compareStudiesData,
      isAnalysisSaveModalConfig,
      checkAnalysisSaveConfig,
      selectedAnalysisData,
      advanceFilterDropdownList,
    },
  } = useSelector((state) => state);

  const [openCreateAnalysisConfig, setOpenCreateAnalysisConfig] = useState(
    isAnalysisSaveModalConfig
  );
  const [analysisForm] = Form.useForm();
  const [initalFormData, setInitialFormData] = useState({});
  const [therapyList, setTherapyList] = useState([]);
  const [isCheckAnalysisSave, setIsCheckAnalysisSave] = useState(
    checkAnalysisSaveConfig
  );

  useEffect(() => {
    if (isAnalysisSaveModalConfig.analysisData) {
      setInitialFormData(isAnalysisSaveModalConfig.analysisData);
      analysisForm.setFieldsValue(isAnalysisSaveModalConfig.analysisData);
    } else {
      analysisForm.setFieldsValue({
        analysisName: null,
        therapyArea: null,
        description: null,
      });
    }
    setSaveLoading(false);
    setOpenCreateAnalysisConfig(isAnalysisSaveModalConfig);
  }, [isAnalysisSaveModalConfig]);

  useEffect(() => {
    getTherapyList();
  }, []);

  useEffect(() => {
    setIsCheckAnalysisSave(checkAnalysisSaveConfig);
    onAnalysisSaveCheck(checkAnalysisSaveConfig);
  }, [checkAnalysisSaveConfig]);

  const getTherapyList = async () => {
    let newTherapyList = [];
    let data = advanceFilterDropdownList;
    if (!data) {
      const data = await getAdvancedSearchList();
      dispatch(setAdvanceFilterDropdownList(data));
    }
    const rowList = await mapFilterToList(data);
    dispatch(setGlobalRawList(rowList));
    rowList?.forEach((item) => {
      if (item.level_one_key === "therapy_area") {
        newTherapyList = item.subAttributes || [];
        return;
      }
    });
    setTherapyList([...newTherapyList]);
  };

  const onAnalysisSubmit = async (values) => {
    try {
      setSaveLoading(true);
      let payload = {
        analysis_name: values.analysisName,
        analysis_description: values.description || "",
        therapy_area: values.therapyArea,
        search_text: "",
        edit: false,
        analysis_id: "",
        study_filters: {},
        chart_filters: {},
        comparison_data: [],
        story_board_section_ids: [],
        excluded_study_ids: [],
      };

      if (
        openCreateAnalysisConfig.source === "saved-analysis" &&
        openCreateAnalysisConfig.mode === "edit"
      ) {
        payload.edit = true;
        payload.analysis_id = openCreateAnalysisConfig.analysisData.analysisId;
        delete payload.search_text;
        delete payload.study_filters;
        delete payload.chart_filters;
        delete payload.comparison_data;
        delete payload.story_board_section_ids;
        delete payload.excluded_study_ids;
      } else if (openCreateAnalysisConfig.source === "analysis-save") {
        payload.search_text = searchTerm;
        payload.study_filters = selectedAdvanceFilters;
        payload.comparison_data = compareStudiesData.selectData.map(
          (item) => item.pm_id
        );
        if (openCreateAnalysisConfig.mode === "edit") {
          payload.edit = true;
          payload.analysis_id =
            openCreateAnalysisConfig.analysisData.analysisId;
          delete payload.chart_filters;
          delete payload.story_board_section_ids;
        }
      } else if (
        [
          "confirm-save-analysis",
          "add-to-storyboard",
          "storyboard-container",
          "exclude-studies",
        ].indexOf(openCreateAnalysisConfig.source) > -1 &&
        openCreateAnalysisConfig.mode === "add"
      ) {
        payload.search_text = searchTerm;
        payload.study_filters = selectedAdvanceFilters;
        payload.comparison_data = compareStudiesData.selectData.map(
          (item) => item.pm_id
        );
        if (openCreateAnalysisConfig.source === "exclude-studies") {
          payload.excluded_study_ids = openCreateAnalysisConfig.excludeStudyIds;

          // logic to remove excluded studies from compare
          const newCompareData = compareStudiesData.selectData.filter(
            (item) =>
              !openCreateAnalysisConfig.excludeStudyIds.includes(item.pm_id)
          );
          const newCompareKeys = newCompareData.map((i) => i.key);
          dispatch(
            removeFromList({
              selectKey: [...newCompareKeys],
              selectData: [...newCompareData],
            })
          );

          payload.comparison_data = newCompareData.map((item) => item.pm_id);
        }
      }

      const response = await saveAnalysis(payload);
      if (response?.status?.toLowerCase() === "success") {
        await handleSaveAnalysisResult(response);
        showNotification(
          "success",
          "Success",
          "Successfully saved the analysis."
        );
      } else {
        setSaveLoading(false);
        showNotification("error", "Error", "Failed saved the analysis.");
      }
    } catch (error) {
      console.log(error);
    }
    setSaveLoading(false);
  };

  const handleSaveAnalysisResult = async (resp) => {
    try {
      switch (openCreateAnalysisConfig.source) {
        case "saved-analysis":
          dispatch(onAnalysisUpdated(CurrentDateTime()));
          break;
        case "analysis-save":
        case "add-to-storyboard":
        case "storyboard-container":
        case "exclude-studies":
          const payload = {
            analysis_id: resp.analysis_id,
          };
          const response = await fetchAnalysisById(payload);
          if (response?.status?.toLowerCase() === "success") {
            const anaysisData = response?.result?.length
              ? response.result[0]
              : {};
            if (openCreateAnalysisConfig.source === "add-to-storyboard") {
              await saveStoryboardData(
                { ...openCreateAnalysisConfig },
                anaysisData
              );
            } else if (openCreateAnalysisConfig.source === "exclude-studies") {
              dispatch(setPagination(1)); // set page number to 1 after exclude studies
              dispatch(
                setSelectedStudyListData({ selectKey: [], selectData: [] })
              );
            }
            dispatch(setSelectedAnalysisData(anaysisData));
          }
          break;
        case "confirm-save-analysis":
          await resetAnalysisAndNavigate(isCheckAnalysisSave);
          break;
        default:
          break;
      }

      closeSaveModal();
    } catch (error) {
      console.log(error);
    }
  };

  const closeSaveModal = () => {
    setInitialFormData({});
    analysisForm.resetFields();
    setOpenCreateAnalysisConfig({ isOpen: false });
    dispatch(
      toggleSaveNewAnalysis({
        isOpen: false,
        mode: "add",
        editData: null,
        source: "",
      })
    );
    dispatch(
      toggleCheckAnalysisSave({ isCheck: false, source: "", navToPath: null })
    );
  };

  const onAnalysisSaveCheck = (checkConfig) => {
    if (!checkConfig.isCheck) {
      return;
    }
    if (isNewAnalysis()) {
      saveConfirmModal(true, checkConfig);
      return;
    }
    if (!selectedAnalysisData) {
      resetAnalysisAndNavigate(checkConfig, false);
      return;
    }
    const newData = {
      search_text: searchTerm,
      study_filters: selectedAdvanceFilters,
      comparison_data: compareStudiesData.selectData.map((item) => item.pm_id),
    };
    const oldData = {
      search_text: selectedAnalysisData?.search_text,
      study_filters: selectedAnalysisData?.study_filters,
      comparison_data: selectedAnalysisData?.comparison_data,
    };
    if (isEqual(oldData, newData)) {
      if (checkConfig?.navToPath) resetAnalysisAndNavigate(checkConfig, true);
    } else {
      saveConfirmModal(false, checkConfig);
    }
  };

  const isNewAnalysis = () => {
    return (
      !selectedAnalysisData &&
      (searchTerm?.trim() !== "" ||
        Object.keys(selectedAdvanceFilters).length > 0 ||
        compareStudiesData?.selectData?.length > 0)
    );
  };

  const saveConfirmModal = (isAdd = true, checkConfig) => {
    const analysisData = {
      analysisName: searchTerm || null,
      therapyArea: null,
      description: null,
    };
    confirm({
      title: (
        <>
          <strong className="confirm-title">Confirm Action</strong>
        </>
      ),
      okText: "Save",
      okType: "primary",
      icon: null,
      mask: true,
      autoFocusButton: "cancel",
      wrapClassName: "analysis-save-or-update-confirm",
      closeIcon: (
        <>
          <CloseIcon />
        </>
      ),
      content: (
        <span>
          Are you sure you want to leave this page? <br />
          The changes you made will not be saved.
        </span>
      ),
      centered: true,

      onOk: async () => {
        if (isAdd) {
          analysisForm.setFieldsValue(analysisData);
          setOpenCreateAnalysisConfig({
            isOpen: true,
            mode: "add",
            source: "confirm-save-analysis",
          });
        } else {
          await updateAnalysisData();
          resetAnalysisAndNavigate(checkConfig);
        }
      },
      onCancel: () => {
        resetAnalysisAndNavigate(checkConfig);
      },
    });
  };

  const updateAnalysisData = async () => {
    const payload = {
      edit: true,
      analysis_id: selectedAnalysisData?.analysis_id,
      search_text: searchTerm,
      study_filters: selectedAdvanceFilters,
      comparison_data: compareStudiesData.selectData.map((item) => item.pm_id),
    };
    const response = await saveAnalysis(payload);
    if (response?.status?.toLowerCase() === "success") {
      showNotification(
        "success",
        "Success",
        "Successfully saved the analysis."
      );
    } else {
      showNotification("error", "Error", "Failed update the analysis.");
    }
  };

  const resetAnalysisAndNavigate = async (checkConfig, isReload = true) => {
    dispatch(
      toggleCheckAnalysisSave({ isCheck: false, source: "", navToPath: null })
    );
    dispatch(setSelectedAnalysisData(null));
    if (checkConfig?.navToPath) {
      sessionStorage.setItem("search_value", "");
      setTimeout(() => {
        if (isReload) {
          dispatch(resetAll());
        }
      }, 500);
      navigate(checkConfig?.navToPath);
    }
  };

  const saveStoryboardData = async (config, anaysisData) => {
    try {
      if (
        !config ||
        config.source !== "add-to-storyboard" ||
        !config.imageData ||
        !anaysisData
      )
        return;
      const storyBoardList = await getStoryBoard(anaysisData);
      const storyboardId =
        storyBoardList.storyboards[0].story_board_id ||
        storyBoardList.storyboards[0].storyboard_id;
      const payload = {
        storyboards: [
          {
            storyboard_id: storyboardId,
            sections: [
              {
                section_id:
                  storyBoardList.storyboards[0].sections[0].section_id,
                section_name:
                  storyBoardList.storyboards[0].sections[0].section_name,
                section_elements: [
                  {
                    element_id: "",
                    edit: false,
                    element_type: "image",
                    content: config.imageData.split(",")[1],
                    properties: {
                      search: searchTerm ? searchTerm : "",
                      tab: config.currentTab ? config.currentTab : "",
                    },
                  },
                ],
              },
            ],
          },
        ],
        analysis_id: anaysisData?.analysis_id,
      };
      const data = await addToStoryBoard(payload);
    } catch (error) {
      console.log(error);
    }
  };

  const getStoryBoard = async (anaysisData) => {
    if (!anaysisData || !anaysisData.analysis_id) return;
    const payload = {
      analysis_id: anaysisData?.analysis_id,
    };
    const data = await getStoryBoardNameList(payload);
    return data;
  };

  return (
    <>
      <Modal
        title={
          openCreateAnalysisConfig.mode === "add"
            ? "Create New Analysis"
            : "Rename Analysis"
        }
        centered
        open={openCreateAnalysisConfig.isOpen}
        okText={
          openCreateAnalysisConfig.mode === "add" ? "Create Analysis" : "Save"
        }
        onOk={() => analysisForm.submit()}
        onCancel={() => closeSaveModal()}
        width={1000}
        className="create-new-analysis-modal"
        destroyOnClose={true}
        maskClosable={false}
        focusTriggerAfterClose={false}
        forceRender
        okButtonProps={{ loading: saveLoading }}
        zIndex={1001}
      >
        <h3 className="heading">
          {openCreateAnalysisConfig.mode === "add"
            ? "Create a New Analysis"
            : "Rename Analysis"}
        </h3>
        <Form
          form={analysisForm}
          layout="vertical"
          requiredMark={false}
          onFinish={onAnalysisSubmit}
          initialValues={initalFormData}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="Name*"
                required
                name={"analysisName"}
                rules={[
                  {
                    required: true,
                    message: "Analysis Name is mandatory",
                  },
                ]}
              >
                <Input
                  placeholder="Analysis Name"
                  autoComplete="off"
                  maxLength={100}
                />
              </Form.Item>
              <Form.Item
                label="Therapy Area*"
                required
                name={"therapyArea"}
                rules={[
                  {
                    required: true,
                    message: "Therapy Area is mandatory",
                  },
                ]}
              >
                <Select placeholder={"Select Therapy Area"}>
                  {therapyList?.map((item) => (
                    <Select.Option
                      key={item.therapy_area_name}
                      value={item.therapy_area_name}
                    >
                      {item.therapy_area_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Short Description" name={"description"}>
                <TextArea
                  rows={5}
                  placeholder={"Short Description"}
                  className="desc-input"
                  maxLength={250}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default SavedAnalysisModal;
