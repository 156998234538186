import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import dayjs from "dayjs";
import NotificationHeader from "./NotificationHeader";
import NotificationBody from "./NotificationBody";
import "./NotificationLayout.scss";
import { getNotifications } from "../../services/InsightsViewAPI";

const NotificationLayout = () => {
  const [startDate, setStartDate] = useState(
    `${dayjs().startOf("week").format("YYYY-MM-DD")}`
  );
  const [endDate, setEndDate] = useState(
    `${dayjs().endOf("week").format("YYYY-MM-DD")}`
  );
  const [therapy, setTherapy] = useState([]);
  const [selectedTherapy, setSelectedTherapy] = useState([]);
  const [sort, setSort] = useState("DESC");
  const [loader, setLoader] = useState(true);
  const [notificationData, setNotificationData] = useState([]);
  const handleSortChange = () => {
    if (sort === "DESC") {
      setSort("ASC");
    } else {
      setSort("DESC");
    }
  };
  const handleDatePickerChange = (date, dateString) => {
    setStartDate(`${dayjs(date).startOf("week").format("YYYY-MM-DD")}`);
    setEndDate(`${dayjs(date).endOf("week").format("YYYY-MM-DD")}`);
  };
  const getNotificationsData = async () => {
    setLoader(true);
    const payload = {
      start_date: startDate,
      end_date: endDate,
      therapy_area: therapy,
      sort_by: sort,
    };
    const response = await getNotifications(payload);
    setNotificationData(response);
    setLoader(false);
  };
  const handleTherapyChange = (value) => {
    setTherapy(value);
  };
  const onApplyTherapyArea = () => {
    getNotificationsData();
    setSelectedTherapy(therapy);
  };
  const onCancelTherapyArea = () => {
    setTherapy(selectedTherapy);
  };
  useEffect(() => {
    getNotificationsData();
  }, [startDate]);
  const { notifications } = notificationData;
  const total = notifications?.[0];
  const notificationsData = notifications?.[1];
  const spinnerStyle = !notificationsData
    ? { margin: "13% 47%" }
    : { margin: "-10% auto" };
  const totalNotifications = total?.total_notification;
  return (
    <div className="notification-container">
      <NotificationHeader
        totalNotifications={totalNotifications}
        handleDatePickerChange={handleDatePickerChange}
        handleTherapyChange={handleTherapyChange}
        getNotificationsData={getNotificationsData}
        handleSortChange={handleSortChange}
        onApplyTherapyArea={onApplyTherapyArea}
        onCancelTherapyArea={onCancelTherapyArea}
        therapy={therapy}
        sort={sort}
        startDate={startDate}
        selectedTherapy={selectedTherapy}
      />
      <Spin spinning={loader} size="large" style={spinnerStyle}>
        {notificationsData && (
          <NotificationBody
            notificationsData={notificationsData}
            sort={sort}
            totalNotifications={totalNotifications}
          />
        )}
      </Spin>
    </div>
  );
};

export default NotificationLayout;
