import React from "react";
import SavedAnalysisOnFilter from "./saved-analysis-filter/saved-analysis-on-filter/SavedAnalysisOnFilter";
import SavedAnalysisFilterDropdown from "./saved-analysis-filter/saved-analysis-filter-dropdown/SavedAnalysisFilterDropdown";
import SavedAnalysisFilter from "./saved-analysis-filter/SavedAnalysisFilter";
import SavedAnalysisRender from "./saved-analysis-render/SavedAnalysisRender";
import SavedAnalysisSorter from "./saved-analysis-sorter/SaveAnalysisSorter";
import SavedAnalysisFiltersData from "./saved-analysis-filter/saved-analysis-filters-data/SavedAnalysisFiltersData";
import Icon from "@ant-design/icons";
import { TableFilterIcon } from "../../../assets/icons/svg";

const SavedAnalysisColumns = (columnsData, tableData, actionRender, onTableActions) =>
  columnsData.map((data) => {
    return {
      title: data.title,
      key: data.key,
      dataIndex: data.dataIndex,
      render: data.render
        ? (item, record) => SavedAnalysisRender(data.key, item, record, actionRender, onTableActions)
        : null,
      width: data.width,
      defaultSortOrder: data.defaultSortOrder,
      sorter: data.sorter
        ? (a, b) => SavedAnalysisSorter(data.key, a, b)
        : false,
      filterIcon: data.filterIcon
        ? () => <Icon component={TableFilterIcon} />
        : false,
      filterDropdown: data.filterDropdown
        ? ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            const dropDownResult = SavedAnalysisFilter(
              data.key,
              setSelectedKeys,
              selectedKeys,
              confirm
            );
            return (
              <SavedAnalysisFilterDropdown
                dropDownValue={dropDownResult}
                key={data.key}
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
              />
            );
          }
        : false,

      filters: data.filters
        ? SavedAnalysisFiltersData(data.key, tableData)
        : false,
      filterSearch: data.filterSearch,
      onFilter: data.onFilter
        ? (value, record) => SavedAnalysisOnFilter(data.key, value, record)
        : false,
      filterMode: data.filterMode ? "tree" : "menu",
      align: data.align || 'left',
      fixed: data.fixed || false,
    };
  });
export default SavedAnalysisColumns;
