import React, { useState, useEffect } from "react";
import { Modal, List, Card, Collapse } from "antd";
import Icon from "@ant-design/icons";
import VideoPlayer from "../../../shared/video-player/VideoPlayer";
import { getDemoVideosList } from "./DemoVideosHelper";
import { PlayButton } from "../../../assets/icons/svg";

import "./DemoVideosModal.scss";

const DemoVideosModal = ({ showModal, handleClose }) => {
  const { Meta } = Card;
  const [demoVideos, setDemoVideos] = useState([]);
  const [videoURL, setVideoURL] = useState("");
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const showVideoPlyer = (url) => {
    setVideoURL(url);
    setShowVideoPlayer(true);
  };
  const closeVideoPlayer = () => {
    setShowVideoPlayer(false);
    setVideoURL("");
  };
  useEffect(() => {
    async function getDemoVideos() {
      const response = await getDemoVideosList();
      setDemoVideos(response);
    }
    getDemoVideos();
  }, []);
  return (
    <div>
      <Modal
        title="Demo Videos"
        open={showModal}
        onCancel={handleClose}
        footer={null}
        className="demo-video-modal"
        width={1000}
        bodyStyle={{ height: "500px", overflowY: "auto" }}
        centered
        maskClosable={false}
      >
        {showVideoPlayer && (
          <VideoPlayer
            showVideoPlayer={showVideoPlayer}
            handleCloseClick={closeVideoPlayer}
            url={videoURL}
          />
        )}
        <Collapse defaultActiveKey={["1"]}>
          {demoVideos &&
            demoVideos.map((file, index) => {
              return (
                <Collapse.Panel
                  header={file?.file_name}
                  key={(index + 1).toString()}
                >
                  <List
                    grid={{ gutter: 16, column: 3 }}
                    dataSource={
                      file?.children?.length > 0
                        ? [file, ...file?.children]
                        : [file]
                    }
                    renderItem={(item) => (
                      <List.Item>
                        <Card
                          hoverable
                          cover={
                            <>
                              <img
                                alt={item?.file_name}
                                src={item?.image_url}
                                style={{ height: "140px" }}
                              />
                              <div class="demo-video-overlay"></div>
                              <Icon
                                component={PlayButton}
                                className="demo-play-button"
                              />
                            </>
                          }
                          onClick={() => showVideoPlyer(item?.file_url)}
                          className="demo-video-card-title"
                        >
                          <Meta title={item?.file_name} />
                        </Card>
                      </List.Item>
                    )}
                  />
                </Collapse.Panel>
              );
            })}
        </Collapse>
      </Modal>
    </div>
  );
};

export default DemoVideosModal;
