import React, { useState } from "react";
import {
  Button,
  Col,
  Modal,
  Row,
  Dropdown,
  Menu,
} from "antd";
import {
  DeleteIcon,
  MenuIcon,
  StoryboardArchiveIcon,
} from "../../../assets/icons/svg";
import { deleteStoryBoard, getUserStoryboards } from "../../../services/InsightsViewAPI";
import { setStoryBoardData } from "../../../reducer/EICCommonSlice";
import { useDispatch, useSelector} from "react-redux";
import "./SingleClipView.scss";

const SingleClipView = (props) => {
  const dispatch = useDispatch();
  const [expandModalVisible, setExpandModalVisible] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [deleteOperLoader, setDeleteOperLoader] = useState(false);

  const { storyboardID, sectionID, storyboardOwner } = props;
  const clipViewData = props.clipData;

  const selectedAnalysisData = useSelector(
    (state) => state.EICCommon.selectedAnalysisData
  );

  const menu = (
    <Menu id="clip-view-menu">
      {/* <Menu.Item>
        <CopyIcon /> 
        <span className="show-more-text">Copy</span>
      </Menu.Item> */}
      <Menu.Item onClick={() => setExpandModalVisible(true)}>
        <StoryboardArchiveIcon />
        <span className="show-more-text">See Full Screen</span>
      </Menu.Item>
      <Menu.Item 
        onClick={() => setIsRemoveModalOpen(true)} 
        disabled={storyboardOwner ? false : true}
        >
        <DeleteIcon />
        <span className="show-more-text">Delete Snapshot</span>
      </Menu.Item>
    </Menu>
  );

  const handleRemoveOk = async () => {
    setDeleteOperLoader(true);
    let payload = {
      storyboards: [
        {
          storyboard_id: storyboardID,
          sections: [
            {
              // section_name: "",
              section_id: sectionID,
              delete: false,
              section_elements: [clipViewData.element_id]
            },
          ],
        },
      ],
      analysis_id: selectedAnalysisData?.analysis_id,
    };

    const response = await deleteStoryBoard(payload);
    if (response?.status === "success") {
      const response = await getUserStoryboards({ analysis_id: selectedAnalysisData?.analysis_id, });
      dispatch(setStoryBoardData(response));
    }
    setIsRemoveModalOpen(false);
    setDeleteOperLoader(false);
  };

  return (
    <>
      <div style={{ border: "1px solid #DEDCDE", margin: 10, height: 306 }}>
        <div className='clipview-details'>
          <p className='clipview-date'>
            Added: {clipViewData?.properties?.modified_date}

            {/* <div style={{ border: "1px solid #DEDCDE", margin: 10, height: 306 }}>
        <div> */}

            <span style={{ float: "right" }}>
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                placement="bottomRight"
              >
                <Button style={{ border: 0 }}>
                  <MenuIcon />
                </Button>
              </Dropdown>
            </span>
          </p>
          {clipViewData?.properties?.tab != 'compare_studies' && clipViewData?.properties?.search ?
            <p className='clipview-analysis'>
              Analysis Name: {clipViewData?.properties?.search}
            </p> : ''}
        </div>
        <div>
          <img
            style={{
              height: clipViewData?.properties?.tab == 'compare_studies' ? 222 : '100%',
              width: "100%", padding: 5, maxHeight: 250
            }}
            src={clipViewData.content}
            alt=""
          />
        </div>
      </div>

      <Modal
        title=""
        centered
        visible={expandModalVisible}
        onOk={() => setExpandModalVisible(false)}
        width={1200}
        onCancel={() => setExpandModalVisible(false)}
        okText="Close"
        cancelButtonProps={{ style: { display: "none" } }}
        closable={false}
      >
        <Row>
          <Col span={24}>
            <img
              style={{ height: "100%", width: "100%", padding: 5 }}
              src={clipViewData.content}
              alt=""
            />
          </Col>
        </Row>
      </Modal>

      <Modal open={isRemoveModalOpen} footer={null} closable={false}>
        <div className="delete-modal-title">Delete Snapshot?</div>

        <div className="modal-body">
          Are you sure you want to remove Snapshot? You cannot undo this
          action.
        </div>

        <div className="button-container">
          <Button
            // className="delete-action"
            style={{ marginRight: "15px" }}
            onClick={handleRemoveOk}
            loading={deleteOperLoader}
          >
            Yes, Delete
          </Button>
          <Button type='primary' onClick={() => setIsRemoveModalOpen(false)}>
            Don't Delete
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default SingleClipView;
