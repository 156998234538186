import { isString, uniqueId } from "lodash";

const CompareStudiesTableData = (data, colum) => {

  const getUniqueKey = () => {
    return uniqueId('compare-row-');
  }

  const nct_ids = data.map(i => i.nct_id);

  const armsData = data?.reduce((acc, val) => {
    if(val.nct_id) {
      acc[val.nct_id] = val.arm_data;
    }
    return acc;
  }, {});
  
  const getArmDetails = () => {
    let maxArmCount = 0, armDataList = [];
    data.forEach(e => { 
      maxArmCount = e.arm_data.length > maxArmCount? e.arm_data.length : maxArmCount; 
    });
    if(maxArmCount > 0) {
      let i = 0;
      while (i < maxArmCount) {
        const armName = {
          attributes: 'Arm Name',
          className: 'arm-name-row',
          key: getUniqueKey(),
        };
        const treatment = {
          attributes: 'Treatment',
          className: 'arm-treatment-row',
          key: getUniqueKey(),
        };
        const armType = {
          attributes: 'Arm Type',
          className: 'arm-type-row',
          key: getUniqueKey(),
        };
        nct_ids?.forEach(el => {
          armName[el] = armsData[el]?.[i]?.arm_name;
          treatment[el] = armsData[el]?.[i]?.arm_treatment || [];
          armType[el] = armsData[el]?.[i]?.arm_type || [];
          armName['children'] = [armType, treatment];
        });
        armDataList.push(armName);
        i++;
      }
    }
    return armDataList;
  };

  const endpointExtractedKey = 'extracted_name_standardized'; // extracted_name || extracted_name_standardized
  const valueTypeKey = 'value_type';
  const armResultMapping = data?.reduce((acc, val) => {
    if(val.nct_id) {
      acc[val.nct_id] = {
        armResultData: val.arm_results,
        armResultmap: val.arm_results?.reduce((acc1, val1) => {
          if(val1[endpointExtractedKey] && val1[valueTypeKey]?.toLowerCase() !== "wrong_") {
            if(!acc1[val1[endpointExtractedKey]]) acc1[val1[endpointExtractedKey]] = [];
            acc1[val1[endpointExtractedKey]?.toLowerCase()]?.push(val1);
          }
          return acc1;
        }, {})
      };
      acc[val.nct_id]['endpoints'] = []
      Object.entries(acc[val.nct_id]?.armResultmap || {}).forEach(entry => {
        const [key, value] = entry;
        acc[val.nct_id]['endpoints'].push({
          endpoint: key,
          armResultCount: value.length
        })
      });
    }
    return acc;
  }, {});

  const sortedEndpoints = data?.reduce((acc, val) => {
    const res = val.extracted_endpoints?.filter(item => item.standardized).reduce((r, a) => {
      r[a.endpoint_type] = r[a.endpoint_type] || [];
      r[a.endpoint_type].push(a);
      return r;
    }, {});
    acc[val.nct_id] = [...(res?.Primary || []) , ...(res?.Secondary || []), ...(res?.Others || [])].filter(i => i);
    return acc;
  }, {});

  const getArmResultDetails = () => {
    let maxEndpointCount = 0, armResultDataList = [];
    Object.entries(sortedEndpoints).forEach(entry => {
      const [key, value] = entry;
      if(value?.length > 0) {
        maxEndpointCount = value.length > maxEndpointCount ? value.length : maxEndpointCount
      }
    });
    if(maxEndpointCount.length < 1) return armResultDataList;
    
    let i = 0;
    while(i < maxEndpointCount) {
      const endpointRow = {
        attributes: 'Endpoints',
        className: 'endpoint-name-arm-result-row',
        // key: `endpoint-name-${i}`
        key: getUniqueKey(),
      };
      const maxArmResultCount = nct_ids?.reduce((maxCount, nctId) => {
        const ep = sortedEndpoints?.[nctId]?.[i]?.standardized?.toLowerCase();
        if(ep && armResultMapping[nctId]?.armResultmap?.[ep]?.length > 0){
          const armResCount = armResultMapping[nctId]?.armResultmap?.[ep]?.length;
          maxCount = armResCount > maxCount ? armResCount : armResCount;
        }
        return maxCount;
      }, 0);

      const hrCIRow = {
        attributes: 'HR, CI Range & \nP Value',
        className: 'endpoint-name-hr-ci-p-value',
        key: getUniqueKey(),
      };

      const armResultList = [];
      Array(maxArmResultCount).fill({}).forEach((_, idx) => armResultList.push({
        attributes: 'Arm Name & Result',
        className: 'endpoint-name-arm-name-and-result-row',
        key: getUniqueKey(),
      }));
      let foundHrCIVal = false;
      nct_ids?.forEach(el => {
        const endpointData = sortedEndpoints?.[el]?.[i];
        endpointRow[el] = endpointData?.standardized? `${endpointData?.standardized},\n${endpointData?.endpoint_type}` : [];
        hrCIRow[el] = getHrAndCIValues(el, endpointData?.standardized);
        if (hrCIRow[el]?.length > 0) foundHrCIVal = true;
        if(maxArmResultCount > 0) {
          armResultList.map((item, index) => {
            const res = armResultMapping?.[el]?.armResultmap?.[endpointData?.standardized?.toLowerCase()]?.[index];
            item[el] = res? `${res?.arm_name}\n${res?.endpoint_value} ${res?.value_type}` : [];
            return el;
          })
        }
        
      });
      if(foundHrCIVal) armResultList.push(hrCIRow);
      if(armResultList.length > 0) {
        endpointRow['children'] = [...armResultList];
      }
      armResultDataList.push(endpointRow);
      i++;
    }

    return armResultDataList;
  };

  const getHrAndCIValues = (nctId, endpointName) => {
    const hrCIValue = [];
    const endpointValue = data.filter((el) => el.nct_id === nctId)?.reduce((acc, item) => {
      item?.endpoint_values?.forEach((el) => {
        if(el.extracted_name_standardized?.toLowerCase() === endpointName?.toLowerCase() && 
          el.value_type?.toLowerCase() === 'hr') { // if the value type is hr
          acc.push(el);
        }
      });
      return acc;
    }, []);
    if(endpointValue?.length > 0) {
      endpointValue.forEach((i) => {
        hrCIValue.push(
          `${(Array.isArray(i.endpoint_value_standardized)? (i.endpoint_value_standardized.join(", ")) : i.endpoint_value_standardized)} 
          (${i.statistics?.confidence_score} CI, 
          ${i.statistics?.lower_ci_value_standardized}-${i.statistics?.upper_ci_value_standardized}; 
          ${i.statistics?.['p-value']})`
        );
      })
    }
    return hrCIValue;
  };

  const convertTableFormat = colum?.compare_attributes?.reduce((acc1, value) => {
    if (!value.key) {
      let titleData = [value.title];
      let objData = [];
      if (value.children) {
        const childObj = value?.children?.map((subValue) => {
          const result = {
            title: [...titleData, subValue.title],
            obj: [...objData, subValue.dataIndex],
            dataIndex: subValue.dataIndex
          };
          acc1.push(result);
        });
      }
    } else {
      if(['arm_data', 'endpoints'].includes(value.dataIndex.toLowerCase())) {
        acc1.push({
          title: [null, value.title],
          obj: [value.title.toLowerCase()],
          dataIndex: value.dataIndex,
        });
        return acc1;
      }
      const result = {
        title: value.title,
        obj: value.dataIndex,
        dataIndex: value.dataIndex,
      };
      acc1.push(result);
    }
    return acc1;
  }, []);

  const dataConvertor = (title, obj, dataIndex) => {
    return data.map((ele) => {
      let studyTitleObj = { attributes: title, dataIndex };
      isString(obj)
        ? (studyTitleObj[ele.nct_id] = ele[obj])
        : obj?.reduce((acc, val) => {
            acc.push((studyTitleObj[ele.nct_id] = ele[val]));
            return acc;
          }, []);

      return studyTitleObj;
    });
  };

  const objectMerger = (title, obj, dataIndex) => {
    return isString(obj)
      ? Object.assign({}, ...dataConvertor(title, obj, dataIndex))
      : obj.reduce((acc, val) => {
          acc.push(Object.assign({}, ...dataConvertor(title, val, dataIndex)));
          return acc;
        }, []);
  };

  const res =
    convertTableFormat !== undefined &&
    convertTableFormat?.map((ele) => objectMerger(ele.title, ele.obj, ele.dataIndex));

  const filteredResult =
    res &&
    res?.map((val, idx) => {
      if (Array.isArray(val)) {
        let output = {};
        val.forEach((item) => {
          for (const element in item) {
            if (element !== "attributes") {
              if (output[element] === undefined) {
                output[element] = [];
              }
              if (item[element] === undefined) {
                item[element] = [];
              }
              if (!isString(item[element])) {
                output[element] = [...output[element], ...item[element]];
              } else {
                output[element] = [...output[element], item[element]];
              }
            } else {
              output[element] = item[element];
            }
          }
          if(item['dataIndex'] && item['dataIndex']?.toLowerCase() === 'arm_data'){
            const armDetails = getArmDetails();
            if(armDetails?.length > 0) {
              output['children'] = armDetails;
            }
            output['className'] = 'arms-main-heading';
            nct_ids?.forEach(e => output[e] = ' ')
          } else if (item['dataIndex'] && item['dataIndex']?.toLowerCase() === 'endpoints') {
            const armRsultDetails = getArmResultDetails();
            if(armRsultDetails?.length > 0) {
              output['children'] = armRsultDetails;
            }
            output['className'] = 'endpoint-values-main-heading';
            nct_ids?.forEach(e => output[e] = ' ')
          }
          output['key'] = getUniqueKey();
        });

        return output;
      } else {
        val['key'] = getUniqueKey();
        return val;
      }
    });

  return filteredResult;
};

export default CompareStudiesTableData;
