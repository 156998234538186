import React from "react";
import Icon from "@ant-design/icons";
import {
  StudyAdded,
  StudyModified,
  StudyRemoved,
} from "../../assets/icons/svg";
import { Col, Row } from "antd";

const NotificationLegends = () => (
  <Row justify="space-between">
    <Col>
      <span className="notification-legend">
        <Icon component={StudyAdded} /> Study Added
      </span>
    </Col>
    <Col>
      <span className="notification-legend">
        <Icon component={StudyModified} /> Study Modified
      </span>
    </Col>
    <Col>
      <span className="notification-legend">
        <Icon component={StudyRemoved} /> Study Removed
      </span>
    </Col>
  </Row>
);

export default NotificationLegends;
