import React, { useState } from "react";
import { Button, Row, Col, Badge } from "antd";
import Icon from "@ant-design/icons";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import {
  AscendingIcon,
  FilterBtnIcon,
  DescendingIcon,
} from "../../assets/icons/svg";
import NotificationLegends from "./NotificationLegends";
import NotificationFilter from "./NotificationFilter";
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const weekFormat = "MMM-DD-YYYY";
const customWeekStartEndFormat = (value) =>
  `${dayjs(value).startOf("week").format(weekFormat)} - ${dayjs(value)
    .endOf("week")
    .format(weekFormat)}`;

const NotificationHeader = ({
  totalNotifications,
  handleDatePickerChange,
  handleTherapyChange,
  getNotificationsData,
  handleSortChange,
  setSelectedTherapy,
  therapy,
  sort,
  startDate,
  selectedTherapy,
  onApplyTherapyArea,
  onCancelTherapyArea,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleModal = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <Row justify="space-between" align="right">
        <Col span={4}>
          <h1 className="notifications-header">Notifications</h1>
        </Col>
        <Col span={1}>
          <Badge
            count={selectedTherapy?.length}
            showZero={false}
            color="#EE801A"
          >
            <Button
              onClick={() => toggleModal()}
              style={{ marginLeft: 20, float: "right" }}
              icon={<Icon component={FilterBtnIcon} />}
            ></Button>
          </Badge>
        </Col>
      </Row>
      <Row justify="space-between" className="notification-week-picker-row">
        <Col span={4}>
          <DatePicker
            format={customWeekStartEndFormat}
            picker="week"
            onChange={handleDatePickerChange}
            defaultValue={dayjs()}
            value={dayjs(startDate)}
            allowClear={false}
            disabledDate={(current) =>
              current &&
              (current > dayjs().endOf("week") ||
                current < dayjs().endOf("week").day(-29))
            }
          />
        </Col>
        <Col span={6}>
          <NotificationLegends />
        </Col>
      </Row>
      <Row justify="space-between" className="new-notifications-row">
        <Col span={6}>
          <div>
            <span className="new-notifications-circle">
              <span
                className="new-notifications-circle-text"
                title={totalNotifications}
              >
                {totalNotifications > 99 ? "99+" : totalNotifications}
              </span>
            </span>
            <span className="new-notification-text">
              New notifications since you last visited
            </span>
          </div>
        </Col>
        <Col span={4}>
          <div className="notification-latest" onClick={handleSortChange}>
            Latest:{" "}
            <Icon component={sort === "ASC" ? AscendingIcon : DescendingIcon} />
          </div>
        </Col>
      </Row>
      <NotificationFilter
        isVisible={isVisible}
        toggleModal={toggleModal}
        handleTherapyChange={handleTherapyChange}
        getNotificationsData={getNotificationsData}
        therapy={therapy}
        onApplyTherapyArea={onApplyTherapyArea}
        onCancelTherapyArea={onCancelTherapyArea}
      />
    </>
  );
};

export default NotificationHeader;
