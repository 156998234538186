export const ENVIRONMENTS = {
    dev: {
        baseurl: "https://api.evisense.dev.zsservices.com",
    },
    qa: {
        baseurl: "https://api.evisense.qa.zsservices.com"
    }
};

export const SELECTED_ENVIRONMENT = 'dev';

export const IDM_URL = 'https://idm.dev.zsservices.com/RWE/IdentityManager/app/web/login.aspx';

//   export const DEPLOYED_ENVIRONMENT = process.env.REACT_APP_DEPLOYED_ENVIRONMENT;