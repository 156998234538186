import React, { useState, useEffect } from "react";
import { Button, Col, Row, Spin, Tooltip, Modal, Badge, Space } from "antd";
import {
  getUserStoryboards,
  addToStoryBoard,
  lockUnlockStoryBoard,
  getSearchResult
} from "../../../services/InsightsViewAPI";
import "./StoryboardView.scss";
import SingleClipView from "../single-clip-view/SingleClipView";
import SingleTextView from "../single-text-view/SingleTextView";
import { AddCommentIcon, EditIcon, LockIcon } from "../../../assets/icons/svg";
import { setStoryBoardData, setComingFromCollaboratorView, toggleCollaboratorConfirm, setCollaboratorModal, resetAll, setSearchLoadingReducer, setStudyListTableData, setCollaboratorDetails } from "../../../reducer/EICCommonSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showNotification } from '../../../shared/utils/CommonUtil';

const { confirm } = Modal;

const StoryboardView = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { sectionID, storyboardData, storyboardID, sectionName, /*onMovement*/ readAccess, editAccess, isOwner, analysisID, isStoryboardLocked, activeTab, userData } = props;
  const [loading, setLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState();
  const [temp, setTemp] = useState([]);
  // const [disableCommentBox, setDisableCommentBox] = useState(isOwner && selectedAnalysisData?.collaborators?.length == 0 ? false : true);
  const [disableCommentBox, setDisableCommentBox] = useState('');

  const [showTimer, setShowTimer] = useState(false);

  const {
    EICCommon: {
      selectedAnalysisData,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    const beforeCloseCallback = async (event) => {
      if (isStoryboardLocked) {
        event.preventDefault();
        event.returnValue = "Are you sure you want to close?";
        let payload = {
          analysis_id: analysisID,
          storyboard_id: storyboardID,
          sections: [sectionID],
          lock: false,
        };
        await lockUnlockStoryBoard(payload);
        return "";
      }
    };

    window.removeEventListener("beforeunload", beforeCloseCallback);
    window.addEventListener("beforeunload", beforeCloseCallback);
    return () => window.removeEventListener("beforeunload", beforeCloseCallback);
  }, [isStoryboardLocked]);

  useEffect(() => {
    let dd =
      storyboardData &&
      storyboardData.find((item) => item.section_id === sectionID);
    setTemp(dd.section_elements);
    setLastUpdated(dd.last_updated_on);

  }, [storyboardData]);

  const addTextView = async () => {
    setLoading(true);
    let payload = {
      storyboards: [
        {
          storyboard_id: storyboardID ? storyboardID : '',
          edit: false,
          sections: [
            {
              section_name: sectionName ? sectionName : '',
              section_id: sectionID ? sectionID : '',
              edit: false,
              section_elements: [
                {
                  element_id: "",
                  edit: false,
                  element_type: "text",
                  properties: {},
                  content: "",
                },
              ],
            },
          ],
        },
      ],
      analysis_id: selectedAnalysisData?.analysis_id,
    };

    try {
      const response = await addToStoryBoard(payload);
      if (response?.status === "success") {
        setLoading(false);
        const response = await getUserStoryboards({ analysis_id: selectedAnalysisData?.analysis_id, });
        dispatch(setStoryBoardData(response));
      }
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  }

  const enableEditStoryBoard = async () => {
    if (!isOwner && editAccess) {
      setShowTimer(true);
    }
    setDisableCommentBox(false);
    setLoading(true);
    let payload = {
      analysis_id: analysisID,
      storyboard_id: storyboardID,
      sections: [sectionID],
      lock: true,
    }

    try {
      const response = await lockUnlockStoryBoard(payload);
      if (response?.status === "success") {
        setLoading(false);
        showNotification(response.status, 'Success', response.message);
        setLoading(true);
        const data = await getUserStoryboards({ analysis_id: selectedAnalysisData?.analysis_id, });
        dispatch(setStoryBoardData(data));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  }

  const unLockStoryboard = async () => {
    // if(editAccess){
    setDisableCommentBox(true);
    setLoading(true);
    let payload = {
      analysis_id: analysisID,
      storyboard_id: storyboardID,
      sections: [sectionID],
      lock: false,
    }

    try {
      const response = await lockUnlockStoryBoard(payload);
      if (response?.status === "success") {
        setLoading(false);
        showNotification(response.status, 'Success', response.message);
        setLoading(true);
        const data = await getUserStoryboards({ analysis_id: selectedAnalysisData?.analysis_id, });
        dispatch(setStoryBoardData(data));
        setShowTimer(false);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  }
  // } else {
  //   return false;
  // }

  //Neha - Timeout logic starts from here
  var timeoutID;
  var afterFiveTimeoutID;

  function setup() {
    document.addEventListener("mousemove", resetTimer, false);
    document.addEventListener("mousedown", resetTimer, false);
    document.addEventListener("keypress", resetTimer, false);
    document.addEventListener("keydown", resetTimer, false);
    document.addEventListener("keyup", resetTimer, false);
    document.addEventListener("onkeypress", resetTimer, false);
    document.addEventListener("DOMMouseScroll", resetTimer, false);
    document.addEventListener("mousewheel", resetTimer, false);
    document.addEventListener("touchmove", resetTimer, false);
    document.addEventListener("MSPointerMove", resetTimer, false);
    startTimer();
  }

  function clearSetup() {
    document.removeEventListener("mousemove", resetTimer, false);
    document.removeEventListener("mousedown", resetTimer, false);
    document.removeEventListener("keypress", resetTimer, false);
    document.addEventListener("keydown", resetTimer, false);
    document.addEventListener("keyup", resetTimer, false);
    document.addEventListener("onkeypress", resetTimer, false);
    document.removeEventListener("DOMMouseScroll", resetTimer, false);
    document.removeEventListener("mousewheel", resetTimer, false);
    document.removeEventListener("touchmove", resetTimer, false);
    document.removeEventListener("MSPointerMove", resetTimer, false);

    window.clearTimeout(timeoutID);
    window.clearTimeout(afterFiveTimeoutID);

  }

  function startTimer() {
    timeoutID = window.setTimeout(goInactive, 1800000);      // 30 Minutes = 1,800,000 Milliseconds
  }

  useEffect(() => {
    dispatch(setCollaboratorDetails({ edit_access: userData.edit_access, lock: userData.lock, read_access: userData.read_access, section_id: userData.section_id, storyboard_id: storyboardID }));
    // console.log('insdieUseEffect', isOwner, editAccess, isStoryboardLocked)
    if (!isOwner && editAccess && isStoryboardLocked) {
      setup();
    }
  }, [showTimer, activeTab, isStoryboardLocked, /*onMovement*/]);

  useEffect(() => {
    setDisableCommentBox(
      isOwner && (isStoryboardLocked || userData?.collaborator_list != null) ?
        true
        : !isOwner ? true : false
    );
  },[]);


  function resetTimer(e) {
    window.clearTimeout(timeoutID);
    window.clearTimeout(afterFiveTimeoutID);
    // goActive();
  }

  function goInactive() {
    // console.log('goInactive')
    window.clearTimeout(afterFiveTimeoutID);
    showTimeoutConfirm();
    setShowTimer(true);
  }

  function goActive() {  //Keep editing 
    // console.log('goActive')
    startTimer();
  }

  function afterFiveHandle() {
    ExitCollaboratorView();
  }

  function exitAfterFive() {
    afterFiveTimeoutID = window.setTimeout(afterFiveHandle, 300000);   // 5 Minutes = 300,000 Milliseconds
    setShowTimer(false);
  }

  const ExitCollaboratorView = async () => {
    window.clearTimeout(timeoutID);
    window.clearTimeout(afterFiveTimeoutID);
    clearSetup();

    let payload = {
      analysis_id: analysisID,
      storyboard_id: storyboardID,
      sections: [sectionID],
      lock: false,
    }

    try {
      const response = await lockUnlockStoryBoard(payload);
      if (response?.status === "success") {
        //   if(document.getElementsByClassName('timeout-confirm') && modalState == 'hidemodal'){
        //     document.getElementsByClassName('ant-modal-mask')[0].style.background = 'none';
        //     document.getElementsByClassName('timeout-confirm')[0].style.display = 'none';

        // };
        Modal.destroyAll();
        dispatch(setComingFromCollaboratorView(true))
        dispatch(toggleCollaboratorConfirm({ isCheck: true, source: 'navbar', navToPath: '' }));
        navigate("/saved-analysis");
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const showTimeoutConfirm = () => {
    confirm({
      title: "Timeout",
      content:
        (
          <div className="timeout-confirm-content">
            <p>Are you still there?</p>
            <p>You have a shared storyboard locked for editing and have been idle for 30 minutes.  If you're still working and would like to keep editing control, please click 'Keep Editing' below.  If you are done editing, please click 'Save & Exit.'</p>
            <p>Otherwise, after 5 minutes, your editing session will end so that your colleagues are able to edit the storyboard.  Don't worry, all of your changes will be saved.</p>
          </div>
        ),
      okText: "Save & Exit",
      okType: "primary",
      cancelText: "Keep editing ",
      closable: false,
      icon: null,
      wrapClassName: 'timeout-confirm',
      centered: true,
      onOk() {
        ExitCollaboratorView();
      },
      onCancel() {
        window.clearTimeout(timeoutID);
        window.clearTimeout(afterFiveTimeoutID);
        goActive();
        setShowTimer(false);
      },
    });
    exitAfterFive();
  };

  const textBoxClass = (isOwner || !isStoryboardLocked) ? (!isOwner && !isStoryboardLocked) ? 'texbox-padding' : 'texbox-padding-1' : 'texbox-padding';

  //  const spanWidth = isOwner && isStoryboardLocked ? 19 : isOwner && !isStoryboardLocked ? 21 : 19;


  const editTooltipText = () => {
    return isStoryboardLocked ? 'Edit mode is locked because someone else is working on it. You can try again later.'
      : isOwner ? 'Edit will enable you to edit the Storyboard. However, it will lock it for other collaborators'
        : editAccess ? 'Edit will enable you to edit the Storyboard. However, it will lock it for others including the owner.'
          : 'You cannot edit this Storyboard as you do not have Edit access to this. Kindly contact the owner for permissions.';
  };

  const isEditDisabled = () => {
    return isStoryboardLocked ? true
      : isOwner ? false
        : editAccess ? false :
          true;
  };

  const isReadOnlyMode = () => {
    return isOwner ? (userData?.collaborator_list?.length > 0 && !isStoryboardLocked ? true : false)
      : editAccess && isStoryboardLocked ? false : true
  }

  return (
    <>
      <Spin spinning={loading}
      >
        <Row>
          <Col span={12}>
            <>
              <p className='last-updated-details'>
                Last updated on {lastUpdated && lastUpdated}
              </p>
              {temp && temp.length ? (
                ""
              ) : (
                <div className="no-storyboard-class">
                  <span className="no-storyboard-text">
                    Screenshot will display here when added
                  </span>
                </div>
              )}
            </>
          </Col>
          <Col span={12} className={textBoxClass} style={{ direction: 'rtl', paddingRight: 20 }}>
            <>
              {
                ((isOwner && (isStoryboardLocked || userData?.collaborator_list != null)) || !isOwner) ?
                  <>
                    <Badge count={
                      isStoryboardLocked ?
                        <span>
                          <LockIcon
                            style={{
                              color: '#f5222d'
                            }}
                          />
                        </span>
                        : 0
                    }
                    >
                      <Tooltip placement="topLeft" title={editTooltipText()} className='edit-storyboard-tooltip'>
                        <Button type="default" className="edit-storyboard" onClick={enableEditStoryBoard} disabled={isEditDisabled()}>
                          <span>
                            <EditIcon /> &nbsp;
                            Edit
                          </span>
                        </Button>
                      </Tooltip>

                    </Badge>
                  </>
                  : ''
              }
            </>
            <Tooltip placement="left" title={'Add a Textbox'} className="edit-storyboard-tooltip">
              <Button type="default" className="add-comment" disabled={disableCommentBox}>
                <span onClick={addTextView}>
                  <AddCommentIcon /> &nbsp;
                  Add Textbox
                </span>
              </Button>
            </Tooltip>
          </Col>
        </Row>
        <Row>
          {temp &&
            temp.map((item, i) => (
              <Col span={8} key={'content-' + item.element_id}>
                {item.element_type === "text" ? (
                  <SingleTextView key={'editor-' + item.element_id} storyboardID={storyboardID} sectionID={sectionID} sectionName={sectionName} textData={item} storyboardOwner={isOwner} isStoryboardLocked={isStoryboardLocked} readAccess={readAccess} editAccess={editAccess} readOnly={isReadOnlyMode()} />
                ) : (
                  <SingleClipView clipData={item} storyboardID={storyboardID} sectionID={sectionID} storyboardOwner={isOwner} />
                )}
              </Col>
            ))}
        </Row>
      </Spin>
    </>
  );
};

export default StoryboardView;
