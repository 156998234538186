import React from "react";
import { Avatar, Tooltip } from 'antd';
const bgColorList = ["#62D2D1", "#2D535F", "#3af2ef", "#2D8B93", "#2D535F", "#62D2D1"];
const defaultMaxStyle = {
    color: '#fff',
    backgroundColor: '#87848D',
};
const UserAvatar = ({ userList, maxCount = 4, maxStyle, onAvarClick }) => {

    const getUserName = (name) => {
        let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
        let initials = [...name.matchAll(rgx)] || [];
        initials = (
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
        ).toUpperCase();
        return initials;
    }

    return (
        <React.Fragment>
            <Avatar.Group
                maxCount={maxCount}
                maxStyle={
                    {
                        ...defaultMaxStyle, ...maxStyle
                    }
                }
            >
                {userList?.length ? userList.map((user, index) => {
                    return (
                        <Tooltip title={user.user_name} placement="top">
                            <Avatar
                                key={user.user_name}
                                onClick={() => {
                                    if (typeof (onAvarClick) === 'function') {
                                        onAvarClick(user);
                                    }
                                }}
                                style={{
                                    backgroundColor: bgColorList[index],
                                    cursor: 'pointer',
                                }}
                            >
                                {getUserName(user.user_name)}
                            </Avatar>
                        </Tooltip>
                    )
                }) : 'None'}
            </Avatar.Group>
        </React.Fragment>
    );
};

export default UserAvatar;