import moment from "moment";

const SavedAnalysisOnFilter = (type, value, record) => {
  switch (type) {
    case "analysisName":
      return (
        record.analysisName.title
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(value.toLowerCase()) ||
        record.analysisName.description
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(value.toLowerCase())
      );
    case "therapyArea":
      return record.therapyArea.startsWith(value);

    case "dateCreated":
      return record[type]
        ? moment(record[type]).isBetween(value[0], value[1], "day", "[]")
        : "";
    default:
      return null;
  }
};

export default SavedAnalysisOnFilter;
