import React, { useState } from "react";
import DateFormator from "../saved-analysis-date-formattor/DateFormator";
import UserAvatar from "../../UserAvatar";

import { sampleSize } from "lodash";
import TooltipParagraph from './TooltipParagraph';

const SavedAnalysisRender = (key, item, record, actionRender, onTableActions) => {
  switch (key) {
    case "analysis_name":
      return (
        <React.Fragment>
          <p className="analysis-name">
            <a href='#' onClick={
              (e) => {
                e.preventDefault();
                onTableActions({ type: 'nameClick', item, rowData: record });
              }}
            >
              {item.title}
            </a>
          </p>
          <TooltipParagraph className={'analysis-desc'} ellipsis={{ rows: 2 }}>
            {item.description}
          </TooltipParagraph>
        </React.Fragment>
      );

    case "created_date":
      return (
        <React.Fragment>
          <p>{DateFormator(item)}</p>
        </React.Fragment>
      );

    case "last_updated":
      return (
        <React.Fragment>
          <p className="item-updated-time">{DateFormator(item.date)}</p>
          <p className="item-updated-person">by {item.user}</p>
        </React.Fragment>
      );

    case "action":
      return actionRender(record); 

    case "collaborators":
      const list = record.collaborators;
      return (
        <React.Fragment>
          {list?.length ?
            <UserAvatar userList={list} />
            : 'None'
          }
        </React.Fragment>
      )

    default:
      return null;
  }
};

export default SavedAnalysisRender;
