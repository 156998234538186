import React from "react";
import SavedAnalysisModal from "./saved-analysis-modal/SavedAnalysisModal";
import SavedAnalysisGridTable from "./saved-analysis-table/SavedAnalysisGridTable";

const SavedAnalysisContainer = () => {

    return (
        <>
            <div className="saved-analysis-container" style={{ paddingLeft: '80px', paddingTop: '50px' }}>
                <SavedAnalysisGridTable />
            </div>

            <SavedAnalysisModal />
        </>
    );
};

export default SavedAnalysisContainer;
