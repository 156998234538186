import "./App.css";
import '../node_modules/open-sans-fonts/_open-sans-regular.scss';
import DashBoard from "./templates/dashboard";

function App() {
  return (
    <div>
      <DashBoard /> 
    </div>
  );
}

export default App;
