import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Highcharts from "react-highcharts";
import { Button, Col, Modal, Row, Space, Tooltip, Select, Spin } from "antd";
import { DrugInfo, FacetSearchIcon } from "../../../assets/icons/svg";
import "./GeoPieChart.scss";
import {
  geoChartFilterStudiesByBreakdown,
  geoChartStudiesDataReducer,
  geoChartStudiesSearchValue,
  geoPieChartData,
  setLoadingPieData,
  setInteractiveFilters,
} from "../../../reducer/EICCommonSlice";
import Icon from "@ant-design/icons";
import cloneDeep from "lodash/cloneDeep";
import { getGeoMapData, mapAdvanceFilters, getInteractiveSearch } from "../../../services/InsightsViewAPI";
import { useNavigate } from "react-router-dom";
import { containsWhitespace, FormatAdvFilterDataForDynamicChart } from "../../../shared/utils/CommonUtil";

const { Option } = Select;
const { confirm } = Modal;

function GeoPieChart({ data, showInteractiveCharts}) {
  const [chartModalVisible, setChartModalVisible] = useState(false);
  const [pieChartWidth, setPieChatWidth] = useState({
    normalPieChart: {
      width: false,
      expandWidth: false,
      expandHeight: false,
      fontSize: 13,
      legendMargin: 10,
    },
    expandPieChart: {
      width: 800,
      expandHeight: 500,
      expandWidth: 800,
      fontSize: 16,
    },
  });
 
  const [interactiveChartFilters, setInteractiveChartFilters] = useState({});
  const [loading, setLoading] = useState(false);

  const {
    EICCommon: {
      geoChartData: {
        geoPieChartValues,
        geoChartStudiesData,
        searchStudy,
        checkBoxValues,
        defaultBreakDownValue,
        filteredDefaultValues,
        geoChartStudiesBreakDownData,
        LoadingPieData,
      },
      dropDownList,
      searchTerm,
      state,
      selectedAdvanceFilters,
      InteractiveStudyListPageNumber,
      advanceFilterDropdownList,
    },
  } = useSelector((state) => state);

  var viewByOtherValue = '';

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(geoChartFilterStudiesByBreakdown(checkBoxValues));
  }, []);

  const [chartReload, setChartReload] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", rerenderChart);
    return () => window.removeEventListener("resize", rerenderChart);
  }, []);

  const rerenderChart = () => {
    setChartReload(true);
    setTimeout(() => {
      setChartReload(false);
    }, 100);
  };

  const handleSelect = (value) => {
    dispatch(geoChartFilterStudiesByBreakdown(value));
  };

  const setPieChartData = async (value) => {
    dispatch(setLoadingPieData(true));
    dispatch(geoPieChartData({}));
    const data = await getGeoMapData(searchTerm, value, selectedAdvanceFilters);
    dispatch(geoChartStudiesDataReducer(data));
    dispatch(geoChartFilterStudiesByBreakdown(data));
    dispatch(setLoadingPieData(false));
  };

  const geoPieChartInputData = (data) => {
    const othersCount =
      geoChartStudiesData?.chart_1?.chart_data.length > 0 &&
      geoChartStudiesData?.chart_1?.chart_data?.map((val) => {
        if (val.entity_code === geoPieChartValues?.entity_code) {
          const filterOthers = val.sub_chart.reduce((acc, data) => {
            const verifyFilter =
              checkBoxValues?.length > 7
                ? checkBoxValues.slice(0, 7)
                : checkBoxValues;
            if (!verifyFilter.includes(data.sub_entity_name)) {
              acc += data.sub_entity_count;
            }
            return acc;
          }, 0);
          return filterOthers;
        }
      }, []);

    const undefinedFilteredValues = othersCount?.filter((val) => {
      return val !== undefined;
    });

    const pieValues =
      data !== undefined &&
      data.slice(0, 7).map((ele) => {
        return {
          name: ele.sub_entity_name,
          y: ele.sub_entity_count,
        };
      });

    const totalPieValue =
      undefinedFilteredValues[0] > 0
        ? [
          ...pieValues,

          {
            name: "Others",
            y: undefinedFilteredValues[0],
            color: "#532DEC",
          },
        ]
        : [...pieValues];

      //Neha - setting this state to get all the values, when user clicks on "Others" in pie chart
      viewByOtherValue = pieValues;

    return totalPieValue;

  };

  const handleInteractiveCharts = async (selectedViewByValue, isNavToExplore) => {
    var tempAdvanceFilter = cloneDeep(selectedAdvanceFilters);
    var _tempAdvanceFilters = mapAdvanceFilters(tempAdvanceFilter);

    //Neha - changing all the keys of an object to lower case
    var key, keys = Object.keys(_tempAdvanceFilters);
    var n = keys.length;
    var tempAdvanceFilters={}
    while (n--) {
      key = keys[n];
      tempAdvanceFilters[key.toLowerCase()] = _tempAdvanceFilters[key];
    }

    

    var viewBy = searchStudy == 'Data Source' ? 'data_source' : searchStudy.toLowerCase();

    viewBy = containsWhitespace(viewBy);
    var payload_1 = {};

    let filterKeys = Object.keys(tempAdvanceFilters);

   //Neha - Below logic to get the others view by values
    let all_view_by_values = geoChartStudiesData?.chart_1?.view_by_values;
    var filteredViewByOtherValue = viewByOtherValue.map((item)=>{return item.name});
    var filteredOthersValue = all_view_by_values.filter(val => !filteredViewByOtherValue.includes(val));

    if ((filterKeys.indexOf(viewBy) > -1 )) {
      for (let key in tempAdvanceFilters) {
        payload_1 = tempAdvanceFilters[viewBy]?.find((item) => item == selectedViewByValue);
      }
      tempAdvanceFilters = {
        ...tempAdvanceFilters,
        [viewBy]: selectedViewByValue == 'Others' ? filteredOthersValue : [payload_1], 
        country : [geoPieChartValues?.entity_name]
      }
  
    } 
    else { 
      tempAdvanceFilters = {
        ...tempAdvanceFilters,
        [viewBy] : selectedViewByValue == 'Others' ? filteredOthersValue : [selectedViewByValue],
        country : [geoPieChartValues?.entity_name]
      }
  
    }
   
    setLoading(true);

    //Neha - below logic is to change the filter mapping for advance filter
    const capitalizeFirstLetter=(string)=> {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    var _tempAdvanceFilters = FormatAdvFilterDataForDynamicChart(advanceFilterDropdownList, tempAdvanceFilters);

    dispatch(setInteractiveFilters(_tempAdvanceFilters));
    setLoading(false);
    if (isNavToExplore){
      navigate("/home/explore");
    }
  };

  const showInteractiveConfirm = (selectedViewByValue) => {
    confirm({
      title: 'Confirm changes',
      content: `Are you sure you want to leave this page? The changes you made will affect your overall results & add a filter ${searchStudy}`,
      okText: 'Move to Explore',
      okType: 'primary',
      cancelText: 'Continue on Insights',
      closable: true,
      icon: null,
      wrapClassName: 'interactive-filter-confirm',
      centered: true,   
      onOk() {
        handleInteractiveCharts(
          selectedViewByValue, true
        );
      },
      onCancel(closeFn) {
        if(typeof(closeFn) === 'function' && closeFn?.name !== "") {
          handleInteractiveCharts(
            selectedViewByValue, false
          );
          closeFn();
        }
      },
    });
  };

  const geoPieChartConfig = (data) => ({
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      height: (9 / 16 * 75) + '%'
    },

    exporting: { enabled: false },
    credits: {
      enabled: false,
    },
    title: false,
    tooltip: {
      headerFormat: `${searchStudy} : {point.key}</br>`,
      pointFormat: "<br>{series.name} : <span>{point.y:.f}</span>",
      style: {
        backgroundColor: "#2F2C3C",
        borderRadius: 2,
        color: "#ffffff",
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: 14,
        lineHeight: 22,
      },
      backgroundColor: "#2F2C3C",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        colors: [
          "#E0C6FF",
          "#899FFF",
          "#C3E0FF",
          "#FF9836",
          "#FECA98",
          "#B2B0B6",
          "#716E79",
        ],
        borderWidth: 1,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "{<b>endpoint : {point.name}</b>{point.percentage:.1f} %",
          connectorHeight: 1,
          filter: {
            property: "percentage",
            operator: ">",
            value: 0,
          },
          borderColor: "transparent",
          style: {
            textOutline: "transparent",
            color: "black",
          },
        },
        showInLegend: true,
      },
      series: {
        point: {
          events: {
            click: function () {
              showInteractiveConfirm(this.options.name);
            },
          }
        },
      },
    },
    legend: {
      x: 50,
      y: 10,
      itemMarginBottom: 5,
      itemStyle: {
        fontWeight: 400,
        fontSize: data.fontSize,
        lineHeight: 22,
        color: "#454250",
        fontFamily: "Open Sans",
        fontStyle: "normal",
      },
    },
    series: [
      {
        name: "Study Count",
        colorByPoint: true,
        data: geoPieChartInputData(geoPieChartValues?.sub_chart),
      },
    ],
  });

  return (
    <div id="geo-map-id" style={{ width: "100%" }}>
      <Row
        style={{
          paddingTop: '18px'
        }}
      >
        <Col span={6}
          style={{
            textAlign: state === "open" ? "start" : "center",
          }}
        >
          <h2 style={{ margin: 0 }}>
            Country : {geoPieChartValues?.entity_name}
          </h2>
        </Col>
        <Col span={15}>
          <Row>
            <Col span={6} style={{textAlign: 'right', paddingRight: '8px'}}>
              <span className="drug-title">View by</span>
              <span>
                <Tooltip
                  placement="top"
                  title={
                    <span
                      style={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 14,
                        color: "#FAFAFA",
                      }}
                    >
                      Select up to 7 {searchStudy} to compare
                    </span>
                  }
                >
                  <Icon
                    component={DrugInfo}
                    style={{ paddingLeft: "5px", cursor: "pointer" }}
                  />
                </Tooltip>
                :
              </span>
            </Col>
            <Col span={16}>
              <Select
                style={{
                  width: "100%",
                  height: 32,
                  borderRadius: 2,
                }}
                defaultValue={searchStudy}
                onChange={(value) => {
                  dispatch(geoChartStudiesSearchValue(value));
                  setPieChartData(value);
                }}
                disabled={
                  geoChartStudiesData.chart_1.chart_data.length > 0 ? false : true
                }
              >
                {dropDownList?.length > 0 &&
                  dropDownList?.map((ele) => <Option value={ele}>{ele}</Option>)}
              </Select>

              {filteredDefaultValues?.length > 0 && (
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                    height: 32,
                    borderRadius: 2,
                    marginTop: '8px',
                  }}
                  onChange={handleSelect}
                  value={
                    checkBoxValues?.length < 7
                      ? checkBoxValues
                      : checkBoxValues?.slice(0, 7)
                  }
                  showArrow={true}
                  showSearch={false}
                  maxTagCount={"responsive"}
                  disabled={
                    geoChartStudiesData.chart_1.chart_data.length > 0 ? false : true
                  }
                >
                  {filteredDefaultValues?.length > 0 &&
                    filteredDefaultValues?.map((val, index) => {
                      return (
                        <Option
                          value={val}
                          key={index}
                          disabled={
                            checkBoxValues?.length > 6
                              ? checkBoxValues?.slice(0, 7).includes(val)
                                ? false
                                : true
                              : false
                          }
                        >
                          {val}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Col>


          </Row>
        </Col>

        <Col span={3}
          style={{
            textAlign: "center",
          }}
        >
          <Button onClick={() => setChartModalVisible(true)}>
            <FacetSearchIcon />
          </Button>
        </Col>
      </Row>
      <Spin spinning={LoadingPieData}>
        {geoPieChartValues !== undefined &&
          Object.keys(geoPieChartValues).length > 0 ? (
          <Row style={{ display: "block", width: "100%" }}>
            {chartReload ? '' : 
              <Highcharts
                config={geoPieChartConfig(pieChartWidth.normalPieChart)}
              />
            }
          </Row>
        ) : (
          <div className="no-data" style={{ textAlign: "center" }}>
            There is no data available to display based on applied criteria
          </div>
        )}
      </Spin>

      <Modal
        title={`Studies by ${searchStudy}`}
        centered
        visible={chartModalVisible}
        onOk={() => setChartModalVisible(false)}
        width={900}
        onCancel={() => setChartModalVisible(false)}
        okText="Close"
        footer={
          <Space>
            <Button
              onClick={() => setChartModalVisible(false)}
              className="common-ant-btn"
            >
              Close
            </Button>
          </Space>
        }
      >
        <Row>
          <h2>
            Country :{" "}
            {geoPieChartValues !== undefined &&
              Object.keys(geoPieChartValues).length > 0
              ? geoPieChartValues.entity_name
              : ""}
          </h2>
        </Row>
        {geoPieChartValues !== undefined &&
          Object.keys(geoPieChartValues).length > 0 ? (
          <Row>
            <Col span={24} style={{ width: "100%" }}>
              <Highcharts
                config={geoPieChartConfig(pieChartWidth.expandPieChart)}
              />
            </Col>
          </Row>
        ) : !LoadingPieData ? (
          <div className="no-data">
            There is no data available to display based on applied criteria
          </div>
        ) : (
          <div></div>
        )}
      </Modal>
    </div>
  );
}
export default GeoPieChart;
