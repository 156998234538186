export const FAQ_STATIC_DATA = [
  // {
  //   answer:
  //     "<mark><p id='text'>ZS EvisenseTM makes consuming scientific literature simple and efficient.<br/><br/>This cloud-based AI product analyzes a comprehensive internal and external evidence landscape and informs study design by delivering trends and insights using advanced AI technology. <br/><br/>The interface is structured to allow collaboration and ensures transparency. It offers unique functionalities such as semantic search, intuitive visualizations, competitive analyses, study comparisons. This is enabled by the underlying robust evidence ontology, advanced NLP, and knowledge graphs with curated data to deliver relevant insights validated through ZS’s deep domain expertise.</p></mark>",
  //   question: "What is ZS Evisense?",
  //   key: "1",
  // },
  // {
  //   answer:
  //     "<mark><p id='text'>Evidence generation leads are tasked with taking important internal decisions as well as deliver a complete and differentiated evidence package to external healthcare stakeholders. When generating new evidence for a pharmaceutical intervention, it is critical to consider the existing base of medical and scientific information. This data is voluminous, unstructured, disparate, and ever-growing. The volume of scientific knowledge is doubling every 73 days with 1.2M research papers published annually, and more than 256 million papers undergoing peer-review. Research and analysis are an essential part of evidence planning and generation and teams need to rapidly analyze unstructured scientific information. They do this to quantify clinical unmet needs, identify evidence gaps, analyze competitor evidence, and inform their own study design. This necessitates the need for a solution to unearth meaningful insights from literature and to drive efficient evidence planning decisions. By synthesizing large volumes of unstructured data, it can provide a holistic view of the evidence landscape and robust competitive analysis. </p></mark>",
  //   question: "Why do I need ZS Evisense?",
  //   key: "2",
  // },
  // {
  //   answer:
  //     "<mark><p id='text'>ZS Evisense can:<br/> - Inform study design<br/> - Holistic and standardized data provides insights and recommends optimal study designs<br/> - Enable data driven decisions<br/> - Advanced functionalities automate research synthesis and shift the focus from research to decision-making and hence increase efficiency<br/> - Infer evidence gaps<br/> - ZS Evisense can assess all internal, external evidence and can help discover unmet needs and evidence gaps<br/> - Promote cross-collaboration<br/> - Such clipboard functionalities as save and export enable collaboration on the platform. Users can also share, co-develop, and review individual notes and provide comments.</p></mark>",
  //   question: "What use cases can ZS Evisense help me with?",
  //   key: "3",
  // },
  // {
  //   answer:
  //     "<mark><p id='text'>ZS Evisense is positioned to help users across the product lifecycle. It is applicable across functions like Clinical Research, Integrated Evidence Planning, HEOR, Medical Affairs, RWE/Epidemiology<br/>ZS EvisenseTM can help beyond pharma users such as research institutions, clinical and medical societies, external stakeholders etc. in conducting robust evidence research. </p></mark>",
  //   question: "Who can use ZS Evisense?",
  //   key: "4",
  // },
  // {
  //   answer:
  //     "<mark><p id='text'>ZS Evisense has the following search features available-<br/> - Provide search results for both structured and unstructured data such as free text<br/> - Use of Boolean search expressions such as and, or, not, and not etc.<br/> - Autocomplete search suggestions based on search query<br/> - Relevance ranking of search results based on search query<br/> - Highlight search terms from the search query<br/> - Study results from a query are a result of 2 searches – filter search [Filter search picks up studies from a structured data list that match the filter criteria] and full text search [platform conducts a key word search in the entire article to identify filters and ranks studies based on the number of filter entities present]<br/>Example: “Phase 3 trials conducted for NSCLC in America” will filter results as –<br/>     - Filters:<br/>\n\u2022 Study phase – Phase 3\n<br/>\n\u2022 Indication – NSCLC\n<br/>\n\u2022 Region – America (North and South America)<br/>\n\u2022 Study type – interventional (from Phase 3)\n<br/>Evisense will filter the pool of studies for NSCLC to apply all filters and provide results that include each filter criteria. </p></mark>",
  //   question: "What are the search features available?",
  //   key: "5",
  // },
  // {
  //   answer:
  //     "<mark><p id='text'>ZS Evisense currently ingests data from PubMed</p></mark>",
  //   question: "What are the data sources that are used?",
  //   key: "6",
  // },
  // {
  //   answer:
  //     "<mark><p id='text'>The data contains the following indications<br/>- Oncology -> NSCLC<br/>- Gastrointestinal -><br/>\n\u2022 Crohn’s \n<br/>\n\u2022 Ulcerative Colitis (UC)\n <br/>- Neurological Diseases -> Parkinson’s<br/>- Rare Disease -> Narcolepsy</p></mark>",
  //   question: "What are the indications that we have?",
  //   key: "7",
  // },
  // {
  //   answer:
  //     "<mark><p id='text'>Your search data is stored in an encrypted format when saved by you. When not saved, the search terms are temporarily available in your browser memory and can be cleared using the reset option on Evisense or by session logout.</p></mark>",
  //   question: "How does ZS Evisense secure my search? ",
  //   key: "8",
  // },
  // {
  //   answer: `<mark><p id='text'>You can export any parts of the analysis that you may have saved to the storyboard. Follow the below listed steps to export your analysis.<br/> - Click on the storyboard tab from the left pane <br/> - Select the storyboard you want to export <br/><br/><img class="faq-screen_1"><br/><br/> - Click on the download icon on the top right corner. It will download the analysis in PDF format<br/><br/><img class="faq-screen_2"> </p></mark>`,
  //   question: "How can I export my analysis? ",
  //   key: "9",
  // },
  // {
  //   answer:
  //     "<mark><p id='text'>No, users are not allowed to share their username and password within their organization.</p></mark>",
  //   question: "Can I share my username and password?",
  //   key: "10",
  // },
  // {
  //   answer:
  //     "<mark><p id='text'>Yes, the collaboration feature allows you to share the analysis with colleagues in the organization who have a license to Evisense</p></mark>",
  //   question: "Can I share my analysis with anyone in my organization?",
  //   key: "11",
  // },
  // {
  //   answer:
  //     "<mark><p id='text'>Users will be notified via email and on the application upon turning on the notifications option on their saved analysis.</p></mark>",
  //   question:
  //     "How can I get notifications of any new publications relevant to my search?",
  //   key: "12",
  // },
  // {
  //   answer:
  //     "<mark><p id='text'>Users are encouraged to drill down their search using the comprehensive filters available. User may also click on the bars on the charts to reduce the research pool.</p></mark>",
  //   question: "What are some search best practices?",
  //   key: "13",
  // },
  // {
  //   answer:
  //     "<mark><p id='text'>ZS is a management consulting and technology firm focused on transforming global healthcare and beyond. We leverage our leading-edge analytics, plus the power of data, science and products, to help our clients make more intelligent decisions, deliver innovative solutions and improve outcomes for all. Founded in 1983, ZS has more than 12,000 employees in 35 offices worldwide.</p></mark>",
  //   question: "About ZS",
  //   key: "14",
  // },
  {
    answer:
      "<mark><p id='text'>Yes, you must use the email address registered with Evisense.</p></mark>",
    question: "Do I login using my official email address?",
    key: "15",
  },
  {
    answer:
      "<mark><p id='text'>No, users are not allowed to share their username and password within their organization.</p></mark>",
    question: "Can others users login by using my credentials?",
    key: "16",
  },
  {
    answer:
      "<mark><p id='text'>To reset or change your password select the “Forgot your password?” option below the login box <br/><br/> <img class='faq-screen_15'><br/><br/> Add your username <br/><br/> <img class='faq-screen_16'><br/><br/> A link will be shared to you on your official email address. Click the link and add a new password. <br/> Enter your login id and new password to login</p></mark>",
    question: "What should I do if I forget my password?",
    key: "17",
  },
  {
    answer:
      "<mark><p id='text'>In its current scope Evisense extracts studies from PubMed for the following indications and therapy areas: <br/> <br/>\n\u2022 Oncology -> NSCLC <br/>\n\u2022 Gastrointestinal -><br/> &nbsp; &nbsp; \n\u2022 Crohn's <br/> &nbsp; &nbsp; \n\u2022 Ulcerative Colitis (UC)<br/> \n\u2022 Neurological Diseases -> Parkinson's <br/> \n\u2022 Rare Disease -> Narcolepsy</p></mark>",
    question:
      "What data sources does Evisense extract its evidence studies from, and does it cover all therapy areas?",
    key: "18",
  },
  {
    answer:
      "<mark><p id='text'>Evisense processes structured and unstructured data such as free text as well as Boolean search expressions such as AND, OR, NOT, and NOR etc.</p></mark>",
    question: "What type of search criteria does Evisense process?",
    key: "19",
  },
  {
    answer:
      "<mark><p id='text'>ZS Evisense has the following search features available -<br/> - Provide search results for both structured and unstructured data such as free text<br/> - Relevance ranking of search results based on search query<br/> - Fetch key search terms from search query and show them with filters below the search box.<br/> - Study results from a query are a result of 2 searches – filter search [Filter search picks up studies matching the results using ZS NLP pipelines] and full text search [platform conducts a keyword search in the articles to identify the terms in the search query and rank studies based on the occurrence of search terms in the articles]<br/><br/> Example: “Phase 3 trials conducted for NSCLC in America” will filter results as – <br/> - Filters:<br/>&nbsp;&nbsp; \n\u2022 Study phase – Phase 3<br/>&nbsp;&nbsp; \n\u2022 Indication – NSCLC<br/> &nbsp;&nbsp;\n\u2022 Region – America (North and South America) <br/>&nbsp;&nbsp;\n\u2022 Study type – Interventional (from Phase 3)<br/> Evisense will filter the pool of studies for NSCLC to apply all filters and provide results that include each filter criteria.</p></mark>",
    question: "What are the search features available?",
    key: "20",
  },
  {
    answer:
      "<mark><p id='text'>To add filters – <br/> 1. Click on the filter option next to the search box <br/><br/><img class='faq-screen_3'><br/><br/>2. The filter box contains a wide range of filters to select from; chose filters to modify your search and select apply<br/><br/><img class='faq-screen_4'></p></mark>",
    question: "How can I filter my search results?",
    key: "21",
  },
  {
    answer:
      "<mark><p id='text'>To clear filter selections  – <br/> 1. Click on the filter option next to the search box <br/><br/><img class='faq-screen_3'><br/><br/>2.	Select ‘reset’ at the bottom right corner<br/><br/><img class='faq-screen_6'></p></mark>",
    question: "How can I clear my filter selections?",
    key: "22",
  },
  {
    answer:
      "<mark><p id='text'>To reset search, click on the clear all option next to the search box  <br/><br/><br/><img class='faq-screen_7'></p></mark>",
    question: "How can I clear my entire search criteria?",
    key: "23",
  },
  {
    answer:
      "<mark><p id='text'>Evisense provides a unique Storyboard feature that allows users to save snippets of their analysis throughout their search journey on 1 canvas. It also provides a free text box. Users can move and place snippets to create a structured report and download it from the platform.</p></mark>",
    question: "What is Evisense Storyboard?",
    key: "24",
  },
  {
    answer:
      "<mark><p id='text'>Evisense allows users to add following items to storyboard – <br/>1. Full text of studies <br/>2.	All charts <br/>3.	Compared studies analysis </p></mark>",
    question: "What can I add to the Evisense storyboard?",
    key: "25",
  },
  {
    answer:
      "<mark><p id='text'>To add charts/analysis to storyboard – <br/> 1. Click on add to storyboard. <br/><br/><img class='faq-screen_8'><br/><br/>2. Select a storyboard name and click add<br/><br/><img class='faq-screen_9'><br/><br/>To add text to storyboard – <br/>1.	Go to the storyboard tab and select the story name<br/>2.	Select ‘Add Textbox’ <br/><br/><img class='faq-screen_10'></p></mark>",
    question: "How can I add text and charts to the storyboard?",
    key: "26",
  },
  {
    answer:
      "<mark><p id='text'>Yes, you can modify the text saved in the textbox.</p></mark>",
    question: "Can I edit/modify text saved to storyboard?",
    key: "28",
  },
  {
    answer:
      "<mark><p id='text'>No, Evisense retains image of the analyses saved to the storyboard</p></mark>",
    question:
      "If I modify the search criteria, will I lose my storyboard saved analysis?",
    key: "29",
  },
  {
    answer:
      "<mark><p id='text'>To download your storyboard analysis, click on the download button at the right corner. <br/><br/><img class='faq-screen_11'></p></mark>",
    question: "How can I download my storyboard analysis?",
    key: "30",
  },
  {
    answer:
      "<mark><p id='text'>Yes, Evisense allows users to save their search journey. To save analysis click on the ‘Save’ button on the top right corner of the platform<br/><br/><img class='faq-screen_12'><br/><br/>To resume journey, go to the ‘Saved Analysis’ tab and click on the journey you want to continue.</p></mark>",
    question: "Can I save my search journey on the platform?",
    key: "31",
  },
  {
    answer:
      "<mark><p id='text'>ZS uses commercially reasonable security precautions to promote the secure access to the Hosted Application Your search data is a secure ZS server and is stored in an encrypted format when saved by you. When not saved, the search terms are temporarily available in your browser memory and can be cleared using the reset option on Evisense or by session logout.</p></mark>",
    question: "How does ZS Evisense secure my search?",
    key: "32",
  },
  {
    answer:
      "<mark><p id='text'>Evisense allows users to do a 1:1 comparison of study attributes. It creates a table with pre-defined entities and reads study to bucket correct study attributes against these entities. Users can select the studies they want to compare.</p></mark>",
    question: "What does the ‘Add to Comparison’ feature do?",
    key: "33",
  },
  {
    answer:
      "<mark><p id='text'>To compare studies – <br/> 1. Select studies from search results  <br/>2. Select ‘Add to comparison’<br/></p></mark>",
    question: "How can I add studies for comparison?",
    key: "34",
  },
  {
    answer:
      "<mark><p id='text'>Users can compare up to 5 studies at a time.</p></mark>",
    question: "How many studies can I add to the comparison tab?",
    key: "35",
  },
  {
    answer:
      "<mark><p id='text'>Yes, Evisense extracts and reads full text of articles from PubMed</p></mark>",
    question:
      "Does Evisense extract full articles from data sources such as PubMed?",
    key: "36",
  },
  {
    answer:
      "<mark><p id='text'>Yes, users can add up to 10 charts in each insights tab. To add more charts –<br/> 1. Click on ‘Add chart’  <br/><br/><img class='faq-screen_13'><br/><br/>2.Select your axis attributes <br/><br/><img class='faq-screen_14'><br/><br/>3.	Click ‘Ok’ to add chart</p></mark>",
    question: "Can I add more charts in the ‘Insights’ tabs?",
    key: "37",
  },
  {
    answer:
      "<mark><p id='text'>No, currently you cannot modify the type of chart</p></mark>",
    question: "Can I change the type of chart in Evisense?",
    key: "38",
  },
  {
    answer:
      "<mark><p id='text'>No, selecting a country/ region in the Geographic Distribution tab will not change your global search filters. It will change the geographic distribution chart and corresponding pie chart to provide insights of the geography selected.</p></mark>",
    question:
      "If I select a particular country/ region in the Geographic Distribution tab, will it change my search criteria?",
    key: "39",
  },
  {
    answer:
      "<mark><p id='text'>Yes, Evisense allows you to add up to 7 drugs in one chart.</p></mark>",
    question:
      "Can I add more than 5 drugs in the Competitive Intelligence tab?",
    key: "40",
  },
];
