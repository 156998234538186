import React from "react";
import { DeleteIcon } from "../../../assets/icons/svg";
import Icon from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteFromCompareStudies,
  toggleReadFullText
} from "../../../reducer/EICCommonSlice";

const CompareStudiesTableColumns = () => {
  const dispatch = useDispatch();

  const compareStudiesData = useSelector(
    (state) => state.EICCommon?.compareStudiesData?.selectData
  );

  const nctIdData =
    compareStudiesData !== undefined &&
    compareStudiesData.map((ele) => {
      return {
        title: <h4 onClick={() => handleReadFullText(ele)} style={{color: '#27A6A4', cursor: 'pointer', marginBottom: 0, whiteSpace: 'nowrap'}}>PMID: {ele.nct_id}</h4>,
        key: ele.nct_id,
        dataIndex: ele.nct_id,
      };
    });

  const columns = [
    compareStudiesData !== undefined && compareStudiesData.length > 0
      ? {
          title: "Attributes",
          key: "attributes",
          dataIndex: "attributes",
          width: "203px",
        }
      : {},
    ...nctIdData,
  ];

  const compareStudiesSorter = (key, a, b) => {
    const firstValue = Array.isArray(a.attributes)
      ? a.attributes[1]
        ? a.attributes[1]
        : "Data Not Available"
      : a.attributes
      ? a.attributes
      : "Data Not Available";
    const secondValue = Array.isArray(b.attributes)
      ? b.attributes[1]
      : b.attributes;

    switch (key) {
      case "attributes":
        return firstValue.localeCompare(secondValue);

      default:
        return false;
    }
  };

  const updatedColumn = columns.map((ele) => {
    return ele.key === "attributes"
      ? {
          ...ele,
          sorter: (a, b) => compareStudiesSorter(ele.key, a, b),
          render: (text) => {
            return Array.isArray(text) ? (
              <>
                <div style={{ fontSize: "0.7rem", fontWeight: 600 }}>
                  <strong style={{ fontSize: "1rem", fontWeight: 800 }}>
                    {text[1]}
                  </strong>{" "}
                  {text[0] && (<> ({text[0]})</>) }
                </div>
              </>
            ) : (
              <strong>{text}</strong>
            );
          },
          align: "start",
          showSorterTooltip: false,
        }
      : {
          ...ele,
          filterIcon: () =>
            compareStudiesData.length > 1 ? (
              <>
                <Icon
                component={DeleteIcon}
                onClick={() => {
                  handleDelete(ele.key);
                }}
                />
              </>
            ) : (
              false
            ),
          onFilter: (value, record) => true,
          filters: [],
          filterDropdownVisible: false,
          align: "start",
          render: (text) => {
            return Array.isArray(text) ? (
              text.length > 1 ? (
                text.map((val) => (
                  <ul id="discstyle">
                    <li>{val}</li>
                  </ul>
                ))
              ) : text.length > 0 ? (
                <p>{text}</p>
              ) : (
                <div style={{ fontSize: 25 }}>-</div>
              )
            ) : text === undefined ? (
              <div style={{ fontSize: 25 }}>-</div>
            ) : (
              <p>{text}</p>
            );
          },
        };
  });

  const handleDelete = (id) => {
    dispatch(deleteFromCompareStudies(id));
  };

  const handleReadFullText = (id) => {
    dispatch(toggleReadFullText({isOpen: true, id: id.nct_id, key: id.key, title: id.study_title, source: 'compare_studies'}));
  };

  return updatedColumn;
};
export default CompareStudiesTableColumns;
