import React, { useState, useEffect } from "react";
import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import { getUserInfo } from "./services/InsightsViewAPI";
import { IDM_URL } from './environments/environments';

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const [userInfo, setUserInfo] = useState("");

  const userInfoFun = async () => {
    let response = await getUserInfo();
    setUserInfo(response);
  };

  useEffect(() => {
    userInfoFun();
  },[]);

  return (
    <Routes>
      <Route
        {...restOfProps}
        render={(props) =>
          userInfo.auth_status == "success" ? (
            // <Component {...props} />
            <Outlet />
          ) : (
            <Navigate to={IDM_URL} />
          )
        }
      />
    </Routes>
  );
}

export default ProtectedRoute;
