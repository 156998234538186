import { Tooltip } from "antd";
import React, { useState } from "react";
import "./StudyFullTextView.scss";
import Icon from "@ant-design/icons";
import { ExpandIcon } from "../../../../assets/icons/svg";
import { useDispatch } from "react-redux";
import { toggleReadFullText } from "./../../../../reducer/EICCommonSlice";
import Paragraph from "antd/es/typography/Paragraph";

const StudyFullTextView = ({ data, link }) => {
  const dispatch = useDispatch();
  const [truncated, setTruncated] = useState(false);
  const showFullTextView = (textData) => {
    dispatch(toggleReadFullText({
      isOpen: true,
      id: textData.nct_id,
      key: textData.key,
      title: textData.study_title,
      source: 'single_study',
      dataSource: textData.data_source
    }));
  };

  return (
    <Tooltip placement="rightTop" title={truncated ? data : undefined} style={{ width: "550px" }}>
      <Paragraph onClick={() => showFullTextView(link)}
        style={{
          margin: 0,
          color: "#2D8B93",
          cursor: "pointer",
          width: "386px",
          float: 'left',
          whiteSpace: 'normal'
        }}
        ellipsis={{ rows: 3, onEllipsis: setTruncated }}
      >
        <>{data}</>
      </Paragraph>
      <Icon onClick={() => showFullTextView(link)}
        component={ExpandIcon}
        style={{
          cursor: "pointer",
          marginLeft: "1rem",
        }}
      />
    </Tooltip>
  );
};

export default StudyFullTextView;
