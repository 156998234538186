import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { Typography } from "antd";
import { Input, Button } from "antd";
import { useNavigate } from "react-router-dom";
import "./SearchComponent.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  addGlobalFilters,
  setAdvanceFilters,
  setAvailableFilterList,
  setFilterState,
  setSearchLoadingReducer,
  setSearchTerm,
} from "../../../reducer/EICCommonSlice";

const { Title } = Typography;
const { Search } = Input;

const SearchComponent = () => {
  let { selectedAdvanceFilters, searchTerm } = useSelector(
    (state) => state.EICCommon
  );
  const [searchText, setSearchText] = useState(searchTerm);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();


  const sessionSearchterm = sessionStorage.getItem("search_value");

  // const userInfo = async () => {
  //   let response = await getUserInfo();
  //   console.log('userresponse',response)
  // };

  // useEffect(() => {
  //   userInfo();
  // },[]);

  useEffect(() => {
    setSearchText(sessionSearchterm);
  }, [sessionSearchterm]);


  const onSearchClick = async (value) => {
    if (value !== "") {
      dispatch(setSearchLoadingReducer(true));
      dispatch(addGlobalFilters([])); // setting criteria list
      dispatch(setAdvanceFilters({})); // setting selected advance filters
      dispatch(setAvailableFilterList([])); // setting selected values in left filter panel
      dispatch(setFilterState("open"));

      // setLoading(true);
      // const response = await getSearchResult(value, studyListPageNumber);
      // dispatch(setStudyListTableData(response.result));
      dispatch(setSearchTerm(value));
      // setLoading(false);
      sessionStorage.setItem("search_value", value);
      navigate("/home/insights");
      // dispatch(setSearchLoadingReducer(false));
      // setLoading(false);
    }
  };

  const getInputText = (value) => {
    // if (searchedValue){
    //   setSearchText(searchedValue);
    // } else {
    sessionStorage.setItem("search_value", value.target.value);
    setSearchText(value.target.value);
  };

  const customButton = (
    <Button
      type="primary"
      disabled={
        sessionSearchterm
          ? sessionSearchterm.length <= 1
            ? true
            : false
          : true
      }
    >
      Search
    </Button>
  );

  return (
    <>
      <Spin spinning={loading} style={{ top: "50vh", height: "100%" }}>
        <div id="main-container">
              {/* <Breadcrumb.Item className="title">Home</Breadcrumb.Item> */}
          {/* <div className="home-container">
            <Breadcrumb id="home-breadcrumb" separator=">">
              <Breadcrumb.Item href="javascript:void(0)" className="title">
                ZS Evisense
              </Breadcrumb.Item>
            </Breadcrumb>
            <Title className="search-heading">ZS Evisense</Title>
          </div> */}
          <div className="search-container">
            <Title className="search-title">
              Explore external evidence to identify key trends and
              Insights
            </Title>
            <Title className="search-subtitle">
              Start your analysis by entering your search terms below.
            </Title>
            <Search
              className="search-field"
              placeholder="I'm looking for..."
              allowClear
              href="javascript:void(0)"
              enterButton={customButton}
              size="large"
              onSearch={(e) => onSearchClick(e)}
              style={{
                width: 500,
              }}
              // value={searchText ? searchText : searchedValue}
              onChange={getInputText}
              defaultValue={sessionSearchterm ? sessionSearchterm : ""}
            />
          </div>
        </div>
      </Spin>
    </>
  );
};

export default SearchComponent;
