import { Button, Space } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setExploreViewMode, setIsExcludeStudyConfig } from "../../../reducer/EICCommonSlice";

export const ViewExcludeBtn = () => {
    const dispatch = useDispatch();

    const {
        EICCommon: {
            selectedAnalysisData },
    } = useSelector((state) => state);

    const handleOpenExcludedAction = () => {
        dispatch(setExploreViewMode('excluded-study-list'));
    };

    return (
        <>
            {
                selectedAnalysisData?.excluded_study_ids?.length > 0 &&
                <Button onClick={handleOpenExcludedAction} style={{marginLeft: 10}}>
                    <Space>&nbsp;View Excluded Studies</Space>
                </Button>
            }
        </>
    )
};

export const ExcludeActionBtn = () => {
    const dispatch = useDispatch();

    const {
        EICCommon: {
            retrieveSelectedKeys },
    } = useSelector((state) => state);

    const handleExcludeAction = () => {
        const selectedStudyIds = retrieveSelectedKeys?.selectData?.map((item) => item.pm_id);
        if (!selectedStudyIds || selectedStudyIds.length < 1) return;
        dispatch(setIsExcludeStudyConfig({ isOpen: true, newExcludeStudyIds: selectedStudyIds }));
    };
    return (
        <>
            {
                retrieveSelectedKeys?.selectKey?.length > 0 &&
                <Button onClick={handleExcludeAction} style={{ marginLeft: 10 }}>
                    <Space>&nbsp;Exclude Studies</Space>
                </Button>
            }
        </>
    )
};