const SavedAnalysisSorter = (type, value1, value2) => {
  switch (type) {
    case "analysis_name":
      return value1.analysis_name.title.localeCompare(value2.analysis_name.title);
    case "last_updated":
      return (
        new Date(value1.last_updated.date) - new Date(value2.last_updated.date)
      );
    case "created_date":
      return new Date(value1.created_date) - new Date(value2.created_date);
    case "therapy_area":
      return value1.therapy_area.localeCompare(value2.therapy_area);
    default:
      return null;
  }
};

export default SavedAnalysisSorter;
