import React, { useState } from "react";
import { Modal, Input } from "antd";
import './CollaboratorConfirmModal.scss';
import { useDispatch, useSelector } from "react-redux";
import { toggleCollaboratorConfirm, setCollaboratorModal, setComingFromCollaboratorView, resetAll, setSearchLoadingReducer, setStudyListTableData, setSelectedAnalysisData, setGlobalRawList, setAvailableFilterList, setSearchTerm} from '../../../reducer/EICCommonSlice';
import { useNavigate } from "react-router-dom";
import { getSearchResult, lockUnlockStoryBoard } from "../../../services/InsightsViewAPI";
import { cloneDeep } from "lodash";
import { PromiseSleep } from "../../../shared/utils/CommonUtil";


const CollaboratorConfirmModal = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [saveLoading, setSaveLoading] = useState(false);

    const {
        EICCommon: {
            checkCollaboratorConfirm,
            checkCollaboratorModal,
            checkCollaboratorDetails,
            selectedAnalysisData,
            globalRawList,
            studyListTableData,
        },
    } = useSelector((state) => state);

    const closeSaveModal = () => {
        dispatch(toggleCollaboratorConfirm({ isCheck: false, source: 'navbar', navToPath: null }));
        dispatch(setCollaboratorModal({isOpen: false}))
    };

    const navigateCollaborator = async () => {
        if(checkCollaboratorDetails.lock){
          await unLockStoryboard();
        } 
        sessionStorage.setItem("search_value", "");
        dispatch(setSearchTerm(''));
        await PromiseSleep(100);
        const rowList = cloneDeep(globalRawList)
        dispatch(setSelectedAnalysisData(null));
        dispatch(resetAll());
        if(checkCollaboratorConfirm?.navToPath == '/saved-analysis'){
            dispatch(setComingFromCollaboratorView(true))
        }        
        if(checkCollaboratorConfirm?.navToPath?.startsWith('/home')) {
            dispatch(setSearchLoadingReducer(true));
            const data = await getSearchResult('', 1, {}, []);
            dispatch(setStudyListTableData(data.result));
            dispatch(setSearchLoadingReducer(false));
            
            dispatch(setGlobalRawList(rowList));
            dispatch(setAvailableFilterList(rowList));    
        }
        dispatch(setCollaboratorModal({isOpen: false}))
        navigate(checkCollaboratorConfirm?.navToPath);
    }

    const unLockStoryboard = async() => {
      let payload = {
        analysis_id: selectedAnalysisData?.analysis_id,
        storyboard_id: checkCollaboratorDetails?.storyboard_id,
        sections: [checkCollaboratorDetails?.section_id],
        lock: false,
      }
      try {
        const response = await lockUnlockStoryBoard(payload);
        if (response?.status === "success") {
          console.log('success')
        }
      } catch (error) {
       console.log('error',error)
      }   
    }

    const getConfirmModalText = () => {
        if(checkCollaboratorConfirm?.navToPath == '/saved-analysis' && checkCollaboratorDetails.lock){
          return "<p>You are exiting the shared storyboard and returning to the saved analysis page.  Please note your editing session will end and your colleagues may begin editing this storyboard.  All of your changes have been saved.</p>"
        } else if ((checkCollaboratorConfirm?.navToPath?.startsWith('/home') || (checkCollaboratorConfirm?.navToPath == '/landingpage')) && !checkCollaboratorDetails.lock){
          return "<p>Please note the analysis sharing feature currently only supports sharing storyboards.  By navigating to another part of the tool, you will leave the shared storyboard and start a new analysis.<br/><br/>  If you would like to return to the shared storyboard later, please open it from the saved analysis page.</p>"
        } else if ((checkCollaboratorConfirm?.navToPath?.startsWith('/home') || (checkCollaboratorConfirm?.navToPath == '/landingpage')) && checkCollaboratorDetails.lock){
          return "<p>Please note the analysis sharing feature currently only supports sharing storyboards.  By navigating to another part of the tool, you will leave the shared storyboard and start a new analysis.<br/><br/> Your editing session will end and your colleagues may begin editing this storyboard.  All of your changes have been saved.<br/><br/> If you would like to return to the shared storyboard later, please open it from the saved analysis page.</p>"
        }
      }
      
      const getConfirmModalOkText = () => {
        if((checkCollaboratorConfirm?.navToPath == '/saved-analysis' || checkCollaboratorConfirm?.navToPath?.startsWith('/home') || (checkCollaboratorConfirm?.navToPath == '/landingpage')) && checkCollaboratorDetails.lock){
          return 'Keep Editing'
        } else if ((checkCollaboratorConfirm?.navToPath?.startsWith('/home') || (checkCollaboratorConfirm?.navToPath == '/landingpage')) && !checkCollaboratorDetails.lock){
          return 'Stay on the page'
        } 
      }

    return (
        <>
            <Modal
                title={"Confirm Action"}
                centered
                open={checkCollaboratorModal.isOpen}
                // okText={"Continue"}
                okText={getConfirmModalOkText() }
                cancelText={'Leave'}
                onOk={() => closeSaveModal()}
                onCancel={async() => await navigateCollaborator()}
                closable={false}
                wrapClassName={'collaborator-confirm'}
            >
                <p
                  dangerouslySetInnerHTML={{ __html: getConfirmModalText() }}
                  className="collaborator-content"
                /> 
            </Modal>
        </>
    );
};

export default CollaboratorConfirmModal;