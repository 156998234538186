
import React from "react";
import Quill from 'quill';
import { Col, Row } from 'antd';

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
    "arial",
    "comic-sans",
    "courier-new",
    "georgia",
    "helvetica",
    "lucida"
];
Quill.register(Font, true);

// Formats objects for setting up the Quill editor
export const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "align",
    "strike",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
];

// Quill Toolbar component
export const QuillToolbar = (props) => (
    <>
        {
            props.id &&
            <div id={"qillEditorToolbar" + props.id}>
                <Row>
                    <Col span={props.extra ? 23 : 24}>

                        <span className="ql-formats" style={props.readOnly? { pointerEvents: 'none', opacity: 0.7 } : {}}>
                            <select className="ql-size" defaultValue="medium">
                                <option value="small">Small</option>
                                <option value="medium">Medium</option>
                                <option value="large">Large</option>
                            </select>

                            <button className="ql-bold" title="Bold"/>
                            <button className="ql-italic" title="Italic"/>
                            <button className="ql-underline" title="Underline"/>
                            <button className="ql-strike" title="Strikethrough"/>

                            <button className="ql-list" value="ordered" title="Numbered List"/>
                            <button className="ql-list" value="bullet" title="Bullet List"/>
                            <button className="ql-indent" value="-1" title="Decrease Indent"/>
                            <button className="ql-indent" value="+1" title="Increase Indent"/>

                            <select className="ql-align" />

                            <button className="ql-link" title="Add Link"/>
                        </span>
                    </Col>
                    {
                        props.extra &&
                        <Col span={1}>
                            <div style={{ padding: '0 16px' }}>
                                <>
                                    {props.extra}
                                </>
                            </div>
                        </Col>
                    }
                </Row>

            </div>
        }
    </>
);

export default QuillToolbar;