import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Modal,
  Row,
  Select,
  Tooltip,
  Spin,
  notification,
} from "antd";
import {
  DrugInfo,
  FacetSearchIcon,
  DeleteSmallIcon,
} from "../../../assets/icons/svg";
import Highcharts from "react-highcharts";
import { useSelector, useDispatch } from "react-redux";
import {
  addChartEvidenceLandscapeList,
  removeChartEvidenceLandscapeList,
  updateChartEvidenceLandscapeList,
  setDropDownList,
  setEvidenceLandscapeCleanupStatus,
  setAdvanceFilterDropdownList,
  setInteractiveFilters
} from "./../../../reducer/EICCommonSlice";
import "./EvidenceLandscape.scss";
import cloneDeep from "lodash/cloneDeep";
import defaultChartConfig from "./EvidenceLandscapeHelper";
import {
  getAdvancedSearchData,
  getEvidenceBarChartData,
  mapAdvanceFilters,
} from "./../../../services/InsightsViewAPI";
import Icon from "@ant-design/icons";
import AddToStoryBoardView from "../../add-to-storyboard-view/AddToStoryBoardView";
import { useNavigate } from "react-router-dom";
import { FormatAdvFilterDataForEvidenceChart } from "../../../shared/utils/CommonUtil";
import { stubFalse } from "lodash";

const { Option } = Select;
const { confirm } = Modal;

const EvidenceLandscapeContent = () => {
  const [loading, setLoading] = useState(false);
  const [dropdownList, setDropdownList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [chartModalConfig, setChartModalConfig] = useState({
    isVisible: false,
    currentSelectedIndex: 0,
  });
  const [defaultModalView, setDefaultModalView] = useState({
    viewByFilterList: [],
    viewByChild: [],
  });
  const defaultFilterData = {
    viewBy: "Endpoints",
    viewByChild: [],
    // breakdownBy: "None",
    breakdownBy: 'Endpoint Type',
    breakdownByChild: [],
    viewByFilterList: [],
    breakdownByFilterList: [],
  };
  const [multiSelectState, setMultiSelectState] = useState({
    isOpen: false,
  });
  const [windowWidth, setWindowWidth]   = useState(window.innerWidth);
  const updateDimensions = () => {
    setWindowWidth(window.innerWidth);
  }
  const [interactiveSelection, setInteractiveSelection] = useState({});
  useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if(interactiveSelection?.category && interactiveSelection?.seriesName) {
      showInteractiveConfirm({ ...interactiveSelection });
    }
  }, [interactiveSelection])

  const [addNewChartInputValues, setAddNewChartInputValues] =
    useState(defaultFilterData);

  const config = cloneDeep(defaultChartConfig);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let chartList = useSelector(
    (state) => state.EICCommon.evidenceLandscapeChartList
  );
  const {
    EICCommon: {
      InteractiveStudyListPageNumber,
      searchTerm,
      selectedAnalysisData,
      excludedStudyIds,
    },
  } = useSelector((state) => state);

  let cleanupStatus = useSelector(
    (state) => state.EICCommon.cleanupEvidenceLandscape
  );
  if (cleanupStatus) {
    dispatch(setEvidenceLandscapeCleanupStatus(false));
  }

  const selectedAdvanceFilters = useSelector(
    (state) => state.EICCommon.selectedAdvanceFilters
  );

  const advanceFilterDropdownList = useSelector(
    (state) => state.EICCommon.advanceFilterDropdownList
  );

  useEffect(() => {
    getFilterList();
  }, []);

  useEffect(() => {
    if (dropdownList.length > 0 && chartList.length <= 0) {
      const filters = { ...addNewChartInputValues };
      const payload = {
        search_text: selectedAnalysisData ? selectedAnalysisData.search_text : searchTerm,
        study_filters: selectedAnalysisData ? mapAdvanceFilters(selectedAnalysisData.study_filters) : mapAdvanceFilters(selectedAdvanceFilters),
        chart_filters: {
          chart_1: formatFilters(filters), // default values in chart
        },
        excluded_ids: excludedStudyIds
      };
      getChartData(payload);
    }
  }, [dropdownList]);

  useEffect(() => {
    getAllChartsData();
  }, [selectedAdvanceFilters, searchTerm, excludedStudyIds]);

  const getFilterList = async () => {
    // setLoading(true);
    let data = advanceFilterDropdownList;
    if(!data || Object.keys(data).length < 1) {
      data = await getAdvancedSearchData();
      dispatch(setAdvanceFilterDropdownList(data));
    }
    const newDropdownList = await mapDropdownList(data);
    setDropdownList(newDropdownList);
    dispatch(setDropDownList(newDropdownList));
    // setLoading(false);
  };

  const mapDropdownList = (data) => {
    let formattedList = [];
    for (const obj in data) {
      if (obj !== "outcome_types" && obj !== "patient_population_type") {
        const name = obj.split("_").join(" ");
        // update logic to capitalize all words 1st letter

        if (name === "data source") formattedList.push("Data Source");
        else if (name === "study language")
          formattedList.push("Study Language");
        else if (name === "study type category")
          formattedList.push("Study Type");
        else if (name === "statistical analysis")
          formattedList.push("Statistical Analysis");
        else if (name === "study status") formattedList.push("Study Status");
        else if (
          obj !== "publication_min_date" &&
          obj !== "publication_max_date"
        )
          formattedList.push(name.charAt(0).toUpperCase() + name.slice(1));
      } else if (obj === "outcome_types") {
        const outcomeList = data[obj].map((item) => item.outcome_type_name);
        formattedList = [...formattedList, ...outcomeList];
      } else if (obj === "patient_population_type") {
        const subList = data[obj].map((item) => item[`${obj}_name`]);
        formattedList = [...formattedList, ...subList];
      }
    }
    // const newDropdownList = [...formattedList, "Phase"];
    const newDropdownList = [...formattedList];
    return newDropdownList;
  };

  const getChartData = async (payload) => {
    setLoading(true);
    const data = await getEvidenceBarChartData(payload);

    if(!data) setLoading(false);

    if (data.status === undefined && data.status !== "FAILED") {
      const defaultChartConfig = cloneDeep(config);
      const chartConfig = chartConfigMapper(defaultChartConfig, payload, data);
      dispatch(
        addChartEvidenceLandscapeList({
          appliedFilters: {
            viewBy: "Endpoints",
            viewByFilterList: data["chart_1"].view_by_values,
            viewByChild: mapSelection([], data["chart_1"].view_by_values).slice(
              0,
              10
            ),
            // breakdownBy: "None",
            breakdownBy: 'Endpoint Type',
            breakdownByFilterList: data["chart_1"].breakdown_by_values,
            breakdownByChild: mapSelection(
              [],
              data["chart_1"].breakdown_by_values
            ).slice(0, 5),
          },
          config: chartConfig,
        })
      );
      setDefaultModalView({
        ...defaultModalView,
        viewByFilterList: data["chart_1"].view_by_values,
        viewByChild: data["chart_1"].view_by_values.slice(0, 10),
      });
      setLoading(false);
    } else {
      console.log("Something went wrong");
      setLoading(false);
    }
  };

  const getAllChartsData = async () => {
    setLoading(true);

    const tempChartList = cloneDeep(chartList);

    const payload = {
      search_text: searchTerm,
      study_filters: mapAdvanceFilters(selectedAdvanceFilters),
      chart_filters: {},
      excluded_ids: excludedStudyIds
    };

    // Don't trigger API if no chart rendered yet
    if (tempChartList.length <= 0) {
      // setLoading(false);
      return;
    }

    // loop over chart list and create payload
    tempChartList.forEach((element, index) => {
      payload.chart_filters[`chart_${index + 1}`] = formatFilters(
        element.appliedFilters,
        "external"
      );
    });
    const data = await getEvidenceBarChartData(payload);

    if(!data) setLoading(false);

    const responseChartList = cloneDeep(chartList);

    if (data.status === undefined && data.status !== "FAILED") {
      responseChartList.forEach((element, index) => {
        const defaultChartConfig = cloneDeep(config);
        const chartConfig = chartConfigMapper(
          defaultChartConfig,
          payload,
          data,
          `chart_${index + 1}`
        );
        element.config = chartConfig;

        let sliceUpto =
          payload.chart_filters[`chart_${index + 1}`].view_by_key ===
          "Endpoints"
            ? 10
            : 7;
        element.appliedFilters = {
          // viewBy: payload.chart_filters[`chart_${index + 1}`].view_by_key,
          viewBy: getFormattedText(payload.chart_filters[`chart_${index + 1}`].view_by_key),
          // viewBy: payload.chart_filters[`chart_${index + 1}`].breakdown_by, 
          breakdownBy:  getFormattedText(payload.chart_filters[`chart_${index + 1}`].breakdown_by),
          viewByFilterList: data[`chart_${index + 1}`].view_by_values,
          breakdownByFilterList: data[`chart_${index + 1}`].breakdown_by_values,
          viewByChild: mapSelection(
            payload.chart_filters[`chart_${index + 1}`].filter_by_values,
            data[`chart_${index + 1}`].view_by_values
          ).slice(0, sliceUpto),
          breakdownByChild: mapSelection(
            payload.chart_filters[`chart_${index + 1}`].breakdown_by_values,
            data[`chart_${index + 1}`].breakdown_by_values
          ).slice(0, 5),
        };
      });

      dispatch(updateChartEvidenceLandscapeList(responseChartList));
      setLoading(false);
    } else {
      console.log("Something went wrong");
      setLoading(false);
    }
  };

  const formatFilters = (filters, type) => {
    const updatedFilters = {
      view_by_key: filters.viewBy.split(" ").join("_"),
      filter_by_values: type === "external" ? [] : filters.viewByChild,
      breakdown_by: filters.breakdownBy.split(" ").join("_"),
      breakdown_by_values: type === "external" ? [] : filters.breakdownByChild,
      max_entities_view_by: filters.viewBy === "Endpoints" ? 10 : 7,
      max_entities_breakdown_by: 5,
    };
    return updatedFilters;
  };

  const handleFilterChange = async (event, idx, category) => {
    if (
      (category === "viewByChild" || category === "breakdownByChild") && multiSelectState.isOpen
    ) {
      const newChartList = cloneDeep(chartList);
      newChartList[idx].appliedFilters[category] = event;
      dispatch(updateChartEvidenceLandscapeList(newChartList));
    } else {
      setLoading(true);
      const previousCategoryValue = chartList[idx].appliedFilters[category];

      const newChartList = cloneDeep(chartList);
      newChartList[idx].appliedFilters[category] = event;

      //Neha - logic for study type stack bar 
      if((event == "Study Type" || event == 'Study subtype') && newChartList[idx].appliedFilters['viewBy'] == 'Study Type'){
        setMultiSelectState({ isOpen: false });
        newChartList[idx].appliedFilters['breakdownBy'] = 'Study subtype';
      } 
      else if (newChartList[idx].appliedFilters['breakdownBy'] == 'Study subtype' && event != 'Study Type'){
        setMultiSelectState({ isOpen: false });
        newChartList[idx].appliedFilters['breakdownBy'] = 'None';
      }

      if((event == "Endpoints" || event == 'Endpoint Type') && newChartList[idx].appliedFilters['viewBy'] == 'Endpoints'){
        setMultiSelectState({ isOpen: false });
        newChartList[idx].appliedFilters['breakdownBy'] = 'Endpoint Type';
      } 
      else if (newChartList[idx].appliedFilters['breakdownBy'] == 'Endpoint Type' && event != 'Endpoints'){
        setMultiSelectState({ isOpen: false });
        newChartList[idx].appliedFilters['breakdownBy'] = 'None';
      }

      const payload = {
        search_text: searchTerm,
        study_filters: mapAdvanceFilters(selectedAdvanceFilters),
        chart_filters: {
          chart_1: formatFilters(newChartList[idx].appliedFilters),
        },
        excluded_ids: excludedStudyIds
      };

      if (category === "viewBy")
        payload.chart_filters.chart_1.filter_by_values = [];

      if (category === "breakdownBy")
        payload.chart_filters.chart_1.breakdown_by_values = [];

      const data = await getEvidenceBarChartData(payload);

      if (data.status === undefined && data.status !== "FAILED") {
        const defaultChartConfig = cloneDeep(config);
        const chartConfig = chartConfigMapper(
          defaultChartConfig,
          payload,
          data
        );

        newChartList[idx].config = chartConfig;

        let sliceUpto =
          newChartList[idx].appliedFilters.viewBy === "Endpoints" ? 10 : 7;

        newChartList[idx].appliedFilters.viewByChild = mapSelection(
          payload.chart_filters["chart_1"].filter_by_values,
          data["chart_1"].view_by_values
        ).slice(0, sliceUpto);
        newChartList[idx].appliedFilters.breakdownByChild = mapSelection(
          payload.chart_filters["chart_1"].breakdown_by_values,
          data["chart_1"].breakdown_by_values
        ).slice(0, 5);

        newChartList[idx].appliedFilters.viewByFilterList =
          data["chart_1"].view_by_values;
        newChartList[idx].appliedFilters.breakdownByFilterList =
          data["chart_1"].breakdown_by_values;

        dispatch(updateChartEvidenceLandscapeList(newChartList));
        setLoading(false);
      } else {
        openNotification("error");
        newChartList[idx].appliedFilters[category] = previousCategoryValue;
        dispatch(updateChartEvidenceLandscapeList(newChartList));
        setLoading(false);
      }
    }
  };

  const onFilterClose = async (event, idx, category) => {
    if (event) {
      // On open do nothing
      setMultiSelectState({ isOpen: true });
      return;
    } else {
      // On close call the API
      setLoading(true);
      setMultiSelectState({ isOpen: false });
      const newChartList = cloneDeep(chartList);

      const payload = {
        search_text: searchTerm,
        study_filters: mapAdvanceFilters(selectedAdvanceFilters),
        chart_filters: {
          chart_1: formatFilters(newChartList[idx].appliedFilters),
        },
        excluded_ids: excludedStudyIds
      };

      if (category === "viewBy")
        payload.chart_filters.chart_1.filter_by_values = [];

      if (category === "breakdownBy")
        payload.chart_filters.chart_1.breakdown_by_values = [];

      const data = await getEvidenceBarChartData(payload);

      if (data.status === undefined && data.status !== "FAILED") {
        const defaultChartConfig = cloneDeep(config);
        const chartConfig = chartConfigMapper(
          defaultChartConfig,
          payload,
          data
        );

        newChartList[idx].config = chartConfig;

        let sliceUpto =
          newChartList[idx].appliedFilters.viewBy === "Endpoints" ? 10 : 7;

        newChartList[idx].appliedFilters.viewByChild = mapSelection(
          payload.chart_filters["chart_1"].filter_by_values,
          data["chart_1"].view_by_values
        ).slice(0, sliceUpto);
        newChartList[idx].appliedFilters.breakdownByChild = mapSelection(
          payload.chart_filters["chart_1"].breakdown_by_values,
          data["chart_1"].breakdown_by_values
        ).slice(0, 5);

        newChartList[idx].appliedFilters.viewByFilterList =
          data["chart_1"].view_by_values;
        newChartList[idx].appliedFilters.breakdownByFilterList =
          data["chart_1"].breakdown_by_values;

        dispatch(updateChartEvidenceLandscapeList(newChartList));
        setLoading(false);
      } else {
        openNotification("error");
        dispatch(updateChartEvidenceLandscapeList(newChartList));
        setLoading(false);
      }
    }
  };

  const mapSelection = (input, output) => {
    if (input.length <= 0) {
      return output;
    } else {
      return input;
    }
  };

  const getFormattedText = (rawText) => {
    return rawText.split("_").join(" ");
  };

  const chartConfigMapper = (
    chartConfig,
    request,
    response,
    chartKey = "chart_1",
  ) => {
    let  viewBy = request.chart_filters[chartKey].view_by_key;
    let breakdownBy = request.chart_filters[chartKey].breakdown_by;
    // mapping logic based on data and payload
    chartConfig.xAxis.categories = response[chartKey].chart_data.categories;
    chartConfig.tooltip = {
      backgroundColor: "#2F3C2C",
      style: {
        color: "#FAFAFA",
      },
    };
    chartConfig.xAxis.title.text = `<strong>${getFormattedText(
      request.chart_filters?.[chartKey].view_by_key
    )}</strong>`;

    if (request.chart_filters[chartKey].breakdown_by !== "None") {
      chartConfig.series = [...response[chartKey].chart_data.series];
      // chartConfig.tooltip.formatter = function () {
      //   // viewBy = request.chart_filters[chartKey].view_by_key;
      //   // breakdown_by = request.chart_filters[chartKey].breakdown_by;
      //   return `${getFormattedText(
      //     request.chart_filters[chartKey].view_by_key
      //   )} : ${this.x} <br/> ${getFormattedText(
      //     request.chart_filters[chartKey].breakdown_by
      //   )} : ${this.series.name} <br/> Number of Studies : ${this.y}<br/>`;
      // };
      chartConfig.legend.enabled = true;
    } else {
      chartConfig.series = [{ data: response[chartKey].chart_data.series }];
      // chartConfig.tooltip.formatter = function () {
      //   return `${getFormattedText(
      //     request.chart_filters[chartKey].view_by_key
      //   )} : ${this.x} <br/> Number of Studies : ${this.y} <br/>`;
      // };
      chartConfig.colors = ["#2F9BFF"];
      chartConfig.legend.enabled = false;
    }
    chartConfig.extra = {
      viewBy,
      breakdownBy
    };

    //below logic is to show Stack bar chart when Study Type is selected
    if ((request.chart_filters[chartKey].view_by_key == 'Study_Type' && request.chart_filters[chartKey].breakdown_by == 'Study_subtype') ||
    (request.chart_filters[chartKey].view_by_key == 'Endpoints' && request.chart_filters[chartKey].breakdown_by == 'Endpoint_Type')
    ){
      chartConfig.plotOptions.series.stacking = 'normal';
    }

    return chartConfig;
  };

  const handleModalSelection = async (event, field) => {
    setLoading(true);
    const addNewChartObj = { ...addNewChartInputValues, [field]: event };

     //Neha - logic for study type stack bar 
     if((event == "Study Type" || event == 'Study subtype') && addNewChartObj['viewBy'] == 'Study Type'){
      setMultiSelectState({ isOpen: false });
      addNewChartObj['breakdownBy'] = 'Study subtype';
    } 
    else if (addNewChartObj['breakdownBy'] == 'Study subtype' && event != 'Study Type'){
      setMultiSelectState({ isOpen: false });
      addNewChartObj['breakdownBy'] = 'None';
    }

    if((event == "Endpoints" || event == 'Endpoint Type') && addNewChartObj['viewBy'] == 'Endpoints'){
      setMultiSelectState({ isOpen: false });
      addNewChartObj['breakdownBy'] = 'Endpoint Type';
    } 
    else if (addNewChartObj['breakdownBy'] == 'Endpoint Type' && event != 'Endpoints'){
      setMultiSelectState({ isOpen: false });
      addNewChartObj['breakdownBy'] = 'None';
    }
    

    const payload = {
      search_text: searchTerm,
      study_filters: mapAdvanceFilters(selectedAdvanceFilters),
      chart_filters: {
        chart_1: formatFilters(addNewChartObj), // default values in chart
      },
      excluded_ids: excludedStudyIds
    };

    if (field === "viewBy") payload.chart_filters.chart_1.filter_by_values = [];

    if (field === "breakdownBy")
      payload.chart_filters.chart_1.breakdown_by_values = [];

    const data = await getEvidenceBarChartData(payload);

    if (data.status === undefined && data.status !== "FAILED") {
      let sliceUpto =
        payload.chart_filters["chart_1"].view_by_key === "Endpoints" ? 10 : 7;

      const newFilterData = {
        ...addNewChartObj,
        viewByFilterList: data.chart_1.view_by_values,
        viewByChild: mapSelection(
          payload.chart_filters["chart_1"].filter_by_values,
          data.chart_1.view_by_values
        ).slice(0, sliceUpto),
        breakdownByFilterList: data.chart_1.breakdown_by_values,
        breakdownByChild: mapSelection(
          payload.chart_filters["chart_1"].breakdown_by_values,
          data.chart_1.breakdown_by_values
        ).slice(0, 5),
      };
      setAddNewChartInputValues(newFilterData);
      setLoading(false);
    } else {
      openNotification("error");
      setLoading(false);
    }
  };

  const addNewChart = async () => {
    setLoading(true);
    const filters = { ...addNewChartInputValues };
    const payload = {
      search_text: searchTerm,
      study_filters: mapAdvanceFilters(selectedAdvanceFilters),
      chart_filters: {
        chart_1: formatFilters(filters),
      },
      excluded_ids: excludedStudyIds
    };

    const data = await getEvidenceBarChartData(payload);

    if (data.status === undefined && data.status !== "FAILED") {
      const defaultChartConfig = cloneDeep(config);
      const chartConfig = chartConfigMapper(defaultChartConfig, payload, data);

      let sliceUpto =
        payload.chart_filters["chart_1"].view_by_key === "Endpoints" ? 10 : 7;

      filters.viewByFilterList = data["chart_1"].view_by_values;
      filters.viewByChild = mapSelection(
        payload.chart_filters["chart_1"].filter_by_values,
        data["chart_1"].view_by_values
      ).slice(0, sliceUpto);

      filters.breakdownByFilterList = data["chart_1"].breakdown_by_values;
      filters.breakdownByChild = mapSelection(
        payload.chart_filters["chart_1"].breakdown_by_values,
        data["chart_1"].breakdown_by_values
      ).slice(0, 5);

      const newChart = { config: chartConfig, appliedFilters: filters };
      dispatch(addChartEvidenceLandscapeList(newChart));
      setModalVisible(false);
      setLoading(false);
    } else {
      console.log("Something went wrong");
      setLoading(false);
    }

    setAddNewChartInputValues({
      ...defaultFilterData,
      viewByFilterList: defaultModalView.viewByFilterList,
      viewByChild: defaultModalView.viewByChild,
    });
  };

  const deleteChart = (index) => {
    confirm({
      title: "Delete Chart",
      okText: "Delete",
      okType: "danger",
      content: `Are you sure you want to delete the Studies by ${chartList[index].appliedFilters.viewBy} chart from the Evidence Landscape View?`,
      centered: true,

      onOk() {
        setLoading(true);
        const newList = [...chartList];
        newList.splice(index, 1);
        dispatch(removeChartEvidenceLandscapeList(newList));
        setLoading(false);
      },
      onCancel() {},
    });
  };

  const showInteractiveConfirm = ({category, seriesName, viewBy, breakdownBy}) => {
    confirm({
      title: 'Confirm changes',
      content: `Are you sure you want to leave this page? The changes you made will affect your overall results & add a filter ${getFormattedText(viewBy)} ${breakdownBy != 'None' ? 'and' : ''} ${breakdownBy != 'None' ? getFormattedText(breakdownBy) : ''}`,
      okText: 'Move to Explore',
      okType: 'primary',
      cancelText: 'Continue on Insights',
      closable: true,
      icon: null,
      wrapClassName: 'interactive-filter-confirm',
      centered: true,      
      onOk() {
        handleInteractiveCharts(
          category, seriesName, viewBy, breakdownBy, true, selectedAdvanceFilters
        );
      },
      onCancel(closeFn) {
        if(typeof(closeFn) === 'function' && closeFn?.name !== "") {
          handleInteractiveCharts(
            category, seriesName, viewBy, breakdownBy, false, selectedAdvanceFilters
          );
          closeFn();
        }
      },
    });
    setInteractiveSelection({})
  };

  const onAddNewChartModalClose = () => {
    setLoading(true);
    setAddNewChartInputValues({
      ...defaultFilterData,
      viewByFilterList: defaultModalView.viewByFilterList,
      viewByChild: defaultModalView.viewByChild,
    });
    setModalVisible(false);
    setLoading(false);
  };

  const openAddNewModal = async () => {
    setAddNewChartInputValues({
      ...addNewChartInputValues,
      viewByFilterList: defaultModalView.viewByFilterList,
      viewByChild: defaultModalView.viewByChild,
    });
    if (defaultModalView.viewByChild.length < 1) {
      handleModalSelection(addNewChartInputValues.viewBy, "viewBy");
    }
    setModalVisible(true);
  };

  const openNotification = (type) => {
    notification[type]({
      message: "No Data",
      description: "Data not available for selected combination.",
    });
  };

  const isDisableOption = (listFilters, optionItem, category) => {
    // Setting limit 7 for view by and 5 for b401reakdown by
    let limitCount = category === "viewBy" ? 7 : 5;

    if (category === "viewBy" && listFilters[category] === "Endpoints") {
      limitCount = 10;
    }

    if (
      listFilters?.[`${category}Child`].length >= limitCount &&
      !listFilters?.[`${category}Child`].includes(optionItem)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getModalChartConfig = (chartData) => {
    const newChartData = cloneDeep(chartData);

    let totalDatapoints =
      newChartData?.appliedFilters?.viewByChild?.length *
      newChartData?.appliedFilters.breakdownByChild?.length;

    switch (true) {
      case totalDatapoints > 21 && totalDatapoints <= 30:
        newChartData.config.chart.height = 600;
        break;
      case totalDatapoints > 30 && totalDatapoints <= 40:
        newChartData.config.chart.height = 700;
        break;
      case totalDatapoints > 40 && totalDatapoints <= 50:
        newChartData.config.chart.height = 800;
        break;
      default:
    }
    return newChartData?.config;
  };

  const addChartEvents = (chartConfig) => {
    if(!chartConfig || !chartConfig.extra || !chartConfig?.extra.viewBy || !chartConfig?.extra.breakdownBy) return chartConfig;
    let newChartData = cloneDeep(chartConfig);
    if(!newChartData.plotOptions) newChartData.plotOptions = { series: { events: {} } };
    newChartData.plotOptions.series.point.events['click'] = function () {
      setInteractiveSelection({
        category: this.category,
        seriesName: this.series.name,
        viewBy: newChartData?.extra?.viewBy,
        breakdownBy: newChartData?.extra?.breakdownBy
      })
    };
    if(!newChartData.tooltip) newChartData.tooltip = {};
    newChartData.tooltip.formatter = function () {
      const breakdownDetails = newChartData?.extra?.breakdownBy !== "None"
        ? ` ${getFormattedText(newChartData?.extra?.breakdownBy)} : ${this.series.name} <br/>` 
        : '';
      return `${getFormattedText(
        newChartData?.extra?.viewBy
      )} : ${this.x} <br/>${breakdownDetails} Number of Studies : ${this.y} <br/>`;
    };
    return newChartData
  }

  const handleInteractiveCharts = async (selectedViewByValue, selectedBreakdownBy, _viewBy, breakdownBy, isNavToExplore, selectedAdvFilters) => {
    var tempAdvanceFilter = cloneDeep(selectedAdvFilters);
    var _tempAdvanceFilters = mapAdvanceFilters(tempAdvanceFilter);

    //Neha - changing all the keys of an object to lower case
    var key, keys = Object.keys(_tempAdvanceFilters);
    var n = keys.length;
    var tempAdvanceFilters={}
    while (n--) {
      key = keys[n];
      tempAdvanceFilters[key.toLowerCase()] = _tempAdvanceFilters[key];
    }
    
    var viewBy = _viewBy == 'Data Source' ? 'data_source' : _viewBy.toLowerCase();   //selected view by value in dropdown 

    var payload_1 = {};
    var payload_2 = {};

    let filterKeys = Object.keys(tempAdvanceFilters);

    if ((filterKeys.indexOf(viewBy) > -1 ) || (filterKeys.indexOf(breakdownBy.toLowerCase()) > -1)) {
      for (let key in tempAdvanceFilters) {
        payload_1 = tempAdvanceFilters[(viewBy.toLowerCase())]?.find((item) => item == selectedViewByValue);
      }

      if(breakdownBy != 'None'){
      for (let key in tempAdvanceFilters) {
        payload_2 = tempAdvanceFilters[(breakdownBy.toLowerCase())]?.find((item) => item == selectedBreakdownBy);

        tempAdvanceFilters = {
          ...tempAdvanceFilters,
          [viewBy]: payload_1 == undefined ? [selectedViewByValue] : [payload_1],
          [breakdownBy.toLowerCase()] : payload_2 == undefined ? [selectedBreakdownBy] : [payload_2],
        }

      }} else {
        tempAdvanceFilters = {
          ...tempAdvanceFilters,
          [viewBy]: payload_1 == undefined ? [selectedViewByValue] : [payload_1],
        }
      }
    } 
    else { 
      if(breakdownBy != 'None'){
      tempAdvanceFilters = {
        ...tempAdvanceFilters,
        [viewBy] : [selectedViewByValue],
        [breakdownBy.toLowerCase()] : [selectedBreakdownBy]
      }} else {
        tempAdvanceFilters = {
          ...tempAdvanceFilters,
          [viewBy] : [selectedViewByValue],
      }}
    }

    setLoading(true);

    //Neha - below code to handle when selected view by is "Endpoints" and "Study type"
    if (Object.keys(tempAdvanceFilters).includes('endpoint_type')){
      delete tempAdvanceFilters['endpoint_type']; 
    }
    
    var _tempAdvanceFilters = FormatAdvFilterDataForEvidenceChart(advanceFilterDropdownList, tempAdvanceFilters);
 
    dispatch(setInteractiveFilters(_tempAdvanceFilters));
    setLoading(false);
    if (isNavToExplore){
      navigate("/home/explore");
    }   
  };

  const checkBreakdownByType = (breakdownByType) => {
    if (breakdownByType == 'Study subtype'){
      return false
    } else if (breakdownByType == 'Endpoint Type'){
      return false
    } else {
      return true
    }
  }

  return (
    <>
      <div className="evidence-landscape-container">
        <Spin spinning={loading}>
          <div id="container-scroll" className="container-scroll-class">
            <div>
              {" "}
              {chartList.length > 0 &&
                chartList.map((item, index) => (
                    <div
                      id={`el-snap-${index + 1}`}
                      className="chart-container"
                    >
                      <Row className="mt-10">
                        <Col span={4}>
                          <p className="chart-label">
                            Studies By{" "}
                            {item.appliedFilters?.viewBy}
                          </p>
                        </Col>
                        <Col span={windowWidth < 1400 ? 7.5 : 8}>
                          <p className="dropdown-label">
                            View By : &nbsp;
                            <span>
                              {dropdownList.length > 0 && (
                                <Select
                                  defaultValue={item.appliedFilters?.viewBy}
                                  value={item.appliedFilters?.viewBy}
                                  className="selection-dropdown"
                                  onChange={(e) =>
                                    handleFilterChange(e, index, "viewBy")
                                  }
                                >
                                  {dropdownList.map((subItem, index) =>
                                    subItem !==
                                    item.appliedFilters?.breakdownBy ? (
                                      <Option
                                        className="capitalize"
                                        key={index + 1}
                                        value={subItem}
                                      >
                                        {subItem}
                                      </Option>
                                    ) : null
                                  )}
                                </Select>
                              )}
                            </span>
                          </p>

                          <div className="selection-container mt-m20">
                            {item.appliedFilters?.viewByFilterList.length >
                              0 && (
                              <Select
                                mode="multiple"
                                showArrow={
                                  item.appliedFilters?.viewByChild?.length > 1
                                    ? false
                                    : true
                                }
                                defaultValue={item.appliedFilters?.viewByChild}
                                value={item.appliedFilters?.viewByChild}
                                className="view-by-filter"
                                maxTagCount={"responsive"}
                                onDropdownVisibleChange={(e) =>
                                  onFilterClose(e, index, "viewByChild")
                                }
                                onChange={(e) =>
                                  handleFilterChange(e, index, "viewByChild")
                                }
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                              >
                                {item.appliedFilters?.viewByFilterList.map(
                                  (option, index) => (
                                    <Option
                                      disabled={isDisableOption(
                                        item.appliedFilters,
                                        option,
                                        "viewBy"
                                      )}
                                      key={index + 1}
                                      value={option}
                                    >
                                      {option}
                                    </Option>
                                  )
                                )}
                              </Select>
                            )}
                          </div>
                        </Col>
                        <Col span={7} className="breakdown-filter-container">
                          <Row>
                            <Col md={{ span: 14 }} lg={{ span: 10 }}>
                              <p className="dropdown-label">
                                Breakdown By
                                <span>
                                  <Tooltip title="Select up to 5 interventions at a time">
                                    <Icon
                                      component={DrugInfo}
                                      className="breakdown-by"
                                    />{" "}
                                    : &nbsp;
                                  </Tooltip>
                                </span>
                              </p>
                            </Col>
                            <Col md={{ span: 10 }} lg={{ span: 14 }}>
                              <div>
                                <span>
                                  {dropdownList.length > 0 && (
                                    <Select
                                      defaultValue={
                                        item.appliedFilters?.breakdownBy
                                      }
                                      value={item.appliedFilters?.breakdownBy}
                                      className="w-100"
                                      onChange={(e) =>
                                        handleFilterChange(
                                          e,
                                          index,
                                          "breakdownBy"
                                        )
                                      }
                                    >
                                      <Option key="0" value="None">
                                        None
                                      </Option>
                                      {dropdownList.map((subItem, index) =>
                                        subItem !==
                                        item.appliedFilters?.viewBy ? (                                       
                                          <Option
                                            className="capitalize"
                                            key={index + 1}
                                            value={subItem}
                                          >
                                            {subItem}
                                          </Option>
                                        ) : null
                                      )}
                                      {item.appliedFilters?.viewBy == 'Study Type' ? 
                                        <Option key="15" value="Study subtype">
                                          Study subtype
                                        </Option> : null
                                    }
                                     {item.appliedFilters?.viewBy == 'Endpoints' ?
                                        <Option key="16" value="Endpoint Type">
                                          Endpoint Type
                                        </Option> : null
                                     }
                                    </Select>
                                  )}
                                </span>
                              </div>
                              <div className="" style={{ marginTop: "8px" }}>
                                {(item.appliedFilters?.breakdownBy !== "None" && checkBreakdownByType(item.appliedFilters?.breakdownBy))  &&
                                // {(item.appliedFilters?.breakdownBy !== "None")  &&
                                  item.appliedFilters
                                    ?.breakdownByFilterList && (
                                    <Select
                                      defaultValue={
                                        item.appliedFilters?.breakdownByChild
                                      }
                                      value={
                                        item.appliedFilters?.breakdownByChild
                                      }
                                      mode="multiple"
                                      showArrow={
                                        item.appliedFilters?.viewByChild
                                          .length > 1
                                          ? false
                                          : true
                                      }
                                      maxTagCount={"responsive"}
                                      className="w-100"
                                      getPopupContainer={(trigger) =>
                                        trigger.parentNode
                                      }
                                      onDropdownVisibleChange={(e) =>
                                        onFilterClose(
                                          e,
                                          index,
                                          "breakdownByChild"
                                        )
                                      }
                                      onChange={(e) =>
                                        handleFilterChange(
                                          e,
                                          index,
                                          "breakdownByChild"
                                        )
                                      }
                                    >
                                      {item.appliedFilters?.breakdownByFilterList.map(
                                        (option, index) => (
                                          <Option
                                            key={index + 1}
                                            disabled={isDisableOption(
                                              item.appliedFilters,
                                              option,
                                              "breakdownBy"
                                            )}
                                            value={option}
                                          >
                                            {option}
                                          </Option>
                                        )
                                      )}
                                    </Select>
                                  )}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={1}>
                          <div>
                            <Button
                              onClick={() =>
                                setChartModalConfig({
                                  isVisible: true,
                                  currentSelectedIndex: index,
                                })
                              }
                            >
                              <FacetSearchIcon />
                            </Button>
                          </div>
                        </Col>
                        <Col span={1}>
                          <div>
                            {chartList.length > 1 && (
                              <Button
                                className="ml-5"
                                onClick={() => deleteChart(index)}
                              >
                                <DeleteSmallIcon />
                              </Button>
                            )}
                          </div>
                        </Col>
                        <Col span={3}>
                          <AddToStoryBoardView
                            elementID={`el-snap-${index + 1}`}
                          ></AddToStoryBoardView>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={24}>
                          {chartList[0]?.config?.series[0]?.data?.length > 0 ? (
                            <div className="chart-modal-container">
                              <Highcharts
                                className="chart-padding"
                                config={addChartEvents(item.config)}
                              />
                            </div>
                          ) : (
                            <div className="no-data-evidence">
                              There is no data available to display based on
                              applied criteria
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div> 
                ))}
            </div>

            {
              <Row className="add-chart-btn">
                <Col span={24}>
                  <Tooltip
                    placement="top"
                    title={
                      chartList.length < 10
                        ? "View up to 10 Charts at a time"
                        : "You have added the maximum number of 10 charts to the Evidence Landscape."
                    }
                  >
                    <Button
                      disabled={chartList.length >= 10}
                      onClick={() => openAddNewModal()}
                    >
                      Add Chart
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            }
          </div>

          <Modal
            title="Add Chart"
            centered
            open={modalVisible}
            onOk={() => addNewChart()}
            onCancel={() => onAddNewChartModalClose()}
            okButtonProps={{
              disabled: (addNewChartInputValues?.breakdownByFilterList && addNewChartInputValues?.viewByFilterList && !loading) ? false : true,
              // loading: loading
          }}
          >
            <p>
              Add a chart by selecting an attribute for the X-axis and the
              additional dimension to view it by.
            </p>

            <Spin spinning={loading}>
            <h3>View By</h3>
            <Select
              defaultValue={addNewChartInputValues.viewBy}
              value={addNewChartInputValues.viewBy}
              className="w-300"
              onChange={(e) => handleModalSelection(e, "viewBy")}
            >
              {" "}
              {dropdownList.map((item, index) =>
                item !== addNewChartInputValues.breakdownBy ? (
                  <Option className="capitalize" key={index} value={item}>
                    {item}
                  </Option>
                ) : null
              )}
            </Select>

            <div className="selection-container">
              <Select
                defaultValue={addNewChartInputValues.viewByChild}
                value={addNewChartInputValues.viewByChild}
                mode="multiple"
                className="view-by-multiple-modal"
                maxTagCount={"responsive"}
                onChange={(e) => handleModalSelection(e, "viewByChild")}
              >
                {addNewChartInputValues["viewByFilterList"].map((option) => (
                  <Option
                    key={option}
                    disabled={isDisableOption(
                      addNewChartInputValues,
                      option,
                      "viewBy"
                    )}
                    value={option}
                  >
                    {option}
                  </Option>
                ))}
              </Select>
            </div>

            <h3>Breakdown By</h3>
            <Select
              defaultValue="None"
              value={addNewChartInputValues.breakdownBy}
              className="breakdown-by-modal"
              onChange={(e) => handleModalSelection(e, "breakdownBy")}
            >
              <Option key="0" value="None">
                None
              </Option>
              {dropdownList.map((item, index) =>
                item !== addNewChartInputValues.viewBy ? (
                  <Option className="capitalize" key={index + 1} value={item}>
                    {item}
                  </Option>
                ) : null
              )}
               {addNewChartInputValues?.viewBy == 'Study Type' ? 
                  <Option key="15" value="Study subtype">
                    Study subtype
                  </Option> : null
              }
                {addNewChartInputValues?.viewBy == 'Endpoints' ?
                  <Option key="16" value="Endpoint Type">
                    Endpoint Type
                  </Option> : null
                }
            </Select>

            <div className="selection-container">
              {addNewChartInputValues["breakdownByFilterList"].length > 0 && (
                <Select
                  defaultValue={addNewChartInputValues.breakdownByChild}
                  value={addNewChartInputValues.breakdownByChild}
                  mode="multiple"
                  className="view-by-multiple-modal"
                  maxTagCount={"responsive"}
                  onChange={(e) => handleModalSelection(e, "breakdownByChild")}
                >
                  {addNewChartInputValues["breakdownByFilterList"].map(
                    (option) => (
                      <Option
                        key={option}
                        disabled={isDisableOption(
                          addNewChartInputValues,
                          option,
                          "breakdownBy"
                        )}
                        value={option}
                      >
                        {option}
                      </Option>
                    )
                  )}
                </Select>
              )}
            </div>
            </Spin>
          </Modal>
         

          <Modal
            title={`Studies by ${
              chartList[chartModalConfig.currentSelectedIndex]?.appliedFilters
                .viewBy
            }`}
            centered
            open={chartModalConfig.isVisible}
            onOk={() =>
              setChartModalConfig({ isVisible: false, currentSelectedIndex: 0 })
            }
            width={1400}
            onCancel={() =>
              setChartModalConfig({ isVisible: false, currentSelectedIndex: 0 })
            }
            okText="Close"
            cancelButtonProps={{ style: { display: "none" } }}
            bodyStyle={{ maxHeight: 600, overflowY: "scroll" }}
          >
            <Row>
              <Col span={24}>
                <Highcharts
                  config={addChartEvents(getModalChartConfig(
                    chartList[chartModalConfig.currentSelectedIndex]
                  ))}
                />
              </Col>
            </Row>
          </Modal>
        </Spin>
      </div>
    </>
  );
};

export default EvidenceLandscapeContent;
