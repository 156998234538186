import React, { useState } from "react";
import { Input, Row, Col, Button, Form } from "antd";
import { setSendEmail } from "./../../../services/InsightsViewAPI";
import "./SupportScreenForm.scss";
import { showNotification } from "../../../shared/utils/CommonUtil";

const SupportScreenForm = () => {
  const { TextArea } = Input;
  const [analysisForm] = Form.useForm();
  const [sendLoading, setSendLoading] = useState(false);

  const getUserDetails = (e, inputType) => {
    if (inputType == "name") {
      analysisForm.setFieldsValue({
        userName: e.target.value,
      });
    } else if (inputType == "email") {
      analysisForm.setFieldsValue({
        userEmail: e.target.value,
      });
    } else if (inputType == "phone") {
      analysisForm.setFieldsValue({
        userPhone: e.target.value,
      });
    } else if (inputType == "company") {
      analysisForm.setFieldsValue({
        userCompany: e.target.value,
      });
    } else if (inputType == "message") {
      analysisForm.setFieldsValue({
        userMessage: e.target.value,
      });
    }
  };

  const onAnalysisSubmit = async (values) => {
    const form = analysisForm.getFieldValue();
    setSendLoading(true);
    let payload = {
      full_name: form.userName,
      email: form.userEmail,
      phone_no: form.userPhone ? form.userPhone : "",
      company: form.userCompany,
      message: form.userMessage ? form.userMessage : "",
    };
    const data = await setSendEmail(payload);
    if (data && data.status && data.status.toLowerCase() === "success") {
      showNotification(
        "success",
        "Success",
        "Your query has been submitted successfully!"
      );
      onReset();
    } else {
      showNotification(
        "error",
        "Failed",
        "We are facing some difficulty while submitting your query. Please try after sometime."
      );
    }
    setSendLoading(false);
  };

  const onReset = () => {
    analysisForm.resetFields();
  };

  return (
    <div className="support-form-container">
      <Row>
        <Col span={12}>
          <div>
            <div className="support-title">Get in touch</div>
            <div className="supprt-details">
              For any help, or support with respect to product use or queries
              kindly reach out to our customer service team at ZS Evisense by
              filling out the form and we will reach out to you at the earliest
            </div>
            <div className="support-contact">
              <span style={{ color: "#454250" }}>
                Or contact us directly at
              </span>
              <a
                style={{ color: "#27A6A4" }}
                href="mailto: help_evidencesolutions@zs.com"
              >
                help_evidencesolutions@zs.com
              </a>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className="support-title" style={{ fontSize: 14 }}>
            Connect with us
          </div>
          <div style={{ paddingTop: 12 }}>
            <Form
              form={analysisForm}
              layout="horizontal"
              requiredMark={false}
              onFinish={onAnalysisSubmit}
            >
              <Row>
                <Col span={12}>
                  <Form.Item
                    required
                    name={"userName"}
                    rules={[
                      {
                        required: true,
                        message: "Name is mandatory",
                      },
                    ]}
                  >
                    <Input
                      placeholder="*Full Name"
                      autoComplete="off"
                      maxLength={100}
                      onChange={(e) => getUserDetails(e, "name")}
                      className="input-style"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    required
                    name={"userEmail"}
                    rules={[
                      {
                        required: true,
                        message: "Email is mandatory",
                      },
                      {
                        type: "email",
                        message: "The input is not valid E-mail",
                      },
                    ]}
                  >
                    <Input
                      placeholder="*Email"
                      autoComplete="off"
                      maxLength={100}
                      onChange={(e) => getUserDetails(e, "email")}
                      className="input-style"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    required
                    name={"userPhone"}
                    rules={[
                      {
                        message: "The input is not valid Phone number",
                        pattern: new RegExp(/^[0-9]+$/),
                      },
                    ]}
                  >
                    <Input
                      placeholder="Phone"
                      autoComplete="off"
                      maxLength={100}
                      onChange={(e) => getUserDetails(e, "phone")}
                      className="input-style"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    required
                    name={"userCompany"}
                    rules={[
                      {
                        required: true,
                        message: "*Company is mandatory",
                      },
                    ]}
                  >
                    <Input
                      placeholder="*Company "
                      autoComplete="off"
                      maxLength={100}
                      onChange={(e) => getUserDetails(e, "company")}
                      className="input-style"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name={"userMessage"}>
                    <TextArea
                      rows={5}
                      placeholder={"Type your message"}
                      maxLength={500}
                      style={{ height: 120, resize: "none" }}
                      onChange={(e) => getUserDetails(e, "message")}
                      className="input-style"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item htmlType="submit" style={{ float: "right" }}>
                    <Button className="form-button" onClick={onReset}>
                      Reset
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={sendLoading}
                    >
                      Send
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default SupportScreenForm;
