import { configureStore } from "@reduxjs/toolkit";
import EICCommonReducer from "./reducer/EICCommonSlice";
// import EICDataReducer from "./reducer/EICCommonSlice";

export const store = configureStore({
  reducer: {
    // EICData: EICDataReducer,
    EICCommon: EICCommonReducer,
  },
});
