import { isString } from "lodash";

const StudyListTableSorter = (type, value1, value2, total) => {
  const headerColumns = total.reduce((acc, curr) => {
    if (curr.dataIndex !== undefined) {
      acc.push(curr.dataIndex);
    }

    return acc;
  }, []);

  const childrenColumns = total.reduce((acc, curr) => {
    curr?.children?.map((val) => {
      acc.push(val.dataIndex);
    });
    return acc;
  }, []);

  if (headerColumns.includes(type) || childrenColumns.includes(type)) {
    if (isString(value1[type])) {
      //type date
      const dateVal = new Date(value1[type]);
      return dateVal === "Invalid Date"
        ? new Date(value1[type]) - new Date(value2[type])
        : value1[type].localeCompare(value2[type]);
    }

    //type array
    if (Array.isArray(value1[type])) {
      return (value1[type]? value1[type] : '').toString().localeCompare(value2[type]? value2[type].toString(): '');
    }
  }
};

export default StudyListTableSorter;
