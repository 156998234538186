const savedAnalysisColumnsData = [
  {
    title: "Analysis Name",
    key: "analysis_name",
    dataIndex: "analysis_name",
    render: true,
    sorter: true,
    filterIcon: false,
    filterDropdown: false,
    onFilter: false,
    width: 300,
    fixed: 'left'
  },
  {
    title: "Therapy Area",
    key: "therapy_area",
    dataIndex: "therapy_area",
    render: false,
    sorter: true,
    filterIcon: false,
    filters: false,
    filterSearch: false,
    onFilter: false,
    filterMode: false,
  },
  {
    title: "Owner(s)",
    key: "created_by",
    dataIndex: "created_by",
    render: false,
  },
  {
    title: "Date Created",
    key: "created_date",
    dataIndex: "created_date",
    render: false,
    sorter: true,
    filterIcon: false,
    filterDropdown: false,
    onFilter: false,
    render: true,
  },
  {
    title: "Last Updated",
    key: "last_updated",
    dataIndex: "last_updated",
    defaultSortOrder: 'descend',
    render: true,
    sorter: true,
  },
  {
    title: "Collaborators",
    key: "collaborators",
    dataIndex: "collaborators",
    width: 150,
    render: true,
  },
  {
    title: "Actions",
    key: "action",
    dataIndex: "action",
    render: true,
    width: 100,
    align: "center"
  },
];
export default savedAnalysisColumnsData;
