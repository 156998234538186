import React from "react";
import SearchTextFilter from "../saved-analysis-search-filter/SearchTextFilter";
import SavedAnalysisDatePicker from "./saved-analysis-table-date-picker/SavedAnalysisDatePicker";

const SavedAnalysisFilter = (
  filterType,
  setSelectedKeys,
  selectedKeys,
  confirm
) => {
  switch (filterType) {
    case "analysisName":
      return (
        <SearchTextFilter
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
        />
      );

    case "dateCreated":
      return (
        <SavedAnalysisDatePicker
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
        />
      );
    default:
      return null;
  }
};

export default SavedAnalysisFilter;
