import React from "react";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;

const SavedAnalysisDatePicker = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
}) => {
  const handleDate = (e) => {
    setSelectedKeys([e]);
  };
  return (
    <div>
      <RangePicker onChange={handleDate} value={selectedKeys[0]} />
    </div>
  );
};

export default SavedAnalysisDatePicker;
