const fullAttributeList = [
    {
      "attribute_name": "Age",
      "attribute_key": "age",
      "inherit_key": 'Age',
    },
    {
      "attribute_name": "Gender",
      "attribute_key": "gender",
      "inherit_key": 'Sex',
    },
    {
      "attribute_name": "Ethnicity",
      "attribute_key": "ethnicity",
      "inherit_key": "Ethnicity"
    },
    {
      "attribute_name": "Drug",
      "attribute_key": "drugs",
      "inherit_key": 'drug',
    },
    {
      "attribute_name": "Therapy",
      "attribute_key": "therapy",
      "inherit_key": "therapy"
    },
    {
      "attribute_name": "Procedure",
      "attribute_key": "procedure",
      "inherit_key": "procedure"
    },
    {
      "attribute_name": "Endpoint",
      "attribute_key": "endpoint",
      "inherit_key": "Endpoints",
    },
    {
      "attribute_name": "Biomarker",
      "attribute_key": "biomarker",
      "inherit_key": "Biomarkers",
    },
    {
      "attribute_name": "Phase",
      "attribute_key": "phase",
      "inherit_key": "phase",
    },
    {
      "attribute_name": "Statistical Analysis",
      "attribute_key": "statistical analysis",
      "inherit_key": "statistical_analysis"
    },
    {
      "attribute_name": "Sample Size",
      "attribute_key": "sample size",
      "inherit_key": "recruitment"
    },
    {
      "attribute_name": "Location",
      "attribute_key": "location",
      "inherit_key": "country"
    }
  ];

export { fullAttributeList };