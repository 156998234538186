import React, { useState, useEffect } from "react";
import {
  Tabs,
  Input,
  Button,
  Menu,
  Dropdown,
  Modal,
  Spin,
  Row,
  Col,
  Tooltip,
} from "antd";
import "./StoryboardContainer.scss";
import StoryboardView from "./storyboard-view/StoryboardView";
import {
  StoryboardRenameIcon,
  StoryboardDeleteIcon,
  StoryBoardIconMore,
  DownloadStoryBoardIcon,
  ImportStoryBoardIcon,
} from "./../../assets/icons/svg";
import {
  getUserStoryboards,
  addToStoryBoard,
  deleteStoryBoard,
  downloadStoryboard,
  BaseUrl,
  lockUnlockStoryBoard,
} from "../../services/InsightsViewAPI";
import { useDispatch, useSelector } from "react-redux";
import { setStoryBoardOwner } from "../../reducer/EICCommonSlice";
import { toggleSaveNewAnalysis } from "../../reducer/EICCommonSlice";
import { useNavigate } from "react-router-dom";
import { getTableHeightValue } from "../../shared/utils/CommonUtil";

const { TabPane } = Tabs;

const StoryboardContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [storyboardDetails, setStoryboardDetails] = useState();
  const [storyboardID, setStoryboardID] = useState();
  const [storyboardOwner, setStoryboardOwnerState] = useState();
  const [sectionID, setSectionID] = useState();
  const [sectionName, setSectionName] = useState();
  const [renameInput, setRenameInput] = useState();
  const [renameError, setRenameError] = useState(false);
  const [activeTab, setActiveTab] = useState();
  const [deleteOperLoader, setDeleteOperLoader] = useState(false);
  const [renameLoading, setRenameLoading] = useState(false);
  const [storyboardErrorMessage, setStoryboardErrorMessage] = useState();
  const [key, setKey] = useState(false);

  const [tableHeight, setTableHeight] = useState(450);

  const zoom = (window.outerWidth / window.innerWidth) * 100;
  const getTableHeight = () => {
    setTableHeight(getTableHeightValue(225, 310));
  };

  useEffect(() => {
    getTableHeight();
  }, [zoom]);

  const handleKeyPress = (e) => {
    setKey(!key);
  };

  const storyBoardSections = useSelector(
    (state) => state.EICCommon.storyBoardData
  );

  const selectedAnalysisData = useSelector(
    (state) => state.EICCommon.selectedAnalysisData
  );

  useEffect(() => {
    if (selectedAnalysisData?.analysis_id) {
      getStoryBoardDetails();
    } else {
      // promtSaveAnalysis();
      setStoryboardErrorMessage("Please save analysis to see the storyboard.");
    }
  }, [storyBoardSections, selectedAnalysisData]);

  // const promtSaveAnalysis = () => {
  //   const analysisData = {
  //     analysisName: SearchCriteria || null,
  //     therapyArea: null,
  //     description: null
  //   };
  //   dispatch(toggleSaveNewAnalysis({
  //       isOpen: true, mode: 'add',
  //       source: 'storyboard-container',
  //       analysisData,
  //   }));
  // };

  const getStoryBoardDetails = async (isNewAddition = false) => {
    if (!selectedAnalysisData || !selectedAnalysisData.analysis_id) return;
    setLoading(true);
    try {
      const payoad = {
        analysis_id: selectedAnalysisData.analysis_id,
      };
      const data = await getUserStoryboards(payoad);
      if (data.storyboards.length > 0) {
        const sections = data.storyboards[0].sections;
        setStoryboardID(data.storyboards[0].story_board_id);
        setStoryboardOwnerState(data.storyboards[0].is_owner);
        dispatch(setStoryBoardOwner(data.storyboards[0].is_owner));
        setStoryboardDetails(sections);
        const idx = sections?.findIndex((el) => el.section_id == activeTab);
        if (idx < 0 || isNewAddition) {
          setActiveTab(sections[sections.length - 1]?.section_id);
        }
        setStoryboardErrorMessage("");
      }
    } catch (error) {
      //Neha- below condition to handle no data condition
      setStoryboardErrorMessage("No Data");
    }
    setLoading(false);
  };

  const addStoryboard = async (key) => {
    if (key === "new-section") {
      let payload = {
        storyboards: [
          {
            storyboard_id: storyboardID,
            edit: false,
            sections: [
              {
                // section_name: name,
                section_id: "",
                edit: false,
              },
            ],
          },
        ],
        analysis_id: selectedAnalysisData.analysis_id,
      };

      setLoading(true);
      try {
        const response = await addToStoryBoard(payload);
        if (response?.status === "success") {
          getStoryBoardDetails(true);
        }
        setLoading(false);
      } catch (error) {
        setStoryboardErrorMessage(error.message);
        setLoading(false);
      }
      // setLoading(false);
    } else {
      let tabNum = key.split("-")[1];
      // console.log("tabNum", tabNum);
      setActiveTab(tabNum);
    }
  };

  const showRenameModal = () => {
    setIsRenameModalOpen(true);
  };
  const handleRenameOk = async (name) => {
    // setSectionName(name);
    // if (renameInput === sectionName || renameInput === undefined) {
    //   setRenameError(true);
    // } else {
    setRenameLoading(true);
    let payload = {
      storyboards: [
        {
          storyboard_id: storyboardID,
          edit: false,
          sections: [
            {
              section_name: renameInput,
              section_id: sectionID,
              edit: true,
            },
          ],
        },
      ],
      analysis_id: selectedAnalysisData.analysis_id,
    };

    try {
      const response = await addToStoryBoard(payload);
      if (response?.status === "success") {
        getStoryBoardDetails();
        setRenameLoading(false);
        setIsRenameModalOpen(false);
        setRenameError(false);
        setSectionName(renameInput);
      }
    } catch (error) {
      setRenameLoading(false);
      setRenameError(true);
    }
    setRenameLoading(false);
  };
  const handleRenameCancel = () => {
    setIsRenameModalOpen(false);
    setRenameError(false);
  };

  const showDeleteModal = () => {
    storyboardDetails.length <= 1
      ? setIsDeleteModalOpen(false)
      : setIsDeleteModalOpen(true);
  };
  const handleDeleteStoryBoard = async () => {
    setDeleteOperLoader(true);
    let payload = {
      storyboards: [
        {
          storyboard_id: storyboardID,
          sections: [
            {
              section_id: sectionID,
              delete: true,
            },
          ],
        },
      ],
      analysis_id: selectedAnalysisData.analysis_id,
    };
    try {
      const response = await deleteStoryBoard(payload);
      if (response?.status === "success") {
        getStoryBoardDetails();
        setIsDeleteModalOpen(false);
      }
    } catch (error) {
      // setStoryboardErrorMessage(error.message);
      setDeleteOperLoader(false);
    }
    setDeleteOperLoader(false);
  };

  const handleDeleteStoryBoardCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const getInputValue = (e) => {
    setRenameInput(e.target.value);
  };

  const getSectionNameAndID = (name, ID) => {
    setSectionID(ID);
    setSectionName(name);
  };

  const downloadStoryboardSections = async () => {
    setLoading(true);
    let payload = {
      storyboards: [
        {
          storyboard_id: storyboardID,
          sections: [parseInt(activeTab)],
        },
      ],
      analysis_id: selectedAnalysisData.analysis_id,
    };

    setLoading(true);

    try {
      const response = await downloadStoryboard(payload);

      if (response.status === "success") {
        let fileName = response.file_name;
        let url = `${BaseUrl}/api/downloadLink`;

        setTimeout(() => {
          const link = document.createElement("a");
          link.href =
            url +
            `?base_path=${response.base_path}&&file_name=${response.file_name}`;

          link.setAttribute("download", fileName);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        }, 1000);
      }
    } catch (error) {
      // alert(error.message);
      setLoading(false);
    }
    setLoading(false);
  };

  const menu = (
    <Menu id="storyboard-modal">
      <Menu.Item
        onClick={showRenameModal}
        disabled={storyboardOwner ? false : true}
      >
        <StoryboardRenameIcon />
        <span className="show-more-text">Rename</span>
      </Menu.Item>
      <Menu.Item
        disabled={
          storyboardDetails?.length <= 1 ? true : storyboardOwner ? false : true
        }
        onClick={showDeleteModal}
      >
        <StoryboardDeleteIcon />
        <span className="show-more-text"> Delete Storyboard </span>
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <div
        onClick={handleKeyPress}
        onKeyDown={handleKeyPress}
        onKeyUp={handleKeyPress}
        onBeforeInput={handleKeyPress}
        onFocusCapture={handleKeyPress}
        onFocus={handleKeyPress}
        onMouseMove={handleKeyPress}
      >
        <Spin spinning={loading}>
          {storyboardDetails && storyboardDetails.length ? (
            <div className="storyboard-container">
              <div className="tabPane-container panel-class">
                <div className="action-buttons">
                  <Tooltip
                    placement="left"
                    title={"Download"}
                    color={"#d9d9d9"}
                  >
                    {/* <Tooltip placement="left" title={'Download'} color={'#d9d9d9'}> */}

                    <span
                      onClick={downloadStoryboardSections}
                      style={{ cursor: "pointer" }}
                    >
                      <DownloadStoryBoardIcon />
                    </span>
                  </Tooltip>
                  {/* </span></Tooltip> */}

                  {/* <span style={{ marginLeft: 11 }}>
                  <ImportStoryBoardIcon />
                </span> */}
                </div>

                <Tabs
                  defaultActiveKey={`tab-${activeTab}`}
                  id="parent-tab-storyboard"
                  onTabClick={addStoryboard}
                  type="card"
                  activeKey={`tab-${activeTab}`}
                >
                  {storyboardDetails &&
                    storyboardDetails.map((item) => {
                      return (
                        <TabPane
                          // key={`tab${index}`}
                          key={"tab-" + item.section_id}
                          tab={
                            <>
                              <Row>
                                <Col span={23}>
                                  <span
                                    className="tab-title-ellipsis"
                                    title={item.section_name}
                                  >
                                    {item.section_name}
                                  </span>
                                </Col>
                                <Col span={1}>
                                  <Dropdown
                                    overlay={menu}
                                    trigger={["click"]}
                                    placement="bottomRight"
                                  >
                                    <span
                                      className="storyboard-show-more"
                                      id={item.section_id}
                                      onClick={() =>
                                        getSectionNameAndID(
                                          item.section_name,
                                          item.section_id
                                        )
                                      }
                                    >
                                      <StoryBoardIconMore />
                                    </span>
                                  </Dropdown>
                                </Col>
                              </Row>
                            </>
                          }
                        >
                          <div className="storyboard-content" style={{height: tableHeight}}>
                            <StoryboardView
                              sectionID={item?.section_id}
                              sectionName={item?.section_name}
                              storyboardID={storyboardID}
                              storyboardData={storyboardDetails}
                              readAccess={item?.read_access}
                              editAccess={item?.edit_access}
                              isOwner={storyboardOwner}
                              isStoryboardLocked={item?.lock}
                              analysisID={selectedAnalysisData?.analysis_id}
                              activeTab={activeTab}
                              // onMovement={key}
                              userData={item}
                            />
                          </div>
                          <Modal
                            closable={false}
                            destroyOnClose={true}
                            title=""
                            open={isRenameModalOpen}
                            onOk={() =>
                              handleRenameOk(item.section_name, item.section_id)
                            }
                            onCancel={handleRenameCancel}
                            okButtonProps={{ loading: renameLoading }}
                          >
                            <div className="delete-modal-title">
                              Rename {sectionName}
                            </div>
                            <div style={{ padding: "23px 5px 5px 5px" }}>
                              <Input
                                placeholder="Type here...."
                                defaultValue={sectionName}
                                onChange={getInputValue}
                                onPressEnter={() =>
                                  handleRenameOk(
                                    item.section_name,
                                    item.section_id
                                  )
                                }
                              />
                            </div>

                            {renameError ? (
                              <div className="rename-error">
                                Please add a unique Storyboard name
                              </div>
                            ) : (
                              ""
                            )}
                          </Modal>
                        </TabPane>
                      );
                    })}
                  {storyboardDetails && storyboardDetails?.length < 3 ? (
                    <TabPane
                      id="addStoryBoard"
                      tab="Click to add Storyboard"
                      key="new-section"
                      className="add-new-storyboard-tab"
                      disabled={storyboardOwner ? false : true}
                    ></TabPane>
                  ) : (
                    ""
                  )}
                </Tabs>
              </div>

              <Modal open={isDeleteModalOpen} footer={null} closable={false}>
                <div className="delete-modal-title">Delete Storyboard?</div>

                <div className="modal-body">
                  Are you sure you want to delete '{sectionName}'? You cannot
                  undo this action.
                </div>

                <div className="button-container">
                  <Button
                    // className="delete-action"
                    style={{ marginRight: "15px" }}
                    onClick={handleDeleteStoryBoard}
                    loading={deleteOperLoader}
                  >
                    Yes, Delete
                  </Button>
                  <Button type="primary" onClick={handleDeleteStoryBoardCancel}>
                    Don't Delete
                  </Button>
                </div>
              </Modal>
            </div>
          ) : (
            ""
          )}

          {storyboardErrorMessage ? (
            <div className="no-text-storyboard">
              <div
                className="storyboard-content"
                style={{
                  fontSize: 16,
                  color: "grey",
                  padding: 32,
                  height: 450
                }}
              >
                {storyboardErrorMessage}
              </div>
            </div>
          ) : (
            ""
          )}
        </Spin>
      </div>
    </>
  );
};

export default StoryboardContainer;
