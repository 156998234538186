import React, { useState, useEffect } from "react";
import { getTableHeightValue } from "../../../shared/utils/CommonUtil";

//TODO - Implement Custom PDF reader example for React PDF https://github.com/alexanderqchen/pdf-viewer

const UserManual = () => {
  const zoom = (window.outerWidth / window.innerWidth) * 100;
  const [reloading, setReloading] = useState(false);
  const [tableHeight, setTableHeight] = useState(450);

  const rerenderPdfViewer = () => {
    setReloading(true);
    setTableHeight(getTableHeightValue(225, 150));
    setTimeout(() => {
      setReloading(false);
    }, 500);
  };

  useEffect(() => {
    window.addEventListener("resize", rerenderPdfViewer);
    rerenderPdfViewer();
    return () => window.removeEventListener("resize", rerenderPdfViewer);
  }, []);

  return (
    <>
      {
        reloading? '':
          <div>
            <object
              onContextMenu={(e) => e.preventDefault()}
              width="100%"
              type="application/pdf"
              style={{ height: tableHeight }}
              data="EvisenseUserManual.pdf#toolbar=0"
              id="pdf_content"
            >
              <p>Document load was not successful.</p>
            </object>
          </div>
      }
    </>
  );
};

export default UserManual;
