import React from "react";
import "./Header.scss";
import "antd/dist/antd.min.css";
import { HeaderQuestionMarkIcon } from "../../assets/icons/svg";
import EvisenseLogoHeader from "../../assets/icons/EvisenseLogoHeader.svg";
import Icon from "@ant-design/icons";
import { LogoutOutlined } from "@ant-design/icons";
import { Tooltip, Row, Col } from "antd";
import SaveAnalysisView from "../../templates/save-analysis-view/SaveAnalysisView";

function Header() {
  const logoutUser = () => {
    localStorage.removeItem("auth_status");
    sessionStorage.removeItem("search_value");

    window.location.replace(
      "https://idm.dev.zsservices.com/RWE/IdentityManager/app/Web/logout.aspx"
    );
  };
  return (
    <>
      <Row className="header-layout">
        <Col flex="220px">
          <div className="Header ">
            <div className="Heading">
              <img
                src={EvisenseLogoHeader}
                alt="Evisense Logo"
                className="evisense-logo-header"
              />
            </div>
          </div>
        </Col>
        <Col flex="auto">
          <div className="analysis-section">
            <SaveAnalysisView />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Header;
