import axios from "axios";
import { BaseUrl } from "../../../services/InsightsViewAPI";
import { UserUnautherizedAction } from "../../../shared/utils/CommonUtil";

export const getDemoVideosList = async () => {
  try {
    const response = await axios.get(`${BaseUrl}/api/faqVideos`, {
      withCredentials: true,
    });
    return response.data.files;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction();
    }
  }
};
