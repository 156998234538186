import { RDSearch } from "@zs-ca/react-cd";
import React from "react";

const SearchTextFilter = ({ setSelectedKeys, selectedKeys, confirm }) => {
  const handleSearch = (confirm) => {
    confirm();
  };

  return (
    <React.Fragment>
      <div>
        <RDSearch
          placeholder="Search value"
          searchTerm={selectedKeys[0]}
          handleChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onSearch={() => handleSearch(confirm)}
        />
      </div>
    </React.Fragment>
  );
};

export default SearchTextFilter;
