import React, { useEffect } from "react";
import { Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  removeFromList,
  setIsExcludeStudyConfig,
  setPagination,
  setSelectedAnalysisData,
  setSelectedStudyListData,
  toggleSaveNewAnalysis,
} from "../../reducer/EICCommonSlice";
import {
  fetchAnalysisById,
  saveAnalysis,
} from "../../services/InsightsViewAPI";
import { showNotification } from "../../shared/utils/CommonUtil";
import "./ExcludeStudiesContainer.scss";
import ExcludedStudyContainer from "./excluded-studies/ExcludedStudyContainer";

const { confirm } = Modal;

const ExcludeStudiesContainer = () => {
  const dispatch = useDispatch();

  const {
    EICCommon: {
      retrieveSelectedKeys,
      selectedAnalysisData,
      searchTerm,
      selectedAdvanceFilters,
      compareStudiesData,
      excludedStudyIds,
      exploreViewMode,
      isExcludeStudyConfig,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (
      isExcludeStudyConfig?.isOpen &&
      isExcludeStudyConfig?.newExcludeStudyIds.length > 0
    ) {
      handleExcludeAction();
    }
  }, [isExcludeStudyConfig]);

  const handleExcludeAction = () => {
    const selectedStudyIds = retrieveSelectedKeys?.selectData?.map(
      (item) => item.pm_id
    );
    if (!selectedStudyIds || selectedStudyIds.length < 1) return;
    showExcludeConfirmModal(selectedStudyIds);
  };

  const excludeAfterCreateAnalysis = (selectedStudyIds) => {
    const analysisData = {
      analysisName: searchTerm || null,
      therapyArea: null,
      description: null,
    };
    dispatch(
      toggleSaveNewAnalysis({
        isOpen: true,
        mode: "add",
        source: "exclude-studies",
        analysisData,
        excludeStudyIds: selectedStudyIds,
      })
    );
  };

  const showExcludeConfirmModal = (selectedStudyIds) => {
    confirm({
      title: (
        <>
          <strong className="confirm-title">Confirm Action</strong>
        </>
      ),
      okText: "Continue",
      okType: "primary",
      icon: null,
      mask: true,
      autoFocusButton: "cancel",
      wrapClassName: "exclude-studies-confirm",
      content: (
        <span>
          Are you certain you'd like to exclude this study from your results? If
          excluded, this study will not appear in any searches performed as part
          of this analysis or be included in any insights generated. Excluded
          studies can be reviewed by clicking 'View Excluded Studies’ on the
          right side of the screen and studies may be restored from there.
          <br /><br />
          If you have not already saved this analysis, you will be prompted to do so.
        </span>
      ),
      centered: true,
      width: 600,
      onOk: async () => {
        if (!selectedAnalysisData || !selectedAnalysisData.analysis_id) {
          excludeAfterCreateAnalysis(selectedStudyIds);
        } else {
          await saveExcludedStudies(selectedStudyIds);
        }
        dispatch(setIsExcludeStudyConfig({ isOpen: false }));
      },
      onCancel: () => {
        dispatch(setIsExcludeStudyConfig({ isOpen: false }));
      },
    });
  };

  const saveExcludedStudies = async (selectedStudyIds) => {
    const allExcludeIds = [...excludedStudyIds, ...selectedStudyIds];

    // logic to remove excluded studies from compare
    const newCompareData = compareStudiesData.selectData.filter(
      (item) => !allExcludeIds.includes(item.pm_id)
    );
    const newCompareKeys = newCompareData.map((i) => i.key);
    dispatch(
      removeFromList({
        selectKey: [...newCompareKeys],
        selectData: [...newCompareData],
      })
    );

    const payload = {
      edit: true,
      analysis_id: selectedAnalysisData.analysis_id,
      search_text: searchTerm,
      study_filters: selectedAdvanceFilters,
      comparison_data: newCompareData.map((item) => item.pm_id),
      excluded_study_ids: allExcludeIds,
    };
    const response = await saveAnalysis(payload);
    if (response?.status?.toLowerCase() === "success") {
      dispatch(setSelectedStudyListData({ selectKey: [], selectData: [] }));
      const payload = {
        analysis_id: selectedAnalysisData.analysis_id,
      };
      const response = await fetchAnalysisById(payload);
      if (response?.status?.toLowerCase() === "success") {
        const anaysisData = response?.result?.length ? response.result[0] : {};
        dispatch(setPagination(1)); // set page number to 1 after exclude studies
        dispatch(setSelectedAnalysisData(anaysisData));
      }
      showNotification("success", "Success", "Successfully excluded studies.");
    }
  };

  return (
    <>
      {exploreViewMode === "excluded-study-list" && <ExcludedStudyContainer />}
    </>
  );
};

export default ExcludeStudiesContainer;
