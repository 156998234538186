import { Space, Table, Spin, Row, Col } from "antd";
import React, { useState } from "react";
import StudyListTableColumns from "./StudyListTableColumns";
import "./StudyListTable.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  addToComparision,
  removeFromReadFullTextData,
  setPagination,
  setSearchLoadingReducer,
  setSelectedStudyListData,
  setStudyListTableData,
} from "../../../reducer/EICCommonSlice";
import CompareStudiesDropDown from "../../compare-studies/compare-studies-dropdown/CompareStudiesDropDown";
import { useEffect } from "react";
import {
  getSearchResult,
  mapAdvanceFilters,
  getInteractiveSearch,
} from "../../../services/InsightsViewAPI";
import isEmpty from "lodash/isEmpty";
import {
  ExcludeActionBtn,
  ViewExcludeBtn,
} from "../../exclude-studies/exclude-actions/ExcludeActionContainer";
import { uniq, uniqBy } from "lodash";
import { getTableHeightValue } from "../../../shared/utils/CommonUtil";

function StudyListTable() {
  const dispatch = useDispatch();

  const {
    EICCommon: {
      studyListTableData,
      studyListPageNumber,
      deleteCompareStudies,
      retrieveSelectedKeys,
      searchTerm,
      selectedAdvanceFilters,
      interactiveFilters,
      excludedStudyIds,
      compareStudiesData,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (deleteCompareStudies.length > 0) {
      deleteCompare();
    }
  }, [deleteCompareStudies]);

  const handleSelectAllAction = (allSelected) => {
    if (allSelected) {
      const allKeys = [
        ...retrieveSelectedKeys.selectKey,
        ...studyListTableData.data.map((item) => item.key),
      ];
      const uniqueKeys = uniq(allKeys);
      const allStudyData = [
        ...retrieveSelectedKeys.selectData,
        ...studyListTableData.data,
      ];
      const uniqueStudies = uniqBy(allStudyData, "key");
      handleSsStorageSet(uniqueKeys, uniqueStudies);
      return;
    }
    const keyResult = studyListTableData.data.map((item) => item.key);

    const selectKeys = retrieveSelectedKeys.selectKey.filter(
      (item) => !keyResult.includes(item)
    );
    const selectData = retrieveSelectedKeys.selectData.filter(
      (item) => !keyResult.includes(item.key)
    );

    handleSsStorageSet([...selectKeys], [...selectData]);
  };

  const handleSsStorageSet = (keys, data) => {
    dispatch(setSelectedStudyListData({ selectKey: keys, selectData: data }));
  };

  const deleteCompare = () => {
    const getID = compareStudiesData?.selectData?.map((val) => {
      if (val.pm_id === deleteCompareStudies) {
        return val.key;
      }
    });

    const keyResult = compareStudiesData.selectKey.filter((val) => {
      return !getID.includes(val);
    });

    const dataResult = compareStudiesData.selectData.filter((val) => {
      return !getID.includes(val.key);
    });

    // console.log(keyResult, "delete", dataResult);

    // handleSsStorageSet(keyResult, dataResult);

    dispatch(
      addToComparision({ selectKey: keyResult, selectData: dataResult })
    );

    dispatch(removeFromReadFullTextData(deleteCompareStudies));
  };

  const handleNewInput = (keyData, rowData) => {
    if (keyData.includes("delete") && rowData.includes("delete")) {
      handleSsStorageSet([], []);
    } else {
      const dataResult =
        rowData.length > 0 && retrieveSelectedKeys.selectData.length > 0
          ? rowData.reduce((acc, curr) => {
              if (!retrieveSelectedKeys.selectKey.includes(curr.key)) {
                acc = [...retrieveSelectedKeys.selectData, ...rowData];
              }
              return acc;
            }, [])
          : retrieveSelectedKeys.selectData.length > 0
          ? retrieveSelectedKeys.selectData.length > 1
            ? retrieveSelectedKeys.selectData.filter((val) => {
                return val.key !== keyData[0];
              })
            : []
          : rowData; //important edge case

      const keyResult =
        keyData.length > 0 && retrieveSelectedKeys.selectKey.length > 0
          ? keyData.reduce((acc, curr) => {
              if (!retrieveSelectedKeys.selectKey.includes(curr)) {
                acc = [...retrieveSelectedKeys.selectKey, ...keyData];
              }
              return acc;
            }, [])
          : retrieveSelectedKeys.selectKey.length > 0
          ? retrieveSelectedKeys.selectKey.length > 1
            ? retrieveSelectedKeys.selectKey.filter((val) => {
                return val !== keyData[0];
              })
            : []
          : keyData; //important edge case

      handleSsStorageSet(keyResult, dataResult);
    }
  };

  const handleSelectedKeyFilter = (keyData, rowData, select) => {
    const keyResult = keyData.filter((val) => {
      return !retrieveSelectedKeys.selectKey.includes(val);
    });

    const dataResult = rowData.filter((val) => {
      return !retrieveSelectedKeys.selectKey.includes(val.key);
    });

    handleSsStorageSet(
      [...retrieveSelectedKeys.selectKey, ...keyResult],
      [...retrieveSelectedKeys.selectData, ...dataResult]
    );
  };

  const handleSelectedKeys = (keyData, rowData, select) => {
    const keyResult =
      keyData.length > 1 && rowData.length > 1 //here i changed
        ? handleSelectedKeyFilter(keyData, rowData, select)
        : handleNewInput(keyData, rowData);
  };

  const handleFilterSelectedData = (keyData, select) => {
    const keyResult = keyData.includes(select)
      ? retrieveSelectedKeys.selectKey.filter((val) => {
          return !keyData.includes(val);
        })
      : retrieveSelectedKeys.selectKey.filter((val) => {
          return val !== select;
        });
    const dataResult = keyData.includes(select)
      ? retrieveSelectedKeys.selectData.filter((val) => {
          return !keyData.includes(val.key);
        })
      : retrieveSelectedKeys.selectData.filter((val) => {
          return val.key !== select;
        });

    handleSsStorageSet(keyResult, dataResult);
  };
  const [tableHeight, setTableHeight] = useState(getTableHeightValue(255, 450));
  const getTableHeight = () => {
    setTableHeight(getTableHeightValue(255, 450));
  };
  useEffect(() => {
    window.addEventListener("resize", getTableHeight);
    return () => window.removeEventListener("resize", getTableHeight);
  }, []);
  useEffect(() => {
    getTableHeight();
  }, []);
  const getPaginationData = async (pageNo) => {
    dispatch(setSearchLoadingReducer(true));
    const data = await getSearchResult(
      searchTerm,
      pageNo,
      mapAdvanceFilters(selectedAdvanceFilters),
      excludedStudyIds
    );

    dispatch(setStudyListTableData(data.result));
    dispatch(setSearchLoadingReducer(false));
    // }
  };

  return (
    <div id="study-list-table-container" className="page-content">
      <Row>
        <Col span={12}>
          <CompareStudiesDropDown />
          <ExcludeActionBtn />
        </Col>
        <Col span={12} style={{ direction: "rtl" }}>
          <ViewExcludeBtn />
        </Col>
      </Row>
      <Table
        columns={StudyListTableColumns(studyListTableData.columns)}
        dataSource={studyListTableData.data}
        bordered
        scroll={
          studyListTableData?.total_results > 0 && {
            x: 4500,
            y: tableHeight,
          }
        }
        rowSelection={
          studyListTableData.total_results > 0
            ? {
                selectedRowKeys: retrieveSelectedKeys.selectKey,

                selections: [
                  {
                    key: "select",
                    text: "Clear All Selection",
                    onSelect: () => {
                      handleNewInput(["delete"], ["delete"]);
                      handleNewInput(["delete"], ["delete"]);
                    },
                  },
                ],

                onSelect: (changableRowKeys, b, selectedRows) => {
                  const undefinedFilteredValues = selectedRows.filter((val) => {
                    return val !== undefined;
                  });

                  const filteredRowkeys = undefinedFilteredValues.map((val) => {
                    return val.key;
                  });

                  filteredRowkeys.length > 1
                    ? filteredRowkeys.reduce((acc, curr) => {
                        if (!retrieveSelectedKeys.selectKey.includes(curr)) {
                          handleSelectedKeys(
                            filteredRowkeys,
                            undefinedFilteredValues,
                            changableRowKeys.key
                          );
                        }
                      })
                    : handleSelectedKeys(
                        filteredRowkeys,
                        undefinedFilteredValues,
                        changableRowKeys.key
                      );

                  if (!filteredRowkeys.includes(changableRowKeys.key)) {
                    handleFilterSelectedData(
                      filteredRowkeys,
                      changableRowKeys.key
                    );
                  }
                },
                onSelectAll: (selected, selectedRows, changeRows) => {
                  handleSelectAllAction(selected);
                },
              }
            : false
        }
        pagination={{
          total: studyListTableData.total_results,
          pageSize: 10,
          showTotal: (total, range) =>
            `${range[0]} - ${range[1]} of ${total} studies `,
          showSizeChanger: false,
          onChange: (value) => {
            dispatch(setPagination(value));
            getPaginationData(value);
          },
          current: studyListPageNumber,
        }}
      />
    </div>
  );
}

export default StudyListTable;
