import React, { useState, useEffect } from "react";
import SavedAnalysisColumns from "./SavedAnalysisColums";
import SavedAnalysisColumnsData from "./SavedAnalysisColumnsData";
// import savedAnalysisData from "./db.json";
import { Table, Card, Menu, Dropdown, Button, Modal, Spin, Select, Col, Row, Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import { getSavedAnalysisList, deleteAnalysis, getStoryBoardNameList, getUserList, getStoryBoardCollabrator, addCollabratorToStoryBoard } from "../../../services/InsightsViewAPI";
import {
  DeleteIcon,
  EditIcon,
  MenuIcon,
  CloseIcon,
  ShareIcon
} from "../../../assets/icons/svg";
import { toggleSaveNewAnalysis, setSelectedAnalysisData, setComingFromCollaboratorView } from "../../../reducer/EICCommonSlice";
import { useDispatch, useSelector } from "react-redux";
import { showNotification } from '../../../shared/utils/CommonUtil';

import './SavedAnalysisGridTable.scss';
import UserAvatar from "../UserAvatar";

const { confirm } = Modal;
const { Option } = Select;

const collaborators = [
  {
    name: "User",
    shot_name: ""
  },
  {
    name: "User",
    shot_name: ""
  },
  {
    name: "User",
    shot_name: ""
  }
];

const bgColorList = ["#62D2D1", "#2D535F", "#3af2ef", "#2D8B93", "#2D535F", "#62D2D1"];

const SavedAnalysisGridTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anaysisData, setData] = useState([]);
  const [openCollaboratorModal, setOpenCollaboratorModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [analysisLoading, setAnalysisLoading] = useState(false);
  //state variables for share Analysis 
  const [storyBoardList, setStoryBoardList] = useState([]);
  const [selectedStoryBoardList, setSelectedStoryBoardList] = useState([]);
  const [selectedUserToShare, setSelectedUserToShare] = useState();
  const [userList, setUserList] = useState([]);
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [selectedStoryBoardIDs, setSelectedStoryBoardIDs] = useState([]);
  const [selectedUserDetails, setSelectedUserDetails] = useState('');
  const [analysisDetails, setAnalysisDetails] = useState('');
  const [showSelectedAccess, setShowSelectedAccess] = useState('');
  const [selectedAccess, setSelectedAccess] = useState('');
  const [selectedExistingAccess, setSelectedExistingAccess] = useState('');
  const [collabratorList, setCollabratorList] = useState([]);
  const [collaboratorsCount, setCollaboratorsCount] = useState([[]]);
  const [loading, setLoading] = useState(false);

  const {
    EICCommon: {
      advanceFilterDropdownList,
      analysisLastUpdatedTime,
      selectedAnalysisData,
      isComingFromCollaboratorView,
      userID,
      checkCollaboratorConfirm,
      checkCollaboratorModal
    },
  } = useSelector((state) => state);

  useEffect(() => {
    getSavedAnalysisDetails();
  }, [analysisLastUpdatedTime]);

  useEffect(() => {
    if (selectedAnalysisData && selectedAnalysisData.analysis_id && selectedAnalysisData.is_owner) {
      navigate("/home/insights");
      setAnalysisLoading(false);
    } else if (selectedAnalysisData && selectedAnalysisData.analysis_id && !selectedAnalysisData.is_owner && !isComingFromCollaboratorView) {
      navigate("/home/storyboard");
      setAnalysisLoading(false);
    }
    dispatch(setComingFromCollaboratorView(false));
  }, [selectedAnalysisData])

  const getSavedAnalysisDetails = async () => {
    setIsLoading(true);
    try {
      const { data } = await getSavedAnalysisList();
      setData(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const menu = (row) => (
    <Menu id="clip-view-menu" key={`text-menu-${row?.id}`}>
      <Menu.Item key={`menu-fullscreen-${row?.id}`} onClick={() => onRenameAnalysis(row)} className="action-menu">
        <EditIcon />
        <span className="show-more-text">Rename</span>
      </Menu.Item>
      {row?.is_owner &&
        <Menu.Item key={`menu-share-${row?.id}`} onClick={() => onShareAnalysis(row)} className="action-menu">
          <ShareIcon />
          <span className="show-more-text">Share</span>
        </Menu.Item>
      }
      <Menu.Item key={`menu-remove-${row?.id}`} onClick={() => onDeleteAnalysis(row)} className="action-menu">
        <DeleteIcon />
        <span className="show-more-text">Delete Analysis</span>
      </Menu.Item>
    </Menu>
  );

  const extraIcon = (row) => (
    <Dropdown
      overlay={menu(row)}
      trigger={["click"]}
      placement="bottomRight"
      disabled={row.is_owner ? false : true}
    >
      <Button style={{ border: 0, margin: 'auto', display: 'block' }}>
        <MenuIcon />
      </Button>
    </Dropdown>
  );

  const onRenameAnalysis = (row) => {
    const analysisData = {
      analysisName: row.analysis_name.title,
      therapyArea: row.therapy_area,
      description: row.analysis_name.description,
      analysisId: row.analysis_id,
    };
    dispatch(toggleSaveNewAnalysis({
      isOpen: true, mode: 'edit',
      analysisData,
      source: 'saved-analysis'
    }));
  }

  const onDeleteAnalysis = (row) => {
    if (!row || !row.analysis_id) return;
    confirm({
      title: "Confirm Action",
      okText: "Delete",
      okType: "primary",
      icon: null,
      mask: true,
      autoFocusButton: 'cancel',
      wrapClassName: 'analysis-delete-confirm',
      closeIcon: (<><CloseIcon /></>),
      content: (
        <span>Are you sure you want to Delete&nbsp;
          <strong>'{row.analysis_name.title}'</strong> permanently from the Saved Analysis List?
          <br /> You cannot undo this action.
        </span>
      ),
      centered: true,

      onOk: async () => {
        const payload = {
          analysis_ids: [row.analysis_id]
        }
        const response = await deleteAnalysis(payload);
        if (response?.status?.toLowerCase() === "success") {
          getSavedAnalysisDetails();
          showNotification('success', 'Success', 'Successfully deleted the analysis.');
        } else {
          showNotification('error', 'Error', 'Failed to delete the analysis.');
        }
      },
    });
  }

  //Neha - functions for share storyboard starts from here
  const handleAccessChange = (e) => {
    setShowSelectedAccess(e.label);
    setSelectedAccess(e.key);
  };

  const handleExistingAccessChange = (e, value) => {
    setLoading(true);
    setSelectedExistingAccess(e.key);
    updateUserAccess(value, e);
    setLoading(true);
  };

  const showExistingAccess = (value) => {
    return value.read_access == true ? 'View' : 'Edit';
  };

  const getUserName = (name) => {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
    let initials = [...name.matchAll(rgx)] || [];
    initials = (
      (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase();
    return initials;
  }

  const handlePeopleSearch = (value) => {
    setSelectedUserToShare(value.value);
    setSelectedUserDetails(value);
  };

  const ShareModalClose = async () => {
    setOpenShareModal(false);
    setSelectedStoryBoardList([]);
    setSelectedUserToShare(null);
    setShowSelectedAccess('');
    setCollabratorList([]);
    setFilteredUserList([]);

    setIsLoading(true);
    try {
      const { data } = await getSavedAnalysisList();
      setData(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }

  const addStoryBoardToShare = (event) => {
    const newList = [...event];
    setSelectedStoryBoardIDs(newList);
    setSelectedStoryBoardList(newList);
    getStoryBoardCollabratorList(newList)
  }

  const onShareAnalysis = (row) => {
    console.log('row', row)
    setAnalysisDetails(row);
    getStoryBoardToShare(row);
    getUserListForShare();
    setShowSelectedAccess('View');
    setSelectedAccess('add');
    setOpenShareModal(true);
    setLoading(false);
  }

  //get the list of storyboards to share
  const getStoryBoardToShare = async (row) => {
    const payload = {
      analysis_id: row?.analysis_id
    }
    const data = await getStoryBoardNameList(payload);
    setStoryBoardList(data);
  }

  //get the list of user to share storyboard
  const getUserListForShare = async () => {
    const data = await getUserList();
    if (data?.users) {
      let filleredUser = data.users.filter(item => {
        return item.userId != userID;
      })
      setUserList(filleredUser);
    }
  }

  const getStoryBoardCollabratorList = async (newList) => {
    setLoading(true)
    const payload = {
      analysis_id: analysisDetails?.analysis_id,
      storyboard_id: storyBoardList?.storyboards[0]?.story_board_id,
      sections: newList,
    }
    var data = await getStoryBoardCollabrator(payload);
    setCollabratorList(data?.collaborators);

    //getting the list for total collaborators
    var collaboratorCount = [];
    data?.collaborators?.map(item => {
      item?.collabrator_list?.map(list => {
        collaboratorCount.push(list);
      })
    });
    setCollaboratorsCount(collaboratorCount);
    setLoading(true);
    if (data?.collaborators[0]?.collabrator_details.length > 0) {
      let updatedUser = userList.filter(item => !data.collaborators[0].collabrator_list.includes(item.userId));
      setFilteredUserList(updatedUser);
    } else {
      setFilteredUserList(userList);
    }
    setLoading(false)
  }

  const SendInvite = async () => {
    setLoading(true);
    const payload = {
      action: 'add',
      analysis_id: analysisDetails?.analysis_id,
      storyboard_id: storyBoardList?.storyboards[0]?.story_board_id,
      sections: selectedStoryBoardIDs,
      collaborator: [
        {
          user_id: selectedUserDetails?.key,
          read_access: true,
          edit_access: showSelectedAccess == 'Edit' ? true : false,
          user_name: selectedUserDetails?.value,
        },
      ],
    };
    const data = await addCollabratorToStoryBoard(payload);
    if (data.status == 'success') {
      setLoading(false);
      setSelectedUserToShare('');
      showNotification(data.status, 'Success', data.message);
      getStoryBoardCollabratorList(selectedStoryBoardIDs);
    }
    setLoading(false);
  }

  const updateUserAccess = async (modifyUser, userAction) => {
    setLoading(true);
    const payload = {
      action: userAction.key == "delete" ? "delete" : 'update_role',
      analysis_id: analysisDetails?.analysis_id,
      storyboard_id: storyBoardList?.storyboards[0]?.story_board_id,
      sections: selectedStoryBoardIDs,
      collaborator: [
        {
          user_id: modifyUser?.user_id,
          read_access: true,
          edit_access: userAction.key == 'edit' ? true : false,
          user_name: modifyUser?.user_name
        },
      ],
    };
    const data = await addCollabratorToStoryBoard(payload);
    if (data.status == 'success') {
      setLoading(true);
      setSelectedUserToShare('');
      showNotification(data.status, 'Success', data.message);
      getStoryBoardCollabratorList(selectedStoryBoardIDs);
    }
    setLoading(false);
  }

  //..............Ends here..............

  const createNewAnalysis = () => {
    dispatch(toggleSaveNewAnalysis({
      isOpen: true, mode: 'add',
      analysisData: null,
      source: 'saved-analysis'
    }));
  };

  const onCollaboratorClick = (user) => {
    setOpenCollaboratorModal(true);
  }

  const onAnalysisNameClick = async (data) => {
    if (!data || !data.rowData || data.type !== "nameClick" || advanceFilterDropdownList.length <= 0) return;
    setAnalysisLoading(true);
    dispatch(setSelectedAnalysisData({ ...data.rowData }));
  };

  const onTableActions = (data) => {
    if (!data || !data.type) return;
    switch (data.type) {
      case 'collaboratorClick':
        onCollaboratorClick(data);
        break;
      case 'nameClick':
        onAnalysisNameClick(data);
        break;
      default:
        break;
    }
  }

  return (
    <React.Fragment>
      <Spin spinning={analysisLoading}>
        <div className="saved-analysis-table-container">
          <Card
            bodyStyle={{
              paddingBottom: 0,
              paddingTop: '18px'
            }}
          >
            <>
              <h2>
                <span className="page-title">Saved Analyses</span>
                <Button
                  style={{
                    float: 'right',
                  }}
                  className="common-ant-btn"
                  onClick={() => createNewAnalysis()}
                >
                  Create New Analysis
                </Button>
              </h2>
              <Table
                columns={SavedAnalysisColumns(SavedAnalysisColumnsData, anaysisData, extraIcon, onTableActions)}
                dataSource={anaysisData}
                loading={isLoading}
                scroll={
                  anaysisData?.length > 0 && {
                    y: 'calc(100vh - 245px)',
                    x: 1350
                  }
                }
              />
            </>
          </Card>
        </div>
      </Spin>

      <Modal
        title="Collaborators"
        className="collaborator-manage-modal"
        centered
        open={openCollaboratorModal}
        width={900}
        footer={[
          <Button key="back" onClick={() => setOpenCollaboratorModal(false)}>
            Cancel
          </Button>
        ]}
        onCancel={() => setOpenCollaboratorModal(false)}
      >
        <div className="coming-soon-container">
          <p><UserAvatar userList={collaborators} maxCount={10} /></p>
          <h2>Coming Soon</h2>
          <p className="coming-soon-message">
            Hello there! we are coming soon with <strong>Collaborator</strong> feature, <br />
            here you'll be able to add multiple team members to collaborate on the same file.
          </p>
          <h4>Stay tuned!</h4>
        </div>
      </Modal>

      <Modal
        title=''
        className="collaborator-share-modal"
        centered
        open={openShareModal}
        width={687}
        footer={[
          <div className="share-modal-footer">
            <div style={{ borderTop: '1px solid #f0f0f0', borderRadius: '0 0 2px 2px' }}>
              <p className="text-style" style={{ marginTop: 10 }}>Two access levels are available when sharing storyboards:</p>
              <p className="text-style" style={{ marginBottom: 0 }}><strong>View</strong> will allow collaborators to view but not edit the storyboard</p>
              <p className="text-style"><strong>Edit</strong> will allow collaborators to view and add textboxes to the storyboard. They will not be able to add screenshots or change anything already in the storyboard</p>
              <p className="text-style">More collaboration features are coming soon!</p>
            </div>
          </div>
        ]}
        onCancel={() => ShareModalClose()}
      >
        <div className="collaborator-share-container">
          <p className="share-modal-subtitle">Share<strong> Storyboard</strong> for <strong>{analysisDetails?.analysis_name?.title}</strong></p>
          <div className="share-modal-actions">
            <h4 className="share-modal-text">Select storyboard you want to share</h4>
            <div id="collaborator-storyboard-select">
              <Select
                placeholder="Select storyboard"
                defaultValue={selectedStoryBoardList}
                value={selectedStoryBoardList}
                mode="multiple"
                className='share-storyboard-modal'
                maxTagCount={'responsive'}
                showArrow={true}
                getPopupContainer={() => document.getElementById('collaborator-storyboard-select')}
                onChange={(e) => addStoryBoardToShare(e)}
              >
                {
                  storyBoardList?.storyboards?.length && storyBoardList?.storyboards?.[0]?.sections.length > 0 && storyBoardList?.storyboards?.[0]?.sections.map((option) =>
                    <Option key={option.section_id} value={option.section_id}>{option.section_name}</Option>
                  )
                }
              </Select>
            </div>

            <Row style={{ paddingTop: 30 }}>
              <Col span={12}>
                <h4 className="share-modal-text">Search people</h4>
                <Select
                  placeholder="Enter name"
                  showArrow={false}
                  showSearch
                  labelInValue
                  value={selectedUserToShare}
                  className='share-modal-add shareModal'
                  onChange={handlePeopleSearch}
                  disabled={selectedStoryBoardList.length == 0 ? true : false}
                  width={272}
                >
                  {
                    filteredUserList && filteredUserList.length > 0 && filteredUserList.map((item, index) =>
                      <Option key={item.userId} value={item.userName}>{item.userName}</Option>
                    )
                  }
                </Select>

              </Col>
              <Col span={5} style={{ paddingLeft: 18 }}>
                <h4 className="share-modal-text">Select access</h4>
                <Select
                  labelInValue
                  defaultValue={showSelectedAccess}
                  value={showSelectedAccess}
                  style={{ width: 95 }}
                  onChange={handleAccessChange}
                  disabled={selectedStoryBoardList.length == 0 ? true : false}
                  options={[
                    { value: 'add', label: 'View' },
                    { value: 'update_role', label: 'Edit' },
                  ]}

                />
              </Col>
              <Col span={7} style={{ paddingLeft: 26 }}>
                <Button
                  style={{
                    marginTop: 30,
                  }}
                  className="share-modal-button"
                  onClick={() => SendInvite()}
                  disabled={(selectedStoryBoardList.length == 0 || !selectedUserToShare) ? true : false}
                >
                  Invite
                </Button></Col>


              <Col span={21} style={{ paddingTop: 10 }}>
                <Spin spinning={loading}>
                  <div className="share-modal-user-access" style={collaboratorsCount?.length > 2 ? { width: '468px' } : { width: '485px' }}>
                    <div className="access-title">{collabratorList && collabratorList.length > 0 ? collabratorList[0].section_name : ''}</div>
                    {collabratorList && collabratorList.length > 0 && collabratorList[0]?.collabrator_details?.length > 0 && collabratorList[0]?.collabrator_details?.map((item, index) =>

                      <div style={{ padding: '10px 10px 10px 0px' }}>
                        <Avatar
                          style={{
                            backgroundColor: bgColorList[index],
                            verticalAlign: 'middle',
                            marginRight: 14,
                            fontSize: 12
                          }}
                          size={26}
                        >
                          {getUserName(item.user_name)}
                        </Avatar>
                        <span style={{ fontSize: 12 }}>{item.user_name}</span>
                        <Select
                          key={item.user_id}
                          labelInValue
                          defaultValue={() => showExistingAccess(item)}
                          value={item.edit_access ? 'Edit' : item.read_access ? 'View' : () => showExistingAccess(item)}
                          style={{ width: 81, float: 'right', fontSize: 12 }}
                          onChange={(e) => handleExistingAccessChange(e, item)}
                          options={[
                            { value: 'view', label: 'View' },
                            { value: 'edit', label: 'Edit' },
                            { value: 'delete', label: 'Remove' }
                          ]}
                        />
                      </div>
                    )}
                    <div className="access-title" style={{ paddingTop: 11 }}>{collabratorList && collabratorList[1] ? collabratorList[1].section_name : ''}</div>
                    {collabratorList && collabratorList.length > 0 && collabratorList[1]?.collabrator_details?.length > 0 && collabratorList[1]?.collabrator_details?.map((item, index) =>

                      <div style={{ padding: '10px 10px 10px 0px' }}>
                        <Avatar
                          style={{
                            backgroundColor: bgColorList[index],
                            verticalAlign: 'middle',
                            marginRight: 14,
                            fontSize: 12
                          }}
                          size={26}
                        >
                          {getUserName(item.user_name)}
                        </Avatar>
                        <span style={{ fontSize: 12 }}>{item.user_name}</span>
                        <Select
                          key={item.user_id}
                          labelInValue
                          defaultValue={() => showExistingAccess(item)}
                          value={item.edit_access ? 'Edit' : item.read_access ? 'View' : () => showExistingAccess(item)}
                          style={{ width: 81, float: 'right', fontSize: 12 }}
                          onChange={(e) => handleExistingAccessChange(e, item)}
                          options={[
                            { value: 'view', label: 'View' },
                            { value: 'edit', label: 'Edit' },
                            { value: 'delete', label: 'Remove' }
                          ]}
                        />
                      </div>
                    )}
                    <div className="access-title" style={{ paddingTop: 11 }}>{collabratorList && collabratorList[2] ? collabratorList[2].section_name : ''}</div>
                    {collabratorList && collabratorList.length > 0 && collabratorList[2]?.collabrator_details?.length > 0 && collabratorList[2]?.collabrator_details?.map((item, index) =>
                      <div style={{ padding: '10px 10px 10px 0px' }}>
                        <Avatar
                          style={{
                            backgroundColor: bgColorList[index],
                            verticalAlign: 'middle',
                            marginRight: 14,
                            fontSize: 12
                          }}
                          size={26}
                        >
                          {getUserName(item.user_name)}
                        </Avatar>
                        <span style={{ fontSize: 12 }}>{item.user_name}</span>
                        <Select
                          key={item.user_id}
                          labelInValue
                          defaultValue={() => showExistingAccess(item)}
                          value={item.edit_access ? 'Edit' : item.read_access ? 'View' : () => showExistingAccess(item)}
                          style={{ width: 81, float: 'right', fontSize: 12 }}
                          onChange={(e) => handleExistingAccessChange(e, item)}
                          options={[
                            { value: 'view', label: 'View' },
                            { value: 'edit', label: 'Edit' },
                            { value: 'delete', label: 'Remove' }
                          ]}
                        />
                      </div>
                    )}
                  </div>
                </Spin>
              </Col>
            </Row>

          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default SavedAnalysisGridTable;
