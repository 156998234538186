import React, { useEffect, useState } from "react";
import { RDMenu } from "@zs-ca/react-cd";
import "antd/dist/antd.min.css";
import { useNavigate, useLocation } from "react-router-dom";
import { setting, SideNavBarData } from "./SideNavBarData";
import "./Sidenavbar.scss";
import { Link } from "react-router-dom";
import { toggleCheckAnalysisSave, toggleCollaboratorConfirm, setCollaboratorModal, setComingFromCollaboratorView, resetAll } from "../../reducer/EICCommonSlice";
import { useDispatch, useSelector } from "react-redux";
import { lockUnlockStoryBoard } from "../../services/InsightsViewAPI";

const SideNavBar = () => {
  const [tab, setTab] = useState("backToLanding");
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const routes = {
    backToLanding: '/landingpage',
    explore: '/home/explore',
    insights: '/home/insights',
    storyboard: '/home/storyboard',
    'saved-analysis': '/saved-analysis',
    'support-privacy': '/support-privacy',
    'notifications': '/notifications'
  };

  const analysisSaveCheckPages = ['/home/explore', '/home/insights', '/home/storyboard'];

  const {
    EICCommon: {
      isStoryboardOwner,
      checkCollaboratorModal,
      checkCollaboratorConfirm,
      checkCollaboratorDetails,
      selectedAnalysisData
    },
} = useSelector((state) => state);

  useEffect(() => {
    // set the navbar selection on page load
    if (!location.pathname) return;
    switch (location.pathname) {
      case '/landingpage':
        setTab('backToLanding'); break;
      case '/home/explore':
        setTab('explore'); break;
      case '/home/insights':
        setTab('insights'); break;
      case '/home/storyboard':
        setTab('storyboard'); break;
      case '/saved-analysis':
        setTab('saved-analysis'); break;
      case '/support-privacy':
        setTab('support-privacy'); break;
      case '/notifications':
        setTab('notifications'); break;
      default:
        setTab('backToLanding'); break;
    }
  }, [location])

  const unLockStoryboard = async() => {
    let payload = {
      analysis_id: selectedAnalysisData?.analysis_id,
      storyboard_id: checkCollaboratorDetails?.storyboard_id,
      sections: [checkCollaboratorDetails?.section_id],
      lock: false,
    }
    try {
      const response = await lockUnlockStoryBoard(payload);
      if (response?.status === "success") {
        console.log('success')
      }
    } catch (error) {
     console.log('error',error)
    }   
  }

  const onMenuChange = (e) => {
    try {
      const path = routes[e.key];
      if (!path) return;

      if(!isStoryboardOwner && !checkCollaboratorConfirm.isCheck){
        if((path == '/saved-analysis') && !checkCollaboratorDetails.lock && (checkCollaboratorDetails.edit_access || !checkCollaboratorDetails.edit_access)){
          navigate(routes[e.key]);
          dispatch(setComingFromCollaboratorView(true))
          dispatch(toggleCollaboratorConfirm({ isCheck: false, source: 'navbar', navToPath: null }));
          // dispatch(setStoryBoardOwner(true))
          
          dispatch(resetAll());
        } else {
        dispatch(toggleCollaboratorConfirm({ isCheck: true, source: 'navbar', navToPath: routes[e.key] }));
        dispatch(setCollaboratorModal({isOpen: true}))
        }
      } else {
         //Neha- if owner have storyboard locked then unlock it when he move out from the view
         if(isStoryboardOwner && checkCollaboratorDetails.lock){
          unLockStoryboard();
        }
        if (analysisSaveCheckPages.indexOf(location.pathname) > -1 && analysisSaveCheckPages.indexOf(path) === -1) {
          dispatch(toggleCheckAnalysisSave({ isCheck: true, source: 'navbar', navToPath: routes[e.key] }));
          // navigate(routes[e.key]);
        } else {
          navigate(routes[e.key]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "fixed",
            top: 0,
            overflowY: 'auto',
            height: '100%',
          }}
          id="navbar-container"
        >
          {/* <Link to="/landingpage"> */}
          <RDMenu
            menuItems={SideNavBarData}
            selectedOption={tab}
            // handleMenu={(e) => (e.key == "EIC" ? setTab(e.key) : "")}
            handleMenu={(e) => onMenuChange(e)}
            mode="vertical"
            style={{
              width: 80,
              background: "#2F2C3C",
              height: "76vh",
              minHeight: "430px",
            }}
          />
          {/* </Link> */}
          <RDMenu
            menuItems={setting}
            selectedOption={tab}
            handleMenu={(e) => onMenuChange(e)}
            mode="vertical"
            style={{
              width: 80,
              background: "#2F2C3C",
              height: "24vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              minHeight: '140px',
            }}
          />
        </div>
        {/* <div style={{ padding: 20, marginTop: 56, marginLeft: 81 }}>
          {tab === "zaidynLogo" && <p>Content from Zaidyn Logo</p>}
          {tab === "home" && <p>Content from Home</p>}
          {tab === "library" && <p>Content from Library</p>}
          {tab === "planner" && <p>Content from Planner</p>}
          {tab === "EIC" && <AppRoutes />}
          {tab === "user" && <p>Content from user</p>}
          {tab === "window" && <p>Content from window</p>}
          {tab === "query" && <p>Content from query</p>}
        </div> */}
      </div>
    </div>
  );
};

export default SideNavBar;
