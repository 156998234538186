import React, { useEffect, useState } from "react";
import Header from "../shared/header/Header";
import SideNavBar from "../shared/side-nav-bar/SideNavBar";
import AppRoutes from "../App.routes";
import { getUserInfo } from "./../services/InsightsViewAPI";
import { Spin } from "antd";
import "./../shared/header/Header";
import { IDM_URL } from '../environments/environments';
import { useDispatch } from "react-redux";
import { getAdvancedSearchList } from "./advanced-filter-view/AdvancedFilterViewHelper";
import { setAdvanceFilterDropdownList, setUserID } from "../reducer/EICCommonSlice";


function DashBoard() {
  const [loading, setLoading] = useState(false);
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  const userDetails = localStorage.getItem("auth_status");

  const dispatch = useDispatch();

  const redirectToLogin = () => {
    window.location.replace(
      `${IDM_URL}?ReturnUrl=https://evisense.dev.zsservices.com/`
    );
  };

  const loadAdvFilterDropdown = async () => {
    const data = await getAdvancedSearchList();
    await dispatch(setAdvanceFilterDropdownList(data));
  }

  const userInfoFun = async () => {
    setLoading(true);
    let response = await getUserInfo();

    if (userDetails === 'success' || (response && response.auth_status === 'success')) {
      dispatch(setUserID( response?.userId ));
      await loadAdvFilterDropdown();
      setUserAuthenticated(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } else {
      redirectToLogin();
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  useEffect(() => {
    userInfoFun();
  }, []);

  return (
    <div className="dashboard-container">
      <Spin spinning={loading} size="large">
        {
          userAuthenticated && <>
            {" "}
            <Header />
            <AppRoutes />
            <SideNavBar />
          </>
        }
      </Spin>
    </div>
  );
}

export default DashBoard;
