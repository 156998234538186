import {
  ExploreIcon,
  InsightsIcon,
  NavbarBackIcon,
  SideNavQuestionMarkIcon,
  StoryboardSideNavIcon,
  UserIcon,
  WindowIcon,
  ZaidynLogo,
  NotificationIcon,
  SavedAnalysisSideNavIcon,
} from "../../assets/icons/svg";
import ZaidynNewLogo from "../../assets/icons/EvisenseLogo.svg";
import Icon from "@ant-design/icons";
import React from "react";
import { Popover, List } from "antd";
import { userLogout } from "../../services/InsightsViewAPI";

export const Profile = () => {
  const username = sessionStorage.getItem("username");

  const logoutUser = async () => {
    const data = await userLogout();

    localStorage.removeItem("auth_status");
    sessionStorage.removeItem("search_value");
    sessionStorage.removeItem("username");

    window.location.replace(data.logout_url);
    // window.location.replace("https://idm.dev.zsservices.com/RWE/IdentityManager/app/Web/logout.aspx");
  };

  return (
    <>
      <List header={null} footer={null} bordered={false} size={"small"}>
        <List.Item>
          <strong>{username}</strong>
        </List.Item>
        <List.Item>
          <span onClick={logoutUser} className={"logout-menu"}>
            Logout
          </span>
        </List.Item>
      </List>
    </>
  );
};

export const SideNavBarData = [
  {
    key: "zaidynLogo",
    value: (
      <React.Fragment>
        <div>
          <img
            src={ZaidynNewLogo}
            alt="Evisense Logo"
            className="evisense-logo"
          />
          {/* <Icon component={ZaidynNewLogo} /> */}
        </div>
      </React.Fragment>
    ),
  },
  {
    key: "backToLanding",
    value: (
      <React.Fragment>
        <div className="main-menu">
          <div>
            <Icon
              style={{ verticalAlign: "middle" }}
              component={NavbarBackIcon}
            />
          </div>
          <div>
            <span>EVISENSE</span>
          </div>
        </div>
      </React.Fragment>
    ),
  },
  {
    key: "explore",
    value: (
      <React.Fragment>
        <div className="main-menu">
          <div>
            <Icon style={{ verticalAlign: "middle" }} component={ExploreIcon} />
          </div>
          <div>
            <span>Explore</span>
          </div>
        </div>
      </React.Fragment>
    ),
  },
  {
    key: "insights",
    value: (
      <React.Fragment>
        <div className="main-menu">
          <div>
            <Icon
              style={{ verticalAlign: "middle" }}
              component={InsightsIcon}
            />
          </div>
          <div>
            <span>Insights</span>
          </div>
        </div>
      </React.Fragment>
    ),
  },
  {
    key: "storyboard",
    value: (
      <React.Fragment>
        <div className="main-menu">
          <div>
            <Icon
              style={{ verticalAlign: "middle" }}
              component={StoryboardSideNavIcon}
            />
          </div>
          <div>
            <span>Storyboard</span>
          </div>
        </div>
      </React.Fragment>
    ),
  },
  {
    key: "saved-analysis",
    value: (
      <React.Fragment>
        <div className="main-menu">
          <div>
            <Icon
              style={{ verticalAlign: "middle" }}
              component={SavedAnalysisSideNavIcon}
            />
          </div>
          <div>
            <span>
              Saved <br /> Analyses
            </span>
          </div>
        </div>
      </React.Fragment>
    ),
  },
];

export const setting = [
  {
    key: "notifications",
    value: (
          <div>
            <Icon style={{ verticalAlign: 'middle' }} component={NotificationIcon} />
          </div>
    ),
  },
  {
    key: "user",
    value: (
      <React.Fragment>
        <Popover
          placement="rightBottom"
          overlayClassName="user-profile-popover"
          content={
            <>
              <Profile />
            </>
          }
          trigger="click"
        >
          <div>
            <Icon style={{ verticalAlign: "middle" }} component={UserIcon} />
          </div>
        </Popover>
      </React.Fragment>
    ),
  },
  {
    key: "window",
    value: (
      <React.Fragment>
        <div>
          <Icon style={{ verticalAlign: "middle" }} component={WindowIcon} />
        </div>
      </React.Fragment>
    ),
  },
  {
    key: "support-privacy",
    value: (
      <React.Fragment>
        <div>
          <Icon
            style={{ verticalAlign: "middle" }}
            component={SideNavQuestionMarkIcon}
          />
        </div>
      </React.Fragment>
    ),
  },
];
