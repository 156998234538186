import { Row, Spin } from "antd";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import EICContainer from "./templates/eic-container/EicContainer";
import SearchComponent from "./templates/landing-page/search/SearchComponent";
import ProtectedRoute from "./ProtectedRoute";
import SavedAnalysisContainer from "./templates/saved-analysis/SavedAnalysisContainer";
import SupportPrivacyPolicyLayout from "./templates/support-privacy-policy/SupportPrivacyPolicyLayout";
import NotificationLayout from "./templates/notifications/NotificationLayout";

function AppRoutes() {
  function MissingRoute() {
    return <Navigate to={{ pathname: "/landingpage" }} />;
  }

  return (
    <Routes>
      <Route path="/landingpage" element={<SearchComponent />} />
      <Route path="/home/:pageName" element={<EICContainer />} />
      <Route path="/saved-analysis" element={<SavedAnalysisContainer />} />
      <Route path="/support-privacy" element={<SupportPrivacyPolicyLayout />} />
      <Route path="/notifications" element={<NotificationLayout />} />
      <Route path="*" element={<MissingRoute />} />
    </Routes>

    // <Routes>
    //   <Route exact path='/' element={<ProtectedRoute/>}>
    //   </Route>
    //   <Route exact path='/landingpage' element={<SearchComponent/>}/>
    //   <Route exact path='/home' element={<EICContainer/>}/>
    // </Routes>
  );
}

export default AppRoutes;
