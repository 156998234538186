import React from "react";
import StudyListTableSorter from "./study-list-table-sorter/StudyListTableSorter";
import { Tooltip } from "antd";
import StudyFullTextView from "./study-full-text-view/StudyFullTextView";
import Paragraph from "antd/es/typography/Paragraph";

const StudyListTableColumns = (columns) => {

  const getNestedChildrenData = (childrenData) => {
    return childrenData.map((ele) => {
      return {
        ...ele,

        sorter: (a, b) => StudyListTableSorter(ele.dataIndex, a, b, columns),
        showSorterTooltip: false,
        render: (text) =>
          text == undefined || text.length < 1 ? (
            <Tooltip placement="right" title="Data Not Available">
              <span style={{ fontSize: "1.5rem" }}>-</span>
            </Tooltip>
          ) : (
            <Tooltip
              placement="right"
              title={
                Array.isArray(text) ? text.map((val) => <div>{val}</div>) : text
              }
            >
              <span>{Array.isArray(text) ? text.join(",") : text}</span>
            </Tooltip>
          ),
        ellipsis: true,
        ...customFeaturedColumn(ele),
      };
    });
  };

  const getColumnChildrenData = (childrenData) => {
    return childrenData.map((ele, index) => {
      return ele.children
        ? { ...ele, children: getNestedChildrenData(ele.children) }
        : {
          ...ele,
          sorter: (a, b) =>
            StudyListTableSorter(ele.dataIndex, a, b, columns),
          align: "center",
          showSorterTooltip: false,
          render: (text) =>
            text == undefined || text.length < 1 ? (
              <span style={{ fontSize: "1.5rem" }}>-</span>
            ) : (
              <Tooltip
                placement="right"
                title={
                  Array.isArray(text)
                    ? text.map((val) => <div>{val}</div>)
                    : text
                }
              >
                <span>{Array.isArray(text) ? text.join(",") : text}</span>
              </Tooltip>
            ),
          ellipsis: true,
          ...customFeaturedColumn(ele),
        };
    });
  };

  const customFeaturedColumn = (ele) => {
    switch (ele.title) {
      case "Study Title":
        return {
          width: 450,
          fixed: "left",
          render: (text, record) =>
            text == undefined || text.length < 1 ? (
              <span style={{ fontSize: "1.5rem" }}>-</span>
            ) : (
              <StudyFullTextView data={text} link={record} />
            ),
          sortDirections: ["descend"],
          showSorterTooltip: false,
          align: "start",
        };

      case "Ethnicity":
        return {
          ellipsis: true,
        };
      case 'Treatment':
        if(ele.dataIndex === 'treatment_data') {
          return {
            ellipsis: false,
            render: (text, record) =>
            (
              <>
                {
                  text?.length > 0 ?
                    <>
                      <Tooltip
                        placement="right"
                        title={
                          Array.isArray(text)
                            ? text?.map((val, i) => <>{i !== 0 ? <br /> : ''} <div>{val}</div></>)
                            : '-'
                        }
                      >
                        <ul className="treatment-data">
                          {text?.map((item) => (
                            <li>
                              <Paragraph ellipsis={{ rows: 1 }}
                                style={{
                                  marginBottom: 0
                                }}
                              >
                                {item}
                              </Paragraph>
                            </li>
                          ))}
                        </ul>
                      </Tooltip>
                    </>
                    : <span style={{ fontSize: "1.5rem" }}>-</span>
                }
              </>
            )
          }
        }
      default:
    }
  };

  const updatedColumn = columns?.map((ele) => {
    return ele?.children
      ? { ...ele, children: getColumnChildrenData(ele.children) }
      : {
        ...ele,
        sorter: (a, b) => StudyListTableSorter(ele.dataIndex, a, b, columns),
        showSorterTooltip: false,
        align: "center",
        render: (text) => 
          text == undefined || text?.length < 1 ? (
            <span style={{ fontSize: "1.5rem" }}>-</span>
          ) : (
            <Tooltip
              placement="right"
              title={
                Array.isArray(text)
                  ? text.map((val) => <div>{val}</div>)
                  : text
              }
            >
              <span>{Array.isArray(text) ? text.join(",") : text}</span>
            </Tooltip>
          ),
        ellipsis: true,
        ...customFeaturedColumn(ele),
      };
  });

  return updatedColumn;
};

export default StudyListTableColumns;
