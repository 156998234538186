import React from "react";
import './SectionTable.scss';

const SectionTable = (props) => {

  const tableData = props.tableData;

  const processTableData = (inputData) => {
    
    let previousId = 0;

    let newData = {
      data: [],
      row: [],
      column: []
    };

    let outputData = {
      data: [],
      row: [],
      column: []
    };
    
    inputData.table_details.headers.forEach((item, index)=> {
        if (item.row_id === previousId){
          newData.data.push(item.value);
          newData.row.push(item.row_span);
          newData.column.push(item.col_span);
          if (inputData.table_details.headers.length === index + 1){
            outputData.data.push(newData.data);
            outputData.row.push(newData.row);
            outputData.column.push(newData.column);
            newData = {
              data: [],
              row: [],
              column: []
            };
          }
        } else {
            previousId = item.row_id;
            outputData.data.push(newData.data);
            outputData.row.push(newData.row);
            outputData.column.push(newData.column);
            newData = {
              data: [],
              row: [],
              column: []
            };
        }
    });

    previousId = 1;

    inputData.table_details.body.forEach((item, index)=> {
      if (previousId === item.row_id){
          newData.data.push(item.value);
          newData.row.push(item.row_span);
          newData.column.push(item.col_span);
        if (inputData.table_details.body.length === index + 1){
          outputData.data.push(newData.data);
          outputData.row.push(newData.row);
          outputData.column.push(newData.column);
          newData = {
            data: [],
            row: [],
            column: []
          };
        }
      } else {
        previousId = item.row_id;
        outputData.data.push(newData.data);
        outputData.row.push(newData.row);
        outputData.column.push(newData.column);
        newData = {
          data: [],
          row: [],
          column: []
        };
        newData.data.push(item.value);
        newData.row.push(item.row_span);
        newData.column.push(item.col_span);
      }
  });

    let data = outputData.data;
    let rowspanData = outputData.row;
    let colspanData = outputData.column;
  
    return {data, colspanData, rowspanData};
  }

  const createTable = (inputData) => {

    const {data, colspanData, rowspanData} = processTableData(inputData);

    let table = document.createElement('table');

    let tbody = document.createElement('tbody');
    table.appendChild(tbody);

    for (let i = 0; i < data.length; i++) {
      let row = document.createElement('tr');
  
      for (let j = 0; j < data[i].length; j++) {
        if (colspanData[i][j] !== 0 && rowspanData[i][j] !== 0){

          let cell = document.createElement('td');
          cell.textContent = data[i][j];
          // if the current cell has a colspan attribute
          if (colspanData[i][j] > 1) {
            cell.colSpan = colspanData[i][j];
          }
          // if the current cell has a rowspan attribute
          if (rowspanData[i][j] > 1) {
            cell.rowSpan = rowspanData[i][j];
          }
          row.appendChild(cell);
        }
      }
      tbody.appendChild(row);
    }

    return table.outerHTML;
  }

  return (
    <div className="section-table-view">
        <table id="dynamic-table" dangerouslySetInnerHTML={{__html: createTable(tableData)}}/>
        <p style={{marginTop: 20}}><strong>{tableData.table_details.caption}</strong></p>
        <p style={{marginTop: -10}}>{tableData.table_details.label}</p>
    </div>
  );
};

export default SectionTable;