import React, { useEffect, useState } from 'react';
import './SingleTextView.scss';
import QuillEditor from './QuillEditor';
import {
    StoryboardDeleteIcon,
    MenuIcon,
    StoryboardArchiveIcon,
} from "../../../assets/icons/svg";
import {
    Button,
    Dropdown,
    Menu,
    Modal,
    Spin,
    Row,
    Col
} from "antd";

import { addToStoryBoard, deleteStoryBoard, getUserStoryboards } from "../../../services/InsightsViewAPI";
import { useDispatch, useSelector } from "react-redux";
import { setStoryBoardData } from "../../../reducer/EICCommonSlice";

const SingleTextView = (props) => {
    const dispatch = useDispatch();

    const selectedAnalysisData = useSelector(
        (state) => state.EICCommon.selectedAnalysisData
    );

    const { storyboardID, maxLength, textData, sectionID, sectionName, storyboardOwner, isStoryboardLocked, readAccess, editAccess, readOnly } = props;
    const [expandModalVisible, setExpandModalVisible] = useState(false);
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

    const [editorText, setEditorText] = useState(textData ? textData.content : ""); // html content
    const [editorLoading, setEditorLoading] = useState(false);
    const [deleteOperLoader, setDeleteOperLoader] = useState(false);

    const onEditContentChange = async ({ editorHtmlContent }) => {
        setEditorText(editorHtmlContent);
        let payload = {
            storyboards: [
                {
                    storyboard_id: storyboardID ? storyboardID : '',
                    edit: false,
                    sections: [
                        {
                            section_id: sectionID ? sectionID : '',
                            section_name: sectionName || '',
                            edit: false,
                            section_elements: [
                                {
                                    element_id: textData && textData.element_id ? textData.element_id : '',
                                    edit: true,
                                    element_type: "text",
                                    properties: {},
                                    content: editorHtmlContent,
                                },
                            ],
                        },
                    ],
                },
            ],
            analysis_id: selectedAnalysisData?.analysis_id,
        };
        const response = await addToStoryBoard(payload);
        if (response?.status === "failed") {
            console.log('failed to delete storyboard text');
        }
    };

    const handleRemoveCancel = () => {
        setIsRemoveModalOpen(false);
    }

    const handleRemoveOk = async () => {
        setDeleteOperLoader(true);
        let payload = {
            storyboards: [
                {
                    storyboard_id: storyboardID,
                    sections: [
                        {
                            section_id: sectionID ? sectionID : '',
                            section_name: sectionName || '',
                            edit: false,
                            section_elements: [textData.element_id],
                        },
                    ],
                },
            ],
            analysis_id: selectedAnalysisData?.analysis_id,
        };
        const response = await deleteStoryBoard(payload);
        if (response?.status === "success") {
            const response = await getUserStoryboards({ analysis_id: selectedAnalysisData?.analysis_id, });
            dispatch(setStoryBoardData(response));
        }
        setIsRemoveModalOpen(false);
        setDeleteOperLoader(false);
    };

    const closeFullScreen = () => {
        setExpandModalVisible(false);
        setEditorLoading(true);
        setTimeout(() => {
            setEditorLoading(false);
        }, 500);
    };

    useEffect(() => {
        setEditorLoading(true);
        setTimeout(() => {
            setEditorLoading(false);
        }, 500);
    }, [readOnly])

    const menu = (
        <Menu id="clip-view-menu" key={`text-menu-${textData.element_id}`}>
            <Menu.Item key={`menu-fullscreen-${textData.element_id}`} onClick={() => setExpandModalVisible(true)}>
                <StoryboardArchiveIcon />
                <span className="show-more-text">See Full Screen</span>
            </Menu.Item>
            <Menu.Item key={`menu-remove-${textData.element_id}`} onClick={() => setIsRemoveModalOpen(true)}  disabled={storyboardOwner ? false : true}>
                <StoryboardDeleteIcon />
                <span className="show-more-text">Delete Textbox</span>
            </Menu.Item>
        </Menu>
    );

    const extraIcon = (
        <span style={{ float: 'right' }}>
            <Dropdown
                overlay={menu}
                trigger={["click"]}
                placement="bottomRight"
            >
                <Button style={{ border: 0 }}>
                    <MenuIcon />
                </Button>
            </Dropdown>
        </span>
    )
   
    const collabratorView = {
        pointerEvents: 'none',
        opacity: 0.7
    };

    return (
        <>
            <div style={{ border: "1px solid #DEDCDE", margin: 10, minHeight: '305px' }}>
                <Row>
                    <Col span={23} className={'comment-details'}>
                        <p className='added-date'>
                            Added: {textData?.properties?.modified_date}
                        </p>
                    </Col>
                    <Col span={1} className={'comment-ele-more-action'}>
                        {extraIcon}
                    </Col>
                </Row>
                <Spin spinning={editorLoading}>
                    {
                        !editorLoading &&
                        <QuillEditor
                            defaultContent={editorText}
                            maxLength={maxLength}
                            className={'editor-cards-view'}
                            // extra={
                            //     extraIcon
                            // }
                            onEditorBlurUpdate={onEditContentChange}
                            readOnly={readOnly}
                        />
                    }
                </Spin>
            </div>

            <Modal
                title={null}
                centered
                destroyOnClose
                closable={false}
                visible={expandModalVisible}
                onOk={() => closeFullScreen()}
                width={1200}
                onCancel={() => closeFullScreen()}
                okText="Close"
                cancelButtonProps={{ style: { display: "none" } }}
            >
                {
                    expandModalVisible &&
                    <QuillEditor
                        defaultContent={editorText}
                        maxLength={maxLength}
                        className={'fullscreen-editor'}
                        // extra={
                        //     extraIcon
                        // }
                        onEditorBlurUpdate={onEditContentChange}
                        readOnly={readOnly}
                    />

                }
            </Modal>

            <Modal visible={isRemoveModalOpen} footer={null} closable={false}>
                <div className="delete-modal-title">Delete Textbox?</div>

                <div className="modal-body">
                    Are you sure you want to delete the textbox? <br /> You cannot undo this action
                </div>

                <div className="button-container">
                    <Button
                        style={{ marginRight: "15px" }}
                        onClick={handleRemoveOk}
                        loading={deleteOperLoader}
                    >
                        Yes, Delete
                    </Button>
                    <Button type='primary' onClick={handleRemoveCancel}>
                        Don't Delete
                    </Button>
                </div>
            </Modal>
        </>
    )

};

export default SingleTextView;