import { Spin, Typography, Tabs, Card, Badge } from "antd";
import React, { useEffect } from "react";
import ExploreTabPage from "../explore-tab/ExploreTabPage";
import StoryboardContainer from "../storyboard/StoryboardContainer";
import InsightsTabs from "../insights-tab/InsightsTab";
import StudyLayoutContent from "../study-layout/StudyLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CompareStudiesTable from "../compare-studies/compare-studies-table/CompareStudiesTable";
import "./EicContainer.scss";
import FullTextModal from "../read-full-text/full-text-modal/FullTextModal";
import SavedAnalysisContainer from "../saved-analysis/SavedAnalysisContainer";
import SavedAnalysisModal from "../saved-analysis/saved-analysis-modal/SavedAnalysisModal";
import CollaboratorConfirmModal from "../storyboard/collaborator-confirm-modal/CollaboratorConfirmModal";

const { Title } = Typography;

const EICContainer = () => {
  let SearchValue = useSelector((state) => state.EICCommon.searchTerm);
  let { searchSetLoading, compareStudiesData } = useSelector((state) => state.EICCommon);
  const navigate = useNavigate();
  const { pageName } = useParams();
  const allowedPages = ['explore', 'insights', 'storyboard'];
  const { TabPane } = Tabs;

  useEffect(() => {
    if (allowedPages.indexOf(pageName) === -1) {
      navigate("/landingpage");
    }
  }, [pageName]);

  return (
    <div id="EIC_container">
      <Spin tip="Loading..." spinning={searchSetLoading}>
        <div className="main-container-search">
          <div className="home-container-search">
            <StudyLayoutContent></StudyLayoutContent>
          </div>
        </div>

        {/* Compare Studies Flyout Button */}
        <div style={{ paddingLeft: '80px' }}>

          {pageName === 'explore' &&
            <div className="card-container">
              <Tabs
                defaultActiveKey="1"
                id="parent-tab"
                type="line"
                size={'small'}
              >
                <TabPane tab="Study List" key="studyList" className="h-200">
                  <ExploreTabPage />
                </TabPane>
                <TabPane 
                  tab={
                    <Badge count={compareStudiesData.selectData.length} color="#EE801A" offset={[16, 12]}>
                      Compare Studies
                    </Badge>
                  } 
                  key="compareStudies"
                >
                  <div>
                    <Card>
                      <CompareStudiesTable />
                    </Card>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          }
          {pageName === 'insights' && <InsightsTabs />}
          {pageName === 'storyboard' && <StoryboardContainer />}
        </div>
      </Spin>

      <FullTextModal/>
      <SavedAnalysisModal/>
      <CollaboratorConfirmModal />
    </div>
  );
};

export default EICContainer;
