import { createSlice } from "@reduxjs/toolkit";
import { EICInitialState } from "./EICInitialState";

export const EICCommonSlice = createSlice({
  name: "EICCommon",
  initialState: {
    ...EICInitialState
  },
  reducers: {
    resetAll: (state) => {
      return {
        ...EICInitialState,
        advanceFilterDropdownList: state.advanceFilterDropdownList,
      }
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    setSearchLoadingReducer: (state, action) => {
      state.searchSetLoading = action.payload;
    },
    setFilterState: (state, action) => {
      state.state = action.payload;
    },
    addToComparision: (state, action) => {
      state.compareStudiesData = action.payload;
    },
    removeFromList: (state, action) => {
      state.compareStudiesData = action.payload;
    },
    deleteFromCompareStudies: (state, action) => {
      state.deleteCompareStudies = action.payload;
    },
    rowDataStudyTableSelected: (state, action) => {
      const filteredData =
        action.payload.length > 1
          ? action?.payload?.filter((val) => {
            if (state.selectedRowKeysData.includes(val.key)) {
              return val;
            }
          })
          : action.payload;

      state.studyTableRowDataSelected = filteredData;
    },
    addChartEvidenceLandscapeList: (state, action) => {
      state.evidenceLandscapeChartList = [
        ...state.evidenceLandscapeChartList,
        action.payload,
      ];
    },
    removeChartEvidenceLandscapeList: (state, action) => {
      state.evidenceLandscapeChartList = [...action.payload];
    },
    updateChartEvidenceLandscapeList: (state, action) => {
      state.evidenceLandscapeChartList = [...action.payload];
    },
    addGlobalFilters: (state, action) => {
      state.appliedGlobalFilters = [...action.payload];
    },
    setAdvanceFilters: (state, action) => {
      state.selectedAdvanceFilters = { ...action.payload };
    },
    removeGlobalFilters: (state, action) => {
      const newList = state.appliedGlobalFilters.filter(
        (item) => item !== action.payload
      );
      state.appliedGlobalFilters = [...newList];
    },
    geoPieChartData: (state, action) => {
      state.geoChartData.geoPieChartValues = action.payload;

      const checkboxval = action?.payload?.sub_chart?.map((val) => {
        return val.sub_entity_name;
      });
      // state.geoChartData.defaultBreakDownValue = checkboxval;
      state.geoChartData.checkBoxValues = checkboxval;

      const filteredData =
        state?.geoChartData?.geoChartStudiesData?.chart_1?.chart_data.length >
        0 &&
        state?.geoChartData?.geoChartStudiesData?.chart_1?.chart_data?.map(
          (val) => {
            if (
              state.geoChartData.geoPieChartValues.entity_code ===
              val.entity_code
            ) {
              const result = val?.sub_chart?.map((val) => {
                return val.sub_entity_name;
              });
              state.geoChartData.filteredDefaultValues = result;
            }
          }
        );
    },
    setCompetitiveBarChartData: (state, action) => {
      state.competitiveBarChartData = action.payload;
    },
    setCompetitiveBarSubCatData: (state, action) => {
      state.competitiveBarSubCatData = action.payload;
    },
    setTimelineChartData: (state, action) => {
      state.timelineChartData = action.payload;
    },
    setTimeLineSubCatData: (state, action) => {
      state.timeLineSubCatData = action.payload;
    },
    setCompetitiveBreakdownBy: (state, action) => {
      state.competitiveBreakdownBy = action.payload;
    },
    setAvailableFilterList: (state, action) => {
      state.availableFilterList = action.payload;
    },
    setGlobalRawList: (state, action) => {
      state.globalRawList = action.payload;
    },
    geoChartFilterStudiesByBreakdown: (state, action) => {
      const { payload } = action;

      payload?.length > 0 &&
        payload?.reduce((acc, curr) => {
          if (state?.geoChartData?.checkBoxValues?.includes(curr)) {
            const result = state.geoChartData.checkBoxValues.filter(
              (val) => val === curr
            );

            state.geoChartData.checkBoxValues = result;
          } else {
            state.geoChartData.checkBoxValues.push(curr);
          }
          return acc;
        }, []);

      const subChartOutput =
        state?.geoChartData?.geoChartStudiesData?.chart_1?.chart_data.length >
        0 &&
        state?.geoChartData?.geoChartStudiesData?.chart_1?.chart_data.map(
          (ele) => {
            return {
              ...ele,
              sub_chart: ele.sub_chart.filter((val) => {
                if (
                  state?.geoChartData?.checkBoxValues?.length > 0 &&
                  state?.geoChartData?.checkBoxValues?.includes(
                    val.sub_entity_name
                  )
                ) {
                  {
                    return val;
                  }
                }
              }),
            };
          }
        );

      state.geoChartData.geoChartStudiesBreakDownData = subChartOutput;

      if (state.geoChartData.countryCode.length < 1) {
        const result =
          subChartOutput?.length > 0 &&
          subChartOutput?.reduce((acc, curr) => {
            return acc.entity_count > curr.entity_count ? acc : curr;
          });

        state.geoChartData.geoPieChartValues = result;

        const checkBoxVal = result?.sub_chart?.map((val) => {
          return val.sub_entity_name;
        });
        // state.geoChartData.defaultBreakDownValue = checkBoxVal;
        state.geoChartData.checkBoxValues = checkBoxVal;

        const filteredData =
          state?.geoChartData?.geoChartStudiesData?.chart_1?.chart_data
            ?.length > 0 &&
          state?.geoChartData?.geoChartStudiesData?.chart_1?.chart_data?.forEach(
            (val) => {
              if (
                state.geoChartData.geoPieChartValues.entity_code ===
                val.entity_code
              ) {
                const result = val?.sub_chart?.map((val1) => {
                  return val1.sub_entity_name;
                });

                state.geoChartData.filteredDefaultValues = result;
              }
            }
          );
      }
    },
    geoChartStudiesDataReducer: (state, action) => {
      state.geoChartData.geoChartStudiesData = action.payload;

      if (action?.payload.chart_1?.chart_data?.length > 0) {
        const result = action?.payload?.chart_1?.chart_data?.reduce(
          (arr, curr) => {
            const res = curr.sub_chart.map((val) => {
              if (!arr.includes(val.sub_entity_name)) {
                return val.sub_entity_name;
              }
            });

            arr = arr.concat(...res);
            const output = arr.filter((val) => val !== undefined);
            return output;
          },
          []
        );

        state.geoChartData.defaultBreakDownValue = result;
        state.geoChartData.checkBoxValues = result;
      }
    },
    geoChartStudiesSearchValue: (state, action) => {
      state.geoChartData.searchStudy = action.payload;
    },
    setStudyListTableData: (state, action) => {
      state.studyListTableData = action.payload;
    },
    setDropDownList: (state, action) => {
      state.dropDownList = action.payload;
    },
    setPagination: (state, action) => {
      state.studyListPageNumber = action.payload;
    },
    setLoadingGeoDataReducer: (state, action) => {
      state.geoChartData.setLoadingGeoData = action.payload;
    },

    setDoNotShowCheckbox: (state, action) => {
      state.doNotShowCheckBox = action.payload;
    },
    setCountryCode: (state, action) => {
      state.geoChartData.countryCode = action.payload;
    },
    setSelectedStudyListData: (state, action) => {
      state.retrieveSelectedKeys = action.payload;
    },
    setLoadingPieData: (state, action) => {
      state.geoChartData.LoadingPieData = action.payload;
    },
    setEvidenceLandscapeCleanupStatus: (state, action) => {
      state.cleanupEvidenceLandscape = action.payload;
    },
    setCompetitiveIntelligenceCleanupStatus: (state, action) => {
      state.cleanupCompetitiveIntelligence = action.payload;
    },
    setGeoDistributionCleanupStatus: (state, action) => {
      state.cleanupGeoDistribution = action.payload;
    },
    setTimelineTrendCleanupStatus: (state, action) => {
      state.cleanupTimelineTrend = action.payload;
    },
    toggleReadFullText: (state, action) => {
      state.isReadFullTextModalConfig = action.payload;
    },
    setStoryBoardData: (state, action) => {
      state.storyBoardData = action.payload;
    },
    addToReadFullTextData: (state, action) => {
      state.readFullTextDataList = [...state.readFullTextDataList, action.payload];
    },
    removeFromReadFullTextData: (state, action) => {
      const newReadFullTextDataList = state.readFullTextDataList.filter((element) => element.id !== action.payload);
      state.readFullTextDataList = newReadFullTextDataList;
    },
    setStoryBoardID: (state, action) => {
      state.storyBoardID = action.payload;
    },
    toggleSaveNewAnalysis: (state, action) => {
      state.isAnalysisSaveModalConfig = action.payload;
    },
    setSelectedAnalysisData: (state, action) => {
      state.selectedAnalysisData = action.payload;
    },
    onAnalysisUpdated: (state, action) => {
      state.analysisLastUpdatedTime = action.payload;
    },
    toggleCheckAnalysisSave: (state, action) => {
      state.checkAnalysisSaveConfig = action.payload;
    },
    setAdvanceFilterDropdownList: (state, action) => {
      state.advanceFilterDropdownList = action.payload;
    },
    // .............Dynamic & Interactive Insights tab reducers starts here.........
    setInteractiveStudyListTableData: (state, action) => {
      state.IteractiveStudyListTableData = action.payload;
    },
    setInteractivePagination: (state, action) => {
      state.InteractiveStudyListPageNumber = action.payload;
    },
    deleteFromInteractiveCompareStudies: (state, action) => {
      state.deleteInteractiveCompareStudies = action.payload;
    },
    setInteractiveSelectedStudyListData: (state, action) => {
      state.retrieveInteractiveSelectedKeys = action.payload;
    },
    addToComparisionInteractive: (state, action) => {
      state.compareStudiesDataInteractive = action.payload;
    },
    removeFromListInteractive: (state, action) => {
      state.compareStudiesDataInteractive = action.payload;
    },
    // toggleInteractiveReadFullText: (state, action) => {
    //   state.isInteractiveReadFullTextModalConfig = action.payload;
    // },
    //neha- new insights chart enhancement
    setInteractiveFilters: (state, action) => {
      state.interactiveFilters = action.payload;
    },
    // ..............ends here................
    setIsAnalysisLoaded: (state, action) => {
      state.isAnalysisLoaded = action.payload;
    },
    setExcludedStudyIds: (state, action) => {
      state.excludedStudyIds = action.payload;
    },
    setExploreViewMode: (state, action)=> {
      state.exploreViewMode = action.payload;
    },
    setIsExcludeStudyConfig: (state, action) => {
      state.isExcludeStudyConfig = action.payload;
    },
    setStoryBoardOwner: (state, action) => {
      state.isStoryboardOwner = action.payload;
    },
    toggleCollaboratorConfirm: (state, action) => {
      state.checkCollaboratorConfirm = action.payload;
    },
    setCollaboratorModal: (state, action) => {
      state.checkCollaboratorModal = action.payload;
    },
    setComingFromCollaboratorView: (state, action) => {
      state.isComingFromCollaboratorView = action.payload;
    },
    setCollaboratorDetails: (state, action) => {
      state.checkCollaboratorDetails = action.payload;
    },
    setUserID: (state, action) => {
      state.userID = action.payload;
    },
  },
});

export const {
  resetAll,
  setSearchTerm,
  setFilterState,
  addToComparision,
  removeFromList,
  deleteFromCompareStudies,
  rowDataStudyTableSelected,
  addChartEvidenceLandscapeList,
  removeChartEvidenceLandscapeList,
  updateChartEvidenceLandscapeList,
  addGlobalFilters,
  setAdvanceFilters,
  removeGlobalFilters,
  geoPieChartData,
  setCompetitiveBarChartData,
  setAvailableFilterList,
  geoChartFilterStudiesByBreakdown,
  geoChartStudiesDataReducer,
  geoChartStudiesSearchValue,
  setStudyListTableData,
  setDropDownList,
  setTimelineChartData,
  setPagination,
  setCompetitiveBarSubCatData,
  setCompetitiveBreakdownBy,
  setSearchLoadingReducer,
  setTimeLineSubCatData,
  setLoadingGeoDataReducer,
  setDoNotShowCheckbox,
  setCountryCode,
  setSelectedStudyListData,
  setLoadingPieData,
  setGlobalRawList,
  setEvidenceLandscapeCleanupStatus,
  setCompetitiveIntelligenceCleanupStatus,
  setGeoDistributionCleanupStatus,
  setTimelineTrendCleanupStatus,
  toggleReadFullText,
  setStoryBoardData,
  addToReadFullTextData,
  removeFromReadFullTextData,
  setStoryBoardID,
  toggleSaveNewAnalysis,
  setSelectedAnalysisData,
  onAnalysisUpdated,
  toggleCheckAnalysisSave,
  setAdvanceFilterDropdownList,
  setInteractiveStudyListTableData,
  setInteractivePagination,
  deleteFromInteractiveCompareStudies,
  setInteractiveSelectedStudyListData,
  addToComparisionInteractive,
  removeFromListInteractive,
  setIsAnalysisLoaded,
  setInteractiveFilters,
  // toggleInteractiveReadFullText,
  setExcludedStudyIds,
  setExploreViewMode,
  setIsExcludeStudyConfig,
  setStoryBoardOwner,
  toggleCollaboratorConfirm,
  setCollaboratorModal,
  setComingFromCollaboratorView,
  setUserID,
  setCollaboratorDetails
} = EICCommonSlice.actions;

export default EICCommonSlice.reducer;
