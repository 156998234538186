import React, { useState } from "react";
import { Tabs, Button } from "antd";
import Icon from "@ant-design/icons";
import SupportScreenForm from "./support-screen-form/SupportScreenForm";
import SupportFAQ from "./support-screen-FAQ/SupportFAQ";
import PrivacyPolicy from "./privacy-policy/PrivacyPolicy";
import "./SupportPrivacyPolicyLayout.scss";
import { EyeIcon } from "../../assets/icons/svg";
import DemoVideosModal from "./demo-videos/DemoVideosModal";
import UserManual from "./view-manual/UserManual";

const SupportPrivacyPolicyLayout = () => {
  const { TabPane } = Tabs;
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <DemoVideosModal showModal={showModal} handleClose={handleClose} />
      <div className="support-privacy-container">
        <div className="support-page-buttons">
          <Button className="view-demo-button" onClick={handleClick}>
            <Icon component={EyeIcon} />
            View Demo
          </Button>
        </div>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Support" key="1">
            <SupportScreenForm />
            <SupportFAQ />
          </TabPane>
          <TabPane tab="Privacy Policy" key="2">
            <PrivacyPolicy />
          </TabPane>
          <TabPane tab="User Manual" key="3">
            <UserManual />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};
export default SupportPrivacyPolicyLayout;
