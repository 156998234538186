import React, { useEffect, useState } from "react";
import { Col, Row, Modal, Button, Tooltip, Spin } from "antd";
import { Select } from "antd";
import Icon from "@ant-design/icons";
import Highcharts from "react-highcharts";
import { useDispatch, useSelector } from "react-redux";
import { DrugInfo, FacetSearchIcon } from "../../../assets/icons/svg";
import {
  getCompetitiveBarChartData,
  getInteractiveSearch,
  mapAdvanceFilters
} from "./../../../services/InsightsViewAPI";
import {
  setCompetitiveBarChartData,
  setCompetitiveBarSubCatData,
  setCompetitiveBreakdownBy,
  setCompetitiveIntelligenceCleanupStatus,
  setInteractiveFilters
} from "../../../reducer/EICCommonSlice";
import "./CompetitiveIntelligence.scss";
import AddToStoryBoardView from "../../add-to-storyboard-view/AddToStoryBoardView";
import { useNavigate } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import { FormatAdvFilterDataForDynamicChart } from "../../../shared/utils/CommonUtil";

const { Option } = Select;
const { confirm } = Modal;
var zoom = (window.outerWidth / window.innerWidth) * 100;

function CompetitiveIntelligenceContent() {
  const selectedAdvanceFilters = useSelector(
    (state) => state.EICCommon.selectedAdvanceFilters
  );

  const [chartModalVisible, setChartModalVisible] = useState(false);
  const [barChartData, setBarChartData] = useState("");
  const [loading, setLoading] = useState(false);

  const [viewByValue, setViewByValue] = useState("Endpoints");
  const [viewBySubCat, setViewBySubCat] = useState([]);
  const [Intervention, setIntervention] = useState(
    selectedAdvanceFilters?.drug
      ? selectedAdvanceFilters.drug.slice(0, 7)
      : ["Top 5"]
  );
  const [checkIntervention, setcheckIntervention] = useState(false);  
  
  let defaultBreakdownBy;
  let breakdown_by = barChartData?.chart_1?.breakdown_by_values;
  defaultBreakdownBy = ["Top 5"].concat(breakdown_by);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let dropDownList = useSelector((state) => state.EICCommon.dropDownList);

  const {
    EICCommon: {
      InteractiveStudyListPageNumber,
      searchTerm,
      excludedStudyIds,
      advanceFilterDropdownList,
    },
  } = useSelector((state) => state);

  let competitiveBreakdownBy = useSelector(
    (state) => state.EICCommon.competitiveBreakdownBy
  );
  const [chartReload, setChartReload] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", rerenderChart);
    return () => window.removeEventListener("resize", rerenderChart);
  }, []);

  const rerenderChart = () => {
    setChartReload(true);
    setTimeout(() => {
      setChartReload(false);
    }, 100);
  };

  const handleInteractiveCharts = async (selectedViewByValue, selectedDrug, isNavToExplore) => {
    var tempAdvanceFilter = cloneDeep(selectedAdvanceFilters);
    var _tempAdvanceFilters = mapAdvanceFilters(tempAdvanceFilter);

    //Neha - changing all the keys of an object to lower case
    var key, keys = Object.keys(_tempAdvanceFilters);
    var n = keys.length;
    var tempAdvanceFilters={}
    while (n--) {
      key = keys[n];
      tempAdvanceFilters[key.toLowerCase()] = _tempAdvanceFilters[key];
    }
      
    var viewBy = viewByValue == 'Data Source' ? 'data_source' : viewByValue.toLowerCase();    

    var payload_1 = {};
    var payload_2 = {};

    let filterKeys = Object.keys(tempAdvanceFilters);

    if ((filterKeys.indexOf(viewBy) > -1 ) || (filterKeys.indexOf('drug') > -1)) {
      for (let key in tempAdvanceFilters) {
        payload_1 = tempAdvanceFilters[viewBy]?.find((item) => item == selectedViewByValue);
      }

      for (let key in tempAdvanceFilters) {
        payload_2 = tempAdvanceFilters['drug']?.find((item) => item == selectedDrug);
      }

      tempAdvanceFilters = {
        ...tempAdvanceFilters,
        [viewBy]: payload_1 == undefined ? [selectedViewByValue] : [payload_1],
        drug : payload_2 == undefined ? [selectedDrug] : [payload_2],
      }
    } 
    else { 
      tempAdvanceFilters = {
        ...tempAdvanceFilters,
        [viewBy] : [selectedViewByValue],
        drug : [selectedDrug]
      }
    }

    setLoading(true);

    //Neha - below logic is to change the filter mapping for advance filter
    const capitalizeFirstLetter=(string)=> {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    var _tempAdvanceFilters = FormatAdvFilterDataForDynamicChart(advanceFilterDropdownList, tempAdvanceFilters);
    dispatch(setInteractiveFilters(_tempAdvanceFilters));
    setLoading(false);
    if (isNavToExplore){
      navigate("/home/explore");
    }
};

  const showInteractiveConfirm = (seriesName, category) => {
    confirm({
      title: 'Confirm changes',
      content: `Are you sure you want to leave this page? The changes you made will affect your overall results & add a filter ${viewByValue}`,
      okText: 'Move to Explore',
      okType: 'primary',
      cancelText: 'Continue on Insights',
      closable: true,
      icon: null,
      wrapClassName: 'interactive-filter-confirm',
      centered: true,   
      onOk() {
        handleInteractiveCharts(
          seriesName,category, true
        );
      },
      onCancel(closeFn) {
        if(typeof(closeFn) === 'function' && closeFn?.name !== "") {
          handleInteractiveCharts(
            seriesName,category, false
          );
          closeFn();
        }
      },
    });
  };

  const barChartResponse = async (interventionPassed) => {
    setLoading(true);

    let max_entities_breakdown_by;

    if (interventionPassed && interventionPassed.length) {
      max_entities_breakdown_by =
      interventionPassed == "Top 5" || interventionPassed.length == 0
        ? 5
        : interventionPassed.length;
    }
    let viewBySubCatTemp = [];
    setViewBySubCat([]);
    let InterventionTemp = selectedAdvanceFilters?.drug?.length
      ? selectedAdvanceFilters?.drug
      : interventionPassed == 'Top 5' ? ["Top 5"] : Intervention;

    // //checking if the value contains white space or not
    if (viewByValue !== "") {
      var tempViewByValue = containsWhitespace(viewByValue);
    }

    try {
      const response = await getCompetitiveBarChartData(
        searchTerm,
        selectedAdvanceFilters,
        tempViewByValue,
        viewBySubCatTemp,
        InterventionTemp,
        max_entities_breakdown_by,
        excludedStudyIds
      );
      setLoading(false);

      if (
        response?.chart_1?.chart_data?.series?.length &&
        response?.chart_1?.chart_data?.categories?.length
      ) {
        setBarChartData(response);

        setViewBySubCat((response?.chart_1?.view_by_values).slice(0, 7));
        dispatch(setCompetitiveBarChartData(response));
        dispatch(
          setCompetitiveBarSubCatData(response?.chart_1?.view_by_values)
        );
        dispatch(
          setCompetitiveBreakdownBy(response?.chart_1?.breakdown_by_values)
        );

        //Neha- setting the state varible when user made changes in advance filters
        if (selectedAdvanceFilters?.drug?.length) {
          setIntervention((response?.chart_1?.breakdown_by_values).slice(0, 7));
        }

        setLoading(false);
      } else {
        setLoading(false);
        setBarChartData("");
        setViewBySubCat([]);
        setIntervention(["Top 5"]);
        setcheckIntervention(false);
      }
    } catch (error) {
      setLoading(false);
      setBarChartData("");
      setViewBySubCat([]);
      setIntervention(["Top 5"]);
      setcheckIntervention(false);
    }
  };

  let cleanupStatus = useSelector(
    (state) => state.EICCommon.cleanupCompetitiveIntelligence
  );
  if (cleanupStatus) {
    setIntervention(["Top 5"]);
    setViewByValue("Endpoints");
    setViewBySubCat([]);

    barChartResponse();
    dispatch(setCompetitiveIntelligenceCleanupStatus(false));
  }

  useEffect(() => {
    if (selectedAdvanceFilters?.drug?.length) {
      let intervention = selectedAdvanceFilters.drug.slice(0, 7);
      setIntervention([...intervention]);
      barChartResponse([...intervention]);
    } else {
      setIntervention(["Top 5"]);
      barChartResponse(["Top 5"]);
    }
  }, [selectedAdvanceFilters]);

  useEffect(() => {
    barChartResponse();
  }, [searchTerm, excludedStudyIds]);

  const containsWhitespace = (str) => {
    let TextHaveSpace = str.indexOf(" ") >= 0;
    return TextHaveSpace == true ? str.split(" ").join("_") : str;
  };

  const handleChange = async (value, category) => {
    let ViewByValue = viewByValue;

    if (category == "viewby") {
      ViewByValue = value;
    } else if (category == "viewbysubcat") {
      setViewBySubCat(value);
    } else if (category == "intervention") {
      if (value.length == 0 && !checkIntervention) {
        setIntervention(competitiveBreakdownBy.slice(0, 5));
        setcheckIntervention(!checkIntervention);
      } else if (
        value.length == 0 &&
        checkIntervention &&
        !selectedAdvanceFilters?.drug?.length
      ) {
        setIntervention(["Top 5"]);
        setcheckIntervention(!checkIntervention);
      } else if (
        value.length &&
        checkIntervention == true &&
        value.includes("Top 5")
      ) {
        setIntervention(["Top 5"]);
        setcheckIntervention(!checkIntervention);
      } else if (value.length == 0 && selectedAdvanceFilters?.drug?.length) {
        setIntervention(selectedAdvanceFilters?.drug.slice(0, 7));
      } else if (value.includes("Top 5") && value.length > 1) {
        setIntervention(["Top 5"]);
        setcheckIntervention(true);
      } else {
        setIntervention(value);
      }
    }

    // //checking if the value contains white space or not
    if (ViewByValue !== "") {
      var tempViewByValue = containsWhitespace(ViewByValue);
    }

    if (category == "viewby") {
      let max_entities_breakdown_by =
        Intervention == "Top 5"
          ? 5
          : Intervention.length == 0
          ? 5
          : Intervention.length;
      setLoading(true);
      try {
        const response = await getCompetitiveBarChartData(
          searchTerm,
          selectedAdvanceFilters,
          tempViewByValue,
          [],
          Intervention == "Top 5" ? [] : Intervention,
          max_entities_breakdown_by,
          excludedStudyIds
        );

        setViewByValue(value);
        setViewBySubCat(response?.chart_1?.view_by_values.slice(0, 7));

        if (
          response?.chart_1?.chart_data?.series?.length &&
          response?.chart_1?.chart_data?.categories?.length
        ) {
          setLoading(false);
          setBarChartData(response);
          dispatch(setCompetitiveBarChartData(response));

        } else {
          setLoading(false);
          setBarChartData("");
          setViewBySubCat([]);
          setIntervention(["Top 5"]);
          setcheckIntervention(false);
        }
      } catch (error) {
        setLoading(false);
        setBarChartData("");
        setViewBySubCat([]);
        setIntervention(["Top 5"]);
        setcheckIntervention(false);
      }
    }
  };

  const onFilterClose = async (value) => {
    let max_entities_breakdown_by =
      Intervention == "Top 5"
        ? 5
        : Intervention.length == 0
        ? 5
        : Intervention.length;
    if (value == false) {
      try {
        setLoading(true);

        if (viewByValue !== "") {
          var tempViewByValue = containsWhitespace(viewByValue);
        }

        const response = await getCompetitiveBarChartData(
          searchTerm,
          selectedAdvanceFilters,
          tempViewByValue,
          viewBySubCat,
          Intervention == "Top 5" ? [] : Intervention,
          max_entities_breakdown_by,
          excludedStudyIds
        );

        if (viewBySubCat.length == 0) {
          setViewBySubCat(response?.chart_1?.view_by_values.slice(0, 7));
        }

        if(Intervention.length == 0) {
          setIntervention(["Top 5"]);
          setcheckIntervention(!checkIntervention);
        }

        if (
          response?.chart_1?.chart_data?.series?.length &&
          response?.chart_1?.chart_data?.categories?.length
        ) {
          setLoading(false);
          setBarChartData(response);
          dispatch(setCompetitiveBarChartData(response));
        } else {
          setLoading(false);
          setBarChartData("");
          setViewBySubCat([]);
          setIntervention(["Top 5"]);
          setcheckIntervention(false);
        }
      } catch (error) {
        setLoading(false);
        setBarChartData("");
        setViewBySubCat([]);
        setIntervention(["Top 5"]);
        setcheckIntervention(false);
      }
    }
    setLoading(false);
  };

  const isDisableOption = (breakdownList, optionItem) => {
    if (Intervention == "Top 5" && optionItem == "Top 5") {
      return false;
    } else if (
      breakdownList.includes("Top 5") &&
      optionItem == "Top 5" &&
      checkIntervention &&
      Object.keys(selectedAdvanceFilters).length == 0
    ) {
      return false;
    } else if (
      Intervention !== "Top 5" &&
      optionItem &&
      checkIntervention &&
      Object.keys(selectedAdvanceFilters).length == 0
    ) {
      return Intervention.length >= 7
        ? Intervention.includes(optionItem)
          ? false
          : true
        : false;
    } else if (
      Intervention !== "Top 5" &&
      Object.keys(selectedAdvanceFilters).length == 0
    ) {
      return true;
    } else if (
      Intervention &&
      Object.keys(selectedAdvanceFilters).length >= 1
    ) {
      if (optionItem == "Top 5") {
        return Intervention.length > 5 ? false : true;
      } else if (!Intervention.includes("Top 5")) {
        return Intervention.length >= 7
          ? Intervention.includes(optionItem)
            ? false
            : true
          : false;
      } else {
        return true;
      }

    }
  };

  const barChartConfig = () => ({
    chart: {
      type: "column",
      height: (9 / 16 * 50) + '%'
    },
    tooltip: {
      backgroundColor: "#2F3C2C",
      style: {
        color: "#FAFAFA",
      },
      formatter: function () {
        return (
          "Drug : " +
          this.x +
          "<br/>" +
          viewByValue +
          " " +
          ":" +
          " " +
          this.series.name +
          "<br/>" +
          "Number of Studies : " +
          this.y
        );
      },
    },
    xAxis: {
      allowDecimals: false,
      tickLength: 0,
      lineWidth: 1,
      title: {
        text: "Drug",
      },
      categories: barChartData?.chart_1?.chart_data?.categories,
    },
    colors: [
      "#027AFF",
      "#7CC0FF",
      "#C4E3FF",
      "#FF9836",
      "#FECA98",
      "#B2B0B6",
      "#716E79",
    ],
    title: "# of Studies",
    plotOptions: {
      series: {
        cursor: "pointer",
        point: {
          events: {
            click: function () {
              showInteractiveConfirm(this.series.name, this.category);
            },
          },
        },
      },
      column: {
        borderWidth: 0,
        pointPadding: 0,
        minPointLength: 3,
      },
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: "# of Studies",
      },
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      x: 0,
      y: 0,
      itemStyle: {
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: 14,
      },
    },
    series: barChartData?.chart_1?.chart_data?.series,
    credits: {
      enabled: false,
    },
    responsive: {
      rules: [{
          condition: {
              maxWidth: 500
          }
      }]
  }
  });

  return (
        <>
          <Row style={{ marginTop: 5, marginBottom: 5, justifyContent:'end',display:'grid' }}>
            <Col span={3}>
              <AddToStoryBoardView elementID={"ci-snap"}></AddToStoryBoardView>
            </Col>
          </Row>
          <Spin spinning={loading}>
            <div id="ci-snap" className="competitive-container">
              <div className="selection-container">
                <div className="bar-chart-title">
                  Study Distribution by Drug
                </div>
                <div className="drug-selection">
                  <span className="drug-title">Drug</span>
                  <span>
                    <Tooltip title="Select up to 7 Drugs to compare">
                      <Icon
                        component={DrugInfo}
                        style={{ paddingLeft: "5px", cursor: "pointer" }}
                      />{" "}
                      :
                    </Tooltip>
                  </span>
                  <Select
                    mode="multiple"
                    defaultValue={Intervention}
                    value={Intervention}
                    style={{ width: 240, marginLeft: 6 }}
                    maxTagCount={"responsive"}
                    onChange={(e) => handleChange(e, "intervention")}
                    onDropdownVisibleChange={(e) => onFilterClose(e)}
                  >
                    {defaultBreakdownBy?.map((item) => (
                      <>
                        <Option
                          value={item}
                          key={item}
                          disabled={isDisableOption(defaultBreakdownBy, item)}
                        >
                          {item}
                        </Option>
                      </>
                    ))}
                  </Select>
                </div>
                <div className="viewby-selection-container">
                  <span className="drug-title">View by</span>
                  <span>
                    <Tooltip
                      title={
                        "Select up to 7 " + viewByValue
                      }
                    >
                      <Icon
                        component={DrugInfo}
                        style={{ paddingLeft: "5px", cursor: "pointer" }}
                      />{" "}
                      :
                    </Tooltip>
                  </span>
                  <Select
                    defaultValue="Endpoints"
                    value={viewByValue}
                    style={{ width: 240, marginLeft: 6 }}
                    onChange={(e) => handleChange(e, "viewby")}
                  >
                    {dropDownList &&
                      dropDownList.map((item) =>
                        item !== "Drug" ? (
                          <Option value={item}>
                            {item}
                          </Option>
                        ) : (
                          ""
                        )
                      )}
                  </Select>
                  <Select
                    mode="multiple"
                    defaultValue={viewBySubCat}
                    value={viewBySubCat}
                    style={{ width: 240, marginLeft: 83, marginTop: 8, display: 'flex'}}
                    onChange={(e) => handleChange(e, "viewbysubcat")}
                    onDropdownVisibleChange={(e) => onFilterClose(e)}
                    maxTagCount={"responsive"}
                  >
                    {barChartData?.chart_1?.view_by_values.map((option) => (
                      <Option
                        value={option}
                        disabled={
                          viewBySubCat.length >= 7
                            ? viewBySubCat.includes(option)
                              ? false
                              : true
                            : false
                        }
                      >
                        {option}
                      </Option>
                    ))}
                  </Select>
                  <div className="expand-chart">
                    <Button onClick={() => setChartModalVisible(true)}>
                      <FacetSearchIcon />
                    </Button>
                  </div>
                </div>
              </div>

              {barChartData?.chart_1?.chart_data?.categories?.length &&
              barChartData?.chart_1?.chart_data?.series?.length ? (
                <div>
                  { chartReload ? '' : 
                    <Highcharts
                      style={{ padding: 20 }}
                      config={barChartConfig("mainChart")}
                    />
                  }
                </div>
              ) : (
                <div className="no-data">
                  There is no data available to display based on applied
                  criteria
                </div>
              )}
            </div>
          </Spin>

          <Modal
            title="Study Distribution by Intervention"
            centered
            visible={chartModalVisible}
            onOk={() => setChartModalVisible(false)}
            width={1200}
            onCancel={() => setChartModalVisible(false)}
            okText="Close"
            cancelButtonProps={{ style: { display: "none" } }}
          >
            <Row>
              <Col span={24}>
                <Highcharts config={barChartConfig()} />
              </Col>
            </Row>
          </Modal>
        </>
  );
}

export default CompetitiveIntelligenceContent;
