import React, { useEffect, useState } from "react";
import './SectionContent.scss';
import { mapHighlightColor } from "./HighlightColors";
import SectionTable from "../section-table/SectionTable";
import { cloneDeep } from "lodash";

const SectionContent = (props) => {

  const appliedAttributes = props.appliedAttributes;
  const attributeList = props.attributeList;
  const IS_LOAD_BASE64_IMAGE = true; // load image as base64 string
  const boldHighlight = props.boldHighlight;

  const [sectionData, setSectionData] = useState();

  useEffect(() => {
    loadBase64Image();
  }, [props.sectionData]);

  /**
   * Logic to Highlight the text using index
   * @param {*} section - Specific section data 
   * @returns 
   */
  const getHighlightHtml = (section) => {
    if (section?.section_text?.length <= 0) {
      return "";
    }
    let textToHighlight = section.section_text;
    let textChunksToHighlightList = [];

    if (section.annotation_config.length === 0) return `<p>${textToHighlight}</p>`;

    const inheritedList = attributeList.filter((item) => item.isInherited);

    let tempAppliedAttributes = cloneDeep(appliedAttributes);
    section.annotation_config?.forEach((item) => {
      let isHighlight = tempAppliedAttributes.includes(item.entity_type.toLowerCase());
      if(!isHighlight) {
        const index = inheritedList.findIndex((ele) => ele.attribute_key === item.entity_type.toLowerCase());
        isHighlight = index > -1 && inheritedList[index].inheritedData?.findIndex((ele) => ele?.toLowerCase() === item.addn_metadata?.standard_value?.toString().toLowerCase()) > -1? true : false;
      }
      if (boldHighlight || isHighlight) {
        textChunksToHighlightList.push({ ...item, isHighlight, boldHighlight });
      }
    });
    if (textChunksToHighlightList.length === 0) return `<p>${textToHighlight}</p>`;

    textChunksToHighlightList.sort((a, b) => a.start_index - b.start_index);

    let htmlString = '';
    let currentIndex = 0;
    textChunksToHighlightList.forEach((item) => {
      if (currentIndex > item.start_index) return;
      if (item.start_index > currentIndex) {
        htmlString += textToHighlight.substring(currentIndex, item.start_index);
      }
      let textHtml = textToHighlight.substring(item.start_index, item.end_index);
      let tooltipHtml = null;
      let tooltipAttr = null;
      let tootlipClass = '';
      if (item.boldHighlight) {
        // browser tooltip
        tooltipAttr = `title="Mapped to: ${capitalize(item.entity_type)}"`;
        
        textHtml = `<strong>${textHtml}</strong>`;
      }

      const styleStr = item.isHighlight ?
        `style="background-color: ${mapHighlightColor(item.entity_type.toLowerCase(), tempAppliedAttributes)}"`
        : null;

      htmlString += `<span class="highlight-text-content ${tootlipClass}"  ${styleStr} ${tooltipAttr}>${tooltipHtml || ''}${textHtml}</span>`;
      currentIndex = item.end_index;
    });
    if (currentIndex < textToHighlight.length) {
      htmlString += textToHighlight.substring(currentIndex, textToHighlight.length - 1);
    }
    return `<p>${htmlString}</p>`;
  }

  const imageUrlToBase64 = async url => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((onSuccess, onError) => {
      try {
        const reader = new FileReader();
        reader.onload = function () { onSuccess(this.result) };
        reader.readAsDataURL(blob);
      } catch (e) {
        onError(e);
      }
    });
  };

  const loadBase64Image = async () => {
    const sections = { ...props.sectionData };
    setSectionData(sections);
    if (!IS_LOAD_BASE64_IMAGE) return;

    sections?.section_data?.map(async (ele, idx) => {
      if (ele.section_type === 'figure' && !ele.image_details?.imageBase64) {
        const secList = { ...props.sectionData };

        const imageBase64 = await imageUrlToBase64(ele.image_details?.path);
        secList.section_data[idx].image_details.imageBase64 = imageBase64;
        setSectionData(secList);
      }
      return ele;
    });
  };

  const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || ""

  return (
    <>
      <div id="parent-section">
        {
          sectionData?.section_data?.map((element, index) =>
            <div key={`element${index}`}>
              <section style={{ marginRight: 20 }}>
                {
                  element.section_type === 'text' &&
                  <div>
                    <h3>{element.section_name?.length > 0 && element.section_name}</h3>
                    {
                      element.section_text.length > 0 && <div dangerouslySetInnerHTML={{ __html: getHighlightHtml(element) }} />
                    }
                  </div>
                }
              </section>

              <section>
                {
                  element.section_type === 'figure' &&
                  <div className="image-table-wrapper">
                    <div style={{ padding: 20 }}>
                      {
                        IS_LOAD_BASE64_IMAGE ?
                          <img style={{ height: 500, width: '100%' }} src={element.image_details?.imageBase64} alt="" />
                          :
                          <img style={{ height: 500, width: '100%' }} src={element.image_details?.path} alt="" />
                      }
                      <p>{element.image_details?.label?.length > 0 && element.image_details?.label}</p>
                      <p>{element.image_details?.desc?.length > 0 && element.image_details?.desc}</p>
                    </div>
                  </div>
                }
              </section>

              <section style={{ marginTop: 20 }}>
                {
                  element.section_type === 'table' &&
                  <div className="image-table-wrapper">
                    <SectionTable tableData={element}></SectionTable>
                  </div>
                }
              </section>
            </div>
          )
        }
      </div>

      <div>
        <section style={{ marginRight: 20 }}>
          {
            sectionData?.sub_section?.length > 0 &&
            <>
              {
                sectionData?.sub_section?.map((eachSec) => {
                  return (
                    <>
                      <h4><strong>{eachSec?.section_name.length > 0 ? eachSec?.section_name : null}</strong></h4>
                      <SectionContent sectionData={eachSec} appliedAttributes={appliedAttributes} attributeList={attributeList} boldHighlight={boldHighlight} />
                    </>
                  )
                })
              }
            </>
          }
        </section>
      </div>
    </>
  );
};

export default SectionContent;