import { Card, Tabs } from 'antd';
import React from 'react';
import CompetitiveIntelligenceContent from './competitive-intelligence/CompetitveIntelligenceContainer';
import EvidenceLandscapeContent from './evidence-landscape/EvidenceLandscapeContainer';
import GeographicDistributionContent from './geographic-distribution/GeographicDistributionContainer';
import TimelineTrendsContent from './timeline-trends/TimelineTrendsContainer';
const { TabPane } = Tabs;

const InsightsTabs = () => (
    <div style={{padding: '15px 15px 15px 15px'}}>
      <Tabs defaultActiveKey="1" type="line" size='small'>
      <TabPane
        tab="Evidence Landscape"
        key="1"
        style={{
          height: 200,
        }}
      >
        <EvidenceLandscapeContent/>
      </TabPane>
      <TabPane tab="Competitive Intelligence" key="2">
        <CompetitiveIntelligenceContent/>
      </TabPane>
      <TabPane tab="Geographic Distribution" key="3">
        <GeographicDistributionContent/>
      </TabPane>
      <TabPane tab="Timeline Trends" key="4">
        <TimelineTrendsContent/>
      </TabPane>
      </Tabs>
    </div>
);

export default InsightsTabs;