import React from "react";
import "./VideoPlayer.scss";
import ReactPlayer from "react-player";

const VideoPlayer = ({ showVideoPlayer, handleCloseClick, url }) => {
  const config = {
    file: {
      attributes: {
        controlsList: "nodownload",
        disablePictureInPicture: true,
      },
    },
  };

  return (
    <div>
      {showVideoPlayer && (
        <div className="overlay">
          <div>
            <button className="close-button" onClick={handleCloseClick}>
              X
            </button>
            <ReactPlayer
              url={url}
              controls={true}
              playing={true}
              config={config}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
