import React from "react";
import { PRIVACY_POLICY_STATIC_DATA } from "./../privacy-policy-data";

import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      {PRIVACY_POLICY_STATIC_DATA.map((item) => {
        return (
          <div className="data-container">
            <div className="privacy-title" key={item.key}>
              {item.Title}{" "}
            </div>
            <p
              dangerouslySetInnerHTML={{ __html: item.context }}
              className="privacy-context"
            />
          </div>
        );
      })}
    </div>
  );
};
export default PrivacyPolicy;
