import { RDButton } from "@zs-ca/react-cd";

const SavedAnalysisFilterDropdown = ({
  dropDownValue,
  key,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}) => {
  const handleReset = () => {
    clearFilters();
    setSelectedKeys([]);
  };
  return (
    <div>
      <div>{dropDownValue}</div>
      <div>
        <RDButton
          btnText="Reset"
          key={key}
          btnClick={() => clearFilters && handleReset(clearFilters)}
        />
        <RDButton
          btnText="OK"
          key={key}
          btnClick={() => {
            confirm({
              closeDropdown: true,
            });
          }}
        />
      </div>
    </div>
  );
};

export default SavedAnalysisFilterDropdown;
