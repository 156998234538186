import {
  Input,
  Collapse,
  Checkbox,
  Badge,
  Button,
  DatePicker,
  Modal,
  Tooltip,
  Spin,
  Row,
  Col,
  Card,
} from "antd";
import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import "./AdvancedFilterView.scss";
import {
  DownArrowIcon,
  UpArrowIcon,
  DownArrowIconColored,
  UpArrowIconColored,
  DrugInfo,
  ModalBackBtnIcon,
} from "../../assets/icons/svg";
import {
  addGlobalFilters,
  setFilterState,
  setAdvanceFilters,
  setAvailableFilterList,
  setGlobalRawList,
  setSearchTerm,
  addToComparision,
  setSelectedStudyListData,
  setAdvanceFilterDropdownList,
  setIsAnalysisLoaded,
  setExcludedStudyIds,
  setSearchLoadingReducer,
  setStudyListTableData,
} from "../../reducer/EICCommonSlice";
import { useSelector, useDispatch } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import {
  getAdvancedSearchList,
  mapFilterToList,
} from "./AdvancedFilterViewHelper";
import Icon from "@ant-design/icons";
import moment from "moment";
import VirtualList from "rc-virtual-list";
import debounce from "lodash.debounce";
import { getSearchResult, mapAdvanceFilters } from "../../services/InsightsViewAPI";
import { intersection, isEqual } from "lodash";

const { Search } = Input;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { confirm } = Modal;

const AdvancedFilterView = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const sidebarOpen =
    useSelector((state) => state.EICCommon.state) === "open" ? true : false;

  const selectedAdvanceFilters = useSelector(
    (state) => state.EICCommon.selectedAdvanceFilters
  );

  const availableFilterList = useSelector(
    (state) => state.EICCommon.availableFilterList
  );

  const {
    EICCommon: {
      searchTerm,
      compareStudiesData,
      selectedAnalysisData,
      advanceFilterDropdownList,
      globalRawList,
      interactiveFilters,
      excludedStudyIds,
      studyListPageNumber,
    },
  } = useSelector((state) => state);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setAvailableLocalList(availableFilterList);
  }, [availableFilterList]);

  useEffect(() => {
    const temp = cloneDeep(selectedAdvanceFilters);
    setLocalAdvanceFilters(temp);
  }, [selectedAdvanceFilters]);

  const sidebarClass = sidebarOpen ? "sidebar" : "sidebar-toggle";

  const [activePanel, setActivePanel] = useState({
    key: 0,
    category: "",
  });

  const dateFormat = "YYYY";

  const [rawList, setRawList] = useState([]);
  const [availableLocalList, setAvailableLocalList] =
    useState(availableFilterList);
  const [localAdvanceFilters, setLocalAdvanceFilters] = useState(
    selectedAdvanceFilters
  );
  const [publicationYearRange, setPublicationYearRange] = useState({
    startYear: "",
    endYear: "",
  });
  const [currentYearRange, setCurrentYearRange] = useState("");

  const [isNewFilterSelected, setNewFilterSelected] = useState(false);

  const [inputValues, setInputValues] = useState({
    publication_start_year: "",
    publication_end_year: "",
    recruitment: "",
    study_duration_start: "",
    study_duration_end: "",
  });
  const [isAdvFilterModalOpen, setIsAdvFilterModalOpen] = useState(false);

  useEffect(() => {
    const newInputs = {
      publication_start_year:
        selectedAdvanceFilters.publication_start_year || "",
      publication_end_year: selectedAdvanceFilters.publication_end_year || "",
      recruitment: selectedAdvanceFilters.recruitment || "",
      study_duration_start: selectedAdvanceFilters.study_duration_start || "",
      study_duration_end: selectedAdvanceFilters.study_duration_end || "",
    };
    setInputValues(newInputs);
  }, [selectedAdvanceFilters]);

  useEffect(() => {
    getFilterList();
  }, []);

  useEffect(() => {
    isFilterUpdated();
  }, [localAdvanceFilters]);

  //Neha - added below code for showing current date as a default date in publication date range
  //..............Starts from here..........
  useEffect(() => {
    getCurrentDate();
  }, []);

  const getCurrentDate = () => {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear().toString();

    setCurrentYearRange(currentYear);
  };

  //.......Ends Here............

  useImperativeHandle(ref, () => ({
    getFilterTotalCriteriaCount: () => {
      return getTotalCriteriaCount();
    },
    openAdvFilterModal: () => {
      return openfilter();
    },
  }));

  const openfilter = () => {
    setIsAdvFilterModalOpen(true);
  };

  const handleAdvFilterCancel = () => {
    setIsAdvFilterModalOpen(false);
  };

  useEffect(() => {
    if (selectedAnalysisData && Object.keys(selectedAnalysisData).length > 0) {
      loadSelectedAnalysis();
    }
  }, [selectedAnalysisData]);

  //Neha - useEffect for interactive table Filters
  useEffect(() => {
    if (interactiveFilters && Object.keys(interactiveFilters).length > 0) {
      assignInteractiveFilterSelection(interactiveFilters);
    }
  }, [interactiveFilters]);

  const loadSelectedAnalysis = async () => {
    const newData = {
      search_text: searchTerm,
      study_filters: selectedAdvanceFilters,
      comparison_data: compareStudiesData.selectData.map((item) => item.pm_id),
    };
    const oldData = {
      search_text: selectedAnalysisData?.search_text,
      study_filters: selectedAnalysisData?.study_filters,
      comparison_data: selectedAnalysisData?.comparison_data,
    };
    if (!isEqual(oldData, newData)) {
      // compare the analysis config data to reload the data
      await getFilterList();
      dispatch(setSearchTerm(selectedAnalysisData?.search_text));
      setTimeout(() => {
        assignFilterSelection(selectedAnalysisData?.study_filters);
        assignComparisonData(selectedAnalysisData?.comparison_data);
        assignExcludedStudies(selectedAnalysisData?.excluded_study_ids);
        dispatch(setIsAnalysisLoaded(true));
      }, 300);
    } else {
      if (
        !isEqual(selectedAnalysisData?.excluded_study_ids, excludedStudyIds)
      ) {
        assignExcludedStudies(selectedAnalysisData?.excluded_study_ids, true);
        dispatch(setIsAnalysisLoaded(true));
      }
    }
  };

  const assignFilterSelection = (filters) => {
    let data = advanceFilterDropdownList;
    const tempLocalList = cloneDeep(mapFilterToList(data));
    let newInputs = null;
    tempLocalList.map((item) => {
      if (item.attributeName === "Publication Year Range") {
        newInputs = {
          publication_start_year: filters.publication_start_year || "",
          publication_end_year: filters.publication_end_year || "",
          recruitment: filters.recruitment || "",
          study_duration_start: filters.study_duration_start || "",
          study_duration_end: filters.study_duration_end || "",
        };
        setInputValues(newInputs);
      } else {
        item.subAttributes.map((subItem) => {
          let childCheckedCount = 0;
          if (item.isNested && subItem[item.level_two_key]?.length > 0) {
            const checkList =
              filters[item.level_two_key] ||
              filters[subItem[item.level_one_key_name]];
            if (checkList && checkList.length > 0) {
              subItem[item.level_two_key].map((innerItem) => {
                const childCheckList = filters[item.level_three_key];
                if (
                  innerItem[item.level_three_key]?.length > 0 &&
                  childCheckList &&
                  childCheckList.length > 0
                ) {
                  let grandChildCheckedCount = 0;
                  innerItem[item.level_three_key].map((subInnerItem) => {
                    subInnerItem.isChecked =
                      childCheckList.indexOf(
                        subInnerItem[item.level_three_key_name]
                      ) > -1
                        ? true
                        : false;
                    if (subInnerItem.isChecked) grandChildCheckedCount++;
                    return subInnerItem;
                  });
                  if (innerItem[item.level_three_key].length === 0) {
                    innerItem.isChecked = false;
                    innerItem.isIndeterminate = false;
                  } else if (
                    grandChildCheckedCount ===
                    innerItem[item.level_three_key].length
                  ) {
                    innerItem.isChecked = true;
                    innerItem.isIndeterminate = false;
                  } else if (grandChildCheckedCount > 0) {
                    innerItem.isChecked = false;
                    innerItem.isIndeterminate = true;
                  }
                } else {
                  innerItem.isChecked =
                    checkList.indexOf(innerItem[item.level_two_key_name]) > -1
                      ? true
                      : false;
                }

                if (innerItem.isChecked) childCheckedCount++;
                return innerItem;
              });
            }
            subItem.isChecked = false;
            subItem.isIndeterminate = false;
            if (subItem[item.level_two_key].length === 0) {
              subItem.isChecked = false;
              subItem.isIndeterminate = false;
            } else if (
              childCheckedCount === subItem[item.level_two_key].length
            ) {
              subItem.isChecked = true;
              subItem.isIndeterminate = false;
            } else if (childCheckedCount > 0) {
              subItem.isChecked = false;
              subItem.isIndeterminate = true;
            }
          } else {
            subItem.isChecked =
              filters[item.level_one_key] &&
              filters[item.level_one_key].indexOf(
                subItem[item.level_one_key_name]
              ) > -1
                ? true
                : false;
          }
          return subItem;
        });
      }
      return item;
    });
    setAvailableLocalList(tempLocalList);
    applyFilters(filters, tempLocalList, newInputs);
  };

  //Neha - below logic is show slected advance filters when user come from interactive view
  const assignInteractiveFilterSelection = (filters) => {
    const tempLocalList = cloneDeep(availableLocalList);
    let newInputs = null;
    tempLocalList.map((item) => {
      if (item.attributeName == "Publication Year Range") {
        newInputs = {
          publication_start_year: filters.publication_start_year || "",
          publication_end_year: filters.publication_end_year || "",
          recruitment: filters.recruitment || "",
          study_duration_start: filters.study_duration_start || "",
          study_duration_end: filters.study_duration_end || "",
        };
        setInputValues(newInputs);
      } else {
        item.subAttributes.map((subItem) => {
          let childCheckedCount = 0;
          if (item.isNested && subItem[item.level_two_key]?.length > 0) {
            const checkList =
              filters[item.level_two_key] ||
              filters[subItem[item.level_one_key_name]];
            if (checkList && checkList.length > 0) {
              subItem[item.level_two_key].map((innerItem) => {
                const childCheckList = filters[item.level_three_key];
                if (
                  innerItem[item.level_three_key]?.length > 0 &&
                  childCheckList &&
                  childCheckList.length > 0
                ) {
                  let grandChildCheckedCount = 0;
                  innerItem[item.level_three_key].map((subInnerItem) => {
                    subInnerItem.isChecked =
                      childCheckList.indexOf(
                        subInnerItem[item.level_three_key_name]
                      ) > -1
                        ? true
                        : false;
                    if (subInnerItem.isChecked) grandChildCheckedCount++;
                    return subInnerItem;
                  });
                  if (innerItem[item.level_three_key].length === 0) {
                    innerItem.isChecked = false;
                    innerItem.isIndeterminate = false;
                  } else if (
                    grandChildCheckedCount ===
                    innerItem[item.level_three_key].length
                  ) {
                    innerItem.isChecked = true;
                    innerItem.isIndeterminate = false;
                  } else if (grandChildCheckedCount > 0) {
                    innerItem.isChecked = false;
                    innerItem.isIndeterminate = true;
                  }
                } else {
                  innerItem.isChecked =
                    checkList.indexOf(innerItem[item.level_two_key_name]) > -1
                      ? true
                      : false;
                }

                if (innerItem.isChecked) childCheckedCount++;
                return innerItem;
              });
            }
            subItem.isChecked = false;
            subItem.isIndeterminate = false;
            if (subItem[item.level_two_key].length === 0) {
              subItem.isChecked = false;
              subItem.isIndeterminate = false;
            } else if (
              childCheckedCount === subItem[item.level_two_key].length
            ) {
              subItem.isChecked = true;
              subItem.isIndeterminate = false;
            } else if (childCheckedCount > 0) {
              subItem.isChecked = false;
              subItem.isIndeterminate = true;
            }
          } else {
            subItem.isChecked =
              filters[item.level_one_key] &&
              filters[item.level_one_key].indexOf(
                subItem[item.level_one_key_name]
              ) > -1
                ? true
                : false;
          }
          return subItem;
        });
      }
      return item;
    });
    setAvailableLocalList(tempLocalList);
    applyFilters(filters, tempLocalList, newInputs);
  };

  const assignComparisonData = async (compareStudyList) => {
    let data = { selectKey: [], selectData: [] };
    if (compareStudyList?.length > 0) {
      const response = await getSearchResult("", 1, {
        pmc_id: compareStudyList,
      });
      if (response?.result?.data?.length) {
        data.selectData = response.result.data;
        data.selectKey = response.result.data.map((item) => item.key);
      } else {
        console.log("No comparison data!");
      }
    }

    dispatch(addToComparision({ ...data }));
    dispatch(setSelectedStudyListData({ ...data }));
  };

  const assignExcludedStudies = (excludedIds, isLoadStudies=false) => {
    dispatch(setExcludedStudyIds(excludedIds));
    if(excludedIds.length === 0 && isLoadStudies) {
      getStudyListData();
    }
  };

  const getStudyListData = async() => {
    dispatch(setSearchLoadingReducer(true));
    const data = await getSearchResult(
      searchTerm,
      studyListPageNumber,
      mapAdvanceFilters(selectedAdvanceFilters),
      []
    );

    if(!data) dispatch(setSearchLoadingReducer(false));

    dispatch(setStudyListTableData(data.result));
    dispatch(setSearchLoadingReducer(false));
  }

  const getFilterList = async () => {
    setLoading(true);
    let data = advanceFilterDropdownList;
    if (!data || Object.keys(data).length < 1) {
      data = await getAdvancedSearchList();
      dispatch(setAdvanceFilterDropdownList(data));
    }
    const newList = await mapFilterToList(data);

    setPublicationYearRange({
      startYear: data.publication_min_date,
      endYear: data.publication_max_date,
    });
    setRawList(newList);
    dispatch(setGlobalRawList(newList));
    dispatch(setAvailableFilterList(newList));
    setLoading(false);
  };

  const onSearchEntities = (event, attribute) => {
    setLoading(true);
    const newList = cloneDeep(availableLocalList);

    const searchIdx = newList.findIndex((item) => item.id === attribute.id);
    newList[searchIdx].searchedTerm = event.target.value;
    if (newList[searchIdx].searchedTerm.length > 0) {
      newList[searchIdx].isExpanded = true;
      newList[searchIdx].subAttributes?.forEach((item) => {
        if (item[newList[searchIdx]?.level_two_key]?.length > 5)
          item.isExpanded = true;
      });
    } else if (newList[searchIdx].searchedTerm.length === 0) {
      newList[searchIdx].isExpanded = false;
      newList[searchIdx].subAttributes?.forEach((item) => {
        if (item[newList[searchIdx]?.level_two_key]?.length > 5)
          item.isExpanded = false;
      });
    }

    setAvailableLocalList(newList);

    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  const isItemFiltered = (item, attribute, level, parentIndex, childItem) => {
    if (level === "parent") {
      if (attribute.searchedTerm.length <= 0) {
        return true;
      } else if (isSearchTextInclude(attribute.searchedTerm, item)) {
        return true;
      } else if (attribute.isNested) {
        let childFound = false;
        attribute.subAttributes[parentIndex][attribute.level_two_key].forEach(
          (element) => {
            if (
              isSearchTextInclude(
                attribute.searchedTerm,
                element[attribute.level_two_key_name]
              )
            ) {
              childFound = true;
            } else if (element[attribute.level_three_key]?.length > 0) {
              const foundCount = element[attribute.level_three_key].filter(
                (el) =>
                  isSearchTextInclude(
                    attribute.searchedTerm,
                    el[attribute.level_three_key_name]
                  )
              );
              childFound = foundCount.length > 0 ? true : childFound;
            }
          }
        );
        if (childFound) {
          return true;
        } else return false;
      } else {
        return false;
      }
    } else if (level === "child") {
      return attribute.searchedTerm.length <= 0
        ? true
        : isSearchTextInclude(attribute.searchedTerm, item)
        ? true
        : childItem &&
          childItem[attribute.level_three_key]?.length > 0 &&
          childItem[attribute.level_three_key]?.filter((el) =>
            isSearchTextInclude(
              attribute.searchedTerm,
              el[attribute.level_three_key_name]
            )
          ).length > 0
        ? true
        : false;
    } else {
      return attribute.searchedTerm.length <= 0
        ? true
        : isSearchTextInclude(attribute.searchedTerm, item)
        ? true
        : false;
    }
  };

  const isSearchTextInclude = (searchTerm, text) => {

    if (text?.toLowerCase().startsWith(searchTerm?.toLowerCase())) {
      return true;
    }

    let tempText = text.replace(/[&\/\\#,+()$~%.":*?<>{}-]/g, " "); // remove special char from text
    tempText = tempText.replace(/\s+/g, " ").trim(); // remove extra whte spaces

    const wordAry = tempText.split(" ");
    const searchText = searchTerm.trim();

    const result = wordAry.filter((word) =>
      word.toLowerCase().startsWith(searchText.toLowerCase())
    );

    return result.length > 0 ? true : false;
  };

  const getFilteredList = (list, attribute, level) => {
    return list.filter((item, index) => {
      return isItemFiltered(
        item[attribute.level_one_key_name],
        attribute,
        level,
        index
      );
    });
  };

  const onCheckedParent = (subAttributeIndex, attribute, subAttribute) => {
    const tempLocalList = cloneDeep(availableLocalList);
    let tempLocalAdvanceFilters = cloneDeep(localAdvanceFilters);

    const attributeIndex = tempLocalList.findIndex(
      (item) => item.id === attribute.id
    );

    subAttributeIndex = tempLocalList[attributeIndex].subAttributes.findIndex(
      (item) =>
        item[attribute.level_one_key_name] ===
        subAttribute[attribute.level_one_key_name]
    );

    if (
      tempLocalList[attributeIndex].subAttributes[subAttributeIndex].isChecked
    ) {
      tempLocalList[attributeIndex].subAttributes[
        subAttributeIndex
      ].isChecked = false;
      tempLocalList[attributeIndex].subAttributes[
        subAttributeIndex
      ].isIndeterminate = false;

      // handle child logic here
      if (tempLocalList[attributeIndex].level_one_key.length > 0) {
        const childList =
          tempLocalList[attributeIndex].subAttributes[subAttributeIndex][
            `${tempLocalList[attributeIndex].level_two_key}`
          ];
        childList?.forEach((child) => {
          child.isChecked = false;
          if (child[attribute.level_three_key]?.length) {
            child.isIndeterminate = false;
            child[attribute.level_three_key].forEach(
              (subChild) => (subChild.isChecked = false)
            );
          }
        });
      }

      if (!attribute.isNested) {
        const idx = tempLocalAdvanceFilters[attribute.level_one_key].findIndex(
          (item) => item === subAttribute[attribute.level_one_key_name]
        );
        tempLocalAdvanceFilters[attribute.level_one_key].splice(idx, 1);

        if (tempLocalAdvanceFilters[attribute.level_one_key].length <= 0) {
          delete tempLocalAdvanceFilters[attribute.level_one_key];
        }
      } else {
        // In case of Region, Study Type, Patient Population and Outcome we need separately

        // Separate Logic for Region Selections
        if (attribute.isMultiLevel) {
          tempLocalAdvanceFilters = updateAdvFilterForMultiLevel(
            tempLocalList[attributeIndex],
            tempLocalAdvanceFilters
          );
        } else {
          const unselected =
            tempLocalList[attributeIndex].subAttributes[subAttributeIndex];
          unselected[attribute.level_two_key].forEach((item) => {
            const idx = tempLocalAdvanceFilters[
              `${unselected[attribute.level_one_key_name]}`
            ].findIndex(
              (element) => element === item[attribute.level_two_key_name]
            );
            if (idx >= 0) {
              tempLocalAdvanceFilters[
                `${unselected[attribute.level_one_key_name]}`
              ].splice(idx, 1);
            }
          });
          if (tempLocalAdvanceFilters["Observational"]?.length === 0)
            delete tempLocalAdvanceFilters["Observational"];
          if (tempLocalAdvanceFilters["Secondary Research"]?.length === 0)
            delete tempLocalAdvanceFilters["Secondary Research"];
        }
      }
    } else {
      tempLocalList[attributeIndex].subAttributes[
        subAttributeIndex
      ].isChecked = true;
      tempLocalList[attributeIndex].subAttributes[
        subAttributeIndex
      ].isIndeterminate = false;

      // handle child logic here
      if (tempLocalList[attributeIndex].level_one_key.length > 0) {
        const childList =
          tempLocalList[attributeIndex].subAttributes[subAttributeIndex][
            `${tempLocalList[attributeIndex].level_two_key}`
          ];
        childList?.forEach((child) => {
          child.isChecked = true;
          if (child[attribute.level_three_key]?.length) {
            child.isIndeterminate = false;
            child[attribute.level_three_key].forEach(
              (subChild) => (subChild.isChecked = true)
            );
          }
        });
      }

      if (!attribute.isNested) {
        if (tempLocalAdvanceFilters[attribute.level_one_key] !== undefined) {
          tempLocalAdvanceFilters[attribute.level_one_key].push(
            subAttribute[attribute.level_one_key_name]
          );
        } else {
          tempLocalAdvanceFilters[attribute.level_one_key] = [];
          tempLocalAdvanceFilters[attribute.level_one_key].push(
            subAttribute[attribute.level_one_key_name]
          );
        }
      } else {
        // In case of nested attributes

        // Separate Logic for Region, study type Selections
        if (attribute.isMultiLevel) {
          tempLocalAdvanceFilters = updateAdvFilterForMultiLevel(
            tempLocalList[attributeIndex],
            tempLocalAdvanceFilters
          );
        } else {
          const selected =
            tempLocalList[attributeIndex].subAttributes[subAttributeIndex];
          if (
            tempLocalAdvanceFilters[selected[attribute.level_one_key_name]] !==
            undefined
          ) {
            const attributesInSelection = selected[attribute.level_two_key]
              .map((item) => item[attribute.level_two_key_name])
              .filter(
                (itemEl) =>
                  !tempLocalAdvanceFilters[
                    selected[attribute.level_one_key_name]
                  ].includes(itemEl)
              );

            tempLocalAdvanceFilters[
              `${selected[attribute.level_one_key_name]}`
            ] = [
              ...tempLocalAdvanceFilters[
                `${selected[attribute.level_one_key_name]}`
              ],
              ...attributesInSelection,
            ];
          } else {
            const attributesInSelection = selected[attribute.level_two_key].map(
              (item) => item[attribute.level_two_key_name]
            );
            tempLocalAdvanceFilters[
              `${selected[attribute.level_one_key_name]}`
            ] = [...attributesInSelection];
          }
        }
      }
    }

    setAvailableLocalList(tempLocalList);
    setLocalAdvanceFilters(tempLocalAdvanceFilters);
  };

  const onCheckedChild = (
    subAttributeIndex,
    attribute,
    subAttribute,
    childItem,
    childIndex
  ) => {
    const tempLocalList = cloneDeep(availableLocalList);
    let tempLocalAdvanceFilters = cloneDeep(localAdvanceFilters);
    const attributeIndex = tempLocalList.findIndex(
      (item) => item.id === attribute.id
    );

    subAttributeIndex = tempLocalList[attributeIndex].subAttributes.findIndex(
      (item) =>
        item[attribute.level_one_key_name] ===
        subAttribute[attribute.level_one_key_name]
    );

    childIndex = tempLocalList[attributeIndex].subAttributes[subAttributeIndex][
      attribute.level_two_key
    ].findIndex(
      (item) =>
        item[attribute.level_two_key_name] ===
        childItem[attribute.level_two_key_name]
    );

    if (
      tempLocalList[attributeIndex].subAttributes[subAttributeIndex][
        attribute.level_two_key
      ][childIndex].isChecked
    ) {
      tempLocalList[attributeIndex].subAttributes[subAttributeIndex][
        attribute.level_two_key
      ][childIndex].isChecked = false;

      const unselected =
        tempLocalList[attributeIndex].subAttributes[subAttributeIndex];

      // logic for level 3 deselect: For Study Type
      if (
        unselected[attribute.level_two_key][childIndex][
          attribute.level_three_key
        ]?.length > 0
      ) {
        tempLocalList[attributeIndex].subAttributes[subAttributeIndex][
          attribute.level_two_key
        ][childIndex][attribute.level_three_key].forEach(
          (el) => (el.isChecked = false)
        );
      }

      // check all unchecked
      const checkedChildCount = tempLocalList[attributeIndex].subAttributes[
        subAttributeIndex
      ][attribute.level_two_key].filter((item) => item.isChecked).length;

      const intermediateChildCount = tempLocalList[
        attributeIndex
      ].subAttributes[subAttributeIndex][attribute.level_two_key].filter(
        (item) => item.isIndeterminate
      ).length;
      if (
        tempLocalList[attributeIndex].subAttributes[subAttributeIndex][
          attribute.level_two_key
        ].length !== checkedChildCount
      ) {
        tempLocalList[attributeIndex].subAttributes[
          subAttributeIndex
        ].isChecked = false;
        tempLocalList[attributeIndex].subAttributes[
          subAttributeIndex
        ].isIndeterminate = true;

        if (checkedChildCount === 0) {
          tempLocalList[attributeIndex].subAttributes[
            subAttributeIndex
          ].isIndeterminate = intermediateChildCount > 0 ? true : false;
        }
      }

      if (attribute.isMultiLevel) {
        tempLocalAdvanceFilters = updateAdvFilterForMultiLevel(
          tempLocalList[attributeIndex],
          tempLocalAdvanceFilters
        );
      } else {
        const idx = tempLocalAdvanceFilters[
          unselected[attribute.level_one_key_name]
        ].findIndex((item) => item === childItem[attribute.level_two_key_name]);
        tempLocalAdvanceFilters[
          unselected[attribute.level_one_key_name]
        ].splice(idx, 1);

        if (
          tempLocalAdvanceFilters[unselected[attribute.level_one_key_name]]
            .length <= 0
        ) {
          delete tempLocalAdvanceFilters[
            unselected[attribute.level_one_key_name]
          ];
        }
      }
    } else {
      const selected =
        tempLocalList[attributeIndex].subAttributes[subAttributeIndex];

      tempLocalList[attributeIndex].subAttributes[subAttributeIndex][
        attribute.level_two_key
      ][childIndex].isChecked = true;

      // logic for level 3 deselect: For Study Type
      if (
        selected[attribute.level_two_key][childIndex][attribute.level_three_key]
          ?.length > 0
      ) {
        tempLocalList[attributeIndex].subAttributes[subAttributeIndex][
          attribute.level_two_key
        ][childIndex].isIndeterminate = false;
        tempLocalList[attributeIndex].subAttributes[subAttributeIndex][
          attribute.level_two_key
        ][childIndex][attribute.level_three_key].forEach(
          (el) => (el.isChecked = true)
        );
      }

      // check all checked
      const checkedChildCount = tempLocalList[attributeIndex].subAttributes[
        subAttributeIndex
      ][attribute.level_two_key].filter((item) => item.isChecked).length;

      if (
        tempLocalList[attributeIndex].subAttributes[subAttributeIndex][
          attribute.level_two_key
        ].length === checkedChildCount
      ) {
        tempLocalList[attributeIndex].subAttributes[
          subAttributeIndex
        ].isChecked = true;
        tempLocalList[attributeIndex].subAttributes[
          subAttributeIndex
        ].isIndeterminate = false;

        setAvailableLocalList(tempLocalList);
        setLocalAdvanceFilters(tempLocalAdvanceFilters);
      } else {
        tempLocalList[attributeIndex].subAttributes[
          subAttributeIndex
        ].isIndeterminate = true;
      }

      if (attribute.isMultiLevel) {
        tempLocalAdvanceFilters = updateAdvFilterForMultiLevel(
          tempLocalList[attributeIndex],
          tempLocalAdvanceFilters
        );
      } else {
        if (
          tempLocalAdvanceFilters[selected[attribute.level_one_key_name]] !==
          undefined
        ) {
          if (
            !tempLocalAdvanceFilters[
              selected[attribute.level_one_key_name]
            ].includes(childItem[attribute.level_two_key_name])
          ) {
            tempLocalAdvanceFilters[
              selected[attribute.level_one_key_name]
            ].push(childItem[attribute.level_two_key_name]);
          }
        } else {
          tempLocalAdvanceFilters[`${selected[attribute.level_one_key_name]}`] =
            [];
          tempLocalAdvanceFilters[
            `${selected[attribute.level_one_key_name]}`
          ].push(childItem[attribute.level_two_key_name]);
        }
      }
    }

    setAvailableLocalList(tempLocalList);
    setLocalAdvanceFilters(tempLocalAdvanceFilters);
  };

  const onCheckedGrandChild = (
    subAttributeIndex,
    attribute,
    subAttribute,
    childItem,
    childIndex,
    grandChildItem,
    grandChildItemIndex
  ) => {
    const tempLocalList = cloneDeep(availableLocalList);
    let tempLocalAdvanceFilters = cloneDeep(localAdvanceFilters);
    const attributeIndex = tempLocalList.findIndex(
      (item) => item.id === attribute.id
    );

    subAttributeIndex = tempLocalList[attributeIndex].subAttributes.findIndex(
      (item) =>
        item[attribute.level_one_key_name] ===
        subAttribute[attribute.level_one_key_name]
    );

    childIndex = tempLocalList[attributeIndex].subAttributes[subAttributeIndex][
      attribute.level_two_key
    ].findIndex(
      (item) =>
        item[attribute.level_two_key_name] ===
        childItem[attribute.level_two_key_name]
    );

    grandChildItemIndex = tempLocalList[attributeIndex].subAttributes[
      subAttributeIndex
    ][attribute.level_two_key][childIndex][attribute.level_three_key].findIndex(
      (item) =>
        item[attribute.level_three_key_name] ===
        grandChildItem[attribute.level_three_key_name]
    );
    let isItemSelected = false; // variable for saving selection or deselection
    if (
      tempLocalList[attributeIndex].subAttributes[subAttributeIndex][
        attribute.level_two_key
      ][childIndex][attribute.level_three_key][grandChildItemIndex].isChecked
    ) {
      tempLocalList[attributeIndex].subAttributes[subAttributeIndex][
        attribute.level_two_key
      ][childIndex][attribute.level_three_key][
        grandChildItemIndex
      ].isChecked = false;
    } else {
      tempLocalList[attributeIndex].subAttributes[subAttributeIndex][
        attribute.level_two_key
      ][childIndex][attribute.level_three_key][
        grandChildItemIndex
      ].isChecked = true;
      isItemSelected = true;
    }

    // level 2 selection update logic
    const checkedGrandChildCount = tempLocalList[attributeIndex].subAttributes[
      subAttributeIndex
    ][attribute.level_two_key][childIndex][attribute.level_three_key].filter(
      (item) => item.isChecked
    ).length;

    let intermediate = false,
      checked = false;
    if (checkedGrandChildCount === 0) {
      intermediate = checked = false;
    } else if (
      tempLocalList[attributeIndex].subAttributes[subAttributeIndex][
        attribute.level_two_key
      ][childIndex][attribute.level_three_key].length === checkedGrandChildCount
    ) {
      intermediate = false;
      checked = true;
    } else {
      intermediate = true;
      checked = false;
    }
    tempLocalList[attributeIndex].subAttributes[subAttributeIndex][
      attribute.level_two_key
    ][childIndex].isChecked = checked;
    tempLocalList[attributeIndex].subAttributes[subAttributeIndex][
      attribute.level_two_key
    ][childIndex].isIndeterminate = intermediate;

    // level 1 selection update logic
    const checkedChildCount = tempLocalList[attributeIndex].subAttributes[
      subAttributeIndex
    ][attribute.level_two_key].filter((item) => item.isChecked).length;

    const intermediateChildCount = tempLocalList[attributeIndex].subAttributes[
      subAttributeIndex
    ][attribute.level_two_key].filter((item) => item.isIndeterminate).length;

    if (checkedChildCount === 0) {
      intermediate = intermediateChildCount > 0 ? true : false;
      checked = false;
    } else if (
      tempLocalList[attributeIndex].subAttributes[subAttributeIndex][
        attribute.level_two_key
      ].length === checkedChildCount
    ) {
      intermediate = false;
      checked = true;
    } else {
      intermediate = true;
      checked = false;
    }
    tempLocalList[attributeIndex].subAttributes[subAttributeIndex].isChecked =
      checked;
    tempLocalList[attributeIndex].subAttributes[
      subAttributeIndex
    ].isIndeterminate = intermediate;

    // advance filter selection variable update

    if (attribute.isMultiLevel) {
      tempLocalAdvanceFilters = updateAdvFilterForMultiLevel(
        tempLocalList[attributeIndex],
        tempLocalAdvanceFilters
      );
    } else {
      // TODO: Logic Ignored, bcoz in the current scope 3 level is for only study type
    }

    setAvailableLocalList(tempLocalList);
    setLocalAdvanceFilters(tempLocalAdvanceFilters);
  };

  const updateAdvFilterForMultiLevel = (attrData, advFilter) => {
    if (!attrData || attrData.length < 1) return advFilter;
    let l1Select = [],
      l2Select = [],
      l3Select = [];
    attrData.subAttributes.forEach((i) => {
      if (i.isChecked || i.isIndeterminate) {
        l1Select.push(i[attrData.level_one_key_name]);
        i[attrData.level_two_key]?.forEach((j) => {
          if (j.isChecked || j.isIndeterminate) {
            l2Select.push(j[attrData.level_two_key_name]);
            j[attrData.level_three_key]?.forEach((k) => {
              if (k.isChecked || k.isIndeterminate) {
                l3Select.push(k[attrData.level_three_key_name]);
              }
            });
          }
        });
      }
    });
    delete advFilter[attrData.level_one_key];
    delete advFilter[attrData.level_two_key];
    delete advFilter[attrData.level_three_key];
    if (l1Select.length > 0) {
      advFilter[attrData.level_one_key] = l1Select;
    }
    if (l2Select.length > 0) {
      advFilter[attrData.level_two_key] = l2Select;
    }
    if (l3Select.length > 0) {
      advFilter[attrData.level_three_key] = l3Select;
    }
    return advFilter;
  };

  const handleViewSidebar = () => {
    if (!sidebarOpen) {
      dispatch(setFilterState("open"));
    } else {
      if (isNewFilterSelected) {
        confirm({
          title: "Unapplied changes",
          okText: "OK",
          okType: "danger",
          content:
            "If you close the filter panel without saving, unapplied changes will be removed.",
          centered: true,

          onOk() {
            setAvailableLocalList(availableFilterList);
            setLocalAdvanceFilters(selectedAdvanceFilters);
            dispatch(setFilterState("closed"));
            setNewFilterSelected(false);
          },
        });
      } else {
        setAvailableLocalList(availableFilterList);
        setLocalAdvanceFilters(selectedAdvanceFilters);
        dispatch(setFilterState("closed"));
        setNewFilterSelected(false);
      }
    }
  };

  const dynamicPlaceholder = (attributeName) => {
    if (attributeName === "Region") return `Search Region or Country`;
    if (attributeName === "Indication")
      return `Search Indication or Therapy Area`;
    else return `Search ${attributeName}`;
  };

  const applyFilters = (filterData, localList, newInputs) => {
    let tempLocalAdvanceFilters = filterData
      ? cloneDeep(filterData)
      : cloneDeep(localAdvanceFilters);
    let tempLocalList = localList
      ? cloneDeep(localList)
      : cloneDeep(availableLocalList);
    let criteriaList = [];

    const tempInputValues = newInputs ? { ...newInputs } : { ...inputValues };

    if (
      tempInputValues["publication_start_year"]?.length > 0 &&
      tempInputValues["publication_end_year"]?.length > 0
    ) {
      const temp = `${tempInputValues.publication_start_year}-${tempInputValues.publication_end_year}`;
      if (!criteriaList.includes(temp)) criteriaList.push(temp);
    }
    if (
      tempInputValues["study_duration_start"]?.length > 0 &&
      tempInputValues["study_duration_end"]?.length > 0
    ) {
      const temp = `${tempInputValues.study_duration_start}-${tempInputValues.study_duration_end}`;
      if (!criteriaList.includes(temp)) criteriaList.push(temp);
    }
    if (tempInputValues.recruitment?.length > 0) {
      if (!criteriaList.includes(tempInputValues.recruitment))
        criteriaList.push(tempInputValues.recruitment);
    } else {
      delete tempLocalAdvanceFilters.recruitment;
    }
    for (const input in tempInputValues) {
      if (
        tempInputValues[input] === undefined ||
        tempInputValues[input] === ""
      ) {
        delete tempInputValues[input];
      }
    }

    for (const element in tempLocalAdvanceFilters) {
      if (
        typeof tempLocalAdvanceFilters[element] === "object" &&
        tempLocalAdvanceFilters[element].length === 0 &&
        element !== "Observational" &&
        element !== "Secondary Research"
      ) {
        delete tempLocalAdvanceFilters[element];
      }

      if (element === "region") {
        delete tempLocalAdvanceFilters[element];
      }
    }
    tempLocalAdvanceFilters = {
      ...tempLocalAdvanceFilters,
      ...tempInputValues,
    };

    const entityList = [
      "drug",
      "sponsor",
      "author",
      "study_status",
      "data_source",
      "statistical_analysis",
      "therapy",
      "procedure",
    ];
    const level2Keys = ["country", "indication", "study_type_level_2"];
    const notIncludeList = [
      "therapy_area",
      "region",
      "study_type_level_1",
      "study_type_level_3",
    ];
    for (const item in tempLocalAdvanceFilters) {
      if (
        (Array.isArray(tempLocalAdvanceFilters[item]) &&
          tempLocalAdvanceFilters[item].length > 0) ||
        item === "Observational" ||
        item === "Secondary Research"
      ) {
        if (entityList.includes(item)) {
          criteriaList = [...criteriaList, ...tempLocalAdvanceFilters[item]];
        } else if (level2Keys.includes(item)) {
          let outputList = [];

          const attribute = tempLocalList.find(
            (element) => element.level_two_key === item
          );

          const level1List = attribute?.subAttributes || [];
          level1List.forEach((element) => {
            if (element.isChecked) {
              outputList = [
                ...outputList,
                element[attribute.level_one_key_name],
              ];
            } else if (element.isIndeterminate) {
              element[attribute.level_two_key]?.forEach((subEl) => {
                if (subEl.isChecked) {
                  outputList = [
                    ...outputList,
                    subEl[attribute.level_two_key_name],
                  ];
                } else if (subEl.isIndeterminate) {
                  subEl[attribute.level_three_key]?.forEach((subSubEl) => {
                    if (subSubEl.isChecked) {
                      outputList = [
                        ...outputList,
                        subSubEl[attribute.level_three_key_name],
                      ];
                    }
                  });
                }
              });
            }

            // Apply region, study type filter to narrow results based on region across
            /////////////////////////////////
            if (element.isChecked || element.isIndeterminate) {
              if (
                tempLocalAdvanceFilters[attribute.level_one_key] === undefined
              ) {
                tempLocalAdvanceFilters[attribute.level_one_key] = [];
              }
              if (
                !tempLocalAdvanceFilters[attribute.level_one_key]?.includes(
                  element[attribute.level_one_key_name]
                )
              ) {
                tempLocalAdvanceFilters[attribute.level_one_key].push(
                  element[attribute.level_one_key_name]
                );
              }
              const interList = element[attribute.level_two_key].filter(
                (el) => el.isIndeterminate
              );
              if (
                interList.length > 0 &&
                tempLocalAdvanceFilters[attribute.level_two_key] === undefined
              ) {
                tempLocalAdvanceFilters[attribute.level_two_key] = [];
              }
              interList.forEach((item) => {
                if (
                  !tempLocalAdvanceFilters[attribute.level_two_key].includes(
                    item[attribute.level_two_key_name]
                  )
                ) {
                  tempLocalAdvanceFilters[attribute.level_two_key].push(
                    item[attribute.level_two_key_name]
                  );
                }
              });
            }
            /////////////////////////////////
          });
          criteriaList = [...criteriaList, ...outputList];
        } else if (!notIncludeList.includes(item)) {
          let count;
          tempLocalList.forEach((element) => {
            const foundIndex = element.subAttributes?.findIndex(
              (subElement) => subElement[element.level_one_key_name] === item
            );
            if (foundIndex >= 0) {
              count =
                element.subAttributes[foundIndex][element.level_two_key]
                  ?.length;
            }
          });
          if (
            count !== undefined &&
            count === tempLocalAdvanceFilters[item].length
          ) {
            criteriaList = [...criteriaList, item];
          } else {
            criteriaList = [...criteriaList, ...tempLocalAdvanceFilters[item]];
          }
        } else if (
          item === "study_type_level_1" &&
          !tempLocalAdvanceFilters["study_type_level_2"]
        ) {
          criteriaList = [...criteriaList, ...tempLocalAdvanceFilters[item]];
        }
      }
    }

    dispatch(addGlobalFilters(criteriaList));
    dispatch(setAdvanceFilters(tempLocalAdvanceFilters)); // setting selected advance filters
    dispatch(setAvailableFilterList(tempLocalList)); // setting selected values in left filter panel
    dispatch(setFilterState("closed"));
    setNewFilterSelected(false);
    handleAdvFilterCancel();
  };

  const cancelFilters = () => {
    // Reset back to global state
    setAvailableLocalList(availableFilterList);
    setLocalAdvanceFilters(selectedAdvanceFilters);
    setNewFilterSelected(false);
    handleAdvFilterCancel();
  };

  const resetFilters = () => {
    confirm({
      title: "Reset Search Criteria",
      okText: "Reset",
      okType: "danger",
      content:
        "Are you sure you want to remove all your selected search criteria?",
      centered: true,

      onOk() {
        setAvailableLocalList(rawList);
        dispatch(addGlobalFilters([]));
        dispatch(setAdvanceFilters({}));
        dispatch(setAvailableFilterList(rawList));
        setNewFilterSelected(false);
        handleAdvFilterCancel();
        getCurrentDate();
      },
    });
  };

  const updateInput = (event, field) => {
    const updatedInputValue = { ...inputValues, [field]: event.target.value };
    setNewFilterSelected(true);
    setInputValues(updatedInputValue);
  };

  const updateYearRangeInput = (date, dateString) => {
    const updatedInputValue = {
      ...inputValues,
      publication_start_year: dateString[0],
      publication_end_year: dateString[1],
    };
    setNewFilterSelected(true);
    setInputValues(updatedInputValue);
  };

  const getLimitCount = (
    attribute,
    actionOn,
    subAttribute,
    subSubAttribute
  ) => {
    if (actionOn === "parent") {
      if (attribute.isExpanded === undefined || attribute.isExpanded === false)
        return 7;
      else return attribute.subAttributes.length;
    } else if (actionOn === "child") {
      if (
        subAttribute.isExpanded === undefined ||
        subAttribute.isExpanded === false
      )
        return 7;
      else return subAttribute[attribute.level_two_key].length;
    } else if (actionOn === "grandChild") {
      if (
        subSubAttribute.isExpanded === undefined ||
        subSubAttribute.isExpanded === false
      )
        return 7;
      else return subSubAttribute[attribute.level_three_key].length;
    }
  };

  const expandContractList = (
    attribute,
    subIndex,
    action,
    actionOn,
    subSubIndex
  ) => {
    setLoading(true);

    setTimeout(() => {
      const tempLocalList = cloneDeep(availableLocalList);
      const attributeIndex = tempLocalList.findIndex(
        (item) => item.id === attribute.id
      );

      if (actionOn === "parent") {
        tempLocalList[attributeIndex].isExpanded =
          action === "expand" ? true : false;
      } else if (actionOn === "child") {
        tempLocalList[attributeIndex].subAttributes[subIndex].isExpanded =
          action === "expand" ? true : false;
      } else if (actionOn === "grandChild") {
        tempLocalList[attributeIndex].subAttributes[subIndex][
          attribute.level_two_key
        ][subSubIndex].isExpanded = action === "expand" ? true : false;
      }

      setAvailableLocalList(tempLocalList);

      setLoading(false);
    }, 250);
  };

  const isFilterUpdated = () => {
    if (
      JSON.stringify(localAdvanceFilters) !==
      JSON.stringify(selectedAdvanceFilters)
    )
      setNewFilterSelected(true);
    else setNewFilterSelected(false);
  };

  const checkNumberInput = (event, source) => {
    const restricted = ["e", "E", "-", "+", "."];
    const inputString = event.target.value.toString();

    switch (source) {
      case "recruitment":
        if (
          restricted.includes(event.key) ||
          (inputString.length > 4 && event.key !== "Backspace")
        )
          event.preventDefault();
        break;
      case "duration":
        if (
          restricted.includes(event.key) ||
          (inputString.length > 2 && event.key !== "Backspace")
        )
          event.preventDefault();
        break;
      default:
        console.log("No match found");
    }
  };

  const checkRecruitmentDisabled = () => {
    const enableItems = ["Observational", "Clinical Trial"];

    const isDisabled = intersection(
      enableItems,
      localAdvanceFilters["study_type_level_1"] || []
    ).length > 0
      ? false
      : true;
    if (isDisabled && inputValues.recruitment !== '') {
      setInputValues({
        ...inputValues,
        recruitment: ''
      })
    }
    return isDisabled;
  };

  const getCount = (attribute) => {
    let totalCount = attribute.subAttributes?.reduce((prev, current) => {
      if (current.isChecked) return prev + 1;
      else if (current.isIndeterminate) {
        const childSelectionCount = current[attribute.level_two_key].filter(
          (item) => item.isChecked
        ).length;
        let grandChildSelectionCount = 0;
        current[attribute.level_two_key]
          .filter((item) => item.isIndeterminate)
          .forEach((child) => {
            grandChildSelectionCount += child[
              attribute.level_three_key
            ]?.filter((i) => i.isChecked).length;
          });
        return prev + childSelectionCount + grandChildSelectionCount;
      } else return prev;
    }, 0);

    return <Badge style={{ backgroundColor: "#2B44C7" }} count={totalCount} />;
  };

  const getDotBadge = (attribute) => {
    let totalCount = attribute.subAttributes?.reduce((prev, current) => {
      if (current.isChecked) return prev + 1;
      else if (current.isIndeterminate) {
        const childSelectionCount = current[attribute.level_two_key].filter(
          (item) => item.isChecked
        ).length;
        return prev + childSelectionCount;
      } else return prev;
    }, 0);
    return <>{totalCount > 0 && <Badge color="blue" />}</>;
  };

  const getStudyDesignCount = () => {
    let totalCount = 0;
    availableLocalList.slice(9, 13).forEach((attribute, index) => {
      let count = attribute.subAttributes?.reduce((prev, current) => {
        if (current.isChecked) return prev + 1;
        else if (current.isIndeterminate) {
          const childSelectionCount = current[attribute.level_two_key].filter(
            (item) => item.isChecked
          ).length;
          return prev + childSelectionCount;
        } else return prev;
      }, 0);
      if (count > 0) totalCount++;
    });
    return <Badge style={{ backgroundColor: "#2B44C7" }} count={totalCount} />;
  };

  const getTreatmentCount = () => {
    let totalCount = 0;
    availableLocalList.slice(6, 9).forEach((attribute, index) => {
      let count = attribute.subAttributes?.reduce((prev, current) => {
        if (current.isChecked) return prev + 1;
        else if (current.isIndeterminate) {
          const childSelectionCount = current[attribute.level_two_key].filter(
            (item) => item.isChecked
          ).length;
          return prev + childSelectionCount;
        } else return prev;
      }, 0);
      if (count > 0) totalCount++;
    });
    return <Badge style={{ backgroundColor: "#2B44C7" }} count={totalCount} />;
  };

  const getTotalCriteriaCount = () => {
    let totalCount = 0;
    availableLocalList.slice(0, 6).forEach((attribute) => {
      let entityCount = attribute.subAttributes?.reduce((prev, current) => {
        if (current.isChecked) return prev + 1;
        else if (current.isIndeterminate) {
          const childSelectionCount = current[attribute.level_two_key].filter(
            (item) => item.isChecked
          ).length;
          let grandChildSelectionCount = 0;
          current[attribute.level_two_key]
            .filter((item) => item.isIndeterminate)
            .forEach((child) => {
              grandChildSelectionCount += child[
                attribute.level_three_key
              ]?.filter((i) => i.isChecked).length;
            });
          return prev + childSelectionCount + grandChildSelectionCount;
        } else return prev;
      }, 0);
      if (entityCount !== undefined) totalCount = entityCount + totalCount;
    });
    availableLocalList.slice(6, 9).forEach((attribute) => {
      let treatmentCount = attribute.subAttributes?.reduce((prev, current) => {
        if (current.isChecked) return prev + 1;
        else if (current.isIndeterminate) {
          const childSelectionCount = current[attribute.level_two_key].filter(
            (item) => item.isChecked
          ).length;
          return prev + childSelectionCount;
        } else return prev;
      }, 0);
      if (treatmentCount !== undefined && treatmentCount > 0) {
        totalCount++;
      }
    });
    availableLocalList.slice(9, 13).forEach((attribute) => {
      let studyDesignCount = attribute.subAttributes?.reduce(
        (prev, current) => {
          if (current.isChecked) return prev + 1;
          else if (current.isIndeterminate) {
            const childSelectionCount = current[attribute.level_two_key].filter(
              (item) => item.isChecked
            ).length;
            return prev + childSelectionCount;
          } else return prev;
        },
        0
      );
      if (studyDesignCount !== undefined && studyDesignCount > 0) {
        totalCount++;
      }
    });

    return totalCount;
  };

  const onCollapseToggle = (key, category) => {
    if (activePanel.key === 0 && activePanel.category.length <= 0) {
      setActivePanel({
        key: key,
        category: category,
      });
    } else {
      setLoading(true);

      let searchIdx;
      const newList = cloneDeep(availableLocalList);

      // if current active panel is NOT study design
      if (activePanel.category === "generic" && activePanel.key !== 9) {
        searchIdx = activePanel.key - 1;
      }
      // if current active panel is study design
      else {
        searchIdx = 10; // Outcome Index from Study Design
      }

      if (newList[searchIdx]?.searchedTerm?.length <= 0) {
        newList[searchIdx].isExpanded = false;
        if (newList[searchIdx].isNested) {
          newList[searchIdx].subAttributes?.forEach((item) => {
            if (item[newList[searchIdx]?.level_two_key]?.length > 5)
              item.isExpanded = false;
          });
        }
      }

      dispatch(setAvailableFilterList(newList));

      setTimeout(() => {
        if (key !== undefined) {
          setActivePanel({
            key: key,
            category: category,
          });
        } else {
          setActivePanel({
            key: 0,
            category: "",
          });
        }
        setLoading(false);
      }, 1000);
    }
  };

  const disabledPublicationRange = (current) => {
    let start_year = moment(publicationYearRange.startYear).format(dateFormat);
    let end_year = moment().format(dateFormat);
    const endYearString = (Number(end_year) + 1).toString();

    return current.isBefore(start_year) || current.isAfter(endYearString);
  };

  return (
    <>
      <Modal
        title={
          <>
            <Icon
              component={ModalBackBtnIcon}
              className="info-icon"
              onClick={() => cancelFilters()}
            />{" "}
            Filter
          </>
        }
        centered
        width={"80%"}
        maskClosable={false}
        okText="Apply Search Rules"
        open={isAdvFilterModalOpen}
        onCancel={cancelFilters}
        destroyOnClose={true}
        footer={
          <>
            <Button
              className="zs-teal"
              type="text"
              onClick={() => resetFilters()}
            >
              Reset
            </Button>
            <Button onClick={() => cancelFilters()}>Cancel</Button>
            <Button
              className="filter-pane-apply-btn"
              onClick={() => applyFilters()}
              disabled={!isNewFilterSelected}
            >
              Apply
            </Button>
          </>
        }
      >
        <div className="filter-modal-body">
          <div>
            <Spin spinning={loading}>
              <div id="container-scroll" className="adv-container-scroll">
                <Row gutter={[24, 8]}>
                  <Col span={12}>
                    <Row gutter={[24, 8]}>
                      {availableLocalList
                        .slice(0, 6)
                        .map((attribute, index) => (
                          <Col span={12}>
                            <Card
                              title={attribute.attributeName}
                              bordered={false}
                              key={attribute.id}
                              size={"small"}
                              extra={getCount(attribute)}
                            >
                              {attribute.attributeName !==
                                "Publication Year Range" && (
                                <>
                                  {attribute.isSearchAvailable && (
                                    <Search
                                      placeholder={dynamicPlaceholder(
                                        attribute.attributeName
                                      )}
                                      key={"filter-search-" + index}
                                      onChange={debounce(
                                        (e) => onSearchEntities(e, attribute),
                                        500
                                      )}
                                      defaultValue={attribute.searchedTerm}
                                      className="search-input-adv"
                                    />
                                  )}
                                  <div>
                                    {attribute.subAttributes?.length > 0 ? (
                                      <VirtualList
                                        key={"virtual-list-" + index}
                                        id={"virtual-list-id-" + index}
                                        data={getFilteredList(
                                          attribute.subAttributes?.slice(
                                            0,
                                            getLimitCount(attribute, "parent")
                                          ),
                                          attribute,
                                          "parent"
                                        )}
                                        height={157}
                                        itemHeight={22}
                                        itemKey={attribute.level_one_key_name}
                                        prefixCls={"virtual-list-item"}
                                      >
                                        {(subAttr, subIndex) => (
                                          <>
                                            <Checkbox
                                              key={
                                                subAttr[
                                                  attribute.level_one_key_name
                                                ]
                                              }
                                              checked={subAttr.isChecked}
                                              indeterminate={
                                                subAttr.isIndeterminate
                                              }
                                              onChange={() =>
                                                onCheckedParent(
                                                  subIndex,
                                                  attribute,
                                                  subAttr
                                                )
                                              }
                                            >
                                              <Tooltip
                                                title={
                                                  subAttr[
                                                    attribute.level_one_key_name
                                                  ].length > 25
                                                    ? subAttr[
                                                        attribute
                                                          .level_one_key_name
                                                      ]
                                                    : null
                                                }
                                              >
                                                {subAttr[
                                                  attribute.level_one_key_name
                                                ].length <= 25
                                                  ? subAttr[
                                                      attribute
                                                        .level_one_key_name
                                                    ]
                                                  : `${subAttr[
                                                      attribute
                                                        .level_one_key_name
                                                    ].slice(0, 25)}...`}
                                              </Tooltip>
                                            </Checkbox>
                                            {attribute.isNested &&
                                              subAttr[attribute.level_two_key]
                                                ?.length > 0 && (
                                                <>
                                                  {" "}
                                                  {subAttr[
                                                    attribute.level_two_key
                                                  ]
                                                    .slice(
                                                      0,
                                                      getLimitCount(
                                                        attribute,
                                                        "child",
                                                        subAttr
                                                      )
                                                    )
                                                    .map(
                                                      (
                                                        subSubAttr,
                                                        subSubIndx
                                                      ) =>
                                                        isItemFiltered(
                                                          subSubAttr[
                                                            attribute
                                                              .level_two_key_name
                                                          ],
                                                          attribute,
                                                          "child",
                                                          null,
                                                          subSubAttr
                                                        ) && (
                                                          <>
                                                            <Checkbox
                                                              className="ml-15"
                                                              key={
                                                                subSubAttr[
                                                                  attribute
                                                                    .level_two_key_name
                                                                ]
                                                              }
                                                              checked={
                                                                subSubAttr.isChecked
                                                              }
                                                              indeterminate={
                                                                subSubAttr.isIndeterminate
                                                              }
                                                              onChange={() =>
                                                                onCheckedChild(
                                                                  subIndex,
                                                                  attribute,
                                                                  subAttr,
                                                                  subSubAttr,
                                                                  subSubIndx
                                                                )
                                                              }
                                                            >
                                                              <Tooltip
                                                                title={
                                                                  subSubAttr[
                                                                    attribute
                                                                      .level_two_key_name
                                                                  ].length > 20
                                                                    ? subSubAttr[
                                                                        attribute
                                                                          .level_two_key_name
                                                                      ]
                                                                    : null
                                                                }
                                                              >
                                                                {subSubAttr[
                                                                  attribute
                                                                    .level_two_key_name
                                                                ].length <= 20
                                                                  ? subSubAttr[
                                                                      attribute
                                                                        .level_two_key_name
                                                                    ]
                                                                  : `${subSubAttr[
                                                                      attribute
                                                                        .level_two_key_name
                                                                    ].slice(
                                                                      0,
                                                                      20
                                                                    )}...`}
                                                              </Tooltip>
                                                            </Checkbox>
                                                            {subSubAttr[
                                                              attribute
                                                                .level_three_key
                                                            ]?.length > 0 && (
                                                              <>
                                                                {" "}
                                                                {subSubAttr[
                                                                  attribute
                                                                    .level_three_key
                                                                ]
                                                                  .slice(
                                                                    0,
                                                                    getLimitCount(
                                                                      attribute,
                                                                      "grandChild",
                                                                      subAttr,
                                                                      subSubAttr
                                                                    )
                                                                  )
                                                                  .map(
                                                                    (
                                                                      item,
                                                                      itemIdx
                                                                    ) =>
                                                                      isItemFiltered(
                                                                        item[
                                                                          attribute
                                                                            .level_three_key_name
                                                                        ],
                                                                        attribute,
                                                                        "grandChild"
                                                                      ) && (
                                                                        <>
                                                                          <Checkbox
                                                                            className="ml-30"
                                                                            key={
                                                                              item[
                                                                                attribute
                                                                                  .level_three_key_name
                                                                              ]
                                                                            }
                                                                            checked={
                                                                              item.isChecked
                                                                            }
                                                                            onChange={() =>
                                                                              onCheckedGrandChild(
                                                                                subIndex,
                                                                                attribute,
                                                                                subAttr,
                                                                                subSubAttr,
                                                                                subSubIndx,
                                                                                item,
                                                                                itemIdx
                                                                              )
                                                                            }
                                                                          >
                                                                            <Tooltip
                                                                              title={
                                                                                item[
                                                                                  attribute
                                                                                    .level_three_key_name
                                                                                ]
                                                                                  .length >
                                                                                20
                                                                                  ? item[
                                                                                      attribute
                                                                                        .level_three_key_name
                                                                                    ]
                                                                                  : null
                                                                              }
                                                                            >
                                                                              {item[
                                                                                attribute
                                                                                  .level_three_key_name
                                                                              ]
                                                                                .length <=
                                                                              20
                                                                                ? item[
                                                                                    attribute
                                                                                      .level_three_key_name
                                                                                  ]
                                                                                : `${item[
                                                                                    attribute
                                                                                      .level_three_key_name
                                                                                  ].slice(
                                                                                    0,
                                                                                    20
                                                                                  )}...`}
                                                                            </Tooltip>
                                                                          </Checkbox>
                                                                        </>
                                                                      )
                                                                  )}
                                                                {subSubAttr[
                                                                  attribute
                                                                    .level_three_key
                                                                ].length > 5 &&
                                                                  subSubAttr.isExpanded !==
                                                                    true &&
                                                                  attribute
                                                                    .searchedTerm
                                                                    .length <=
                                                                    0 && (
                                                                    <p
                                                                      onClick={() =>
                                                                        expandContractList(
                                                                          attribute,
                                                                          subIndex,
                                                                          "expand",
                                                                          "grandChild",
                                                                          subSubIndx
                                                                        )
                                                                      }
                                                                      className="expand-contract-p"
                                                                    >
                                                                      <DownArrowIconColored />{" "}
                                                                      Show all{" "}
                                                                      {
                                                                        subSubAttr[
                                                                          attribute
                                                                            .level_three_key
                                                                        ].length
                                                                      }{" "}
                                                                      records
                                                                    </p>
                                                                  )}
                                                                {subSubAttr.isExpanded ===
                                                                  true &&
                                                                  attribute
                                                                    .searchedTerm
                                                                    .length <=
                                                                    0 && (
                                                                    <p
                                                                      onClick={() =>
                                                                        expandContractList(
                                                                          attribute,
                                                                          subIndex,
                                                                          "contract",
                                                                          "grandChild",
                                                                          subSubIndx
                                                                        )
                                                                      }
                                                                      className="expand-contract-p"
                                                                    >
                                                                      <UpArrowIconColored />{" "}
                                                                      Show less
                                                                      records
                                                                    </p>
                                                                  )}
                                                              </>
                                                            )}
                                                          </>
                                                        )
                                                    )}
                                                  {subAttr[
                                                    attribute.level_two_key
                                                  ].length > 5 &&
                                                    subAttr.isExpanded !==
                                                      true &&
                                                    attribute.searchedTerm
                                                      .length <= 0 && (
                                                      <p
                                                        onClick={() =>
                                                          expandContractList(
                                                            attribute,
                                                            subIndex,
                                                            "expand",
                                                            "child"
                                                          )
                                                        }
                                                        className="expand-contract-p"
                                                      >
                                                        <DownArrowIconColored />{" "}
                                                        Show all{" "}
                                                        {
                                                          subAttr[
                                                            attribute
                                                              .level_two_key
                                                          ].length
                                                        }{" "}
                                                        records
                                                      </p>
                                                    )}
                                                  {subAttr.isExpanded ===
                                                    true &&
                                                    attribute.searchedTerm
                                                      .length <= 0 && (
                                                      <p
                                                        onClick={() =>
                                                          expandContractList(
                                                            attribute,
                                                            subIndex,
                                                            "contract",
                                                            "child"
                                                          )
                                                        }
                                                        className="expand-contract-p"
                                                      >
                                                        <UpArrowIconColored />{" "}
                                                        Show less records
                                                      </p>
                                                    )}
                                                </>
                                              )}
                                          </>
                                        )}
                                      </VirtualList>
                                    ) : (
                                      <p>No matching records</p>
                                    )}
                                    {attribute.subAttributes?.length > 7 &&
                                      attribute.isExpanded !== true &&
                                      attribute.searchedTerm.length <= 0 && (
                                        <p
                                          onClick={() =>
                                            expandContractList(
                                              attribute,
                                              index,
                                              "expand",
                                              "parent"
                                            )
                                          }
                                          className="expand-contract-parent"
                                        >
                                          <DownArrowIconColored /> Show all{" "}
                                          {attribute.subAttributes?.length}{" "}
                                          records
                                        </p>
                                      )}
                                    {attribute.isExpanded === true &&
                                      attribute.searchedTerm.length <= 0 && (
                                        <p
                                          onClick={() =>
                                            expandContractList(
                                              attribute,
                                              index,
                                              "contract",
                                              "parent"
                                            )
                                          }
                                          className="expand-contract-parent"
                                        >
                                          <UpArrowIconColored /> Show less
                                          records
                                        </p>
                                      )}
                                  </div>
                                </>
                              )}

                              {attribute.attributeName ===
                                "Publication Year Range" && (
                                <>
                                  <RangePicker
                                    onChange={updateYearRangeInput}
                                    disabledDate={(current) =>
                                      disabledPublicationRange(current)
                                    }
                                    inputReadOnly={true}
                                    picker="year"
                                    value={
                                      inputValues?.publication_start_year &&
                                      inputValues?.publication_end_year
                                        ? [
                                            moment(
                                              inputValues.publication_start_year,
                                              dateFormat
                                            ),
                                            moment(
                                              inputValues.publication_end_year,
                                              dateFormat
                                            ),
                                          ]
                                        : ""
                                    }
                                    placeholder={[
                                      currentYearRange,
                                      currentYearRange,
                                    ]}
                                    format={dateFormat}
                                  />
                                </>
                              )}
                            </Card>
                          </Col>
                        ))}
                    </Row>
                  </Col>
                  <Col span={6} style={{ paddingLeft: "0px" }}>
                    <Card
                      title={"Treatment"}
                      bordered={false}
                      key={"treatment"}
                      size={"small"}
                      extra={getTreatmentCount()}
                    >
                      <Collapse
                        className="collapse-ant-treatment"
                        accordion
                        ghost
                        onChange={(e) => onCollapseToggle(e, "treatment")}
                        expandIcon={({ isActive }) =>
                          isActive ? (
                            <span>
                              <DownArrowIcon />
                            </span>
                          ) : (
                            <span>
                              <UpArrowIcon />
                            </span>
                          )
                        }
                      >
                        {availableLocalList
                          .slice(6, 9)
                          .map((attribute, index) => (
                            <Panel
                              header={attribute.attributeName}
                              key={attribute.attributeId}
                              extra={getDotBadge(attribute)}
                              className="treatment-filter"
                            >
                              <div id="collapse-scroll">
                                {attribute.isSearchAvailable && (
                                  <Search
                                    placeholder={dynamicPlaceholder(
                                      attribute.attributeName
                                    )}
                                    key={"treatment-search-" + index}
                                    onChange={debounce(
                                      (e) => onSearchEntities(e, attribute),
                                      500
                                    )}
                                    defaultValue={attribute.searchedTerm}
                                    className="search-input-adv"
                                  />
                                )}
                                <div className="collapse-scroll-div">
                                  {attribute.subAttributes?.length > 0 ? (
                                    <VirtualList
                                      key={"virtual-treatment-list-" + index}
                                      id={"virtual-treatment-list-id-" + index}
                                      data={getFilteredList(
                                        attribute.subAttributes,
                                        attribute,
                                        "parent"
                                      )}
                                      height={160}
                                      itemHeight={22}
                                      itemKey={attribute.level_one_key_name}
                                      prefixCls={"virtual-treatment-list-item"}
                                    >
                                      {(subAttr, subIndex) =>
                                        (
                                          <>
                                            <Checkbox
                                              key={
                                                subAttr[
                                                  attribute.level_one_key_name
                                                ]
                                              }
                                              checked={subAttr.isChecked}
                                              indeterminate={
                                                subAttr.isIndeterminate
                                              }
                                              onChange={() =>
                                                onCheckedParent(
                                                  subIndex,
                                                  attribute,
                                                  subAttr
                                                )
                                              }
                                            >
                                              <Tooltip
                                                title={
                                                  subAttr[
                                                    attribute.level_one_key_name
                                                  ].length > 25
                                                    ? subAttr[
                                                        attribute
                                                          .level_one_key_name
                                                      ]
                                                    : null
                                                }
                                              >
                                                {subAttr[
                                                  attribute.level_one_key_name
                                                ].length <= 25
                                                  ? subAttr[
                                                      attribute
                                                        .level_one_key_name
                                                    ]
                                                  : `${subAttr[
                                                      attribute
                                                        .level_one_key_name
                                                    ].slice(0, 25)}...`}
                                              </Tooltip>
                                            </Checkbox>
                                            {attribute.isNested &&
                                              subAttr[attribute.level_two_key]
                                                ?.length > 0 && (
                                                <>
                                                  {" "}
                                                  {subAttr[
                                                    attribute.level_two_key
                                                  ]
                                                    .slice(
                                                      0,
                                                      getLimitCount(
                                                        attribute,
                                                        "child",
                                                        subAttr
                                                      )
                                                    )
                                                    .map(
                                                      (item, itemIdx) =>
                                                        isItemFiltered(
                                                          item[
                                                            attribute
                                                              .level_two_key_name
                                                          ],
                                                          attribute,
                                                          "child"
                                                        ) && (
                                                          <>
                                                            <Checkbox
                                                              className="ml-15"
                                                              key={
                                                                item[
                                                                  attribute
                                                                    .level_two_key_name
                                                                ]
                                                              }
                                                              checked={
                                                                item.isChecked
                                                              }
                                                              onChange={() =>
                                                                onCheckedChild(
                                                                  subIndex,
                                                                  attribute,
                                                                  subAttr,
                                                                  item,
                                                                  itemIdx
                                                                )
                                                              }
                                                            >
                                                              <Tooltip
                                                                title={
                                                                  item[
                                                                    attribute
                                                                      .level_two_key_name
                                                                  ].length > 20
                                                                    ? item[
                                                                        attribute
                                                                          .level_two_key_name
                                                                      ]
                                                                    : null
                                                                }
                                                              >
                                                                {item[
                                                                  attribute
                                                                    .level_two_key_name
                                                                ].length <= 20
                                                                  ? item[
                                                                      attribute
                                                                        .level_two_key_name
                                                                    ]
                                                                  : `${item[
                                                                      attribute
                                                                        .level_two_key_name
                                                                    ].slice(
                                                                      0,
                                                                      20
                                                                    )}...`}
                                                              </Tooltip>
                                                            </Checkbox>
                                                          </>
                                                        )
                                                    )}
                                                  {subAttr[
                                                    attribute.level_two_key
                                                  ].length > 5 &&
                                                    subAttr.isExpanded !==
                                                      true &&
                                                    attribute.searchedTerm
                                                      .length <= 0 && (
                                                      <p
                                                        onClick={() =>
                                                          expandContractList(
                                                            attribute,
                                                            subIndex,
                                                            "expand",
                                                            "child"
                                                          )
                                                        }
                                                        className="expand-contract-p"
                                                      >
                                                        <DownArrowIconColored />{" "}
                                                        Show all{" "}
                                                        {
                                                          subAttr[
                                                            attribute
                                                              .level_two_key
                                                          ].length
                                                        }{" "}
                                                        records
                                                      </p>
                                                    )}
                                                  {subAttr.isExpanded ===
                                                    true &&
                                                    attribute.searchedTerm
                                                      .length <= 0 && (
                                                      <p
                                                        onClick={() =>
                                                          expandContractList(
                                                            attribute,
                                                            subIndex,
                                                            "contract",
                                                            "child"
                                                          )
                                                        }
                                                        className="expand-contract-p"
                                                      >
                                                        <UpArrowIconColored />{" "}
                                                        Show less records
                                                      </p>
                                                    )}
                                                </>
                                              )}
                                          </>
                                        )
                                      }
                                    </VirtualList>
                                  ) : (
                                    <p>No matching records</p>
                                  )}
                                </div>
                              </div>
                            </Panel>
                          ))}
                      </Collapse>
                    </Card>
                  </Col>
                  <Col span={6} style={{ paddingLeft: "0px" }}>
                    <Card
                      title={"Study Design"}
                      bordered={false}
                      key={"stydyDesign"}
                      size={"small"}
                      extra={getStudyDesignCount()}
                    >
                      <>
                        <h4>
                          Recruitment
                          <span>
                            <Tooltip title="Only enabled when study type is selected as 'Clinical Trial' or  'Observational'">
                              <Icon
                                component={DrugInfo}
                                className="info-icon"
                              />
                            </Tooltip>
                          </span>
                        </h4>
                        <Input
                          type="number"
                          min={0}
                          max={99999}
                          disabled={checkRecruitmentDisabled()}
                          className="recruitment-input"
                          value={inputValues.recruitment}
                          onKeyDown={(e) => checkNumberInput(e, "recruitment")}
                          onChange={(e) => updateInput(e, "recruitment")}
                          placeholder="Enter Sample Size"
                        />
                      </>
                      <Collapse
                        className="collapse-ant"
                        accordion
                        ghost
                        onChange={(e) => onCollapseToggle(e, "study_design")}
                        expandIcon={({ isActive }) =>
                          isActive ? (
                            <span>
                              <DownArrowIcon />
                            </span>
                          ) : (
                            <span>
                              <UpArrowIcon />
                            </span>
                          )
                        }
                      >
                        {availableLocalList
                          .slice(9, 13)
                          .map((attribute, index) => (
                            <Panel
                              header={attribute.attributeName}
                              key={attribute.attributeId}
                              extra={getDotBadge(attribute)}
                              className="study-design-filter"
                            >
                              <div id="collapse-scroll">
                                {attribute.isSearchAvailable && (
                                  <Search
                                    placeholder={dynamicPlaceholder(
                                      attribute.attributeName
                                    )}
                                    key={"study-design-search-" + index}
                                    onChange={debounce(
                                      (e) => onSearchEntities(e, attribute),
                                      500
                                    )}
                                    defaultValue={attribute.searchedTerm}
                                    className="search-input-adv"
                                  />
                                )}
                                <div className="collapse-scroll-div">
                                  {attribute.subAttributes?.length > 0 ? (
                                    <VirtualList
                                      key={"virtual-study-design-list-" + index}
                                      id={"virtual-study-design-list-id-" + index}
                                      data={getFilteredList(
                                        attribute.subAttributes,
                                        attribute,
                                        "parent"
                                      )}
                                      height={160}
                                      itemHeight={22}
                                      itemKey={attribute.level_one_key_name}
                                      prefixCls={"virtual-study-design-list-item"}
                                    >
                                      {(subAttr, subIndex) =>
                                        (
                                          <>
                                            <Checkbox
                                              key={
                                                subAttr[
                                                  attribute.level_one_key_name
                                                ]
                                              }
                                              checked={subAttr.isChecked}
                                              indeterminate={
                                                subAttr.isIndeterminate
                                              }
                                              onChange={() =>
                                                onCheckedParent(
                                                  subIndex,
                                                  attribute,
                                                  subAttr
                                                )
                                              }
                                            >
                                              <Tooltip
                                                title={
                                                  subAttr[
                                                    attribute.level_one_key_name
                                                  ].length > 25
                                                    ? subAttr[
                                                        attribute
                                                          .level_one_key_name
                                                      ]
                                                    : null
                                                }
                                              >
                                                {subAttr[
                                                  attribute.level_one_key_name
                                                ].length <= 25
                                                  ? subAttr[
                                                      attribute
                                                        .level_one_key_name
                                                    ]
                                                  : `${subAttr[
                                                      attribute
                                                        .level_one_key_name
                                                    ].slice(0, 25)}...`}
                                              </Tooltip>
                                            </Checkbox>
                                            {attribute.isNested &&
                                              subAttr[attribute.level_two_key]
                                                ?.length > 0 && (
                                                <>
                                                  {" "}
                                                  {subAttr[
                                                    attribute.level_two_key
                                                  ]
                                                    .slice(
                                                      0,
                                                      getLimitCount(
                                                        attribute,
                                                        "child",
                                                        subAttr
                                                      )
                                                    )
                                                    .map(
                                                      (item, itemIdx) =>
                                                        isItemFiltered(
                                                          item[
                                                            attribute
                                                              .level_two_key_name
                                                          ],
                                                          attribute,
                                                          "child"
                                                        ) && (
                                                          <>
                                                            <Checkbox
                                                              className="ml-15"
                                                              key={
                                                                item[
                                                                  attribute
                                                                    .level_two_key_name
                                                                ]
                                                              }
                                                              checked={
                                                                item.isChecked
                                                              }
                                                              onChange={() =>
                                                                onCheckedChild(
                                                                  subIndex,
                                                                  attribute,
                                                                  subAttr,
                                                                  item,
                                                                  itemIdx
                                                                )
                                                              }
                                                            >
                                                              <Tooltip
                                                                title={
                                                                  item[
                                                                    attribute
                                                                      .level_two_key_name
                                                                  ].length > 20
                                                                    ? item[
                                                                        attribute
                                                                          .level_two_key_name
                                                                      ]
                                                                    : null
                                                                }
                                                              >
                                                                {item[
                                                                  attribute
                                                                    .level_two_key_name
                                                                ].length <= 20
                                                                  ? item[
                                                                      attribute
                                                                        .level_two_key_name
                                                                    ]
                                                                  : `${item[
                                                                      attribute
                                                                        .level_two_key_name
                                                                    ].slice(
                                                                      0,
                                                                      20
                                                                    )}...`}
                                                              </Tooltip>
                                                            </Checkbox>
                                                          </>
                                                        )
                                                    )}
                                                  {subAttr[
                                                    attribute.level_two_key
                                                  ].length > 5 &&
                                                    subAttr.isExpanded !==
                                                      true &&
                                                    attribute.searchedTerm
                                                      .length <= 0 && (
                                                      <p
                                                        onClick={() =>
                                                          expandContractList(
                                                            attribute,
                                                            subIndex,
                                                            "expand",
                                                            "child"
                                                          )
                                                        }
                                                        className="expand-contract-p"
                                                      >
                                                        <DownArrowIconColored />{" "}
                                                        Show all{" "}
                                                        {
                                                          subAttr[
                                                            attribute
                                                              .level_two_key
                                                          ].length
                                                        }{" "}
                                                        records
                                                      </p>
                                                    )}
                                                  {subAttr.isExpanded ===
                                                    true &&
                                                    attribute.searchedTerm
                                                      .length <= 0 && (
                                                      <p
                                                        onClick={() =>
                                                          expandContractList(
                                                            attribute,
                                                            subIndex,
                                                            "contract",
                                                            "child"
                                                          )
                                                        }
                                                        className="expand-contract-p"
                                                      >
                                                        <UpArrowIconColored />{" "}
                                                        Show less records
                                                      </p>
                                                    )}
                                                </>
                                              )}
                                          </>
                                        )
                                      }
                                    </VirtualList>
                                  ) : (
                                    <p>No matching records</p>
                                  )}
                                </div>
                              </div>
                            </Panel>
                          ))}
                      </Collapse>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Spin>
          </div>
        </div>
      </Modal>
    </>
  );
});

export default AdvancedFilterView;
