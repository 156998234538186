const mapHighlightColor = (entity_type, appliedAttributes) => {

    const colorMapping = {
        'age': '#FFE0C2',
        'gender': '#AFDCFC',
        'ethnicity': '#D4F5D7',
        'drugs': '#FDBEF0',
        'intervention': '#FDBEF0', // same color as drug
        'treatment': '#E9FFAC',
        'endpoint': "#32a3ff",
        'biomarker': "#f92eff",
        'phase': "#ff7991",
        'statistical analysis': "#ffba5d",
        'sample size': "#6eff27",
        'location': "#36fffc",
        'therapy': '#c8fd3d',
        'procedure': '#bfafb2',
    }

    return colorMapping[entity_type];
};

export { mapHighlightColor };