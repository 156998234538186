import { Button, Space, message, Badge } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addToComparision,
  removeFromList,
  setExploreViewMode,
} from "../../../reducer/EICCommonSlice";
import "./CompareStudiesDropDown.scss";
import { uniq, uniqBy } from "lodash";

const CompareStudiesDropDown = (data1) => {
  const dispatch = useDispatch();

  const {
    EICCommon: { retrieveSelectedKeys, compareStudiesData },
  } = useSelector((state) => state);

  const handleCompareStudies = () => {
    if (retrieveSelectedKeys.selectKey.length < 1) return;

    const MaxStudiesErrorMsg = "Maximum 5 studies are allowed for comparison.";

    const allSelectKeys = [
      ...compareStudiesData.selectKey,
      ...retrieveSelectedKeys.selectKey,
    ];
    const uniqueKeys = uniq(allSelectKeys);
    if (uniqueKeys.length > 5) {
      message.config({
        maxCount: 1,
      });
      message.error(MaxStudiesErrorMsg, 3);
      return;
    }

    const allStudyData = [
      ...compareStudiesData.selectData,
      ...retrieveSelectedKeys.selectData,
    ];
    const uniqueStudies = uniqBy(allStudyData, "key");

    dispatch(
      addToComparision({
        selectKey: [...uniqueKeys],
        selectData: [...uniqueStudies],
      })
    );
  };

  const handleRemoveCompareStudies = () => {
    dispatch(removeFromList({ selectKey: [], selectData: [] }));
  };

  /** Logic to disable the add to compare button. Not using now */
  const isAddToCompareDisabled = () => {
    const newArticles = retrieveSelectedKeys?.selectKey?.filter(i => !compareStudiesData?.selectKey?.includes(i));
    return compareStudiesData?.selectKey?.length >= 5 ? true
      : (retrieveSelectedKeys?.selectKey?.length > 5) ? true
        : (retrieveSelectedKeys?.selectKey?.length < 1 ? true
          : (
            newArticles?.length > 0 && (newArticles?.length + compareStudiesData?.selectKey?.length < 6) ? false
              : true
          )
        );
  };

  return (
    <React.Fragment>
      <Space>
        <p className="studies-selected">
          {retrieveSelectedKeys?.selectKey === undefined
            ? 0
            : retrieveSelectedKeys?.selectKey?.length}{" "}
          Studies Selected :
        </p>

        <Button onClick={handleCompareStudies}>
          <Space>&nbsp;Add to Comparison</Space>
        </Button>
        <Button
          style={{fontSize: 14}}
          className="common-ant-btn"
          onClick={handleRemoveCompareStudies}
          disabled={compareStudiesData.selectData.length ? false : true}
        >
          <Space>Clear Compare Studies</Space>
        </Button>
      </Space>
    </React.Fragment>
  );
};

export default CompareStudiesDropDown;
