const defaultChartConfig = {
    chart: {
      type: 'bar',
      marginTop: 50,
      height: (9 / 16 * 50) + '%' // 16:9 ratio
    },
    xAxis: {
      categories: [],
      tickLength: 0,
      lineWidth: 1,
      title: {
        text: ''
      }
    },
    colors: ['#0952CA', '#2F9BFF', '#C4E3FF', '#BA5422', '#DB6C03', '#FF9836', '#FECA98', '#40320A', '#CC9D2A', '#FD595F', '#FECDCF',],
    title: '# of Studies',
    plotOptions: {
      series: {
        cursor: "pointer",
        point: {
          events: {
          },
        },
        pointPadding: 0,
      },
      bar: {
        minPointLength: 3,
      }
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: '<strong># of Studies</strong>' 
      }
    },
    legend: {
        enabled: true,
        align: 'left',
        verticalAlign: 'top',
        floating: true,
        x: 0,
        y: 0
    },
    series: [],
    credits: {
        enabled: false
    },
}

export default defaultChartConfig;