import React, { useEffect, useState } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import QuillToolbar, { formats } from "./QuillToolbar";

const charMaxLength = 500; // default max character length

const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

const extractContent = (s) => {
    if(!s) return '';
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
};

const QuillEditor = (props) => {
    const { maxLength, defaultContent, readOnly } = props;

    const [quillState, setQuillState] = useState(null); // quill editor instance
    const uniqeId = uuidv4(); // unique id

    /** Quill Editor Modules */
    const modules = {
        toolbar: {
            container: "#qillEditorToolbar" + uniqeId
        }
    };

    const [editorText, setEditorText] = useState(""); // html content

    /**
     * Initialize the quill reference or state
     * @param {*} ref 
     * @returns 
     */
    const setQuill = ref => {
        if (!ref || ref === null) return;
        setQuillState(ref);
    };

    useEffect(() => {
        if (!uniqeId || quillState) return;
        var quill = new Quill('#editorId' + uniqeId,
            {
                modules: modules,
                formats: formats,
                theme: 'snow',
                placeholder: readOnly && extractContent(defaultContent)?.length > 0 ? '' : 'Start typing...',
                readOnly: readOnly
            }
        );
        setQuill(quill);
        if (defaultContent) {
            quill.root.innerHTML = defaultContent;
        }
        quill.on('editor-change', () => {
            onChangeHandler(quill);
        });
        quill.editor.scroll.domNode.addEventListener('blur', () => {
            onBlurHandler(quill);
        });

    }, []);

    const onChangeHandler = (quill) => {
        const checkLength = maxLength || charMaxLength;
        if (quill.getLength() > checkLength) { // check character length validation
            quill.deleteText(checkLength, quill.getLength());
        }
        setEditorText(quill.root.innerHTML); // set the html content
    };

    const onBlurHandler = (quill) => {
        if (props.onEditorBlurUpdate && typeof (props.onEditorBlurUpdate) === "function") {
            props.onEditorBlurUpdate({
                editorHtmlContent: quill.root.innerHTML
            });
        }
    }

    return (
        <>
            {uniqeId &&
                <>
                    <div className={props.className}>
                        <QuillToolbar
                            id={uniqeId}
                            readOnly={readOnly}
                            extra={
                                props.extra
                            } />
                        <div id={'editorId' + uniqeId}></div>
                    </div>
                </>
            }
        </>
    )

};

export default QuillEditor;