import React, { useState } from 'react';
import './SaveAnalysisView.scss';
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col } from 'antd';
import { useLocation } from "react-router-dom";
import { setSelectedAnalysisData, toggleSaveNewAnalysis } from '../../reducer/EICCommonSlice';
import { showNotification } from '../../shared/utils/CommonUtil';
import { fetchAnalysisById, saveAnalysis } from '../../services/InsightsViewAPI';

const SaveAnalysisView = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const {
        EICCommon: {
            searchTerm,
            selectedAdvanceFilters,
            selectedAnalysisData,
            compareStudiesData,
            isStoryboardOwner
        },
    } = useSelector((state) => state);

    const pagesToDisplay = ['/home/explore', '/home/insights', '/home/storyboard'];
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);

    const onSaveAnalysis = () => {
        const analysisData = {
            analysisName: searchTerm || null,
            therapyArea: null,
            description: null
        };
        dispatch(toggleSaveNewAnalysis({ isOpen: true, mode: 'add', analysisData, source: 'analysis-save' }));
    };

    const onUpdateAnalysis = async () => {
        setIsUpdateLoading(true);

        const payload = {
            edit: true,
            analysis_id: selectedAnalysisData?.analysis_id,
            search_text: searchTerm,
            study_filters: selectedAdvanceFilters,
            comparison_data: compareStudiesData.selectData.map((item) => item.pm_id),
        };
        const response = await saveAnalysis(payload);
        if (response?.status?.toLowerCase() === "success") {
            const payload = {
                analysis_id: response.analysis_id
            }
            const result = await fetchAnalysisById(payload);
            if (result?.status?.toLowerCase() === "success") {
                const anaysisData = result?.result?.length ? result.result[0] : {};
                dispatch(setSelectedAnalysisData(anaysisData));
                showNotification('success', 'Success', 'Successfully saved the analysis.');
            }
        } else {
            showNotification('error', 'Error', 'Failed update the analysis.');
        }
        setIsUpdateLoading(false);
    };

    const isAnalysisSelected = () => {
        return selectedAnalysisData?.analysis_id !== undefined ? true : false;
    };

    const isNewAnalysis = () => {
        return selectedAnalysisData?.analysis_id !== undefined ? false : true;
    };

    const DateFormator = (date) => {
        const moment = require("moment");

        const d = new Date(date);

        return moment(d).format("DD MMM YYYY");
    };

    return (
        <>
            {pagesToDisplay.indexOf(location.pathname) > -1 &&
                <div className='save-analysis-view-layout'>
                    <Row>
                        <Col span={22}>
                            {
                                isAnalysisSelected() && (
                                    <>
                                        <h2 className='saved-analysis-title'>
                                            {selectedAnalysisData.analysis_name?.title}&nbsp;&nbsp;
                                            <small>(Last Updated on {DateFormator(selectedAnalysisData.last_updated?.date)})</small>
                                        </h2>
                                    </>
                                )
                            }
                            {
                                isNewAnalysis() && (
                                    <>
                                        <h2 className='new-analysis-placeholder'>&lt; New Analysis &gt;</h2>
                                    </>
                                )
                            }
                        </Col>
                        <Col span={2} className={'save-btn-container'}>
                            {
                                isAnalysisSelected() && (
                                    <>
                                        {isStoryboardOwner && 
                                        <Button type='primary' style={{ float: 'right' }} loading={isUpdateLoading} onClick={onUpdateAnalysis}>Save</Button>}
                                    </>
                                )
                            }
                            {
                                isNewAnalysis() && (
                                    <>
                                        <Button type='primary' style={{ float: 'right' }} onClick={onSaveAnalysis}>Save</Button>
                                    </>
                                )
                            }
                        </Col>
                    </Row>
                </div>
            }
        </>
    )
};

export default SaveAnalysisView;