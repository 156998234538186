const SavedAnalysisFiltersData = (type, tabledata) => {
  switch (type) {
    case "therapyArea":
      const uniqueArray = (a) =>
        [...new Set(a.map((o) => JSON.stringify(o)))].map((s) => JSON.parse(s));
      const therapyresult = tabledata.map((ele) => {
        return { text: ele.therapyArea, value: ele.therapyArea };
      });
      const result = uniqueArray(therapyresult);
      return result;
    default:
      return null;
  }
};

export default SavedAnalysisFiltersData;
