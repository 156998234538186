import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import {
  ENVIRONMENTS,
  SELECTED_ENVIRONMENT,
} from "../environments/environments";
import { UserUnautherizedAction } from "../shared/utils/CommonUtil";

export const BaseUrl = ENVIRONMENTS[SELECTED_ENVIRONMENT].baseurl || "";

export const getUserInfo = async () => {
  try {
    const { data } = await axios.get(`${BaseUrl}/api/userInfo`, {
      withCredentials: true,
    });
    localStorage.setItem("auth_status", data.auth_status);
    sessionStorage.setItem("username", data.username);
    return data;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction();
    }
  }
};

// below function returns data for competitive intelligence  Bar Chart
export const getCompetitiveBarChartData = async (
  searchText,
  advanceFilter,
  ViewByValue,
  ViewBySubCat,
  selectedIntervention,
  breakdownByEntities,
  excludedStudyIds = []
) => {
  let payload = {
    type: "competitive",
    search_text: searchText,
    study_filters: advanceFilter ? mapAdvanceFilters(advanceFilter) : {},
    excluded_ids: excludedStudyIds,
    chart_filters: {
      chart_1: {
        view_by_key: ViewByValue,
        filter_by_values: ViewBySubCat ? ViewBySubCat : [],
        // filter_by_values:  advanceFilter && advanceFilter.Endpoints ? [] : ViewBySubCat,
        breakdown_by: "drug",
        breakdown_by_values: selectedIntervention ? selectedIntervention : [],
        max_entities_view_by: 7,
        // max_entities_breakdown_by: advanceFilter?.drug?.length ?  advanceFilter.drug.length >=7 ? 7 : advanceFilter.drug.length : breakdownByEntities,
        max_entities_breakdown_by: breakdownByEntities,
      },
    },
  };
  try {
    const response = await axios.post(
      `${BaseUrl}/api/evidenceLandscapeBarChart`,
      payload,
      { withCredentials: true }
    );
    return response.data;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction();
    }
  }
};

// below function returns data for Timeline Trend  Line Chart
export const getTimelineTrendChartData = async (
  searchText,
  advanceFilter,
  ViewByValue,
  ViewBySubCat,
  excludedStudyIds
  // startDate,
  // endDate
) => {
  let payload = {
    search_text: searchText,
    study_filters: advanceFilter ? mapAdvanceFilters(advanceFilter) : {},
    chart_filters: {
      chart_1: {
        view_by_key: ViewByValue,
        filter_by_values: ViewBySubCat ? ViewBySubCat : [],
        max_entities_view_by: 7,
        // start_date: "2000-01-01",
        // end_date: "2020-01-01",
      },
    },
    excluded_ids: excludedStudyIds,
    // search_text: "",
    // study_filters: {},
    // chart_filters: {
    //   chart_1: {
    //     view_by_key: "Endpoints",
    //     filter_by_values: [],
    //     max_entities_view_by: 10,
    //     start_date: "2000-01-01",
    //     end_date: "2020-01-01",
    //   },
    // },
  };
  try {
    const response = await axios.post(
      `${BaseUrl}/api/timelineTrendLineChart`,
      payload,
      { withCredentials: true }
    );
    return response.data;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction();
    }
  }
};

//below function returns search result on landing page
export const getSearchResult = async (
  searchText,
  pageNumber,
  advancefilters,
  excludedStudyIds = []
) => {
  let payload = {
    search_text: searchText,
    max_results: 10,
    filters: { ...advancefilters },
    search_id: "",
    sort_column: "indication",
    sort_type: "ASC",
    batch_count: pageNumber,
    excluded_ids: excludedStudyIds,
  };

  try {
    const response = await axios.post(`${BaseUrl}/api/search`, payload, {
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction();
    }
  }
};

// below function returns data for Evidence Bar Chart
export const getEvidenceBarChartData = async (payload) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/api/evidenceLandscapeBarChart`,
      payload,
      { withCredentials: true }
    );

    return response.data;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction();
    }
  }
};

// to add/edit/save story board data or elements.
export const addToStoryBoard = async (payload) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/api/saveStoryBoard`,
      payload,
      { withCredentials: true }
    );

    return response.data;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction();
    }
  }
};

export const getReadFullTextData = async (payload) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/api/loadFullTextJson`,
      payload,
      { withCredentials: true }
    );
    return response.data;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction();
    }
  }
};

export const deleteStoryBoard = async (payload) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/api/deleteStoryBoard`,
      payload,
      { withCredentials: true }
    );
    return response.data;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction();
    }
  }
};

export const getStoryBoardNameList = async (payload) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/api/loadStoryBoardSectionDropdowns`,
      payload,
      { withCredentials: true }
    );
    return response.data;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction();
    }
  }
};

export const getUserStoryboards = async (payload) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/api/loadUserStoryBoards`,
      payload,
      { withCredentials: true }
    );
    return response.data;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction();
    }
  }
};

export const downloadStoryboard = async (payload) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/api/downloadStoryBoard`,
      payload,
      { withCredentials: true }
    );
    return response.data;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction();
    }
  }
};

// export const downloadStoryboardLink = async(payload, file_name) => {
//   let url = `${BaseUrl}/api/downloadLink`;
//   url = url + `?base_path=${payload}&&file_name=${file_name}`;

//   const response = await axios.get(
//     url,
//     { withCredentials: true }
//   );
//   return response.data;
// }

// below function returns data for advanced search dropdowns
export const getAdvancedSearchData = async () => {
  try {
    const response = await axios.get(`${BaseUrl}/api/advanceSearchDropdown`, {
      withCredentials: true,
    });
    return response.data.result;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction();
    }
  }
};

// below function returns data for Geographic Distribution Tab
export const getGeoMapData = async (
  searchterm,
  searchStudy,
  studyFilters,
  excludedStudyIds
) => {
  let payload = {
    search_text: searchterm,
    study_filters: studyFilters ? mapAdvanceFilters(studyFilters) : {},
    chart_filters: {
      chart_1: {
        view_by_key: searchStudy.split(" ").join("_"),
        filter_by_values: [],
        max_entities_view_by: 1000,
      },
    },
    excluded_ids: excludedStudyIds,
  };
  try {
    const { data } = await axios.post(
      `${BaseUrl}/api/geographicDistributionMapView`,
      payload,
      { withCredentials: true }
    );
    return data;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction();
    }
  }
};

//below function returns search result on landing page
export const getInteractiveSearch = async (
  searchText,
  pageNumber,
  advancefilters
) => {
  let payload = {
    search_text: searchText,
    max_results: 10,
    filters: { ...advancefilters },
    search_id: "",
    sort_column: "indication",
    sort_type: "ASC",
    batch_count: pageNumber,
  };

  try {
    const response = await axios.post(
      `${BaseUrl}/api/interactiveSearch`,
      payload,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction();
    }
  }
};

export const mapAdvanceFilters = (advanceFilters) => {
  const filters = cloneDeep(advanceFilters);

  filters["study_type_category"] = [];
  if (
    filters["Interventional"] !== undefined &&
    filters["Interventional"].length > 0
  ) {
    filters["study_type_category"].push("Interventional");
  }
  if (
    filters["Observational"] !== undefined &&
    filters["Observational"].length === 0
  ) {
    delete filters["Observational"];
    filters["study_type_category"].push("Observational");
  }
  if (
    filters["Secondary Research"] !== undefined &&
    filters["Secondary Research"].length === 0
  ) {
    delete filters["Secondary Research"];
    filters["study_type_category"].push("Secondary Research");
  }
  if (filters["study_type_category"].length === 0) {
    delete filters["study_type_category"];
  }

  return filters;
};

export const therapyLabelChange = (text) => {
  return text === "Therapy" ? "Therapy Area" : text;
};

export const getSavedAnalysisList = async () => {
  try {
    const response = await axios.get(`${BaseUrl}/api/loadAnalysis`, {
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction();
    }
  }
};

export const saveAnalysis = async (payload) => {
  try {
    const response = await axios.post(`${BaseUrl}/api/saveAnalysis`, payload, {
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction();
    }
  }
};

export const deleteAnalysis = async (payload) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/api/deleteAnalysis`,
      payload,
      { withCredentials: true }
    );
    return response.data;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction();
    }
  }
};

export const fetchAnalysisById = async (payload) => {
  try {
    const response = await axios.post(`${BaseUrl}/api/fetchAnalysis`, payload, {
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction();
    }
  }
};

export const setSendEmail = async (payload) => {
  try {
    const response = await axios.post(`${BaseUrl}/api/sendMail`, payload, {
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction();
    }
  }
};

export const userLogout = async () => {
  try {
    const { data } = await axios.get(`${BaseUrl}/api/logout`, {
      withCredentials: true,
    });
    return data;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction();
    }
  }
};

export const getNotifications = async (payload) => {
  try {
    const { data } = await axios.post(
      `${BaseUrl}/api/fetchNotification`,
      payload,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction();
    }
  }
};
//Neha - API call for Share Storyboard- collaboration starts from here
export const getUserList = async () => {
  try {
    const { data } = await axios.get(`${BaseUrl}/api/userList`, {
      withCredentials: true,
    });
    return data;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction()
    }
  }
};

export const getStoryBoardCollabrator = async (payload) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/api/viewStoryBoardCollabrator`,
      payload,
      { withCredentials: true }
    );
    return response.data;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction()
    }
  }
};

export const addCollabratorToStoryBoard = async (payload) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/api/addStoryBoardCollabrator`,
      payload,
      { withCredentials: true }
    );
    return response.data;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction()
    }
  }
};

export const lockUnlockStoryBoard = async (payload) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/api/lockUnlockStoryBoard`,
      payload,
      { withCredentials: true }
    );
    return response.data;
  } catch (e) {
    if (e.response.status === 401) {
      UserUnautherizedAction()
    }
  }
};
//..............Ends here..............
