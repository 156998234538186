import { Tabs, Input, Tag, Button, Modal, Col, Row, Badge, Space } from "antd";
import React, { useRef } from "react";
import ExploreTabPage from "../explore-tab/ExploreTabPage";
import InsightsTabs from "../insights-tab/InsightsTab";
import StoryboardContainer from "../storyboard/StoryboardContainer";
import { useDispatch, useSelector } from "react-redux";
import "./StudyLayout.scss";
import CompareStudies from "../compare-studies/CompareStudies";
import AdvancedFilterView from "../advanced-filter-view/AdvancedFilterView";
import {
  removeGlobalFilters,
  setAdvanceFilters,
  setSearchTerm,
  setFilterState,
  setAvailableFilterList,
  setStudyListTableData,
  setSearchLoadingReducer,
  setPagination,
  addGlobalFilters,
  removeFromList,
  updateChartEvidenceLandscapeList,
  setSelectedStudyListData,
  setInteractiveSelectedStudyListData,
  setEvidenceLandscapeCleanupStatus,
  setCompetitiveIntelligenceCleanupStatus,
  setGeoDistributionCleanupStatus,
  setTimelineTrendCleanupStatus,
  addToComparision,
  addToComparisionInteractive
} from "../../reducer/EICCommonSlice";
import Icon from "@ant-design/icons";
import {
  DownArrowIconColored,
  searchIcon,
  UpArrowIconColored,
  FilterBtnIcon,
} from "../../assets/icons/svg";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";

import { useState } from "react";
import { useEffect } from "react";
import {
  getSearchResult,
  mapAdvanceFilters,
} from "../../services/InsightsViewAPI";

const { TabPane } = Tabs;
const { Search } = Input;
const { confirm } = Modal;

const StudyLayoutContent = () => {
  const [tagsExpanded, setTagsExpanded] = useState(false);
  const [extractedFilters, setExtractedFilters] = useState([]);

  const advFilterRef = useRef();

  let searchTerm = useSelector((state) => state.EICCommon.searchTerm);

  const [searchTermLocal, setSearchLocal] = useState(
    sessionStorage.getItem("search_value") === searchTerm
      ? sessionStorage.getItem("search_value")
      : ""
  );

  let appliedGlobalFilters = useSelector(
    (state) => state.EICCommon.appliedGlobalFilters
  );
  let selectedAdvanceFilters = useSelector(
    (state) => state.EICCommon.selectedAdvanceFilters
  );
  let availableFilterList = useSelector(
    (state) => state.EICCommon.availableFilterList
  );
  let globalRawList = useSelector(
    (state) => state.EICCommon.globalRawList
  );

  let excludedStudyIds = useSelector(
    (state) => state.EICCommon.excludedStudyIds
  );

  useEffect(() => {
    setSearchLocal(searchTerm);
  }, [searchTerm])

  const {
    EICCommon: { 
      studyListTableData, 
      studyListPageNumber, 
      isAnalysisLoaded, 
      selectedAnalysisData, 
      interactiveFilters,
      isStoryboardOwner
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (!isEmpty(studyListTableData.query_analyser_response_filters)) {
      let extracted = [];
      for (const item in studyListTableData.query_analyser_response_filters) {
        extracted = [
          ...extracted,
          ...studyListTableData.query_analyser_response_filters[item],
        ];
      }
      const extractedSet = [...new Set(extracted)];
      setExtractedFilters(extractedSet);
    } else {
      setExtractedFilters([]);
    }
  }, [studyListTableData]);

  useEffect(() => {
    if (!selectedAnalysisData || (selectedAnalysisData && isAnalysisLoaded) || excludedStudyIds.length > 0
      || (selectedAnalysisData && selectedAnalysisData.study_filters
        && Object.keys(selectedAnalysisData.study_filters).length === 0) || (!isEmpty(interactiveFilters))) { // logic to handle multiple search api call when loading an analysis 
          onSearch();
    }
  }, [selectedAdvanceFilters, excludedStudyIds]);

  // useEffect (() => {
  //   if (!isEmpty(interactiveFilters)) {
  //     onSearch();
  //   }
  // }, [interactiveFilters])

  const layoutMode = useSelector((state) => state.EICCommon);
  const dispatch = useDispatch();

  const onSearch = async (value) => {
    setSearchLocal(value || value === "" ? value : searchTermLocal);
    sessionStorage.setItem(
      "search_value",
      value || value === "" ? value : searchTermLocal
    );
    searchTerm !== searchTermLocal && dispatch(setPagination(1));
    value === "" && dispatch(setPagination(1));
    dispatch(setSearchTerm(value || value === "" ? value : searchTermLocal));
    
    // to clear the study list row selection
    dispatch(setSelectedStudyListData({ selectKey: [], selectData: [] }));

    dispatch(setSearchLoadingReducer(true));
    const data = await getSearchResult(
      value === "" ? "" : searchTermLocal,
      studyListPageNumber,
      mapAdvanceFilters(selectedAdvanceFilters),
      excludedStudyIds
    );

    if(!data) dispatch(setSearchLoadingReducer(false));

    dispatch(setStudyListTableData(data.result));
    dispatch(setSearchLoadingReducer(false));

    //Neha - empty the compare study table when search return 0 results
    if (data?.result?.data?.length == 0) {
      dispatch(addToComparision({ selectKey: [], selectData: [] }));
      dispatch(addToComparisionInteractive({ selectKey: [], selectData: [] }));
      dispatch(setSelectedStudyListData({ selectKey: [], selectData: [] }));
      dispatch(setInteractiveSelectedStudyListData({ selectKey: [], selectData: [] }));
    }
  };

  const onSearchChange = (event) => {
    setSearchLocal(event.target.value);
    setSearchTerm(event.target.value);
  };

  // const handleClear = (value) => {
  //   console.log(value, "value");
  // };

  const removeFilter = (itemToRemove) => {
    let tempAdvanceFilters = cloneDeep(selectedAdvanceFilters);
    const availableList = cloneDeep(availableFilterList);

    // iterate over object keys and remove the deleted filter criteria
    for (const element in tempAdvanceFilters) {
      // for checkbox filters
      if (typeof tempAdvanceFilters[element] === "object") {
        const index = tempAdvanceFilters[element].findIndex(
          (attribute) => attribute === itemToRemove
        );

        // If Region removed
        if (index < 0 && element === "country") {
          const elementIdx = availableList.findIndex(
            (item) => item.level_one_key === "region"
          );
          const idx = availableList[elementIdx].subAttributes?.findIndex(
            (item) => item["region_name"] === itemToRemove
          );
          if (availableList[elementIdx].subAttributes[idx] !== undefined) {
            availableList[elementIdx].subAttributes[idx]?.country.forEach(
              (item) => {
                const countryIndex = tempAdvanceFilters["country"].findIndex(
                  (country) => country === item.country_name
                );
                if (countryIndex >= 0) {
                  tempAdvanceFilters["country"].splice(countryIndex, 1);
                  item.isChecked = false;
                  if (tempAdvanceFilters["country"].length === 0)
                    delete tempAdvanceFilters["country"];
                }
              }
            );
            availableList[elementIdx].subAttributes[idx].isChecked = false;
            availableList[elementIdx].subAttributes[
              idx
            ].isIndeterminate = false;
          }
        }

        if (index >= 0) {
          tempAdvanceFilters[element].splice(index, 1);
          if (tempAdvanceFilters[element].length === 0)
            delete tempAdvanceFilters[element];

          // back update filter selection on filter pane
          const elementIdx = availableList.findIndex(
            (item) => item.level_one_key === element
          );

          if (elementIdx >= 0) {
            const keyName = availableList[elementIdx]?.level_one_key_name;
            const idx = availableList[elementIdx].subAttributes?.findIndex(
              (item) => item[keyName] === itemToRemove
            );

            if (idx >= 0) {
              availableList[elementIdx].subAttributes[idx].isChecked = false;

              // if has child
              if (availableList[elementIdx].level_two_key.length > 0) {
                const childList =
                  availableList[elementIdx].subAttributes[idx][
                  availableList[elementIdx].level_two_key
                  ];
                childList?.forEach((child) => {
                  child.isChecked = false;
                  if(child[availableList[elementIdx].level_three_key]?.length) {
                    child.isIndeterminate = false;
                    child[availableList[elementIdx].level_three_key]?.forEach(subChild => {
                      subChild.isChecked = false;
                    })
                  }
                });
              }
            }
            if(availableList[elementIdx].isMultiLevel) {
              tempAdvanceFilters = updateAdvFilterForMultiLevel(availableList[elementIdx], tempAdvanceFilters);
            }
          } else {
            // check the removed value in level two attributes
            if (elementIdx >= 0) {
              const attributeObject = availableList[elementIdx];
              attributeObject.subAttributes?.forEach((attribute, index) => {
                const matchedIndex = attribute[
                  attributeObject.level_two_key
                ]?.findIndex(
                  (item) =>
                    item[attributeObject.level_two_key_name] === itemToRemove
                );
                if (matchedIndex >= 0) {
                  availableList[elementIdx].subAttributes[index][
                    attributeObject.level_two_key
                  ][matchedIndex].isChecked = false;
                  const isParentEmpty = checkIsParentEmpty(
                    availableList[elementIdx].subAttributes[index][
                    attributeObject.level_two_key
                    ]
                  );
                  if (isParentEmpty) {
                    availableList[elementIdx].subAttributes[
                      index
                    ].isIndeterminate = false;
                  }
                }
              });
              if(attributeObject.isMultiLevel) {
                tempAdvanceFilters = updateAdvFilterForMultiLevel(attributeObject, tempAdvanceFilters);
              }
            } else {
              availableList.forEach((element) => {
                element.subAttributes?.forEach((subElement) => {
                  if (subElement[element.level_two_key]?.length > 0) {
                    const idx = subElement[element.level_two_key].findIndex(
                      (item) =>
                        item[element.level_two_key_name] === itemToRemove
                    );
                    if (idx >= 0) {
                      subElement[element.level_two_key][idx].isChecked = false;
                      subElement[element.level_two_key][idx][element.level_three_key]?.forEach(l3El => l3El.isChecked = false);
                      delete tempAdvanceFilters[element.level_three_key];
                      subElement.isChecked = false;
                      const inderterminateList = subElement[
                        element.level_two_key
                      ].filter((item) => item.isIndeterminate || item.isChecked);
                      subElement.isIndeterminate = inderterminateList.length === 0? false : subElement.isIndeterminate;
                    } else {
                      if(element.level_three_key) {
                        let found = false;
                        subElement[element.level_two_key]?.forEach(subsubEle => {
                          const idx1 = subsubEle[element.level_three_key]?.findIndex(
                            (item) =>
                              item[element.level_three_key_name] === itemToRemove
                          );
                          if(idx1 > -1) {
                            found = true;
                            subsubEle[element.level_three_key][idx1].isChecked = false;
                            const inderterminateList1 = subsubEle[
                              element.level_three_key
                            ].filter((item) => item.isIndeterminate || item.isChecked);
                            subsubEle.isIndeterminate = inderterminateList1.length === 0? false : subsubEle.isIndeterminate;
                          }
                        });
                        if(found) {
                          subElement.isChecked = false;
                          const inderterminateList2 = subElement[
                            element.level_two_key
                          ].filter((item) => item.isIndeterminate || item.isChecked);
                          subElement.isIndeterminate = inderterminateList2.length === 0 ? false : subElement.isIndeterminate;
                        }
                      }
                    }
                  }
                });
                if(element.isMultiLevel) {
                  tempAdvanceFilters = updateAdvFilterForMultiLevel(element, tempAdvanceFilters);
                }
              });
            }
          }
        } else if (element === itemToRemove) {
          delete tempAdvanceFilters[element];

          availableList.forEach((element) => {
            const matchedIndex = element.subAttributes?.findIndex(
              (item) => item[element.level_one_key_name] === itemToRemove
            );
            if (matchedIndex >= 0) {
              element.subAttributes[matchedIndex].isChecked = false;
              if (
                element.subAttributes[matchedIndex][element.level_two_key]
                  .length > 0
              ) {
                element.subAttributes[matchedIndex][
                  element.level_two_key
                ].forEach((sub) => {
                  sub.isChecked = false;
                });
              }
            }
          });
        }
      }
      // for input type filters
      else if (typeof tempAdvanceFilters[element] === "string") {
        if (tempAdvanceFilters[element] === itemToRemove) {
          tempAdvanceFilters[element] = "";
        } else if (
          element === "study_duration_start" ||
          element === "study_duration_end"
        ) {
          const durations = itemToRemove.split("-");
          tempAdvanceFilters[element] =
            tempAdvanceFilters[element] === durations[0]
              ? ""
              : tempAdvanceFilters[element];
          tempAdvanceFilters[element] =
            tempAdvanceFilters[element] === durations[1]
              ? ""
              : tempAdvanceFilters[element];
        } else if (
          element === "publication_start_year" ||
          element === "publication_end_year"
        ) {
          const yearRange = itemToRemove.split("-");
          tempAdvanceFilters[element] =
            tempAdvanceFilters[element] === yearRange[0]
              ? ""
              : tempAdvanceFilters[element];
          tempAdvanceFilters[element] =
            tempAdvanceFilters[element] === yearRange[1]
              ? ""
              : tempAdvanceFilters[element];
        }

        if (
          tempAdvanceFilters[element] === undefined ||
          tempAdvanceFilters[element] === ""
        ) {
          delete tempAdvanceFilters[element];
        }
      }
    }

    dispatch(removeGlobalFilters(itemToRemove));
    dispatch(setAdvanceFilters(tempAdvanceFilters));
    sessionStorage.setItem(
      "advance_filters",
      JSON.stringify(tempAdvanceFilters)
    );
    dispatch(setAvailableFilterList(availableList));
  };

  const updateAdvFilterForMultiLevel = (attrData, advFilter) => {
    if(!attrData || attrData.subAttributes?.length < 1) return advFilter;
    let l1Select = [], l2Select = [], l3Select = [];
    attrData.subAttributes.forEach(i => {
      if(i.isChecked || i.isIndeterminate) {
        l1Select.push(i[attrData.level_one_key_name]);
        i[attrData.level_two_key]?.forEach(j => {
          if(j.isChecked || j.isIndeterminate) {
            l2Select.push(j[attrData.level_two_key_name]);
            j[attrData.level_three_key]?.forEach(k => {
              if(k.isChecked || k.isIndeterminate) {
                l3Select.push(k[attrData.level_three_key_name]);
              }
            });
          }
        });
      }
    });
    delete advFilter[attrData.level_one_key];
    delete advFilter[attrData.level_two_key];
    delete advFilter[attrData.level_three_key];
    if(l1Select.length > 0) {
      advFilter[attrData.level_one_key] = l1Select;
    } 
    if(l2Select.length > 0) {
      advFilter[attrData.level_two_key] = l2Select;
    } 
    if(l3Select.length > 0) {
      advFilter[attrData.level_three_key] = l3Select;
    }
    return advFilter;
  };

  const checkIsParentEmpty = (list) => {
    const selectedItemCount = list.filter(
      (listItem) => listItem.isChecked === true
    ).length;
    return selectedItemCount > 0 ? false : true;
  };

  const insightTabClicked = (key) => {
    if (key === "insights") {
      dispatch(setFilterState("closed"));
    }
  };

  const clearAll = () => {
    confirm({
      title: "Clear All",
      okText: "Clear",
      okType: "danger",
      content:
        "Are you sure you want to reset the application to default?",
      centered: true,

      onOk() {
        setSearchLocal("");
        setSearchTerm("");
        dispatch(setSearchTerm(""));
        sessionStorage.setItem("search_value", "");
        dispatch(addGlobalFilters([]));
        dispatch(setAdvanceFilters({}));
        dispatch(removeFromList({ selectKey: [], selectData: [] }));
        dispatch(setAvailableFilterList(globalRawList));
        dispatch(setSelectedStudyListData({ selectKey: [], selectData: [] }));
        dispatch(setInteractiveSelectedStudyListData({ selectKey: [], selectData: [] }));
        // window.location.reload();
        // onSearch("");
      },
    });
  };

  const collabratorView = {
    pointerEvents: 'none',
    opacity: 0.7
  };

  return (
    <>
      <div style={{ padding: '15px 0' }}>
        {/* Search & Filter Tab Section */}
        <div style={{ marginLeft: "15px" }}>
          <Row>
            <Col flex={4.5}>
              <Search
                disabled={!isStoryboardOwner}
                className="search-input"
                placeholder=""
                value={searchTermLocal}
                prefix={searchIcon("grey")}
                // onSearch={searchTermLocal?.length >= 2 && onSearch}
                onSearch={onSearch}
                onChange={onSearchChange}
                enterButton={<Button
                  type="primary"
                  className="common-ant-btn"
                  // disabled={searchTermLocal?.length <= 1}
                  disabled={!isStoryboardOwner}
                >
                  Search
                </Button>}
                defaultValue={searchTermLocal}
                allowClear={true} />
              <span style={!isStoryboardOwner ?collabratorView:null }>
                {appliedGlobalFilters.length >= 11 && !tagsExpanded && (
                  <span
                    style={{ color: "#27a6a4", cursor: 'pointer' }}
                    onClick={() => setTagsExpanded(true)}
                  >
                    &nbsp; Show All Criteria
                  </span>
                )}
                {tagsExpanded && (
                  <span
                    style={{ color: "#27a6a4", cursor: 'pointer' }}
                    onClick={() => setTagsExpanded(false)}
                  >
                    &nbsp; Show Less Criteria
                  </span>
                )}
              </span>
              <span className="search-result" style={!isStoryboardOwner ?collabratorView:null }>
                <strong className="result-text">{`${studyListTableData?.total_results !== undefined
                  ? studyListTableData?.total_results
                  : 0} results`}</strong>
              </span>
            </Col>
            <Col flex={0.5} style={!isStoryboardOwner ?collabratorView:null }>
              <Badge
                count={
                  advFilterRef ? advFilterRef?.current?.getFilterTotalCriteriaCount() : 5
                }
                color="#EE801A"
              >
                <Button onClick={() => advFilterRef.current.openAdvFilterModal()} style={{ marginLeft: 20 }}
                  icon={<Icon
                    component={FilterBtnIcon} />}>
                </Button>
              </Badge>
              <Button onClick={() => clearAll()} style={{ marginLeft: 20, verticalAlign: 'top' }}>Clear All</Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={!isStoryboardOwner ?collabratorView:null }>
                {(appliedGlobalFilters.length > 0 || extractedFilters.length > 0) && (
                  <div style={{ maxWidth: "100%" }} >
                    Criteria:
                    {!tagsExpanded && (
                      <>
                        {appliedGlobalFilters.slice(0, 10).map((item, index) => (
                          <Tag
                            key={`${item}${index}`}
                            style={{ marginLeft: 5, marginTop: 5 }}
                            closable="true"
                            onClose={() => removeFilter(item)}
                            color="geekblue"
                          >
                            {item}
                          </Tag>
                        ))}
                        <span>
                          {extractedFilters.length > 0 &&
                            extractedFilters.map((item, index) => (
                              <Tag
                                style={{ marginLeft: 5, marginTop: 5 }}
                                key={`${item}${index}`}
                                color="green"
                              >
                                {item}
                              </Tag>
                            ))}
                        </span>
                      </>
                    )}
                    {tagsExpanded && (
                      <>
                        {appliedGlobalFilters.map((item, index) => (
                          <Tag
                            key={`${item}${index}`}
                            style={{ marginLeft: 5, marginTop: 5 }}
                            closable="true"
                            onClose={() => removeFilter(item)}
                            color="geekblue"
                          >
                            {item}
                          </Tag>
                        ))}
                        <span>
                          {extractedFilters.length > 0 &&
                            extractedFilters.map((item, index) => (
                              <Tag
                                style={{ marginLeft: 5, marginTop: 5 }}
                                key={`${item}${index}`}
                                color="green"
                              >
                                {item}
                              </Tag>
                            ))}
                        </span>
                      </>
                    )}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {/* Explore & Insights Tab Section */}
      {/* 
      <Tabs
        defaultActiveKey="1"
        tabBarExtraContent={<CompareStudies />}
        id="parent-tab"
        onTabClick={(key) => insightTabClicked(key)}
      >
        <TabPane tab="Explore" key="explore" className="h-200">
          <ExploreTabPage />
        </TabPane>
        <TabPane tab="Insights" key="insights">
          <InsightsTabs />
        </TabPane>
        <TabPane tab="Storyboard" key="Storyboard">
          <StoryboardContainer />
        </TabPane>
      </Tabs> */}

      {/* advance filter modal */}
      <AdvancedFilterView ref={advFilterRef}></AdvancedFilterView>
    </>
  );
};

export default StudyLayoutContent;
