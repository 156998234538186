import React, { useState } from "react";
import { Input, Row, Col, Collapse } from "antd";
import { AudioOutlined } from "@ant-design/icons";
import { FAQ_STATIC_DATA } from "./../support-faq-data";
import "./SupportFAQ.scss";
import { searchIcon } from "../../../assets/icons/svg";

const { Panel } = Collapse;
const { Search } = Input;

const SupportFAQ = () => {
  const [FAQData, setFAQData] = useState(FAQ_STATIC_DATA);
  const [searchText, setSearchText] = useState();
  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: "#1890ff",
      }}
    />
  );

  const onSearchAction = (searchText) => {
    setSearchText(searchText);
    if (!searchText) {
      setFAQData([...FAQ_STATIC_DATA]);
      return;
    } else {
      const searchResult = FAQ_STATIC_DATA.filter((item) => {
        return (
          stripHtml(item.question)
            ?.toLowerCase()
            .includes(searchText?.toLowerCase()) ||
          stripHtml(item.answer)
            ?.toLowerCase()
            .includes(searchText?.toLowerCase())
        );
      });
      setFAQData([...searchResult]);
    }
  };

  const stripHtml = (html) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  const searchChange = (e) => {
    if (e.target.value == "") {
      setFAQData([...FAQ_STATIC_DATA]);
    }
  };

  const UpArrowIcon = () => (
    <>
      <svg
        width="13"
        height="8"
        viewBox="0 0 13 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.4 3L11.4 7.9L12.8 6.5L6.4 0.2L0 6.6L1.4 8L6.4 3Z"
          fill="#B2B0B6"
        />
      </svg>
    </>
  );

  const DownArrowIcon = () => (
    <>
      <svg
        width="14"
        height="9"
        viewBox="0 0 14 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.9 5.5L11.9 0.6L13.3 2L6.9 8.3L0.5 1.9L1.9 0.5L6.9 5.5Z"
          fill="#B2B0B6"
        />
      </svg>
    </>
  );

  const CollapseIcon = ({ isActive }) => (
    <>
      <span className="anticon anticon-right ant-collapse-arrow">
        {isActive ? <UpArrowIcon /> : <DownArrowIcon />}
      </span>
    </>
  );

  return (
    <div className="support-faq-container">
      <Row>
        <Col span={12}>
          <div className="support-faq-title">FAQ'S</div>
        </Col>
        <Col span={12}>
          <div>
            <Search
              id="searched"
              placeholder="Search"
              allowClear
              enterButton="Search"
              size="large"
              onSearch={onSearchAction}
              prefix={searchIcon("grey")}
              // suffix={suffix}
              style={{
                width: 300,
                float: "right",
                marginBottom: 12,
              }}
              onChange={(e) => searchChange(e)}
            />
          </div>
        </Col>
      </Row>

      <div className="fqa-container">
        <Collapse
          accordion
          collapsible="header"
          expandIconPosition="end"
          expandIcon={({ isActive }) => <CollapseIcon isActive={isActive} />}
        >
          {FAQData?.map((item) => {
            return (
              <Panel
                header={item.question}
                key={item.key}
                className="faq-heading "
              >
                <p
                  dangerouslySetInnerHTML={{ __html: item.answer }}
                  className="faq-answers"
                />
              </Panel>
            );
          })}
        </Collapse>
      </div>
    </div>
  );
};
export default SupportFAQ;
