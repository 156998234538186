import React, { useState, useEffect } from "react";
import { Breadcrumb, Table, Button, Modal } from "antd";
import "./ExcludedStudyContainer.scss";
import { useDispatch, useSelector } from "react-redux";
import StudyListTableColumns from "../../explore-tab/study-list-table/StudyListTableColumns";
import {
  fetchAnalysisById,
  getSearchResult,
  saveAnalysis,
} from "../../../services/InsightsViewAPI";
import { cloneDeep } from "lodash";
import {
  setExploreViewMode,
  setSelectedAnalysisData,
} from "../../../reducer/EICCommonSlice";
import {
  getTableHeightValue,
  showNotification,
} from "../../../shared/utils/CommonUtil";
import { PageMainBackIcon, RestoreIcon } from "../../../assets/icons/svg";
import { uniq, unionBy } from "lodash";

const { confirm } = Modal;

const ExcludedStudyContainer = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [archivedStudyListTableData, setArchivedStudyListTableData] = useState({
    columns: [],
    data: [],
    total_results: 0,
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [tableHeight, setTableHeight] = useState(getTableHeightValue(225, 515));

  const {
    EICCommon: {
      selectedAnalysisData,
      searchTerm,
      selectedAdvanceFilters,
      compareStudiesData,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    getArchivedList();
  }, [pageNumber]);

  useEffect(() => {
    window.addEventListener("resize", getTableHeight);
    return () => window.removeEventListener("resize", getTableHeight);
  }, []);

  const getTableHeight = () => {
    setTableHeight(getTableHeightValue(225, 515));
  };
  useEffect(() => {
    getTableHeight();
  }, []);

  const handleCloseArchiveAction = () => {
    dispatch(setExploreViewMode("study-list"));
  };

  const getArchivedList = async (newAnalysisData = undefined) => {
    let pmc_ids = newAnalysisData
      ? newAnalysisData.excluded_study_ids
      : selectedAnalysisData?.excluded_study_ids;
    if (!pmc_ids || pmc_ids.length < 1) {
      setArchivedStudyListTableData({
        data: [],
        total_results: 0,
      });
      setPageNumber(1);
      return;
    }

    let pageNo = pageNumber;
    if (newAnalysisData?.excluded_study_ids) {
      setPageNumber(1);
      pageNo = 1;
    }

    setLoading(true);
    try {
      const response = await getSearchResult("", pageNo, {
        pmc_id: pmc_ids,
      });
      if (response?.result?.data?.length) {
        setArchivedStudyListTableData(response.result);
      } else {
        console.log("No data!");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleRestoreAction = async () => {
    const restoreList = selectedData
      ?.filter((item) => {
        return selectedKeys.indexOf(item.key) === -1 ? false : true;
      })
      .map((i) => i.pm_id);
    if (restoreList.length < 1) return;

    const excludeIds = selectedAnalysisData?.excluded_study_ids.filter(
      (item) => !restoreList.includes(item)
    );
    confirm({
      title: (
        <>
          <strong className="confirm-title">Confirm Action</strong>
        </>
      ),
      okText: "Include",
      okType: "primary",
      icon: null,
      mask: true,
      autoFocusButton: "cancel",
      wrapClassName: "include-studies-confirm",
      content: (
        <span>
          Are you certain you’d like to include this study in your results? If
          included, this study will appear in any searches performed as part of
          this analysis or be included in any insights generated.
        </span>
      ),
      centered: true,
      onOk: async () => {
        const payload = {
          edit: true,
          analysis_id: selectedAnalysisData.analysis_id,
          search_text: searchTerm,
          study_filters: selectedAdvanceFilters,
          comparison_data: compareStudiesData.selectData.map(
            (item) => item.pm_id
          ),
          excluded_study_ids: [...excludeIds],
        };
        const response = await saveAnalysis(payload);
        if (response?.status?.toLowerCase() === "success") {
          const payload = {
            analysis_id: selectedAnalysisData.analysis_id,
          };
          const response = await fetchAnalysisById(payload);
          setSelectedKeys([]);
          if (response?.status?.toLowerCase() === "success") {
            const anaysisData = response?.result?.length
              ? response.result[0]
              : {};
            dispatch(setSelectedAnalysisData(anaysisData));
            getArchivedList(anaysisData);
          }
          showNotification(
            "success",
            "Success",
            "Successfully included studies."
          );
        }
      },
    });
  };

  return (
    <div className="archived-list-container page-content">
      <div className="archived-list-content">
        <Breadcrumb separator={<>&nbsp;</>} className={"archive-breadcrumb"}>
          <Breadcrumb.Item onClick={() => handleCloseArchiveAction()}>
            <a href="javascript:void(0)">
              <PageMainBackIcon />
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="excluded-studies-title">
            Excluded Studies
          </Breadcrumb.Item>
        </Breadcrumb>

        <p className="archived-studies-selected">
          {selectedKeys === undefined ? 0 : selectedKeys?.length} Studies
          Selected :
          <Button
            onClick={handleRestoreAction}
            style={{ marginLeft: 10 }}
            disabled={selectedKeys?.length < 1}
          >
            <RestoreIcon /> &nbsp;Include Studies
          </Button>
        </p>
        <div className="archived-list-table">
          <Table
            columns={StudyListTableColumns(archivedStudyListTableData.columns)}
            dataSource={archivedStudyListTableData.data}
            bordered
            loading={loading}
            scroll={
              archivedStudyListTableData?.total_results > 0 && {
                x: 4500,
                y: tableHeight,
              }
            }
            rowSelection={
              archivedStudyListTableData.total_results > 0
                ? {
                    selectedRowKeys: selectedKeys,

                    selections: [
                      {
                        key: "select",
                        text: "Clear Selection",
                        onSelect: () => {
                          setSelectedKeys([]);
                          setSelectedData([]);
                        },
                      },
                    ],

                    onSelect: (row, b, selectedRows) => {
                      let tempKeys = cloneDeep(selectedKeys);
                      let tempData = cloneDeep(selectedData);
                      const idx = tempKeys.indexOf(row.key);
                      if (idx > -1) {
                        tempKeys.splice(idx, 1);
                        tempData = tempData.filter(
                          (item) => item.key != row.key
                        );
                      } else {
                        tempKeys.push(row.key);
                        tempData.push(row);
                      }
                      setSelectedKeys([...tempKeys]);
                      setSelectedData([...tempData]);
                    },
                    onSelectAll: (isSelected) => {
                      const keyList = archivedStudyListTableData.data.map(
                        (item) => item.key
                      );
                      if (!isSelected) {
                        const newKeys = selectedKeys.filter(
                          (item) => !keyList.includes(item)
                        );
                        setSelectedKeys([...newKeys]);
                        const newData = selectedData.filter(
                          (item) => !keyList.includes(item.key)
                        );
                        setSelectedData([...newData]);
                      } else {
                        const uniqueKeys = uniq([...selectedKeys, ...keyList]);
                        setSelectedKeys([...uniqueKeys]);
                        const uniqueData = unionBy(
                          [...selectedData, ...archivedStudyListTableData.data],
                          "key"
                        );
                        setSelectedData([...uniqueData]);
                      }
                    },
                  }
                : false
            }
            pagination={{
              total: archivedStudyListTableData.total_results,
              pageSize: 10,
              showTotal: (total, range) =>
                `${range[0]} - ${range[1]} of ${total} studies `,
              showSizeChanger: false,
              onChange: (value) => {
                setPageNumber(value);
              },
              current: pageNumber,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ExcludedStudyContainer;
