import React, { useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";
import GeographicMap from "./GeographicMap";
import GeoPieChart from "./GeoPieChart";
import "./GeographicDistributionContainer.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  geoChartFilterStudiesByBreakdown,
  geoChartStudiesDataReducer,
  setLoadingGeoDataReducer,
  setGeoDistributionCleanupStatus,
  setCountryCode
} from "../../../reducer/EICCommonSlice";
import { getGeoMapData } from "../../../services/InsightsViewAPI";
import AddToStoryBoardView from '../../add-to-storyboard-view/AddToStoryBoardView';
import { PromiseSleep } from "../../../shared/utils/CommonUtil";

function GeographicDistributionContent() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {
    EICCommon: {
      geoChartData: {
        geoChartStudiesBreakDownData,
        geoChartStudiesData: { chart_1 },
        searchStudy,
        setLoadingGeoData,
      },
      searchTerm,
      selectedAdvanceFilters,
      excludedStudyIds,
    },
  } = useSelector((state) => state);

  const getGeoList = async (searchterm, val, advancefilters, excludedStudyIdList) => {
    dispatch(setLoadingGeoDataReducer(true));
    dispatch(geoChartStudiesDataReducer({}));
    dispatch(geoChartFilterStudiesByBreakdown([]));
    dispatch(setCountryCode(''));

    const data = await getGeoMapData(searchterm, val, advancefilters, excludedStudyIdList);
    dispatch(geoChartStudiesDataReducer(data));
    await PromiseSleep(500)
    dispatch(geoChartFilterStudiesByBreakdown(data));
    dispatch(setLoadingGeoDataReducer(false));
  };

  useEffect(() => {
    getGeoList(searchTerm, searchStudy, selectedAdvanceFilters, excludedStudyIds);
  }, [searchTerm, selectedAdvanceFilters, excludedStudyIds]);


  let cleanupStatus = useSelector((state) => state.EICCommon.cleanupGeoDistribution);
  if (cleanupStatus){
      console.log('cleanup GD');    
      dispatch(setGeoDistributionCleanupStatus(false));
  }

  return (
    <>
      <Row style={{marginTop: 5, marginBottom: 5, justifyContent:'end' ,display:'grid'}}>
        <Col span={3}>
          <AddToStoryBoardView elementID={'gd-snap'}></AddToStoryBoardView>
        </Col>
      </Row>
    <div id="gd-snap" className="geo-tab-container">
      <Spin spinning={setLoadingGeoData}>
        <Row>
          <Col span={12} style={{ padding: 18 }}>
            <Row>
              <h2>Studies By Geography</h2>
            </Row>
            <Row>
              <GeographicMap
                data={
                  geoChartStudiesBreakDownData?.length > 0
                    ? geoChartStudiesBreakDownData
                    : chart_1
                }
              />
            </Row>
          </Col>
          {chart_1 !== undefined && Object.keys(chart_1)?.length > 0 ? (
            <Col span={12}>
              <GeoPieChart data={geoChartStudiesBreakDownData}/>
            </Col>
          ) : (
            <div
              className="no-data"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              There is no data available to display based on applied criteria
            </div>
          )}
        </Row>
      </Spin>

      
    </div>
    </>
  );
}

export default GeographicDistributionContent;
