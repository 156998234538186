
const DateFormator = (date) => {
  const moment = require("moment");

  const d = new Date(date);

  return moment(d).format("DD MMM YYYY");
};

export default DateFormator;
