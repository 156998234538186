import React from "react";
import { Col, Modal, Row, Select } from "antd";

const therapyOptions = [
  "Oncology",
  "Neurological Diseases",
  "Inflammatory Bowel Diseases",
  "Rare Diseases",
];

const NotificationFilter = ({
  isVisible,
  toggleModal,
  handleTherapyChange,
  getNotificationsData,
  therapy,
  onCancelTherapyArea,
  onApplyTherapyArea,
}) => (
  <Modal
    centered={false}
    open={isVisible}
    style={{ top: "6.6rem", float: "right", marginRight: "1rem" }}
    width={350}
    onOk={() => {
      onApplyTherapyArea();
      toggleModal();
    }}
    onCancel={() => {
      onCancelTherapyArea();
      toggleModal();
    }}
    okText="Apply"
    cancelText="Cancel"
    closable={false}
    className="notification-modal"
    maskClosable={false}
  >
    <Row>
      <Col span={24}>
        <h4>
          <strong>Therapy Area</strong>
        </h4>
        <Select
          mode="multiple"
          className="add-notification-modal"
          maxTagCount="responsive"
          placeholder="Select"
          onChange={(value) => handleTherapyChange(value)}
          options={therapyOptions.map((item) => ({ value: item, label: item }))}
          value={therapy}
        />
      </Col>
    </Row>
  </Modal>
);
export default NotificationFilter;
