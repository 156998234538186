import { Modal, Button, Checkbox, Row, Col, Spin, Switch, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CancelIcon, LeftIcon, RightIcon, ModalBackBtnIcon, InfoIcon } from "../../../assets/icons/svg";
import { getReadFullTextData, getSearchResult, mapAdvanceFilters, getInteractiveSearch } from "../../../services/InsightsViewAPI";
import SectionContent from "../section-content/SectionContent";
import AddToStoryBoardView from "../../add-to-storyboard-view/AddToStoryBoardView";
import Icon from "@ant-design/icons";
import {
  deleteFromCompareStudies,
  setPagination,
  setStudyListTableData,
  deleteFromInteractiveCompareStudies,
  setInteractiveStudyListTableData,
  setInteractivePagination,
  toggleReadFullText
} from "../../../reducer/EICCommonSlice";
import { mapHighlightColor } from './../section-content/HighlightColors';
import { fullAttributeList } from './ExtractedAttributeList';
import './FullTextModal.scss';
import { isString } from 'lodash'

const FullTextModal = () => {
  const dispatch = useDispatch();

  const {
    EICCommon: {
      isReadFullTextModalConfig,
      compareStudiesData,
      studyListTableData,
      studyListPageNumber,
      searchTerm,
      selectedAdvanceFilters,
      compareStudiesDataInteractive,
      IteractiveStudyListTableData,
      InteractiveStudyListPageNumber,
      excludedStudyIds
    }
  } = useSelector((state) => state);

  const [openConfig, setOpenConfig] = useState(isReadFullTextModalConfig);
  const [fullTextData, setFullTextData] = useState({});
  const [sectionList, setSectionList] = useState([]);
  const [attributeList, setAttributeList] = useState(fullAttributeList);
  const [currentSelectedSectionIndex, setCurrentSelectedSectionIndex] = useState(0);
  const [appliedAttributes, setAppliedAttributes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isBoldExtracted, setIsBoldExtracted] = useState(false);

  const handleClose = () => {
    setOpenConfig({ isOpen: false, id: '', key: '', source: '' });
    setAppliedAttributes([]);
    setCurrentSelectedSectionIndex(0);
    setFullTextData({});
    setSectionList([]);
    dispatch(toggleReadFullText({ isOpen: false, id: 0, key: '', source: '' }));
  };

  const removeFromComparison = (config) => {
    dispatch(deleteFromCompareStudies(config.id));
    dispatch(deleteFromInteractiveCompareStudies(config.id));
  };

  useEffect(() => {
    if (attributeList.length > 0 && isReadFullTextModalConfig?.isOpen) {
      attributeList.map((item) => {
        if (selectedAdvanceFilters[item.inherit_key]?.length) {
          item.isInherited = true;
          item.isChecked = false;
          item.inheritedData = isString(selectedAdvanceFilters[item.inherit_key]) ?
            [selectedAdvanceFilters[item.inherit_key]] : selectedAdvanceFilters[item.inherit_key];
        } else if (item.inherit_key === 'phase') { // custom logic for Phase
          const filterPhase = selectedAdvanceFilters['study_type_level_2']?.filter(e => e?.toLowerCase().startsWith('phase'));
          if(filterPhase?.length > 0) {
            item.isInherited = true;
            item.isChecked = false;
            item.inheritedData = filterPhase;
          }
        } else {
          item.isInherited = false;
          item.inheritedData = [];
        }
      })
      const newMappedList = attributeList.filter((item) => item.isChecked === true).map((element) => element.attribute_key);
      setAppliedAttributes(newMappedList);
    }
    setOpenConfig(isReadFullTextModalConfig);
  }, [isReadFullTextModalConfig])

  useEffect(() => {
    const payload = { pm_id: openConfig.id };
    if (openConfig.isOpen) {
      getFullText(payload);
    }
  }, [openConfig])

  useEffect(() => {
    const newMappedList = attributeList.filter((item) => item.isChecked === true).map((element) => element.attribute_key);
    setAppliedAttributes(newMappedList);
  }, [attributeList]);

  useEffect(() => {
    if (compareStudiesData.selectKey?.length > 0) {
      setOpenConfig({ ...openConfig, id: compareStudiesData.selectData[0]['nct_id'], key: compareStudiesData.selectData[0]['key'] })
    }
  }, [compareStudiesData]);

  const getFullText = async (payload) => {

    if (payload.pm_id === '' || payload.pm_id === 0 || payload.pm_id === undefined)
      return;

    setLoading(true);

    try {
      const data = await getReadFullTextData(payload);
      const newSectionList = data.article_content.map((item) => item.section_name);
      setCurrentSelectedSectionIndex(0);
      setSectionList(newSectionList);
      setFullTextData(data);
    } catch (error) {
      setSectionList([]);
      setFullTextData({});
      setLoading(false);
    }
    setLoading(false);
  }

  const switchSection = (index) => {
    setCurrentSelectedSectionIndex(index);
  }
  useEffect(() => {
    if (document.getElementById("content-scroll")) {
      document.getElementById("content-scroll").scroll(0, 0)
    }
  }, [currentSelectedSectionIndex])

  const switchArticle = (switchTo, config) => {

    const studiesInCompare = compareStudiesData?.selectData?.map((item) => item.nct_id);

    if (compareStudiesData?.selectData?.length > 1) {
      const idx = studiesInCompare.findIndex((item) => item === config.id);

      let switchToIndex;
      if (switchTo === 'next')
        switchToIndex = idx !== studiesInCompare.length - 1 ? idx + 1 : 0;
      else
        switchToIndex = idx !== 0 ? idx - 1 : studiesInCompare.length - 1;

      setOpenConfig({ ...openConfig, id: compareStudiesData.selectData[switchToIndex]['nct_id'], key: compareStudiesData.selectData[switchToIndex]['key'] })
    }

    if (document.getElementById("content-scroll")) {
      document.getElementById("content-scroll").scroll(0, 0)
    }
  }

  const checkDisabled = (attribute) => {
    if ((appliedAttributes.length < 5) || attribute.isChecked) {
      return false;
    } else {
      return true;
    }
  }

  const checkRemoveSwitchDisabled = (btnType) => {
    if (compareStudiesData?.selectData?.length <= 1) return true;
    const idx = compareStudiesData?.selectData.findIndex((item) => item.nct_id === openConfig.id)
    if (btnType === 'next') {
      return idx === (compareStudiesData?.selectData.length - 1) ? true : false;
    } else if (btnType === 'previous') {
      return idx === 0 ? true : false;
    } else if (btnType === 'removeFromComparison') {
      return compareStudiesData?.selectData?.length <= 1 ? true : false;
    }
  };

  const checkStudyListSwitchDisabled = (btnType) => {
    if (!openConfig || !openConfig.source === 'single_study') return;
    if (studyListTableData?.data?.length <= 0) return true;
    const idx = studyListTableData?.data.findIndex((item) => item.nct_id === openConfig.id)
    if (btnType === 'next') {
      return (((studyListPageNumber - 1) * 10) + idx) < (studyListTableData?.total_results - 1) ? false : true;
    } else if (btnType === 'previous') {
      return (((studyListPageNumber - 1) * 10) + idx) <= 0 ? true : false;
    }
  };

  const switchStudyListArticle = async (switchTo, config) => {
    if (!openConfig || !openConfig.source === 'single_study') return;
    setLoading(true);

    const studiesList = studyListTableData?.data?.map((item) => item.nct_id);
    const idx = studiesList.findIndex((item) => item === config.id);

    let pmId = null;
    let studyKey = null;
    if (idx != 0 && switchTo === 'previous') {
      pmId = studyListTableData.data[idx - 1]['nct_id'];
      studyKey = studyListTableData.data[idx - 1]['key'];
    } else if (idx != (studiesList.length - 1) && switchTo === 'next') {
      pmId = studyListTableData.data[idx + 1]['nct_id'];
      studyKey = studyListTableData.data[idx + 1]['key'];
    } else {
      const pageNo = switchTo === 'previous' ? studyListPageNumber - 1 : studyListPageNumber + 1;
      dispatch(setPagination(pageNo));
      const data = await getSearchResult(
        searchTerm,
        pageNo,
        mapAdvanceFilters(selectedAdvanceFilters),
        excludedStudyIds
      );

      dispatch(setStudyListTableData(data.result));
      const nextIndex = switchTo === 'previous' ? data.result.data.length - 1 : 0;
      pmId = data.result.data[nextIndex]['nct_id'];
      studyKey = data.result.data[nextIndex]['key'];
    }
    if (pmId && studyKey) {
      setOpenConfig({ ...openConfig, id: pmId, key: studyKey })
      if (document.getElementById("content-scroll")) {
        document.getElementById("content-scroll").scroll(0, 0)
      }
    } else {
      setLoading(false);
    }
  }


  const onCheckAttribute = (attribute) => {
    const newAttributeList = [...attributeList];

    const selectedAttributeIndex = attributeList.findIndex((item) => {
      return item.attribute_name === attribute.attribute_name;
    })
    if (attribute.isChecked === undefined || attribute.isChecked === false) {
      newAttributeList[selectedAttributeIndex].isInherited = false;
      newAttributeList[selectedAttributeIndex].isChecked = true;
      setAttributeList(newAttributeList);
    } else {
      if (newAttributeList[selectedAttributeIndex]?.inheritedData?.length) { 
        newAttributeList[selectedAttributeIndex].isInherited = true;
      }
      newAttributeList[selectedAttributeIndex].isChecked = false;
      setAttributeList(newAttributeList);
    }
  }

  const maskTitle = (title) => {
    return title === 'abstract' ? 'Abstract' : title
  };

  const getInheritedTooltipContent = (attr) => {
    return (
      <>
        <strong>Inherited <br /> {attr?.attribute_name} ({attr?.inheritedData?.length})</strong><br /><br />
        {
          attr?.inheritedData?.join(", ")
        }
      </>
    );
  };

  //.......Interactive Compare Study functions starts here........
  const checkRemoveSwitchDisabledInteractiveCompare = (btnType) => {
    if (compareStudiesDataInteractive?.selectData?.length <= 1) return true;
    const idx = compareStudiesDataInteractive?.selectData.findIndex((item) => item.nct_id === openConfig.id)
    if (btnType === 'next') {
      return idx === (compareStudiesDataInteractive?.selectData.length - 1) ? true : false;
    } else if (btnType === 'previous') {
      return idx === 0 ? true : false;
    } else if (btnType === 'removeFromComparison') {
      return compareStudiesDataInteractive?.selectData?.length <= 1 ? true : false;
    }
  };

  const switchArticleInteractiveCompare = (switchTo, config) => {

    const studiesInCompare = compareStudiesDataInteractive?.selectData?.map((item) => item.nct_id);

    if (compareStudiesDataInteractive?.selectData?.length > 1) {
      const idx = studiesInCompare.findIndex((item) => item === config.id);

      let switchToIndex;
      if (switchTo === 'next')
        switchToIndex = idx !== studiesInCompare.length - 1 ? idx + 1 : 0;
      else
        switchToIndex = idx !== 0 ? idx - 1 : studiesInCompare.length - 1;

      setOpenConfig({ ...openConfig, id: compareStudiesDataInteractive.selectData[switchToIndex]['nct_id'], key: compareStudiesDataInteractive.selectData[switchToIndex]['key'] })
    }

    if (document.getElementById("content-scroll")) {
      document.getElementById("content-scroll").scroll(0, 0)
    }
  }
  //.......Interactive Compare Study functions ends here.........

  //.......Interactive Study List functions starts here........
  const checkInteractiveStudyListSwitchDisabled = (btnType) => {
    if (!openConfig || !openConfig.source === 'interactive_study_list') return;
    if (IteractiveStudyListTableData?.data?.length <= 0) return true;
    const idx = IteractiveStudyListTableData?.data.findIndex((item) => item.nct_id === openConfig.id)
    if (btnType === 'next') {
      return (((InteractiveStudyListPageNumber - 1) * 10) + idx) < (IteractiveStudyListTableData?.total_results - 1) ? false : true;
    } else if (btnType === 'previous') {
      return (((InteractiveStudyListPageNumber - 1) * 10) + idx) <= 0 ? true : false;
    }
  };
  const switchInteractiveStudyListArticle = async (switchTo, config) => {
    if (!openConfig || !openConfig.source === 'interactive_study_list') return;
    setLoading(true);

    const studiesList = IteractiveStudyListTableData?.data?.map((item) => item.nct_id);
    const idx = studiesList.findIndex((item) => item === config.id);

    let pmId = null;
    let studyKey = null;
    if (idx != 0 && switchTo === 'previous') {
      pmId = IteractiveStudyListTableData.data[idx - 1]['nct_id'];
      studyKey = IteractiveStudyListTableData.data[idx - 1]['key'];
    } else if (idx != (studiesList.length - 1) && switchTo === 'next') {
      pmId = IteractiveStudyListTableData.data[idx + 1]['nct_id'];
      studyKey = IteractiveStudyListTableData.data[idx + 1]['key'];
    } else {
      const pageNo = switchTo === 'previous' ? IteractiveStudyListTableData - 1 : IteractiveStudyListTableData + 1;
      dispatch(setInteractivePagination(pageNo));
      const data = await getInteractiveSearch(
        searchTerm,
        pageNo,
        mapAdvanceFilters(selectedAdvanceFilters)
      );

      dispatch(setInteractiveStudyListTableData(data.result));
      const nextIndex = switchTo === 'previous' ? data.result.data.length - 1 : 0;
      pmId = data.result.data[nextIndex]['nct_id'];
      studyKey = data.result.data[nextIndex]['key'];
    }
    if (pmId && studyKey) {
      setOpenConfig({ ...openConfig, id: pmId, key: studyKey })
      if (document.getElementById("content-scroll")) {
        document.getElementById("content-scroll").scroll(0, 0)
      }
    } else {
      setLoading(false);
    }
  }
   //.......Interactive Study List functions ends here........

  return (

    <Modal
      title={
        <>
          {
            fullTextData?.article_metadata &&
            <>
              <Row>
                <Col span={21}>
                  <Icon component={ModalBackBtnIcon} className="info-icon" onClick={handleClose} /> &nbsp;
                  {fullTextData?.article_metadata?.article_title} &nbsp;
                </Col>
                <Col span={3}>
                  <span className="data-source">Source: {isReadFullTextModalConfig?.dataSource}</span>
                </Col>
              </Row>
            </>
          }
        </>
      }
      centered
      open={openConfig.isOpen}
      okButtonProps={{ style: { display: 'none' } }}
      closable={false}
      keyboard={false}
      destroyOnClose={true}
      width={1200}
      wrapClassName={'full-text-modal'}
      footer={[
        <>{(openConfig.source === 'compare_studies' || openConfig.source === 'interactive_compare_studies') && <Button disabled={checkRemoveSwitchDisabled('removeFromComparison')} onClick={() => removeFromComparison(openConfig)} style={{ color: '#27A6A4' }}><CancelIcon />&nbsp; Remove from Comparison</Button>}</>,
        <div style={{ display: 'inline-block', marginLeft: 20, marginRight: 20, marginTop: 5 }}>
          <AddToStoryBoardView elementID={'full-text-snap'}></AddToStoryBoardView>
        </div>,
        <Button onClick={handleClose} type="primary">Close</Button>
      ]}
    >
        <Spin spinning={loading} size="large">
          <div id="full-text-snap" style={{ height: 500, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div id="attribute-scroll" style={{ width: 200, maxHeight: '100%', borderRight: '#000', overflowY: 'auto' }}>
              <p>
                <Switch size="small" checked={isBoldExtracted} onChange={setIsBoldExtracted} />&nbsp;&nbsp;Bold Extracted Content
              </p>
              <h4><strong>Attributes</strong></h4>
              {
                attributeList.map((item) => (
                  <React.Fragment key={item.attribute_key}>
                    <Checkbox style={{ marginTop: 10 }} disabled={checkDisabled(item)} checked={item.isChecked} indeterminate={item.isInherited} onChange={() => onCheckAttribute(item)}>
                      {item.isChecked || item.isInherited ?
                        <p style={{ display: 'inline', backgroundColor: mapHighlightColor(item.attribute_key, appliedAttributes) }}>
                          {item.attribute_name}
                        </p> :
                        <p style={{ display: 'inline' }}>{item.attribute_name}</p>
                      }
                    </Checkbox>
                    {
                      item.isInherited &&
                      (
                        <>
                          <span>
                            <Tooltip placement="right" title={getInheritedTooltipContent(item)} overlayStyle={{ minWidth: '120px', maxWidth: '800px' }}>
                              <Icon
                                component={InfoIcon}
                                style={{ paddingLeft: "5px", cursor: "pointer" }}
                              />
                            </Tooltip>
                          </span>
                        </>
                      )
                    }
                    <br />
                  </React.Fragment>
                ))
              }
            </div>
            <div style={{ height: '100%', width: 900 }}>
              <div>
                {
                  openConfig.source === 'compare_studies' && <Row>
                    <Col span="2">
                      <Button disabled={checkRemoveSwitchDisabled('previous')} onClick={() => switchArticle('previous', openConfig)} style={{ border: 0, paddingLeft: 0 }}><LeftIcon />&nbsp; Prev</Button>
                    </Col>
                    <Col span="20"></Col>
                    <Col span="2">
                      <Button disabled={checkRemoveSwitchDisabled('next')} onClick={() => switchArticle('next', openConfig)} style={{ border: 0, paddingRight: 0 }}>Next &nbsp;<RightIcon /></Button>
                    </Col>
                  </Row>
                }
              </div>
              <div>
                {
                  openConfig.source === 'single_study' && <Row>
                    <Col span="2">
                      <Button disabled={checkStudyListSwitchDisabled('previous')} onClick={() => switchStudyListArticle('previous', openConfig)} style={{ border: 0, paddingLeft: 0 }}><LeftIcon />&nbsp; Prev</Button>
                    </Col>
                    <Col span="20"></Col>
                    <Col span="2">
                      <Button disabled={checkStudyListSwitchDisabled('next')} onClick={() => switchStudyListArticle('next', openConfig)} style={{ border: 0, paddingRight: 0 }}>Next &nbsp;<RightIcon /></Button>
                    </Col>
                  </Row>
                }
              </div>
              <div>
                {
                  openConfig.source === 'interactive_compare_studies' && <Row>
                    <Col span="2">
                      <Button disabled={checkRemoveSwitchDisabledInteractiveCompare('previous')} onClick={() => switchArticleInteractiveCompare('previous', openConfig)} style={{ border: 0, paddingLeft: 0 }}><LeftIcon />&nbsp; Prev</Button>
                    </Col>
                    <Col span="20"></Col>
                    <Col span="2">
                      <Button disabled={checkRemoveSwitchDisabledInteractiveCompare('next')} onClick={() => switchArticleInteractiveCompare('next', openConfig)} style={{ border: 0, paddingRight: 0 }}>Next &nbsp;<RightIcon /></Button>
                    </Col>
                  </Row>
                }
              </div>
              <div>
                {
                  openConfig.source === 'interactive_study_list' && <Row>
                   <Col span="2">
                      <Button disabled={checkInteractiveStudyListSwitchDisabled('previous')} onClick={() => switchInteractiveStudyListArticle('previous', openConfig)} style={{ border: 0, paddingLeft: 0 }}><LeftIcon />&nbsp; Prev</Button>
                    </Col>
                    <Col span="20"></Col>
                    <Col span="2">
                      <Button disabled={checkInteractiveStudyListSwitchDisabled('next')} onClick={() => switchInteractiveStudyListArticle('next', openConfig)} style={{ border: 0, paddingRight: 0 }}>Next &nbsp;<RightIcon /></Button>
                    </Col>
                  </Row>
                }
              </div>
              {
                fullTextData?.article_content?.length > 0 ?
                <>
                  <div className={'page-title-list-container'}>
                    {
                      sectionList.map((item, index) => (
                        <span key={`item${index}`} onClick={() => switchSection(index)}><a href='#' style={{ color: '#1A162899', fontWeight: 400 }} onClick={(e) => e.preventDefault()}>{index === currentSelectedSectionIndex ? <strong style={{ color: '#000000d9' }}>{maskTitle(item)}</strong> : maskTitle(item)}</a> {index !== sectionList.length - 1 ? '|' : null} &nbsp;</span>
                      ))
                    }
                  </div>
                  <div
                    id="content-scroll"
                    style={{ marginTop: 10, maxHeight: '84%', overflowY: 'auto', overflowX: 'hidden' }}>
                    <h3><strong>{maskTitle(fullTextData?.article_content?.[currentSelectedSectionIndex]?.section_name)}</strong></h3>
                    <SectionContent sectionData={fullTextData?.article_content?.[currentSelectedSectionIndex]} appliedAttributes={appliedAttributes} attributeList={attributeList} boldHighlight={isBoldExtracted} />
                  </div>
                </>
                :
                <div>
                  <h3 style={{ textAlign: 'center', paddingTop: '20%'}}>{loading ? "Fetching article details" : "Data not available for the article"}</h3>
                </div>
              }
            </div>
          </div>
        </Spin>
    </Modal>
  );
};

export default FullTextModal;