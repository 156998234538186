import React, { useState } from "react";
import { Tooltip } from "antd";
import Paragraph from "antd/es/typography/Paragraph";

const TooltipParagraph = ({
  children,
  ellipsis,
  ...props
}) => {
  const [truncated, setTruncated] = useState(false);

  return (
    <Tooltip title={truncated ? children : undefined} placement="right" overlayStyle={{maxWidth: '500px'}}>
      <Paragraph
        {...props}
        ellipsis={{ ...ellipsis, onEllipsis: setTruncated }}
      >
        <>{children}</>
      </Paragraph>
    </Tooltip>
  );
};

export default TooltipParagraph;
