import React, { memo } from "react";
import moment from "moment";
import Icon from "@ant-design/icons";
import {
  StudyAddedIcon,
  StudyModifiedIcon,
  StudyRemovedIcon,
} from "../../assets/icons/svg";

const NotificationBody = ({ notificationsData, sort, totalNotifications }) => {
  const datesArr =
    sort === "ASC"
      ? Object.keys(notificationsData)
      : Object.keys(notificationsData).reverse();
  const dateFormat = "DD MMM, YY";
  const formatDate = (dateValue) => moment(dateValue).format(dateFormat);
  const getStudyIcon = (item) => {
    if (item.is_study_added) {
      return StudyAddedIcon;
    } else if (item.is_study_updated) {
      return StudyModifiedIcon;
    } else {
      return StudyRemovedIcon;
    }
  };
  const getStudyData = (date) => {
    const therapyObjArr = notificationsData?.[date];
    let data = [];
    for (
      let therapyIndex = 0;
      therapyIndex < therapyObjArr.length;
      therapyIndex++
    ) {
      const therapyObj = therapyObjArr[therapyIndex];
      const therapyKeys = Object.keys(therapyObjArr[therapyIndex]);
      for (let i = 0; i < therapyKeys.length; i++) {
        data.push(
          <>
            {therapyObj[therapyKeys[i]]?.map((item) => (
              <div className="notification-study-title">
                <Icon
                  component={getStudyIcon(item)}
                  style={{ marginRight: "5px" }}
                />
                {item?.study_name}
              </div>
            ))}
            <div className="notification-tag">
              <span className="notification-tag-text">{therapyKeys[i]}</span>
            </div>
          </>
        );
      }
    }
    return data;
  };
  return (
    <div className="notification-body">
      {totalNotifications !== 0 ? (
        datesArr.map((date) => {
          return (
            <div className="notification-body-content">
              <div className="notification-date">{formatDate(date)}</div>
              {getStudyData(date)}
            </div>
          );
        })
      ) : (
        <div
          className="no-data"
          style={{
            display: "flex",
            justifyContent: "left",
            marginLeft: "-15px",
          }}
        >
          There is no data available to display based on applied criteria
        </div>
      )}
    </div>
  );
};

export default memo(NotificationBody);
