import { notification } from "antd";
import { IDM_URL } from "../../environments/environments";
import { NOTIFICATION_DURATION_IN_SEC } from "../../environments/constants";

export const CurrentDateTime = () => {
  const moment = require("moment");

  const d = new Date();

  return moment(d).format("DD-MM-YYYY hh:mm:ss ZZ");
};

export const showNotification = (type, title, message) => {
  if (notification[type]) {
    notification[type]({
      message: title,
      description: message,
      duration: NOTIFICATION_DURATION_IN_SEC,
    });
  }
};

export const UserUnautherizedAction = () => {
  window.location.replace(IDM_URL);
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const containsWhitespace = (str) => {
  let TextHaveSpace = str.indexOf(" ") >= 0;
  return TextHaveSpace == true ? str.split(" ").join("_") : str;
};

const CapitalizeKeys = ["age", "ethnicity", "sex", "endpoints", "biomarkers"];
const MultiLevelKeys = [
  "region",
  "phase",
  "therapy_area",
  "study_type",
  "study_subtype",
];

export const FormatAdvFilterDataForDynamicChart = (
  advFilterData,
  selectedFilters
) => {
  return Object.keys(selectedFilters).reduce((acc, key) => {
    if (CapitalizeKeys.indexOf(key) > -1) {
      acc[capitalizeFirstLetter(key)] = selectedFilters[key];
      return acc;
    } else if (MultiLevelKeys.indexOf(key) > -1) {
      const selectionData = AdvFilterMultiLevelDataSelection(
        advFilterData,
        key,
        selectedFilters[key]
      );
      acc = { ...acc, ...selectionData };
      return acc;
    } else {
      acc[key.toLowerCase()] = selectedFilters[key];
      return acc;
    }
  }, {});
};

const AdvFilterMultiLevelDataSelection = (
  advFilterData,
  filterKey,
  filterValue
) => {
  const result = {};
  if (!advFilterData || Object.keys(advFilterData).length < 1 || !filterKey)
    return result;
  result[filterKey] = filterValue;
  switch (filterKey) {
    case "therapy_area":
      advFilterData?.therapy_area?.forEach((el) => {
        if (el.therapy_area_name == filterValue) {
          result["indication"] = el.indication.map((i) => i.indication_name);
        }
      });
      break;
    case "study_type":
      delete result[filterKey];
      result["study_type_level_1"] = filterValue;
      result["study_type_level_2"] = [];
      result["study_type_level_3"] = [];
      advFilterData?.study_type_category?.forEach((el) => {
        if (el.study_type_level_1_name == filterValue) {
          el.study_type_level_2?.forEach((el2) => {
            result["study_type_level_2"].push(el2.study_type_level_2_name);
            if (el2.study_type_level_3?.length) {
              const l3Values = el2.study_type_level_3.map(
                (el3) => el3.study_type_level_3_name
              );
              result["study_type_level_3"] = [
                ...result["study_type_level_3"],
                ...l3Values,
              ];
            }
          });
        }
      });
      if (result["study_type_level_2"].length === 0) {
        delete result["study_type_level_2"];
      }
      if (result["study_type_level_3"].length === 0) {
        delete result["study_type_level_3"];
      }
      break;
    case "region":
      advFilterData?.region?.forEach((el) => {
        if (el.region_name == filterValue) {
          result["country"] = el.country.map((i) => i.country_name);
        }
      });
      break;
    case "phase":
      break;

    default:
      break;
  }
  return result;
};

export const PromiseSleep = (m) => new Promise((r) => setTimeout(r, m));

//Neha - below logic is for interactive insights  for Evidence view
export const FormatAdvFilterDataForEvidenceChart = (
  advFilterData,
  selectedFilters
) => {
  console.log("selectedFilters", selectedFilters);
  return Object.keys(selectedFilters).reduce((acc, key) => {
    if (CapitalizeKeys.indexOf(key) > -1) {
      acc[capitalizeFirstLetter(key)] = selectedFilters[key];
      return acc;
    } else if (MultiLevelKeys.indexOf(key) > -1) {
      const selectionData = AdvFilterMultiLevelDataSelectionEvidence(
        advFilterData,
        key,
        selectedFilters[key]
      );
      acc = { ...acc, ...selectionData };
      return acc;
    } else {
      acc[key.toLowerCase()] = selectedFilters[key];
      return acc;
    }
  }, {});
};

const studyTypeLevel1 = [
  "Review",
  "Observational",
  "Clinical Trial",
  "Meta-Analysis",
  "Pre-clinical",
];

const studyTypeLevel2 = [
  "Case Report & Series",
  "Case Control",
  "Cohort Study",
  "Phase II",
  "Phase I",
  "Phase III",
  "Phase IV",
];
const studyTypeLevel3 = ["Retrospective", "Prospective"];

const AdvFilterMultiLevelDataSelectionEvidence = (
  advFilterData,
  filterKey,
  filterValue
) => {
  const result = {};
  if (!advFilterData || Object.keys(advFilterData).length < 1 || !filterKey)
    return result;
  result[filterKey] = filterValue;
  switch (filterKey) {
    // case 'therapy_area':
    //     advFilterData?.therapy_area?.forEach(el => {
    //         if(el.therapy_area_name == filterValue) {
    //             result['indication'] = el.indication.map(i => i.indication_name);
    //         }
    //     });
    //     break;
    case "study_type":
      delete result[filterKey];
      if (studyTypeLevel1.includes(filterValue[0])) {
        result["study_type_level_1"] = filterValue;
      } else if (studyTypeLevel2.includes(filterValue[0])) {
        result["study_type_level_2"] = filterValue;
      } else if (studyTypeLevel3.includes(filterValue[0])) {
        result["study_type_level_3"] = filterValue;
      }
      break;
    case "study_subtype":
      delete result[filterKey];
      if (studyTypeLevel1.includes(filterValue[0])) {
        result["study_type_level_1"] = filterValue;
      } else if (studyTypeLevel2.includes(filterValue[0])) {
        result["study_type_level_2"] = filterValue;
      } else if (studyTypeLevel3.includes(filterValue[0])) {
        result["study_type_level_3"] = filterValue;
      }
      break;
    case "region":
      advFilterData?.region?.forEach((el) => {
        if (el.region_name == filterValue) {
          result["country"] = el.country.map((i) => i.country_name);
        }
      });
      break;
    case "phase":
      break;

    default:
      break;
  }
  return result;
};

export const getTableHeightValue = (minHeight, heightToReduce) => {
  const height = window.innerHeight - heightToReduce;
  return height > minHeight ? height : minHeight;
};
