import React, { useEffect, useState } from "react";
import { Col, Row, Button, Modal, Tooltip, Spin } from "antd";
import { Select } from "antd";
import Icon from "@ant-design/icons";
import Highcharts from "react-highcharts";
import { DrugInfo, FacetSearchIcon } from "../../../assets/icons/svg";
import { DatePicker, Space } from "antd";
import { getTimelineTrendChartData, getInteractiveSearch } from "./../../../services/InsightsViewAPI";
import { useDispatch, useSelector } from "react-redux";
import {
  setTimelineChartData,
  setTimeLineSubCatData,
  setTimelineTrendCleanupStatus,
  setInteractiveFilters,
} from "../../../reducer/EICCommonSlice";
import moment from "moment";
import "./TimelineTrendsContainer.scss";
import AddToStoryBoardView from "../../add-to-storyboard-view/AddToStoryBoardView";
import { mapAdvanceFilters } from '../../../services/InsightsViewAPI';
import cloneDeep from "lodash/cloneDeep";
import { useNavigate } from "react-router-dom";
import { FormatAdvFilterDataForDynamicChart } from "../../../shared/utils/CommonUtil";

const { Option } = Select;
const { confirm } = Modal;
const { RangePicker } = DatePicker;
const dateFormat = "MMM YYYY";
const zoom = (window.outerWidth / window.innerWidth) * 100;

function TimelineTrendsContent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [chartModalVisible, setChartModalVisible] = useState(false);
  const [lineChartData, setLineChartData] = useState("");
  const [viewbyCatSelect, setViewbyCatSelect] = useState("Endpoints");
  const [viewbySubCatSelect, setViewbySubCatSelect] = useState([]);
  const [lineChartSeries, setLineChartSeries] = useState("");
  const [lineChartCategories, setLineChartCategories] = useState("");

  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);

  const [currentStartDate, setCurrentStartDate] = useState("");
  const [currentEndDate, setCurrentEndDate] = useState("");

  let dropDownList = useSelector((state) => state.EICCommon.dropDownList);
  let selectedAdvanceFilters = useSelector(
    (state) => state.EICCommon.selectedAdvanceFilters
  );
  const {
    EICCommon: {
      InteractiveStudyListPageNumber,
      searchTerm,
      excludedStudyIds,
      advanceFilterDropdownList,
    },
  } = useSelector((state) => state);

  let cleanupStatus = useSelector(
    (state) => state.EICCommon.cleanupEvidenceLandscape
  );

  if (cleanupStatus) {
    dispatch(setTimelineTrendCleanupStatus(false));
  }

  const [chartReload, setChartReload] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", rerenderChart);
    return () => window.removeEventListener("resize", rerenderChart);
  }, []);

  const rerenderChart = () => {
    setChartReload(true);
    setTimeout(() => {
      setChartReload(false);
    }, 100);
  };

  const lineChartResponse = async () => {
    setLoading(true);
    try {

      //checking if the value contains white space or not
      if (viewbyCatSelect !== "") {
        var tempViewByValue = containsWhitespace(viewbyCatSelect);
      }
      const response = await getTimelineTrendChartData(
        searchTerm,
        selectedAdvanceFilters,
        tempViewByValue,
        [],
        excludedStudyIds
      );
      setLoading(false);

      if (
        response?.chart_1?.chart_data?.series?.length &&
        response?.chart_1?.chart_data?.categories?.length
      ) {
        setLineChartData(response);
        setLineChartSeries(response?.chart_1?.chart_data?.series);
        setLineChartCategories(response?.chart_1?.chart_data?.categories);

        // setStartDate(moment(response?.chart_1?.start_date).format(dateFormat));
        // setEndDate(moment(response?.chart_1?.end_date).format(dateFormat));

        setViewbySubCatSelect(response?.chart_1?.view_by_values.slice(0, 7));
        dispatch(setTimelineChartData(response));
        dispatch(setTimeLineSubCatData(response?.chart_1?.view_by_values));
        setLoading(false);
      } else {
        setLineChartData("");
        setLineChartSeries("");
        setLineChartCategories("");
        // setStartDate("");
        // setEndDate("");
        setViewbySubCatSelect([]);
      }
    } catch (error) {
      setLineChartData("");
      setLineChartSeries("");
      setLineChartCategories("");
      // setStartDate("");
      // setEndDate("");
      setViewbySubCatSelect([]);
    }
  };

  useEffect(() => {
    getCurrentDate();
  },[selectedAdvanceFilters]);

  const getCurrentDate = () => {
    let currentDate = new Date();
    let _mmm = (currentDate.getMonth() + 1).toString();
    let _yyyy = currentDate.getFullYear().toString();

    let mmm = moment(_mmm).format("MMM");

    //Neha- if user selected publication date in advance filter then it become default date for date picker
    if (selectedAdvanceFilters?.publication_end_year && selectedAdvanceFilters?.publication_start_year){
      let publication_start_year = 'Jan' + ' ' + selectedAdvanceFilters.publication_start_year;
      let publication_end_year = 'Dec' + ' ' + selectedAdvanceFilters.publication_end_year;

      setCurrentStartDate(publication_start_year);
      setCurrentEndDate(publication_end_year);

    } else {
      let _currentDate = mmm + ' ' + _yyyy;
      setCurrentStartDate(_currentDate);
      setCurrentEndDate(_currentDate);
    }  
  }

  useEffect(() => {
    lineChartResponse();
  }, [selectedAdvanceFilters, searchTerm, excludedStudyIds]);

  const disabledDate = (current) => {
    let start_date = moment(lineChartData?.chart_1?.start_date).format(
      dateFormat
    );
    let end_date = moment(lineChartData?.chart_1?.end_date).format(dateFormat);

    return current.isAfter(end_date) || current.isBefore(start_date);
  };

  const containsWhitespace = (str) => {
    let TextHaveSpace = str.indexOf(" ") >= 0;
    return TextHaveSpace === true ? str.split(" ").join("_") : str;
  };

  const handleChange = async (value, cat, date) => {
    setLoading(true);
    let ViewByValue = viewbyCatSelect;

    if (cat === "viewby") {
      ViewByValue = value;
    } else if (cat === "viewbysubcat") {
      setViewbySubCatSelect(value);
    } else if (date === "dateRange") {
      // setStartDate(cat[0]);
      // setEndDate(cat[1]);

      setCurrentStartDate(cat[0]);
      setCurrentEndDate(cat[1]);

      // let start = new Date(cat[0]).valueOf();
      // let end = new Date(cat[1]).valueOf();
      let start =moment(cat[0]).subtract(1, 'days').utc().valueOf();
      let end = moment(cat[1]).utc().valueOf();

      const filtered_response = lineChartData.chart_1.chart_data.series.map(
        (item) => ({
          ...item,
          data: item.data.filter((child) => {
            return start < child[0] && end > child[0];
          }),
        })
      );
      const categories = lineChartData.chart_1.chart_data.categories.filter(
        (item) =>  {
          return start < item && end > item;
        }
        
      );

      // lineChartCategories
      setLineChartSeries(filtered_response);
      setLineChartCategories(categories);
      setLoading(false);
    }
    //checking if the value contains white space or not
    if (ViewByValue !== "") {
      var tempViewByValue = containsWhitespace(ViewByValue);
    }

    if (cat === "viewby") {
      try {
        const response = await getTimelineTrendChartData(
          searchTerm,
          selectedAdvanceFilters,
          tempViewByValue,
          cat === "viewby" ? [] : viewbySubCatSelect,
          excludedStudyIds
        );

        setViewbyCatSelect(value);
        setViewbySubCatSelect(
          response?.chart_1?.view_by_values.slice(0, 7)
        );

        if (
          response?.chart_1?.chart_data?.series?.length &&
          response?.chart_1?.chart_data?.categories?.length
        ) {
          setLineChartData(response);

          setLineChartSeries(response?.chart_1?.chart_data?.series);
          setLineChartCategories(response?.chart_1?.chart_data?.categories);
          // setStartDate(
          //   moment(response?.chart_1?.start_date).format(dateFormat)
          // );
          // setEndDate(moment(response?.chart_1?.end_date).format(dateFormat));
          // dispatch(setTimelineChartData(response));

          setCurrentStartDate(moment(response?.chart_1?.start_date).format(dateFormat));
          setCurrentEndDate(moment(response?.chart_1?.end_date).format(dateFormat)); 
          getCurrentDate();

          setLoading(false);

          // if (cat === "viewby") {
          //   setViewbyCatSelect(value);
          //   setViewbySubCatSelect(
          //     response?.chart_1?.view_by_values.slice(0, 7)
          //   );
          // }
        } else {
          setLineChartData("");
          setLineChartSeries("");
          setLineChartCategories("");
          // setStartDate("");
          // setEndDate("");
          // setViewbySubCatSelect([]);
        }
      } catch (error) {
        setLoading(false);
        setLineChartData("");
        setLineChartSeries("");
        setLineChartCategories("");
        // setStartDate("");
        // setEndDate("");
        setViewbySubCatSelect([]);
      }
    }
    //Neha - when user click on clear icon in date picker
    if(value == null){
      setLoading(true);
      try {
        const response = await getTimelineTrendChartData(
          searchTerm,
          selectedAdvanceFilters,
          tempViewByValue,
          // cat === "viewby" ? [] : viewbySubCatSelect
          viewbySubCatSelect,
          excludedStudyIds
        );

        // setViewbyCatSelect(value);
        setViewbySubCatSelect(
          response?.chart_1?.view_by_values.slice(0, 7)
        );

        if (
          response?.chart_1?.chart_data?.series?.length &&
          response?.chart_1?.chart_data?.categories?.length
        ) {
          setLineChartData(response);

          setLineChartSeries(response?.chart_1?.chart_data?.series);
          setLineChartCategories(response?.chart_1?.chart_data?.categories);

          setCurrentStartDate(moment(response?.chart_1?.start_date).format(dateFormat));
          setCurrentEndDate(moment(response?.chart_1?.end_date).format(dateFormat)); 
          getCurrentDate();

          setLoading(false);
        } else {
          setLineChartData("");
          setLineChartSeries("");
          setLineChartCategories("");
        }
      } catch (error) {
        setLoading(false);
        setLineChartData("");
        setLineChartSeries("");
        setLineChartCategories("");
        setViewbySubCatSelect([]);
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const onFilterClose = async (value) => {
    if (value === false) {
      setLoading(true);

      try {
        if (viewbyCatSelect !== "") {
          var tempViewByValue = containsWhitespace(viewbyCatSelect);
        }
        const response = await getTimelineTrendChartData(
          searchTerm,
          selectedAdvanceFilters,
          tempViewByValue,
          viewbySubCatSelect,
          excludedStudyIds
        );

        if (
          response?.chart_1?.chart_data?.series?.length &&
          response?.chart_1?.chart_data?.categories?.length
        ) {
          setLineChartData(response);

          // let start = new Date(startDate).valueOf();
          // let end = new Date(endDate).valueOf();

          // let start = new Date(currentStartDate).valueOf();
          // let end = new Date(currentEndDate).valueOf();
          let start =moment(currentStartDate).subtract(1, 'days').utc().valueOf();
          let end = moment(currentEndDate).utc().valueOf();

          const filtered_response = response.chart_1.chart_data.series.map(
            (item) => ({
              ...item,
              data: item.data.filter((child) => {
                return start < child[0] && end > child[0];
              }),
            })
          );
          const categories = lineChartData.chart_1.chart_data.categories.filter(
            (item) =>  {
              return start < item && end > item;
            }
            
          );
          setLineChartSeries(filtered_response);
          setLineChartCategories(categories);
          setLoading(false);

          if (viewbySubCatSelect.length === 0) {
            setViewbySubCatSelect(
              response?.chart_1?.view_by_values.slice(0, 7)
            );
          }
        } else {
          setLineChartData("");
          setLineChartSeries("");
          setLineChartCategories("");
          // setStartDate("");
          // setEndDate("");
          setViewbySubCatSelect([]);
        }
      } catch (error) {
        setLoading(false);
        setLineChartData("");
        setLineChartSeries("");
        setLineChartCategories("");
        // setStartDate("");
        // setEndDate("");
        setViewbySubCatSelect([]);
      }
      setLoading(false);
    }
  };

  //Neha - Dynamic and Interactive Charts 
  const handleInteractiveCharts = async (selectedViewByValue, dateRangeMin, dateRangeMax, isNavToExplore) => {
    var tempAdvanceFilter = cloneDeep(selectedAdvanceFilters);
    var _tempAdvanceFilters = mapAdvanceFilters(tempAdvanceFilter);
 
  //Neha - changing all the keys of an object to lower case
    var key, keys = Object.keys(_tempAdvanceFilters);
    var n = keys.length;
    var tempAdvanceFilters={}
    while (n--) {
      key = keys[n];
      tempAdvanceFilters[key.toLowerCase()] = _tempAdvanceFilters[key];
    }
    
    var viewBy = viewbyCatSelect == 'Data Source' ? 'data_source' : viewbyCatSelect.toLowerCase();

    //create payload for time filter
    var payload_1 = {};

      let start_date = moment(dateRangeMin).format("YYYY");
      let end_date = moment(dateRangeMax).format("YYYY");
     
      let filterKeys = Object.keys(tempAdvanceFilters);

      if ((filterKeys.indexOf(viewBy) > -1 ) || (filterKeys.indexOf('publication_end_year') > -1) || (filterKeys.indexOf('publication_start_year') > -1)) {
        for (let key in tempAdvanceFilters) {
          payload_1 = tempAdvanceFilters[viewBy]?.find((item) => item == selectedViewByValue);
        }

      tempAdvanceFilters = {
        ...tempAdvanceFilters,
        [viewBy]: payload_1 == undefined ? Array.isArray(selectedViewByValue) && selectedViewByValue.length > 1 ? selectedViewByValue : [selectedViewByValue] : [payload_1], 
        publication_start_year : start_date,
        publication_end_year : end_date, 
      }
    } 
    else { 
      tempAdvanceFilters = {
        ...tempAdvanceFilters,
        [viewBy] : Array.isArray(selectedViewByValue) && selectedViewByValue.length > 1 ? selectedViewByValue : [selectedViewByValue],
        publication_start_year : start_date,
        publication_end_year : end_date, 
      }
    }
   
      setLoading(true);

      //Neha - below logic is to change the filter mapping for advance filter
      var _tempAdvanceFilters = FormatAdvFilterDataForDynamicChart(advanceFilterDropdownList, tempAdvanceFilters);

      dispatch(setInteractiveFilters(_tempAdvanceFilters));
      setLoading(false);
   
    if (isNavToExplore){
      navigate("/home/explore");
    }
  };

  const showInteractiveConfirm = (seriesName, minTime, maxTime) => {
    confirm({
      title: 'Confirm changes',
      content: `Are you sure you want to leave this page? The changes you made will affect your overall results & add a filter ${viewbyCatSelect}`,
      okText: 'Move to Explore',
      okType: 'primary',
      cancelText: 'Continue on Insights',
      closable: true,
      icon: null,
      wrapClassName: 'interactive-filter-confirm',
      centered: true,   
      onOk() {
        handleInteractiveCharts(
          seriesName, minTime, maxTime, true
        );
      },
      onCancel(closeFn) {
        if(typeof(closeFn) === 'function' && closeFn?.name !== "") {
          handleInteractiveCharts(
            seriesName, minTime, maxTime, false
          );
          closeFn();
        }
      },
    });
  };

  const lineChartConfig = () => ({
    chart: {
      type: 'line',
      zoomType: 'x',
      height: (9 / 16 * 50) + '%'
    },
    title: "",
    subtitle: "",
    rangeSelector: {
    inputDateFormat: "%b %Y",
      inputPosition: {
        height: 100,
          align: 'left',
          x: 0,
          y: 0
      },
  },
    yAxis: {
      allowDecimals: false,
      title: {
        text: "# of Studies",
      },
    },

    xAxis: {
      events: {
        setExtremes: function(e) {
          showInteractiveConfirm(viewbySubCatSelect, e.min, e.max);
        }
    },
      zoomEnabled: true,
      type: "datetime",
      dateTimeLabelFormats: {
        month: "%b %Y",
      },
      time: {
        useUTC: true,
      },
      allowDecimals: false,
      title: {
        text: "Publication Year",
      },
      labels: {
        format: (lineChartCategories && lineChartCategories?.length < 5)? "{value:%b %Y}" : undefined
      }
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      x: 0,
      y: 0,
    },

    tooltip: {
      formatter: function () {
        return (
          (viewbyCatSelect) +
          " " +
          ":" +
          " " +
          this.series.name +
          "<br/>" +
          "Number of Studies : " +
          this.y +
          "<br/>" +
          "Time : " +
          moment(this.x).utc().format("MMM-YYYY")
        );
      },
    },

    plotOptions: {
      series: {
        cursor: "pointer",
        point: {
          events: {
            click: function () {
              showInteractiveConfirm(this.series.name, this.x, this.x);
            },
          },
        },
      },
    },
    colors: [
      "#027AFF",
      "#7CC0FF",
      "#C4E3FF",
      "#FF9836",
      "#FECA98",
      "#B2B0B6",
      "#716E79",
    ],
    series: lineChartSeries,
    responsive: {
      rules: [
        {
          condition: {
            // maxWidth: 500,
            maxWidth: 100,
          },
          chartOptions: {
            legend: {
              align: "center",
              verticalAlign: "bottom",
              x: 0,
              y: 0,
            },
          },
        },
      ],
    },
    credits: {
      enabled: false,
    },
  });

  return (
    <>
      <Row style={{ marginTop: 5, marginBottom: 5, justifyContent:'end' ,display:'grid' }}>
        <Col span={3}>
          <AddToStoryBoardView elementID={"tl-snap"}></AddToStoryBoardView>
        </Col>
      </Row>
      <Spin spinning={loading}>
        <div id="tl-snap" className="timeline-container">
          <div className="selection-container">
            <div className="line-chart-title">
              Studies by {viewbyCatSelect} Over Time
            </div>
            <div className="viewby-trend-selection-container">
              <span className="viewBy-title">View by</span>{" "}
              <span>
                <Tooltip title={`Select up to 7 ${viewbyCatSelect}`}>
                  <Icon
                    component={DrugInfo}
                    style={{ paddingLeft: "5px", cursor: "pointer" }}
                  />{" "}
                  :{" "}
                </Tooltip>
              </span>
              <Select
                value={viewbyCatSelect}
                style={{ width: 240, marginLeft: 6 }}
                onChange={(e) => handleChange(e, "viewby")}
              >
                {dropDownList &&
                  dropDownList.map((item) => (
                    <Option value={item}>{item}</Option>
                  ))}
              </Select>
              <Select
                mode="multiple"
                defaultValue={viewbySubCatSelect}
                value={viewbySubCatSelect}
                style={{ width: 240, marginLeft: 90, marginTop: 8 }}
                onChange={(e) => handleChange(e, "viewbysubcat")}
                onDropdownVisibleChange={(e) => onFilterClose(e)}
                maxTagCount={"responsive"}
              >
                {lineChartData?.chart_1?.view_by_values.map((option) => (
                  <Option
                    value={option}
                    disabled={
                      viewbySubCatSelect.length >= 7
                        ? viewbySubCatSelect.includes(option)
                          ? false
                          : true
                        : false
                    }
                  >
                    {option}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="drug-selection-timeline">
              <span className="viewBy-title">Time Range :</span>
              <Space direction="vertical">
                <RangePicker
                  picker="month"
                  disabledDate={disabledDate}
                  style={{ width: 270, marginLeft: 6 }}
                  onChange={(e, dateString) =>
                    handleChange(e, dateString, "dateRange")
                  }
                  // value={
                  //   startDate && endDate
                  //     ? [
                  //         moment(startDate, dateFormat),
                  //         moment(endDate, dateFormat),
                  //       ]
                  //     : ""
                  // }
                  value={
                    currentStartDate && currentEndDate
                      ? [
                          moment(currentStartDate, dateFormat),
                          moment(currentEndDate, dateFormat),
                        ]
                      : ""
                  }
                  format={dateFormat}
                />
              </Space>
              <div className="expand-line-chart">
                <Button onClick={() => setChartModalVisible(true)}>
                  <FacetSearchIcon />
                </Button>
              </div>
            </div>
          </div>

          {lineChartData?.chart_1?.chart_data?.categories?.length &&
          lineChartData?.chart_1?.chart_data?.series?.length ? (
            <div>
              { chartReload ? '' : 
                <Highcharts config={lineChartConfig("mainChart")} allowChartUpdate={false}/>
              }
            </div>
          ) : (
            <div className="no-data">
              There is no data available to display based on applied criteria
            </div>
          )}
        </div>
      </Spin>
      <Modal
        title={`Studies by ${viewbyCatSelect} Over Time`}
        centered
        visible={chartModalVisible}
        onOk={() => setChartModalVisible(false)}
        width={1200}
        onCancel={() => setChartModalVisible(false)}
        okText="Close"
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <Row>
          <Col span={24} id="timeline-chart-expand">
            <Highcharts config={lineChartConfig()} />
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default React.memo(TimelineTrendsContent);
