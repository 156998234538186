import { Card } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import ExcludeStudiesContainer from "../exclude-studies/ExcludeStudiesContainer";
import StudyListTable from "./study-list-table/StudyListTable";

function ExploreTabPage() {

  const {
    EICCommon: {
      exploreViewMode
    },
  } = useSelector((state) => state);

  return (
    <div>
      <Card
      >
        <ExcludeStudiesContainer />
        {
          exploreViewMode === 'study-list' &&
          <StudyListTable />
        }
      </Card>
    </div>
  );
}

export default ExploreTabPage;
