import React, { useState, useEffect } from 'react';
import { Button, Col, Modal, Row, Select, Tooltip } from 'antd';
import { StoryboardIcon } from '../../assets/icons/svg';
import { addToStoryBoard, getStoryBoardNameList } from '../../services/InsightsViewAPI';
import { useSelector, useDispatch } from "react-redux";
import html2canvas from "html2canvas";
import './AddToStoryBoardView.scss';
import { current } from '@reduxjs/toolkit';
import { toggleSaveNewAnalysis } from '../../reducer/EICCommonSlice';

const { Option } = Select;

const AddToStoryBoardView = (props) => {
    const dispatch = useDispatch();

    const { elementID, currentTab } = props;

    let SearchCriteria = useSelector((state) => state.EICCommon.searchTerm);

    const [loading, setLoading] = useState(false);
    const [addToStoryboardModalConfig, setAddToStoryboardModalConfig] = useState({ isVisible: false });
    const [storyBoardList, setStoryBoardList] = useState([]);
    const [selectedStoryBoardList, setSelectedStoryBoardList] = useState([]);
    const selectedAnalysisData = useSelector(
        (state) => state.EICCommon.selectedAnalysisData
    );

    useEffect(() => {
        if (selectedAnalysisData?.analysis_id) {
            getStoryBoard();
        }
    }, [selectedAnalysisData]);

    const getStoryBoard = async () => {
        if (!selectedAnalysisData || !selectedAnalysisData.analysis_id) return;
        const payload = {
            analysis_id: selectedAnalysisData?.analysis_id
        }
        const data = await getStoryBoardNameList(payload);
        setStoryBoardList(data);
        //Neha - added the below condition if In case where only one Storyboard section is there then it shloud be by default selected
        if (data?.storyboards?.[0].sections.length == 1) {
            setSelectedStoryBoardList([data?.storyboards?.[0].sections[0].section_name]);
        }
    }

    const addToStoryBoardSelection = (event) => {
        const newList = [...event];
        setSelectedStoryBoardList(newList);
    }

    const closeStoryBoardModal = () => {
        setAddToStoryboardModalConfig({ isVisible: false });
        setSelectedStoryBoardList([]);
    }

    const addToStoryBoardHandler = async () => {
        setLoading(true);
        let imageData = "";
        const selectedDOMElement = document.getElementById(elementID);
        const storyboardId = storyBoardList.storyboards[0].story_board_id || storyBoardList.storyboards[0].storyboard_id;
        html2canvas(selectedDOMElement, { useCORS: true, logging: true }).then(canvas => {
            imageData = canvas.toDataURL("image/png");

            const payload = {
                storyboards: [
                    {
                        storyboard_id: "",
                        sections: []
                    }
                ],
                analysis_id: selectedAnalysisData?.analysis_id
            };

            payload.storyboards[0].storyboard_id = storyboardId;

            selectedStoryBoardList.forEach((item, index) => {
                payload.storyboards[0].sections[index] = {
                    section_elements: []
                };
                const idx = storyBoardList.storyboards[0].sections.findIndex((element) => element.section_name === item);
                payload.storyboards[0].sections[index].section_id = storyBoardList.storyboards[0].sections[idx].section_id;
                payload.storyboards[0].sections[index].section_name = storyBoardList.storyboards[0].sections[idx].section_name;
                payload.storyboards[0].sections[index].section_elements.push({
                    element_id: "",
                    edit: false,
                    element_type: "image",
                    content: imageData.split(',')[1],
                    properties: {
                        search: SearchCriteria ? SearchCriteria : '',
                        tab: currentTab ? currentTab : ''
                    }
                });
            })
            callStoryBoardAPI(payload);
        });
    }

    const callStoryBoardAPI = async (payload) => {
        const data = await addToStoryBoard(payload);
        if (data) {
            setAddToStoryboardModalConfig({ isVisible: false });
            setSelectedStoryBoardList([]);
        } else {
            // show error message
        }
        setLoading(false);
    }

    const onAddToStoryBoardClick = () => {
        if (selectedAnalysisData?.analysis_id) {
            setAddToStoryboardModalConfig({ isVisible: true })
        } else {
            createNewAnalysisAndSaveStoryboard();
        }
    };

    const createNewAnalysisAndSaveStoryboard = () => {
        try {
            let imageData = "";
            const selectedDOMElement = document.getElementById(elementID);
            html2canvas(selectedDOMElement, { useCORS: true, logging: true }).then(canvas => {
                imageData = canvas.toDataURL("image/png");
                const analysisData = {
                    analysisName: SearchCriteria || null,
                    therapyArea: null,
                    description: null
                };
                dispatch(toggleSaveNewAnalysis({
                    isOpen: true, mode: 'add',
                    source: 'add-to-storyboard',
                    analysisData,
                    imageData,
                    currentTab
                }));
            });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <Tooltip placement="top" title={'Add to Storyboard'} color={'#d9d9d9'}>
                <Button className="add-to-storyboard-btn" data-html2canvas-ignore="true" type="default" onClick={() => onAddToStoryBoardClick()} icon={<StoryboardIcon />} size="default">
                    &nbsp; Add to Storyboard
                </Button></Tooltip>
            <Modal
                title={`Add to Storyboard`}
                centered
                open={addToStoryboardModalConfig.isVisible}
                onOk={() => addToStoryBoardHandler()}
                onCancel={() => closeStoryBoardModal()}
                okText="Add"
                okButtonProps={{
                    disabled: selectedStoryBoardList.length <= 0 ?
                        storyBoardList?.storyboards?.[0].sections.length == 1 ? false : true
                        : false,
                    loading: loading
                }}
            >
                <Row>
                    <Col span={24}>
                        <h4>Select the Storyboard section(s) to import the file to it.</h4>
                        <Select
                            defaultValue={storyBoardList?.storyboards?.[0].sections.length == 1 ? storyBoardList?.storyboards?.[0].sections[0].section_name : selectedStoryBoardList}
                            value={storyBoardList?.storyboards?.[0].sections.length == 1 ? storyBoardList?.storyboards?.[0].sections[0].section_name : selectedStoryBoardList}
                            mode="multiple"
                            className='add-storyboard-modal'
                            maxTagCount={'responsive'}
                            showArrow={storyBoardList?.storyboards?.[0].sections.length == 1 ? false : true}
                            onChange={(e) => addToStoryBoardSelection(e)}
                        >
                            {
                                storyBoardList?.storyboards?.length && storyBoardList?.storyboards?.[0]?.sections.length > 0 && storyBoardList?.storyboards?.[0]?.sections.map((option) =>
                                    <Option key={option.section_id} value={option.section_name}>{option.section_name}</Option>
                                )
                            }
                        </Select>
                    </Col>
                </Row>
            </Modal>
        </>
    )

};

export default AddToStoryBoardView;