import { Table } from "antd";
import React, { useEffect, useState } from "react";
import CompareStudiesTableData from "./CompareStudiesTableData";
import { useSelector } from "react-redux";
import CompareStudiesTableColumns from "./CompareStudiesTableColumns";
import "./CompareStudiesTable.scss";
import AddToStoryBoardView from "../../add-to-storyboard-view/AddToStoryBoardView";
import { DownArrowIcon, UpArrowIcon } from "../../../assets/icons/svg";
import { getTableHeightValue } from "../../../shared/utils/CommonUtil";

const CompareStudiesTable = () => {
  const {
    EICCommon: { compareStudiesData, studyListTableData, deleteCompareStudies },
  } = useSelector((state) => state);

  const [tableHeight, setTableHeight] = useState(getTableHeightValue(380, 350));
  const getTableHeight = () => {
    setTableHeight(getTableHeightValue(380, 350));
  };
  useEffect(() => {
    window.addEventListener("resize", getTableHeight);
    return () => window.removeEventListener("resize", getTableHeight);
  }, []);

  return (
    <>
      <strong style={{ padding: "15px", fontSize: "18px" }}>
        Compare Studies
      </strong>
      {compareStudiesData.selectData.length > 0 ? (
        <span style={{ float: "right", marginBottom: 10 }}>
          <AddToStoryBoardView
            elementID={"compare-snap"}
            currentTab={"compare_studies"}
          ></AddToStoryBoardView>
        </span>
      ) : (
        ""
      )}

      <div id="compare-snap">
        <Table
          scroll={{ y: tableHeight }}
          columns={CompareStudiesTableColumns()}
          dataSource={
            compareStudiesData.selectData.length > 0
              ? CompareStudiesTableData(
                  compareStudiesData.selectData,
                  studyListTableData
                )
              : []
          }
          expandable={{
            indentSize: 0,
            showExpandColumn: true,
            columnWidth: 100,
            expandIcon: ({ expanded, onExpand, record }) =>
              record && !record?.children ? (
                ""
              ) : expanded ? (
                <button
                  type="button"
                  className="ant-table-row-expand-icon-custom expand-icon-expanded"
                  aria-label="Expand row"
                  onClick={(e) => onExpand(record, e)}
                >
                  <UpArrowIcon />
                </button>
              ) : (
                <button
                  type="button"
                  class="ant-table-row-expand-icon-custom expand-icon-collapsed"
                  aria-label="Collapse row"
                  onClick={(e) => onExpand(record, e)}
                >
                  <DownArrowIcon />
                </button>
              ),
          }}
          rowClassName={(record) => {
            return record.className || "";
          }}
          pagination={false}
          sticky={true}
          id="compare-studies-id"
        />
      </div>
    </>
  );
};

export default CompareStudiesTable;
