export const EICInitialState = {
  searchTerm: "",
  state: "open",
  studyTableRowDataSelected: [],
  compareStudiesData: { selectKey: [], selectData: [] },
  selectedAdvanceFilters: {},
  excludedStudyIds: [],
  appliedGlobalFilters: [],
  evidenceLandscapeChartList: [],
  competitiveBarChartData: "",
  competitiveBarSubCatData: [],
  timelineChartData: "",
  timeLineSubCatData: [],
  competitiveBreakdownBy: [],
  availableFilterList: [],
  globalRawList: [],
  geoChartData: {
    geoPieChartValues: [],
    geoChartStudiesData: {},
    geoChartStudiesBreakDownData: [],
    searchStudy: "Endpoints",
    defaultBreakDownValue: [],
    checkBoxValues: [],
    setLoadingGeoData: false,
    countryCode: "",
    filteredDefaultValues: [],
    LoadingPieData: false,
  },
  searchSetLoading: false,
  studyListTableData: "",
  dropDownList: "",
  studyListPageNumber: 1,
  selectedRowKeysData: [],
  deleteCompareStudies: "",
  doNotShowCheckBox: false,
  retrieveSelectedKeys: { selectKey: [], selectData: [] },
  cleanupEvidenceLandscape: false,
  cleanupCompetitiveIntelligence: false,
  cleanupGeoDistribution: false,
  cleanupTimelineTrend: false,
  isReadFullTextModalConfig: { isOpen: false, id: 0, key: "", source: "" },
  storyBoardData: {},
  readFullTextDataList: [],
  storyBoardData: {},
  storyBoardID: "",
  isAnalysisSaveModalConfig: {
    isOpen: false,
    mode: "add",
    analysisData: null,
    source: "",
  },
  selectedAnalysisData: null,
  isAnalysisLoaded: false,
  analysisLastUpdatedTime: null,
  checkAnalysisSaveConfig: { isCheck: false, source: "", navToPath: null },
  advanceFilterDropdownList: {},
  IteractiveStudyListTableData: "",
  InteractiveStudyListPageNumber: 1,
  deleteInteractiveCompareStudies: "",
  retrieveInteractiveSelectedKeys: { selectKey: [], selectData: [] },
  compareStudiesDataInteractive: { selectKey: [], selectData: [] },
  // isInteractiveReadFullTextModalConfig : {isOpen: false, id: 0, key: '', source: ''},
  interactiveFilters: {},
  exploreViewMode: "study-list",
  isExcludeStudyConfig: { isOpen: false, newExcludeStudyIds: [] },
  isStoryboardOwner: true,
  checkCollaboratorConfirm: { isCheck: false, source: "", navToPath: null },
  checkCollaboratorModal: { isOpen: false },
  isComingFromCollaboratorView: false,
  userID: "",
  checkCollaboratorDetails: { edit_access: '', lock: '', read_access: '', section_id: '', storyboard_id: ''}
};