import React, { useEffect, useState } from "react";
// import Highcharts from 'highcharts';
import * as Highcharts from "highcharts/highmaps";
import mapData from "./GeoMapData.js";
import "./GeographicDistributionContainer.scss";
import Icon from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  geoChartFilterStudiesByBreakdown,
  geoChartStudiesDataReducer,
  geoChartStudiesSearchValue,
  geoPieChartData,
  setCheckBoxValues,
  setCountryCode,
} from "../../../reducer/EICCommonSlice.js";
import { Button, Checkbox, Col, Dropdown, Menu, Row, Select, Spin } from "antd";
import { DrugInfo } from "../../../assets/icons/svg.js";
import "./GeographicMap.scss";
import { Tooltip } from "antd";
import { getGeoMapData } from "../../../services/InsightsViewAPI.js";
const { Option } = Select;

const baseMapPath = "https://code.highcharts.com/mapdata/";

function GeographicMap({ data }) {
  const [countryKey, setCountryKey] = useState("");
  const [multiSelectValue, setMultiSelectValue] = useState("");
  const {
    EICCommon: {
      geoChartData: {
        geoChartStudiesBreakDownData,
        checkBoxValues,
        searchStudy,
        defaultBreakDownValue,
        geoPieChartValues,
        countryCode,
        filteredDefaultValues,
      },
      dropDownList,
      searchTerm,
      state,
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("resize", rerenderChart);
    return () => window.removeEventListener("resize", rerenderChart);
  }, []);

  const rerenderChart = () => {
    buildGeoChart();
  };

  useEffect(() => {
    buildGeoChart();
    if (countryCode !== "") {
      dispatch(geoPieChartData(geographicPieChartData(data, countryKey)));
    }
    if (
      geoChartStudiesBreakDownData === undefined ||
      geoChartStudiesBreakDownData.length === 0
    ) {
      dispatch(geoChartFilterStudiesByBreakdown(checkBoxValues));
    }
  }, [data, geoChartStudiesBreakDownData, searchStudy, state]);

  useEffect(() => {
    if (countryCode === "") {
      dispatch(geoChartFilterStudiesByBreakdown(checkBoxValues));
    }
  }, []);

  const geographicChartData = (data) => {
    const countResult =
      data?.length > 0 &&
      data.map((curr) => {
        let acc = 0;

        acc = acc.entity_count > curr.entity_count ? acc : curr;

        return acc;
      });

    return (
      countResult?.length > 0 &&
      countResult.reduce((arr, curr) => {
        arr.push([curr.entity_code, curr.entity_count]);
        return arr;
      }, [])
    );
  };

  const geographicPieChartData = (data, geoData) => {
    return (
      data?.length > 0 &&
      data.reduce((arr, curr) => {
        if (geoData === curr.entity_code) {
          arr = curr;
        }
        return arr;
      }, {})
    );
  };

  const handleSelect = (value) => {
    dispatch(geoChartFilterStudiesByBreakdown(value));
  };

  const onPointClick = (e) => {
    const pieChartDataKey = e.point["hc-key"];
    setCountryKey(pieChartDataKey);
    dispatch(setCountryCode(pieChartDataKey));
    dispatch(geoPieChartData(geographicPieChartData(data, pieChartDataKey)));
  };

  const buildGeoChart = () => {
    Highcharts.mapChart("geo-tab-container", {
      chart: {
        map: mapData,
        height: (9 / 16 * 75) + '%'
      },
      credits: {
        enabled: false,
      },
      colors: [
        "rgb(222,220,222)",
        "rgb(218,239,254)",
        "rgb(124,192,255)",
        "rgb(2,122,255)",
        "rgb(5,36,164)",
      ],

      title: {
        text: "",
      },

      subtitle: {
        text: "",
      },

      mapNavigation: {
        enabled: true,
        buttonOptions: {
          align: "right",
        },
      },

      colorAxis: {
        dataClasses: [
          {
            from: 0,
            to: 0,
            name: "Data Not Available",
          },
          {
            to: 100,
          },
          {
            from: 100,
            to: 200,
          },
          {
            from: 200,
            to: 500,
          },
          {
            from: 500,
          },
        ],
      },

      legend: {
        title: {
          text: "# of Studies",
          style: {
            color:
              // theme
              (Highcharts.defaultOptions &&
                Highcharts.defaultOptions.legend &&
                Highcharts.defaultOptions.legend.title &&
                Highcharts.defaultOptions.legend.title.style &&
                Highcharts.defaultOptions.legend.title.style.color) ||
              "#454250",
            fontWeight: 700,
            fontSize: 14,
            lineHeight: 22,
          },
        },
        align: "center",
        verticalAlign: "bottom",
        floating: false,
        layout: "horizontal",
        valueDecimals: 0,
        backgroundColor:
          // theme
          (Highcharts.defaultOptions &&
            Highcharts.defaultOptions.legend &&
            Highcharts.defaultOptions.legend.backgroundColor) ||
          "rgba(255, 255, 255, 0.85)",
        symbolRadius: 10,
        symbolHeight: 14,
        itemStyle: {
          fontWeight: 400,
          fontSize: 13,
          lineHeight: 22,
          color: "#454250",
          fontFamily: "Open Sans",
          fontStyle: "normal",
        },
        alignColumns: false,
      },

      series: [
        {
          data: geographicChartData(data),
          name: "",
          tooltip: {
            headerFormat: "",
            pointFormat: '{point.name}: {point.value:f}',
          },
          states: {
            hover: {
              color: "#BADA55",
            },
          },
          dataLabels: {
            enabled: false,
            format: "{point.name}",
          },
          point: {
            events: {
              click: onPointClick,
            },
          },
        },
      ],
      plotOptions: {
        heatmap: {
          point: {
            events: {
              legendItemClick: () => {
                return false;
              },
            },
          },
        },
      },
    });
  };

  return (
    <div id="geo-map-id" style={{ width: "100%" }}>
      <div id="geo-tab-container"></div>
    </div>
  );
}

export default GeographicMap;
